import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArabFlag,
  BahasaFlag,
  ChinaFlag,
  GermanyFlag,
  IndiaFlag,
  JapanFlag,
  RadioButton,
  SpanishFlag,
  USFlag,
} from "components/newSVG";
import { Circle, Plus, Trash, Trash2 } from "react-feather";
import styles from "./index.module.css";
import BotVoices from "../BotVoices";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { languages } from "text-constants/common";
import FunCollapse from "components/FunCollapse";
import { useUserContext } from "context/user";
import FlexBox from "components/FlexBox";
import theme from "theme";
import defaultBotVoices from "utils/botVoices";
import DeleteModal from "components/DeleteModal";

const languageImageMapping = {
  "en-US": <USFlag />,
  "hi-IN": <IndiaFlag />,
  "id-ID": <BahasaFlag />,
  "es-ES": <SpanishFlag />,
  "mr-IN": <IndiaFlag />,
  "ml-IN": <IndiaFlag />,
  "pa-IN": <IndiaFlag />,
  "ta-IN": <IndiaFlag />,
  "bn-IN": <IndiaFlag />,
  "te-IN": <IndiaFlag />,
  "gu-IN": <IndiaFlag />,
  "ar-XA": <ArabFlag />,
  "de-DE": <GermanyFlag />,
  "ja-JP": <JapanFlag />,
  "cmn-CN": <ChinaFlag />,
};

const Personality = (props) => {
  const { onSave, data, defaultLanguage, defaultVoice } = props;

  const { control, watch, setValue } = useForm({
    defaultValues: {
      mode: "single",
      language: languages.find((l) =>
        l.value.startsWith(data?.botVoice?.languageCode.split("-")[0])
      ).value,
      botVoice: data?.botVoice?.name,
      botProvider: "",
      bargeIn: data?.bargeIn,
      instruction: data?.instruction,
      selectedLanguage: "",
      botVoices: data?.botVoices,
    },
  });

  const botVoices = watch("botVoices");
  const { companyDetails } = useUserContext();
  const [addLanguageModal, setAddLanguageModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteVoice, setDeleteVoice] = useState(null);
  const preProcessMessage = companyDetails.preProcessMessage;

  // const handleOnSaveBotVoice = ({ botVoice, botProvider }) => {
  //   setValue("botVoice", botVoice, { shouldValidate: true });
  //   setValue("botProvider", botProvider, { shouldValidate: true });
  // };

  const handleOnSaveBotVoice = ({ botVoice, botProvider, languageCode }) => {
    const updatedBotVoices = botVoices.map((voice) =>
      voice.languageCode === languageCode
        ? {
            ...voice,
            name: botVoice,
            elevenLab: botProvider === "ElevenLab",
          }
        : voice
    );

    if (
      !updatedBotVoices.some((voice) => voice.languageCode === languageCode)
    ) {
      updatedBotVoices.push({
        languageCode,
        name: botVoice,
        elevenLab: botProvider === "ElevenLab",
        default: false,
      });
    }

    setValue("botVoices", updatedBotVoices);
  };

  const watchOnAllFields = () => {
    const watchSubscription = watch((value, fields) => {
      onSave({
        // botVoice: {
        //   languageCode: value?.botVoice.split("-").slice(0, 2).join("-"),
        //   name: value?.botVoice,
        //   elevenLab: value?.botProvider === "ElevenLab",
        // },
        bargeIn: value?.bargeIn,
        mode: "single",
        instruction: value?.instruction,
        botVoices: value.botVoices,
      });
    });
    return watchSubscription;
  };

  const handleAddLanguage = () => {
    const newLanguageCode = watch("selectedLanguage");
    const languageName = defaultBotVoices.find(
      (l) => l.languageCode === newLanguageCode
    ).name;
    if (
      newLanguageCode &&
      !botVoices.some((voice) => voice.languageCode === newLanguageCode)
    ) {
      setValue("botVoices", [
        ...botVoices,
        {
          languageCode: newLanguageCode,
          name: languageName,
          elevenLab: false,
          default: false,
        },
      ]);
      setAddLanguageModal(false);
      setValue("selectedLanguage", "");
    }
  };

  const handleDeleteVoice = () => {
    const updatedBotVoices = botVoices.filter(
      (v) => v.languageCode !== deleteVoice.languageCode
    );
    setValue("botVoices", updatedBotVoices);
    setDeleteModal(false);
  };

  useEffect(() => {
    const subscription = watchOnAllFields();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Box className={styles.container}>
      <Box>
        <Typography variant="body2" fontWeight={500}>
          Pick the virtual agent voice
        </Typography>
        <Typography variant="caption">
          Choose how you want your agent to sound
        </Typography>
      </Box>
      <Box sx={{ pointerEvents: "none", opacity: 0.6 }}>
        <Controller
          control={control}
          name="mode"
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="virtual-agent-voice"
                onChange={onChange}
                value={value}
              >
                <FormControlLabel
                  value="single"
                  control={
                    <Radio
                      checked={value === "single"}
                      icon={<Circle width={12} height={12} />}
                      checkedIcon={<RadioButton width={12} height={12} />}
                      sx={{ "& > span": { padding: "0 !important" } }}
                    />
                  }
                  label={
                    <Typography variant="caption" color="#0f0f0f">
                      Single Language
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="auto"
                  control={
                    <Radio
                      checked={value === "auto"}
                      icon={<Circle width={12} height={12} />}
                      checkedIcon={<RadioButton width={12} height={12} />}
                      sx={{ "& > span": { padding: "0 !important" } }}
                    />
                  }
                  label={
                    <Typography variant="caption" color="#0f0f0f">
                      Auto Detect
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
      {/* <Controller
        control={control}
        name="language"
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={onChange}
            value={value}
            disabled={preProcessMessage}
            placeholder="Select a language"
            className={styles.dropDown}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "40vh",
                  width: "40vw",
                },
              },
            }}
          >
            {languages.map((l) => (
              <MenuItem value={l.value} sx={{ gap: 3 }} key={l.value}>
                {languageImageMapping[l.value]}
                <Typography variant="body2">{l.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        )}
      /> */}

      {botVoices.map((voice) => {
        const language = languages.find((l) => l.value === voice.languageCode);
        return (
          <Box
            key={voice.languageCode}
            sx={{
              border: `1px solid ${theme.palette.borderColor.light}`,
              padding: voice.default ? "22px 16px" : "14px 16px",
              borderRadius: 4,
            }}
          >
            <FunCollapse
              iconPosition="end"
              header={
                <FlexBox columnGap={2}>
                  {languageImageMapping[voice.languageCode]}
                  <Typography variant="body2" fontWeight={500}>
                    {language?.name}
                  </Typography>
                  <Typography variant="caption">{voice.default}</Typography>

                  {!voice.default && (
                    <IconButton
                      sx={{ ml: "auto" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteModal(true);
                        setDeleteVoice(voice);
                      }}
                    >
                      <Trash2 width={16} height={16} />
                    </IconButton>
                  )}
                </FlexBox>
              }
            >
              <Box
                sx={
                  preProcessMessage
                    ? {
                        pointerEvents: "none",
                        opacity: 0.6,
                      }
                    : {}
                }
                style={{ marginTop: 16 }}
              >
                {/* <BotVoices
                onSave={handleOnSaveBotVoice}
                defaultVoice={defaultVoice}
                defaultLanguage={defaultLanguage}
                selectedLanguage={l.value}
              /> */}

                <BotVoices
                  onSave={(voiceData) =>
                    handleOnSaveBotVoice({
                      ...voiceData,
                      languageCode: voice.languageCode,
                    })
                  }
                  defaultVoice={voice.name}
                  defaultLanguage={voice.languageCode}
                  selectedLanguage={voice.languageCode}
                />
              </Box>
            </FunCollapse>
          </Box>
        );
      })}

      <Button
        color="inherit"
        variant="text"
        endIcon={<Plus width={20} height={20} />}
        sx={{ minWidth: 0, width: "max-content" }}
        onClick={() => setAddLanguageModal(true)}
      >
        Add language
      </Button>

      <Box mt={6} sx={{ pointerEvents: "none", opacity: 0.6 }}>
        <Typography variant="body2">Allow customer to barge-in?</Typography>
        <Typography variant="caption">
          Choose whether the customer can interrupt the agent midway or not
        </Typography>
        <Box>
          <Controller
            control={control}
            name="bargeIn"
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="virtual-agent-voice"
                  // onChange={onChange}
                  onChange={(e) => onChange(e.target.value === "true")}
                  value={value}
                >
                  <FormControlLabel
                    value={"false"}
                    control={
                      <Radio
                        checked={value === false}
                        icon={<Circle width={12} height={12} />}
                        checkedIcon={<RadioButton width={12} height={12} />}
                        sx={{ "& > span": { padding: "0 !important" } }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="#0f0f0f">
                        Don't Allow
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={"true"}
                    control={
                      <Radio
                        checked={value === true}
                        icon={<Circle width={12} height={12} />}
                        checkedIcon={<RadioButton width={12} height={12} />}
                        sx={{ "& > span": { padding: "0 !important" } }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="#0f0f0f">
                        Allow
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
      </Box>

      <Dialog
        open={addLanguageModal}
        onClose={() => setAddLanguageModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 5,
          },
        }}
      >
        <Box sx={{ padding: "40px 20px" }}>
          <Typography variant="h3" fontWeight={500} textAlign={"center"}>
            Add language
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            <Controller
              control={control}
              name="selectedLanguage"
              rules={{ required: "Language is required" }}
              render={({ field: { onChange, value } }) => (
                <Select
                  displayEmpty
                  onChange={onChange}
                  value={value}
                  fullWidth
                  placeholder="Select a language"
                  className={styles.select}
                  sx={{ mt: 2, mb: 4 }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: 0,
                        boxShadow: "none",
                        backgroundColor: theme.palette.grey[50],
                        maxHeight: "40vh",
                      },
                    },
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <Typography variant="body2">
                          Select a language
                        </Typography>
                      );
                    }

                    return (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {languageImageMapping[selected]}
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          {languages.find((l) => l.value === selected).name}
                        </Typography>
                      </Box>
                    );
                  }}
                >
                  {languages
                    .filter(
                      (l) =>
                        !botVoices.some(
                          (voice) => voice.languageCode === l.value
                        )
                    )
                    .map((l) => (
                      <MenuItem
                        value={l.value}
                        sx={{ gap: 3 }}
                        key={l.value}
                        classes={{ selected: styles.selected }}
                      >
                        {languageImageMapping[l.value]}
                        <Typography variant="body2">{l.name}</Typography>
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </Box>

          <FlexBox sx={{ mt: 2 }} justifyContent={"center"}>
            <Button
              variant="whiteRound"
              onClick={() => setAddLanguageModal(false)}
            >
              Cancel
            </Button>

            <Button variant="dark" onClick={handleAddLanguage}>
              Add
            </Button>
          </FlexBox>
        </Box>
      </Dialog>

      <DeleteModal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={() => {
          handleDeleteVoice();
        }}
        title="Delete voice"
        subtitle="Are you sure you want to delete this voice?"
      />
    </Box>
  );
};

export default Personality;
