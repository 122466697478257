import {
  Box,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  Grow,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import theme from "theme";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { useSkillrToast } from "context/toast";
import getParentCategoriesApi from "services/getParentCategories";
import FunCollapse from "components/FunCollapse";
import FlexBox from "components/FlexBox";
import { ChevronDown, Circle, Loader, Square } from "react-feather";
import { CheckSquare, RadioButton, SquareMinus } from "components/newSVG";
import { Controller, useForm } from "react-hook-form";

const AdvancedSettings = (props) => {
  const { workFlows, data, onSave } = props;
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showSkillrToast } = useSkillrToast();
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [advancedSettings, setAdvancedSettings] = useState({});

  const { control, watch } = useForm({
    defaultValues: {
      inactiveCategoriesDefaultWorkFlow:
        data?.inactiveCategoriesDefaultWorkFlow,
      // categories: data?.categories,
      responseWaitTime: data?.responseWaitTime,
      responseTimeMaxTries: data?.responseTimeMaxTries,
      waitTimeFallbackWorkFlow: data?.waitTimeFallbackWorkFlow,
      responseTimeExceededWorkFlow: data?.responseTimeExceededWorkFlow,
      intentDigression: data?.intentDigression,
      waitTimeFallbackFlowType: data?.waitTimeFallbackFlow.type,
      waitTimeFallbackFlowMessage: data?.waitTimeFallbackFlow.message,
      digressionThreshold: data?.digressionThreshold,
    },
  });

  const getParentCategories = async () => {
    setLoading(true);
    try {
      const allParentCategories = await getParentCategoriesApi();
      setAllCategories(allParentCategories);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleCheckboxChange = (categoryId, subcategoryId = null) => {
    setCheckedCategories((prevState) => {
      let newState = [...prevState];
      if (subcategoryId) {
        // If a subcategory checkbox is clicked
        const index = newState.indexOf(subcategoryId);
        if (index > -1) {
          newState.splice(index, 1);
        } else {
          newState.push(subcategoryId);
        }
      } else {
        // If a category checkbox is clicked

        const subCategories = allCategories
          .find((category) => category._id === categoryId)
          .categories.map((subCategory) => subCategory._id);
        if (subCategories.every((subCatId) => newState.includes(subCatId))) {
          newState = newState.filter((id) => !subCategories.includes(id));
        } else {
          // Only add a subcategory if it's not already included in newState
          subCategories.forEach((subCatId) => {
            if (!newState.includes(subCatId)) {
              newState.push(subCatId);
            }
          });
        }
      }
      return newState;
    });
  };

  useEffect(() => {
    getParentCategories();
    if (data?.categories) {
      setCheckedCategories(data.categories);
    }
  }, []);

  const watchOnAllFields = () => {
    const watchSubscription = watch((value, fields) => {
      onSave({
        inactiveCategoriesDefaultWorkFlow:
          value?.inactiveCategoriesDefaultWorkFlow,
        responseWaitTime: value?.responseWaitTime,
        responseTimeMaxTries: value?.responseTimeMaxTries,
        waitTimeFallbackWorkFlow: value?.waitTimeFallbackWorkFlow,
        responseTimeExceededWorkFlow: value?.responseTimeExceededWorkFlow,
        intentDigression: value?.intentDigression,
        categories: checkedCategories,
        waitTimeFallbackFlow: {
          type: value?.waitTimeFallbackFlowType,
          message: value?.waitTimeFallbackFlowMessage,
        },
        digressionThreshold: value?.digressionThreshold,
      });
    });
    return watchSubscription;
  };

  useEffect(() => {
    const subscription = watchOnAllFields();
    return () => {
      subscription.unsubscribe();
    };
  }, [checkedCategories]);

  useEffect(() => {
    onSave({
      categories: checkedCategories,
    });
  }, [checkedCategories]);

  return (
    <Box className={styles.container}>
      <Box>
        <Typography variant="body2" fontWeight={500}>
          Active Categories
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.text.primary}
          sx={{
            opacity: 0.8,
            marginTop: 1,
            marginBottom: 2,
            display: "inline-block",
          }}
        >
          Select the categories for which the virtual agent is active
        </Typography>

        <Box
          className={styles.categoriesContainer}
          onChange={() => {
            setAdvancedSettings((prevData) => ({
              ...prevData,
              categories: checkedCategories,
            }));

            // onSave({ advancedSettings: { categories: checkedCategories } });
          }}
        >
          {loading && (
            <Box
              sx={{
                height: "150px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader width={32} height={32} color="#555" />
            </Box>
          )}
          {allCategories?.map((category) => (
            <Box
              key={category._id}
              className={styles.category}
              style={
                category.categories.length === 0
                  ? { pointerEvents: "none", opacity: 0.6 }
                  : {}
              }
            >
              <FunCollapse
                iconPosition={"start"}
                sx={{
                  borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                }}
                header={
                  <FlexBox columnGap={1} sx={{}}>
                    <label>
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        icon={<Square width={12} height={12} />}
                        checkedIcon={<CheckSquare width={12} height={12} />}
                        onChange={() => handleCheckboxChange(category._id)}
                        indeterminate={
                          !category.categories.every((subCategory) =>
                            checkedCategories.includes(subCategory._id)
                          ) &&
                          category.categories.some((subCategory) =>
                            checkedCategories.includes(subCategory._id)
                          )
                        }
                        checked={
                          category.categories.length &&
                          category.categories.every((subCategory) =>
                            checkedCategories.includes(subCategory._id)
                          )
                        }
                        indeterminateIcon={
                          <SquareMinus width={12} height={12} />
                        }
                        disabled={category.categories.length === 0}
                      />
                      <Typography
                        variant="caption"
                        fontWeight={400}
                        color={"text.primary"}
                      >
                        {category.name}
                      </Typography>
                    </label>
                  </FlexBox>
                }
              >
                {category?.categories?.map((subCategory) => (
                  <Box className={styles.subCategory} key={subCategory._id}>
                    <label>
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          handleCheckboxChange(category._id, subCategory._id)
                        }
                        icon={<Square width={12} height={12} />}
                        checkedIcon={<CheckSquare width={12} height={12} />}
                        checked={checkedCategories.includes(subCategory._id)}
                      />
                      <Typography
                        variant="caption"
                        fontWeight={400}
                        color={"text.primary"}
                      >
                        {subCategory.name}
                      </Typography>
                    </label>
                  </Box>
                ))}
              </FunCollapse>
            </Box>
          ))}
        </Box>
      </Box>

      <Box>
        <Typography variant="body2" fontWeight={500}>
          Default behaviour (for inactive categories)
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.text.primary}
          sx={{
            opacity: 0.8,
            marginTop: 1,
            marginBottom: 2,
            display: "inline-block",
          }}
        >
          Set the default behavior for inactive categories
        </Typography>

        <Box className={styles.workflowContainer}>
          <Box className={styles.workFlowText}>
            <Typography variant="body2" color={"#0f0f0f"}>
              Trigger Workflow
            </Typography>
          </Box>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="inactiveCategoriesDefaultWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {workFlows.map((workFlow) => (
                    <MenuItem
                      value={workFlow._id}
                      sx={{ gap: 3 }}
                      key={workFlow._id}
                    >
                      <Typography variant="body2">{workFlow?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography variant="body2" color="#0f0f0f" fontWeight={500}>
          Wait Time
        </Typography>
        <Typography
          variant="caption"
          color="#0f0f0f"
          sx={{
            opacity: 0.8,
            marginTop: 1,
            marginBottom: 2,
            display: "inline-block",
          }}
        >
          How long will the virtual agent wait for a response
        </Typography>

        <FlexBox>
          <Controller
            control={control}
            name="responseWaitTime"
            rules={{
              min: 1,
              max: 60,
              validate: (value) =>
                (value >= 1 && value <= 60) ||
                "Please enter a number between 1 and 60",
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                sx={{ marginTop: 2 }}
                className={styles.numberOfTries}
                // onChange={onChange}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue >= 1 && newValue <= 60) {
                    onChange(e);
                  }
                }}
                value={value}
                type="number"
                inputProps={{
                  min: 1,
                  max: 60,
                }}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />

          <Typography variant="body2">second(s)</Typography>
        </FlexBox>
      </Box>

      <Box>
        <Typography variant="body2" fontWeight={500}>
          Wait Time Fallback Flow
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.text.primary}
          sx={{
            opacity: 0.8,
            marginTop: 1,
            marginBottom: 2,
            display: "inline-block",
          }}
        >
          What happens if the wait time is exceeded{" "}
        </Typography>

        <Box className={styles.workflowContainer} sx={{ alignItems: "center" }}>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="waitTimeFallbackFlowType"
              // name="responseTimeExceededWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  <MenuItem value={"lastMessage"} sx={{ gap: 3 }}>
                    <Typography variant="body2">Play last message</Typography>
                  </MenuItem>
                  <MenuItem value={"waitTimeFiller"} sx={{ gap: 3 }}>
                    <Typography variant="body2">
                      Play wait time filler
                    </Typography>
                  </MenuItem>
                </Select>
              )}
            />
          </Box>
          {watch("waitTimeFallbackFlowType") === "customMessage" && (
            <Box className={styles.workFlowDropDown}>
              <Controller
                control={control}
                name="waitTimeFallbackFlowMessage"
                render={({ field: { onChange, value } }) => (
                  <TextField fullWidth onChange={onChange} value={value} />
                )}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box>
        <Typography variant="body2" color="#0f0f0f" fontWeight={500}>
          Number Of Tries
        </Typography>
        <Typography
          variant="caption"
          color="#0f0f0f"
          sx={{
            opacity: 0.8,
            marginTop: 1,
            marginBottom: 2,
            display: "inline-block",
          }}
        >
          Number of tries for the wait time fallback flow
        </Typography>

        <Box>
          <Controller
            control={control}
            name="responseTimeMaxTries"
            rules={{
              min: 1,
              max: 60,
              validate: (value) =>
                (value >= 1 && value <= 60) ||
                "Please enter a number between 1 and 60",
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                sx={{ marginTop: 2 }}
                className={styles.numberOfTries}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue >= 1 && newValue <= 60) {
                    onChange(e);
                  }
                }}
                value={value}
                type="number"
                inputProps={{
                  min: 1,
                  max: 60,
                }}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>
      </Box>

      <Box>
        <Typography variant="body2" fontWeight={500}>
          Try Exceeded
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.text.primary}
          sx={{
            opacity: 0.8,
            marginTop: 1,
            marginBottom: 2,
            display: "inline-block",
          }}
        >
          What happens if the number of tries for wait time fallback flow exceed{" "}
        </Typography>
        <Box className={styles.workflowContainer}>
          <Box className={styles.workFlowText}>
            <Typography variant="body2" color={"#0f0f0f"}>
              Trigger Workflow
            </Typography>
          </Box>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="responseTimeExceededWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {workFlows.map((workFlow) => (
                    <MenuItem
                      value={workFlow._id}
                      sx={{ gap: 3 }}
                      key={workFlow._id}
                    >
                      <Typography variant="body2">{workFlow?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>
        {/* <Box className={styles.workflowContainer}>
          <Box className={styles.workFlowText}>
            <Typography variant="body2" color={"#0f0f0f"}>
              Trigger Workflow
            </Typography>
          </Box>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="waitTimeFallbackWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {workFlows.map((workFlow) => (
                    <MenuItem
                      value={workFlow._id}
                      sx={{ gap: 3 }}
                      key={workFlow._id}
                    >
                      <Typography variant="body2">{workFlow?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box> */}
      </Box>

      <Box>
        <Box>
          <Typography variant="body2" fontWeight={500}>
            Intent Digression
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.text.primary}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              display: "inline-block",
            }}
          >
            Actively listen for intent digression during an ongoing
            conversation?
          </Typography>
        </Box>
        <Box>
          <Controller
            control={control}
            name="intentDigression"
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="virtual-agent-voice"
                  // onChange={onChange}
                  onChange={(e) => onChange(e.target.value === "true")}
                  value={value}
                >
                  <FormControlLabel
                    value={"false"}
                    control={
                      <Radio
                        checked={value === false}
                        icon={<Circle width={12} height={12} />}
                        checkedIcon={<RadioButton width={12} height={12} />}
                        sx={{ "& > span": { padding: "0 !important" } }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="#0f0f0f">
                        Don't Allow
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={"true"}
                    control={
                      <Radio
                        checked={value === true}
                        icon={<Circle width={12} height={12} />}
                        checkedIcon={<RadioButton width={12} height={12} />}
                        sx={{ "& > span": { padding: "0 !important" } }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="#0f0f0f">
                        Allow
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
      </Box>

      <Grow in={watch("intentDigression")} unmountOnExit mountOnEnter>
        <Box>
          <Box>
            <Typography variant="body2" fontWeight={500}>
              Digression Threshold
            </Typography>
            <Typography
              variant="caption"
              color={theme.palette.text.primary}
              sx={{
                opacity: 0.8,
                marginBottom: 2,
                display: "inline-block",
              }}
            >
              Set the sensitivity for digression while the virtual agent is
              already answering an intent
            </Typography>
          </Box>
          <Box sx={{ maxWidth: "98%", margin: "0 auto" }}>
            <Controller
              control={control}
              name="digressionThreshold"
              render={({ field: { onChange, value } }) => (
                <Slider
                  min={0}
                  max={0.25}
                  valueLabelDisplay="auto"
                  step={0.01}
                  sx={{
                    "& .MuiSlider-rail": { backgroundColor: "#EFEFEF" },
                    "& .MuiSlider-track": {
                      backgroundColor: "#00C8C2",
                      border: 0,
                    },
                    "& .MuiSlider-thumb": {
                      backgroundColor: "#fff",
                      width: 20,
                      height: 20,
                    },
                    marginBottom: 0,
                  }}
                  marks={[
                    {
                      value: 0,
                      label: (
                        <Typography variant="body2" fontWeight={500}>
                          0
                        </Typography>
                      ),
                    },
                    {
                      value: 0.25,
                      label: (
                        <Typography variant="body2" fontWeight={500}>
                          0.25
                        </Typography>
                      ),
                    },
                  ]}
                  value={value}
                  onChange={(e, value) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Grow>
    </Box>
  );
};

export default AdvancedSettings;
