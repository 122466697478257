import { Box, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.css";

const Settings = () => {
  const location = useLocation();
  const currentRoute = location.pathname.split("/")[2];
  const navigate = useNavigate();

  return (
    <PageWrapper className={styles.container}>
      {/* <Typography textAlign="center" variant="h3" fontWeight={500} mb={4}>
        Settings
      </Typography> */}

      {/* <Box className={styles.tabsContainer}>
        <Box
          className={`${styles.tab} ${
            currentRoute === "user-management" ? styles.active : ""
          }`}
          onClick={() => navigate("user-management")}
        >
          <Typography variant="body2" fontWeight={500} color="grey.600">
            User Management
          </Typography>
        </Box>
        <Box
          className={`${styles.tab} ${
            currentRoute === "assistant-settings" ? styles.active : ""
          }`}
          onClick={() => navigate("assistant-settings")}
        >
          <Typography variant="body2" fontWeight={500} color="grey.600">
            Assistant Settings
          </Typography>
        </Box>
      </Box> */}
      <Outlet />
    </PageWrapper>
  );
};

export default Settings;
