export const statusColorMapping = {
  active: {
    backgroundColor: "#F2FDF9",
    color: "#00C8C2",
  },
  paused: {
    backgroundColor: "#FEEBEF",
    color: "#A01E1E",
  },
  draft: {
    backgroundColor: "#FFF4CB",
    color: "#8A6C00",
  },
  complete: {
    backgroundColor: "#E3F2FF",
    color: "#0094FF",
  },
  pending: {
    backgroundColor: "#E3F2FF",
    color: "#0094FF",
  },
  invalidNumber: {
    backgroundColor: "#FEEBEF",
    color: "#A01E1E",
  },
  answered: {
    backgroundColor: "#E8F5E9",
    color: "#388E3C",
  },
  noAnswered: {
    backgroundColor: "#FEEBEF",
    color: "#A01E1E",
  },
  busy: {
    backgroundColor: "#FEEBEF",
    color: "#A01E1E",
  },
  networkError: {
    backgroundColor: "#FEEBEF",
    color: "#A01E1E",
  },
  processing: {
    backgroundColor: "#FFF4CB",
    color: "#8A6C00",
  },
  undefinedError: {
    backgroundColor: "#FEEBEF",
    color: "#A01E1E",
  },
  rejected: {
    backgroundColor: "#FEEBEF",
    color: "#A01E1E",
  },
  incorrectFormat: {
    backgroundColor: "#FEEBEF",
    color: "#A01E1E",
  },
};

export const statusTextMapping = {
  active: "Active",
  paused: "Paused",
  draft: "Draft",
  complete: "Complete",
  pending: "Pending",
  invalidNumber: "Invalid Number",
  answered: "Answered",
  noAnswered: "Not Answered",
  busy: "Busy",
  networkError: "Network Error",
  processing: "Processing",
  undefinedError: "Undefined Error",
  rejected: "Rejected",
  incorrectFormat: "Incorrect Format",
};

export const defaultOutcomes = [
  { id: "Not Answered", name: "noAnswered" },
  { id: "rejected", name: "rejected" },
  { id: "Busy", name: "busy" },
  { id: "Network Error", name: "networkError" },
];

export const sampleCSVDownloadLink =
  "https://s3.ap-south-1.amazonaws.com/assets.skillr.ai/CAMPAIGN.csv";
