import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Radio,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Trash2, X } from "react-feather";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import {
  Circle,
  Info,
  RadioButton,
  TextFlatMp3,
  TextFlatWav,
} from "components/newSVG";
import theme from "theme";
import { useForm, Controller } from "react-hook-form";
import { IconClose, IconUpload2, RedAlert } from "components/SVG";
import { useEffect, useRef, useState } from "react";
import uploadFillerAudioApi from "services/uploadFillerAudio";
import { useSkillrToast } from "context/toast";
import { getAudioDuration, getNewFileName } from "utils/utils";
import { useUserContext } from "context/user";
import { languages as defaultLanguages } from "text-constants/common";

const supportedFileTypes = ["audio/wav", "audio/mpeg"];
const filesType = {
  "audio/mpeg": <TextFlatMp3 width={24} height={32} />,
  "audio/wav": <TextFlatWav width={24} height={32} />,
};
const fileUploadLimitInBytes = 2 * 1024 * 1024;
const MAX_AUDIO_TIME = 20; // 20 sec

const AddFillerModal = (props) => {
  const { open, onClose, onSave } = props;
  const fileInputRef = useRef();
  const { showSkillrToast } = useSkillrToast();
  const fileUploadController = useRef(new AbortController());
  const [languages, setLanguages] = useState([]);
  const { allBot } = useUserContext();
  const [currentLanguage, setCurrentLanguage] = useState("");

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      type: "text",
      text: "",
      file: {},
      fileUrl: "",
      uploadProgress: 0,
      languageVariations: {},
    },
  });

  const handleUploadProgress = (progressEvent) => {
    if (progressEvent?.loaded > 0) {
      const uploadProgress = Math.floor(
        (progressEvent?.loaded / progressEvent?.total) * 100
      );
      setValue("uploadProgress", uploadProgress);
    }
  };

  const uploadFillerAudio = async (file) => {
    try {
      const config = {
        onUploadProgress: handleUploadProgress,
        signal: fileUploadController.current.signal,
      };
      const formData = new FormData();
      formData.append("fillerFile", file);
      const response = await uploadFillerAudioApi(formData, config);
      setValue("fileUrl", response?.gcsUri);
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleOnBrowse = () => {
    fileInputRef.current.click();
  };

  const handleOnFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      const audioDuration = await getAudioDuration(file);
      if (audioDuration < MAX_AUDIO_TIME) {
        const newFile = getNewFileName(file);
        setValue("file", newFile);
        uploadFillerAudio(newFile);
      } else {
        showSkillrToast("Audio duration should not exceed 20 seconds", "error");
      }
    } catch (error) {
      showSkillrToast(error?.message, "error");
    }
  };

  const handleOnCancelFileUpload = () => {
    fileUploadController.current.abort();
    setValue("uploadProgress", 0);
  };

  const handleDeleteFile = () => {
    setValue("fileUrl", "");
    setValue("uploadProgress", 0);
    setValue("file", {});
  };

  const handleLanguageChange = (newLanguage) => {
    const currentType = watch("type");
    const currentValue =
      currentType === "text" ? watch("text") : watch("fileUrl");

    // Save current value to languageVariations
    setValue(`languageVariations.${currentLanguage}`, {
      type: currentType,
      value: currentValue,
    });

    // Set new language
    setCurrentLanguage(newLanguage);

    // Load value for new language if it exists
    const newLanguageValue = watch(`languageVariations.${newLanguage}`);
    if (newLanguageValue) {
      setValue("type", newLanguageValue.type);
      setValue(
        newLanguageValue.type === "text" ? "text" : "fileUrl",
        newLanguageValue.value
      );
    } else {
      // Clear the fields if no value exists for the new language
      setValue("text", "");
      setValue("fileUrl", "");
      setValue("file", {});
      setValue("uploadProgress", 0);
    }
  };

  const handleOnSave = (data) => {
    const currentType = watch("type");
    const currentValue =
      currentType === "text" ? watch("text") : watch("fileUrl");

    // Save current value to languageVariations
    // const updatedLanguageVariations = {
    //   ...data.languageVariations,
    //   [currentLanguage]: {
    //     type: currentType,
    //     value: currentValue,
    //   },
    // };

    if (currentType === "audio" && currentValue === "") {
      showSkillrToast("Upload file", "error");
    } else {
      onSave({
        ...data,
        language: {
          languageCode: currentLanguage,
          languageName: defaultLanguages.find(
            (lang) => lang.value === currentLanguage
          ).name,
        },
      });
    }
  };

  const getUploadProgress = () => {
    return watch("uploadProgress");
  };

  useEffect(() => {
    if (allBot[0]?.botVoices) {
      const botLanguages = allBot[0].botVoices.map(
        (voice) => voice.languageCode
      );
      setLanguages(botLanguages);
      const defaultLang =
        allBot[0].botVoices.find((voice) => voice.default)?.languageCode ||
        botLanguages[0];
      setCurrentLanguage(defaultLang);
    }
  }, [allBot]);

  useEffect(() => {
    if (!open && isDirty) {
      reset();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 500,
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" position="relative">
        <IconButton onClick={onClose} className={styles.closeButton}>
          <X />
        </IconButton>
      </Box>

      <Box className={styles.container}>
        <Typography variant="h3" className={styles.heading}>
          Add Filler
        </Typography>

        <Box mt={8} display="flex" justifyContent="center" gap={8}>
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value } }) => (
              <>
                <FlexBox
                  columnGap={2}
                  component="label"
                  key="text"
                  className={styles.types}
                >
                  <Radio
                    checkedIcon={<RadioButton width={24} height={24} />}
                    icon={<Circle width={24} height={24} />}
                    value="text"
                    onChange={onChange}
                    checked={value === "text"}
                    sx={{ padding: 0 }}
                  />
                  <Typography
                    variant="caption"
                    color={theme.palette.text.primary}
                    fontWeight={400}
                  >
                    Text Filler
                  </Typography>
                </FlexBox>

                <FlexBox
                  columnGap={2}
                  component="label"
                  key="audio"
                  className={styles.types}
                >
                  <Radio
                    checkedIcon={<RadioButton width={24} height={24} />}
                    icon={<Circle width={24} height={24} />}
                    value="audio"
                    onChange={onChange}
                    checked={value === "audio"}
                    sx={{ padding: 0 }}
                  />
                  <Typography
                    variant="caption"
                    color={theme.palette.text.primary}
                    fontWeight={400}
                  >
                    Audio Filler
                  </Typography>
                </FlexBox>
              </>
            )}
          />
        </Box>

        <FlexBox columnGap={2} mt={4}>
          <Typography variant="caption" color="text.primary">
            Filler Language
          </Typography>
          <Tooltip
            placement="top"
            title="Select the language for this filler message. The system will use this language to match the filler message with conversations in the same language."
          >
            <Info />
          </Tooltip>
        </FlexBox>

        <Select
          sx={{ mt: 4 }}
          fullWidth
          value={currentLanguage}
          onChange={(e) => handleLanguageChange(e.target.value)}
          className={styles.select}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: 0,
                boxShadow: "none",
                backgroundColor: theme.palette.grey[50],
                maxHeight: "40vh",
              },
            },
          }}
          renderValue={(selected) => {
            return defaultLanguages.find((lang) => lang.value === selected)
              .name;
          }}
        >
          {languages?.map((language) => (
            <MenuItem value={language} classes={{ selected: styles.selected }}>
              <Typography variant="caption" color={theme.palette.grey[800]}>
                {defaultLanguages.find((lang) => lang.value === language).name}
              </Typography>
            </MenuItem>
          ))}
        </Select>

        {watch("type") === "text" && (
          <Controller
            control={control}
            name="text"
            rules={{ required: "Filler is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                fullWidth
                multiline
                minRows={4}
                className={styles.input}
                placeholder="Filler message here"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        alignSelf: "flex-end",
                        mr: 2,
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      <Typography variant="caption" color="grey.500">
                        {`${watch("text")?.length} / 200`}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 200 }}
                helperText={
                  errors?.text ? (
                    <Box component="span" display="flex" alignItems="center">
                      <RedAlert style={{ marginRight: 8 }} />
                      {errors.text.message}
                    </Box>
                  ) : null
                }
                sx={{ marginTop: 4 }}
              />
            )}
          />
        )}

        {watch("type") === "audio" && (
          <>
            {getUploadProgress() === 0 && (
              <>
                <Box className={styles.audioContainer}>
                  <IconUpload2 height={40} width={40} />

                  <Typography
                    variant="caption"
                    color={theme.palette.primary.main}
                    sx={{ cursor: "pointer" }}
                    onClick={handleOnBrowse}
                  >
                    Browse
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Typography variant="body2" color="#797979">
                    Only support MP3 and WAV files
                  </Typography>
                  <Typography variant="body2" color="#797979">
                    Max time: 20 Sec
                  </Typography>
                </Box>
              </>
            )}

            {getUploadProgress() > 0 && (
              <Box className={styles.uploadedFileContainer}>
                <Box className={styles.uploadedFiles}>
                  {filesType[getValues("file")?.type]}

                  <Typography variant="caption">
                    {getValues("file")?.name}
                  </Typography>

                  {getUploadProgress() === 100 ? (
                    <IconButton
                      sx={{ marginLeft: "auto", padding: 0 }}
                      onClick={handleDeleteFile}
                    >
                      <Trash2 width={24} height={24} />
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{ marginLeft: "auto", padding: 0 }}
                      onClick={handleOnCancelFileUpload}
                    >
                      <IconClose width={24} height={24} />
                    </IconButton>
                  )}
                </Box>

                <Box display="flex" gap={2} alignItems="center">
                  <LinearProgress
                    value={getUploadProgress()}
                    color="primary"
                    variant="determinate"
                    sx={{
                      width: "100%",
                      marginTop: 1,
                      height: 3,
                      borderRadius: 5,
                      bgcolor: theme.palette.grey[100],
                      "& .MuiLinearProgress-bar": {
                        borderRadius: 5,
                        bgcolor: theme.palette.background.primary,
                      },
                    }}
                  />

                  <Typography className={styles.uploadPercentage}>
                    {getUploadProgress()}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}

        <Box className={styles.buttonContainer}>
          <Button variant="outline" className={styles.button} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dark"
            className={styles.button}
            onClick={handleSubmit(handleOnSave)}
          >
            Save
          </Button>
        </Box>
      </Box>

      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleOnFileUpload}
        ref={fileInputRef}
        accept={supportedFileTypes.join(",")}
      />
    </Dialog>
  );
};

export default AddFillerModal;
