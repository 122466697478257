import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { useUserContext } from "context/user";
import yara from "assets/images/yara-circular.png";
import BottomNav from "../BottomNav";
import { Loader, Send } from "react-feather";
import agentQuery from "services/createAgentQuery";
import { useEffect, useState } from "react";

const Chat = () => {
  const { companyDetails } = useUserContext();
  const companyName =
    companyDetails?.name.charAt(0).toUpperCase() +
    companyDetails?.name.slice(1);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [chat, setChat] = useState([
    {
      // message: "Hi, am I speaking with Ankit?",
      message: "Hi, how may I assist you?",
      type: "yara",
    },
  ]);

  const sendQuery = async (query) => {
    setLoading(true);
    try {
      const response = await agentQuery({ question: query });
      // setChat((oldChat) => {
      //   return [...oldChat, { message: <Loader />, type: "loading" }];
      // });
      if (response) {
        setLoading(false);
        setChat((oldChat) => {
          const newChat = oldChat.filter((item) => item.type !== "loading");
          return [...newChat, { message: response.answer, type: "yara" }];
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const addNewMessage = (message) => {
    if (message && message.trim() !== "") {
      const newChat = [...chat, { message, type: "user" }];
      setChat(newChat);
      setChat((oldChat) => {
        return [
          ...oldChat,
          {
            message: <div className={`${styles.dotFlashing}`} />,
            type: "loading",
          },
        ];
      });
      sendQuery(message);
      setMessage("");
    }
  };

  useEffect(() => {
    const chatContainer = document.querySelector(`.${styles.chatContainer}`);
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [chat]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Box
        className={`${styles.container} ${
          companyName === "BUSY" ? styles.busy : ""
        }`}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          mb={7}
        >
          <Typography variant="body2" fontWeight={500}>
            {companyName}
          </Typography>
          <Typography fontSize={10} fontWeight={500} color={"grey.600"}>
            &gt; Powered by Fundamento
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} mb={4}>
          <img src={yara} width={50} height={50} />
          <Box>
            <Typography variant="body1" fontWeight={500}>
              Yara
            </Typography>
            <Box display="flex" alignItems="center" columnGap={2}>
              <span className={styles.online} />
              <Typography variant="body2" fontWeight={500}>
                online
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={styles.chatContainer}>
          {chat.map((item, index) => (
            <Box
              key={index}
              className={`${styles.chat} ${
                item.type === "yara" || item.type === "loading"
                  ? styles.yara
                  : styles.user
              } ${item.type === "loading" ? styles.loading : ""}`}
            >
              <Typography
                variant="body1"
                style={{
                  color: item.type === "yara" ? "#303437" : "#fff",
                  whiteSpace: "pre-line",
                }}
              >
                {item.message}
              </Typography>
            </Box>
          ))}
        </Box>

        <TextField
          fullWidth
          disabled={loading}
          variant="outlined"
          className={styles.chatBox}
          placeholder="Message..."
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          onKeyDown={(e) => {
            e.key === "Enter" && addNewMessage(message);
          }}
          sx={{ "& fieldset": { border: 0 } }}
          value={message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    addNewMessage(message);
                  }}
                >
                  <Send width={16} height={16} />
                </IconButton>
              </InputAdornment>
            ),
            className: styles.input,
          }}
        />
      </Box>

      <BottomNav activeState={"chat"} />
    </div>
  );
};

export default Chat;
