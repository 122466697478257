import axiosClient from "../api/axiosClient";

export function getRequest(url, config = {}) {
  return axiosClient.get(url, config);
}

export function postRequest(url, data, config = {}) {
  return axiosClient.post(url, data, config);
}

export function deleteRequest(url, config = {}) {
  return axiosClient.delete(url, config);
}

export function patchRequest(url, data, config = {}) {
  return axiosClient.patch(url, data, config);
}

export function putRequest(url, data, config = {}) {
  return axiosClient.put(url, data, config);
}
