import { postRequest } from "./common";

// export default function duplicateCheck(data, dataSetId, config = {}) {
//   return postRequest(`/duplicateCheck/${dataSetId}`, data, config);
// }

export default function duplicateCheck(data, dataSetId, queryParams, config = {}) {
  // const queryParams = new URLSearchParams(queryParamsObject).toString();
  const url = `/duplicateCheck/${dataSetId}${queryParams ? `?${queryParams}` : ''}`;
  return postRequest(url, data, config);
}