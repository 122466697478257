import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { Box, Divider } from "@mui/material";
import Header from "components/Header";
import theme from "theme";
import { Outlet } from "react-router-dom";

const ProfileSettings = () => {
  const tabs = ["System Configuration"];

  return (
    <PageWrapper className={styles.container}>
      <Box
        sx={{
          "& [role='tab']:last-of-type ": {
            pointerEvents: "none",
            cursor: "default",
          },
        }}
      >
        <Header
          title="Profile Settings"
          subTitle="Configure your profile settings."
          tabs={tabs}
          defaultActiveTab="System Configuration"
        />
      </Box>
      <Divider sx={{ backgroundColor: theme.palette.borderColor.light }} />
      <Outlet />
    </PageWrapper>
  );
};

export default ProfileSettings;
