import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { Controller, get, useFieldArray, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { ChevronDown, Plus, Trash2, X } from "react-feather";
import theme from "theme";
import { RedAlert } from "components/SVG";
import { didNumberExists } from "services";
import _ from "lodash";

const checkDidNumberExists = async (number) => {
  try {
    if (number) {
      const numberExists = await didNumberExists(number);
      return numberExists;
    }
  } catch (error) {
    return error;
  }
};

const debouncedDidNumberExists = _.debounce(checkDidNumberExists, 3000);

const validate = async (value, formData, apiData) => {
  const numberValidationRegex = /^\d{7,}$/;
  if (!numberValidationRegex.test(value)) {
    return "Number should have the length greater or equal to 7";
  }

  // Checking if the number already exists in
  if (apiData?.didNumbers.includes(value)) {
    return true;
  }

  const numberExists = await checkDidNumberExists(value);

  return numberExists?.exists ? "Number Already exists" : true;
};

const BasicSettings = (props) => {
  const { workFlows, onSave, data, apiData } = props;
  const STTServices = [
    // { label: "Assembly", value: "assembly" },
    { label: "Google V1", value: "google" },
    { label: "Google V2", value: "googleV2" },
    { label: "Google V2 Short", value: "googleV2Short" },
    { label: "Neural Space", value: "neuralSpace" },
  ];
  const nodeLevelSTTtabs = [{ label: "Input Node", value: "inputNode" }];
  const [activeTab, setActiveTab] = useState("inputNode");

  const TTSServices = [
    { label: "Eleven Labs", value: "elevenLabs" },
    { label: "Google", value: "google" },
    { label: "Neural Space", value: "neuralSpace" },
  ];

  const inputNodeValidationTypes = [
    { name: "Name", value: "name" },
    { name: "Number", value: "number" },
    { name: "Phone Number", value: "phoneNumber" },
    { name: "Date", value: "date" },
    { name: "Time", value: "time" },
    { name: "Date & Time", value: "dateTime" },
    { name: "Alphanumeric", value: "alphanumeric" },
  ];

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      welcomeWorkFlow: data?.welcomeWorkFlow,
      threshold: data?.threshold,
      fallBackWorkFlow: data?.fallBackWorkFlow,
      intentMaxTries: data?.intentMaxTries,
      intentExceededWorkFlow: data?.intentExceededWorkFlow,
      postIntentWorkFlow: data?.postIntentWorkFlow,
      sttService: data?.sttService,
      ttsService: data?.ttsService,
      didNumbers: data?.didNumbers,
      selectedValidationType: data?.selectedValidationType || "name",
      nodeLevelSTT: {
        inputNode: {
          name: {
            service: data?.nodeLevelSTT?.inputNode?.name?.service,
          },
          number: {
            service: data?.nodeLevelSTT?.inputNode?.number?.service,
          },
          phoneNumber: {
            service: data?.nodeLevelSTT?.inputNode?.phoneNumber?.service,
          },
          date: {
            service: data?.nodeLevelSTT?.inputNode?.date?.service,
          },
          time: {
            service: data?.nodeLevelSTT?.inputNode?.time?.service,
          },
          dateTime: {
            service: data?.nodeLevelSTT?.inputNode?.dateTime?.service,
          },
          alphanumeric: {
            service: data?.nodeLevelSTT?.inputNode?.alphanumeric?.service,
          },
        },
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "didNumbers",
  });

  const watchOnAllFields = () => {
    const watchSubscription = watch((value, fields) => {
      onSave({
        welcomeWorkFlow: value?.welcomeWorkFlow,
        threshold: value?.threshold,
        fallBackWorkFlow: value?.fallBackWorkFlow,
        intentMaxTries: value?.intentMaxTries,
        intentExceededWorkFlow: value?.intentExceededWorkFlow,
        postIntentWorkFlow: value?.postIntentWorkFlow,
        sttService: value?.sttService,
        ttsService: value?.ttsService,
        didNumbers: value?.didNumbers,
        nodeLevelSTT: {
          inputNode: inputNodeValidationTypes.reduce((acc, type) => {
            acc[type.value] = {
              service: value?.nodeLevelSTT?.inputNode?.[type.value]?.service,
            };
            return acc;
          }, {}),
        },
        basicSettingsError: errors,
        // selectedValidationType: value?.selectedValidationType,
      });
    });
    return watchSubscription;
  };

  useEffect(() => {
    const subscription = watchOnAllFields();

    return () => {
      subscription.unsubscribe();
    };
  }, [errors]);

  return (
    <Box className={styles.container}>
      <Box>
        <Box>
          <Typography variant="body2" fontWeight={500}>
            Welcome Flow
          </Typography>
          <Typography
            variant="caption"
            fontWeight={400}
            color={theme.palette.text.primary}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              marginBottom: 2,
              display: "inline-block",
            }}
          >
            This selection determines the virtual assistant’s welcome flow
          </Typography>
        </Box>
        <Box className={styles.workflowContainer}>
          <Box className={styles.workFlowText}>
            <Typography variant="body2" color={"#0f0f0f"}>
              Trigger Workflow
            </Typography>
          </Box>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="welcomeWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ChevronDown />
                      </InputAdornment>
                    ),
                  }}
                >
                  {workFlows.map((workFlow) => (
                    <MenuItem
                      value={workFlow._id}
                      sx={{ gap: 3 }}
                      key={workFlow._id}
                    >
                      <Typography variant="body2">{workFlow?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography variant="body2" color="#0f0f0f" fontWeight={500}>
          Match Threshold
        </Typography>
        <Typography
          variant="caption"
          color="#0f0f0f"
          sx={{ opacity: 0.8, marginTop: 1, display: "inline-block" }}
        >
          Set the threshold for semantic search for detecting the customer’s
          intent
        </Typography>

        <Box sx={{ maxWidth: "98%", margin: "0 auto" }}>
          <Controller
            control={control}
            name="threshold"
            render={({ field: { onChange, value } }) => (
              <Slider
                min={0}
                max={0.25}
                valueLabelDisplay="auto"
                step={0.01}
                sx={{
                  "& .MuiSlider-rail": { backgroundColor: "#EFEFEF" },
                  "& .MuiSlider-track": {
                    backgroundColor: "#00C8C2",
                    border: 0,
                  },
                  "& .MuiSlider-thumb": {
                    backgroundColor: "#fff",
                    width: 20,
                    height: 20,
                  },
                  marginTop: 12,
                  marginBottom: 0,
                }}
                marks={[
                  {
                    value: 0,
                    label: (
                      <Typography variant="body2" fontWeight={500}>
                        0
                      </Typography>
                    ),
                  },
                  {
                    value: 0.25,
                    label: (
                      <Typography variant="body2" fontWeight={500}>
                        0.25
                      </Typography>
                    ),
                  },
                ]}
                value={value}
                onChange={(e, value) => {
                  onChange(value);
                }}
              />
            )}
          />
        </Box>
      </Box>

      <Box>
        <Box>
          <Typography variant="body2" fontWeight={500}>
            Fallback Flow
          </Typography>
          <Typography
            variant="caption"
            color={"#0f0f0f"}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              marginBottom: 2,
              display: "inline-block",
            }}
          >
            This selection determines the fallback flow if the customer’s intent
            couldn’t be matched
          </Typography>
        </Box>
        <Box className={styles.workflowContainer}>
          <Box className={styles.workFlowText}>
            <Typography variant="body2" color={"#0f0f0f"}>
              Trigger Workflow
            </Typography>
          </Box>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="fallBackWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {workFlows.map((workFlow) => (
                    <MenuItem
                      value={workFlow._id}
                      sx={{ gap: 3 }}
                      key={workFlow._id}
                    >
                      <Typography variant="body2">{workFlow?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography variant="body2" color="#0f0f0f" fontWeight={500}>
          Number Of Tries
        </Typography>
        <Typography
          variant="caption"
          color="#0f0f0f"
          sx={{
            opacity: 0.8,
            marginTop: 1,
            marginBottom: 2,
            display: "inline-block",
          }}
        >
          Maximum number of attempts that are made to detect the customer’s
          intent
        </Typography>

        <Box>
          <Controller
            control={control}
            name="intentMaxTries"
            rules={{
              min: 1,
              max: 60,
              validate: (value) =>
                (value >= 1 && value <= 60) ||
                "Please enter a number between 1 and 60",
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                sx={{ marginTop: 2 }}
                className={styles.intentMaxTries}
                // onChange={onChange}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue >= 1 && newValue <= 60) {
                    onChange(e);
                  }
                }}
                value={value}
                type="number"
                inputProps={{
                  min: 1,
                  max: 60,
                }}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>
      </Box>

      <Box>
        <Box>
          <Typography variant="body2" fontWeight={500}>
            Try Exceeded
          </Typography>
          <Typography
            variant="caption"
            color={"#0f0f0f"}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              marginBottom: 2,
              display: "inline-block",
            }}
          >
            What happens if the number of tries exceed
          </Typography>
        </Box>
        <Box className={styles.workflowContainer}>
          <Box className={styles.workFlowText}>
            <Typography variant="body2" color={"#0f0f0f"}>
              Trigger Workflow
            </Typography>
          </Box>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="intentExceededWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {workFlows.map((workFlow) => (
                    <MenuItem
                      value={workFlow._id}
                      sx={{ gap: 3 }}
                      key={workFlow._id}
                    >
                      <Typography variant="body2">{workFlow?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Box>
          <Typography variant="body2" fontWeight={500}>
            Post Intent
          </Typography>
          <Typography
            variant="caption"
            color={"#0f0f0f"}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              marginBottom: 2,
              display: "inline-block",
            }}
          >
            What happens after an intent has been successfully answered
          </Typography>
        </Box>
        <Box className={styles.workflowContainer}>
          <Box className={styles.workFlowText}>
            <Typography variant="body2" color={"#0f0f0f"}>
              Trigger Workflow
            </Typography>
          </Box>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="postIntentWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {workFlows.map((workFlow) => (
                    <MenuItem
                      value={workFlow._id}
                      sx={{ gap: 3 }}
                      key={workFlow._id}
                    >
                      <Typography variant="body2">{workFlow?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Box>
          <Typography variant="body2" fontWeight={500}>
            Select STT Service
          </Typography>
          <Typography
            variant="caption"
            color={"#0f0f0f"}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              marginBottom: 2,
              display: "inline-block",
            }}
          >
            Choose your desired Speech to Text service
          </Typography>
        </Box>

        <Box>
          <Controller
            control={control}
            name="sttService"
            render={({ field: { onChange, value } }) => (
              <Select
                displayEmpty={true}
                onChange={onChange}
                value={value}
                // placeholder="Select STT service"
                className={styles.dropDown}
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "40vh",
                      width: "20vw",
                    },
                  },
                }}
                renderValue={(value) => {
                  if (!value) {
                    return (
                      <Typography variant="body2">
                        Select STT service
                      </Typography>
                    );
                  }
                  return (
                    <Typography variant="body2">
                      {
                        STTServices.find((service) => service.value === value)
                          ?.label
                      }
                    </Typography>
                  );
                }}
              >
                {STTServices.map((service) => (
                  <MenuItem
                    value={service.value}
                    sx={{ gap: 3 }}
                    key={service.value}
                  >
                    <Typography variant="body2">{service.label}</Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography variant="body2" fontWeight={500}>
            Select Node Level STT
          </Typography>
          <Typography
            variant="caption"
            color={"#0f0f0f"}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              marginBottom: 2,
              display: "inline-block",
            }}
          >
            Choose your node level Speech to Text service
          </Typography>
        </Box>

        <Box>
          <Tabs
            className={styles.tabs}
            TabIndicatorProps={{
              style: {
                background: "#0f0f0f",
                borderRadius: "10px 10px 0 0",
                height: 3,
              },
            }}
            sx={{
              mb: 4,
            }}
            value={activeTab}
            onChange={(e, value) => setActiveTab(value)}
          >
            {nodeLevelSTTtabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 500,
                  fontSize: 14,
                  color: theme.palette.text.primary,
                }}
                className={styles.tab}
              />
            ))}
          </Tabs>

          {activeTab === "inputNode" && (
            <Box>
              <Controller
                control={control}
                name="selectedValidationType"
                render={({ field: { onChange, value } }) => (
                  <Select
                    displayEmpty={true}
                    onChange={onChange}
                    value={value}
                    className={styles.dropDown}
                    fullWidth
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "20vw",
                        },
                      },
                    }}
                    renderValue={(value) => {
                      if (!value) {
                        return (
                          <Typography variant="body2">
                            Select validation type
                          </Typography>
                        );
                      }
                      return (
                        <Typography variant="body2">
                          {
                            inputNodeValidationTypes.find(
                              (type) => type.value === value
                            )?.name
                          }
                        </Typography>
                      );
                    }}
                  >
                    {inputNodeValidationTypes.map((type) => (
                      <MenuItem
                        value={type.value}
                        sx={{ gap: 3 }}
                        key={type.value}
                      >
                        <Typography variant="body2">{type.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {watch("selectedValidationType") && (
                <Box mt={4}>
                  <Typography variant="body2" fontWeight={500} mb={2}>
                    STT Service for{" "}
                    {
                      inputNodeValidationTypes.find(
                        (type) => type.value === watch("selectedValidationType")
                      )?.name
                    }
                  </Typography>
                  <Controller
                    control={control}
                    name={`nodeLevelSTT.inputNode.${watch(
                      "selectedValidationType"
                    )}.service`}
                    key={watch("selectedValidationType")}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        displayEmpty={true}
                        onChange={onChange}
                        value={value}
                        className={styles.dropDown}
                        fullWidth
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: "40vh",
                              width: "20vw",
                            },
                          },
                        }}
                        renderValue={(value) => {
                          if (!value) {
                            return (
                              <Typography variant="body2">
                                Select STT service
                              </Typography>
                            );
                          }
                          return (
                            <Typography variant="body2">
                              {
                                STTServices.find(
                                  (service) => service.value === value
                                )?.label
                              }
                            </Typography>
                          );
                        }}
                      >
                        {STTServices.map((service) => (
                          <MenuItem
                            value={service.value}
                            sx={{ gap: 3 }}
                            key={service.value}
                          >
                            <Typography variant="body2">
                              {service.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {/* <Box>
        <Box>
          <Typography variant="body2" fontWeight={500}>
            Select TTS Service
          </Typography>
          <Typography
            variant="caption"
            color={"#0f0f0f"}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              marginBottom: 2,
              display: "inline-block",
            }}
          >
            Choose your desired Text to Speech service
          </Typography>
        </Box>

        <Box>
          <Controller
            control={control}
            name="ttsService"
            render={({ field: { onChange, value } }) => (
              <Select
                displayEmpty={true}
                onChange={onChange}
                value={value}
                className={styles.dropDown}
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "40vh",
                      width: "20vw",
                    },
                  },
                }}
                renderValue={(value) => {
                  if (!value) {
                    return (
                      <Typography variant="body2">
                        Select TTS service
                      </Typography>
                    );
                  }
                  return (
                    <Typography variant="body2">
                      {
                        TTSServices.find((service) => service.value === value)
                          ?.label
                      }
                    </Typography>
                  );
                }}
              >
                {TTSServices.map((service) => (
                  <MenuItem
                    value={service.value}
                    sx={{ gap: 3 }}
                    key={service.value}
                  >
                    <Typography variant="body2">{service.label}</Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
      </Box> */}

      <Box>
        <Box>
          <Typography variant="body2" fontWeight={500} mb={2}>
            DID Numbers
          </Typography>
          {/* <Typography
            variant="caption"
            color={"#0f0f0f"}
            sx={{
              opacity: 0.8,
              marginTop: 1,
              marginBottom: 2,
              display: "inline-block",
            }}
          >
            Choose your desired Text to Speech service
          </Typography> */}
        </Box>

        {fields.map((item, index) => (
          <Box key={item.id} display="flex" alignItems="center" mb={2}>
            <Controller
              name={`didNumbers[${index}]`}
              control={control}
              defaultValue={item}
              rules={{
                required: "DID number is required",
                validate: (value, formData) =>
                  validate(value, formData, apiData),
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  placeholder="DID Number"
                  variant="outlined"
                  type="tel"
                  fullWidth
                  sx={{ mr: 1, minWidth: "50%" }}
                  inputProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                  error={get(errors, `didNumbers[${index}]`)}
                  helperText={
                    get(errors, `didNumbers[${index}]`) && (
                      <Box component="span" display="flex" alignItems="center">
                        <RedAlert
                          style={{ marginRight: 8 }}
                          width={12}
                          height={12}
                        />
                        <Typography variant="caption" color="error">
                          {get(errors, `didNumbers[${index}]`)?.message}
                        </Typography>
                      </Box>
                    )
                  }
                />
              )}
            />
            <IconButton
              // sx={{ ml: "auto" }}
              onClick={() => {
                remove(index);
                setValue("didNumbers", getValues("didNumbers"));
              }}
              // disabled={fields.length === 1} // Prevent removing the last field
            >
              <Trash2 width={16} height={16} color={theme.palette.grey[600]} />
            </IconButton>
          </Box>
        ))}
        <Button
          variant="text"
          color="inherit"
          my={2}
          startIcon={<Plus />}
          onClick={() => append("")}
        >
          Add DID Number
        </Button>
      </Box>
    </Box>
  );
};

BasicSettings.propTypes = {
  workFlows: PropTypes.array,
  onSave: PropTypes.func,
  data: PropTypes.object,
};

export default BasicSettings;
