import { Close } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { IconSearch } from "components/SVG";
import { useURLState } from "custom-hooks/useUrlState";
import { useEffect } from "react";

const SearchBox = (props) => {
  const { placeholder, onChange, defaultValue, onClear, disabled } = props;
  const [searchValue, setSearchValue] = useURLState(defaultValue, "search");

  const onClearSearchInput = () => {
    setSearchValue("");
    onClear();
  };

  const handleOnChange = (event) => {
    onChange(event.target.value);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (searchValue) {
      onChange(searchValue);
    }
  }, []);

  return (
    <>
      <TextField
        disabled={disabled}
        fullWidth
        autoComplete="off"
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <IconSearch
                width={20}
                height={20}
                style={{ flexShrink: 0 }}
                color="#0f0f0f4d"
              />
            </InputAdornment>
          ),
          endAdornment: searchValue?.length ? (
            <InputAdornment position="end" style={{ cursor: "pointer" }}>
              <Close onClick={onClearSearchInput} />
            </InputAdornment>
          ) : null,
        }}
        variant="standard"
        value={searchValue}
        defaultValue={defaultValue}
        onChange={handleOnChange}
      />
    </>
  );
};

export default SearchBox;
