import {
  Box,
  Button,
  Fade,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Eye,
  Loader,
  Mail,
  X,
  Zap,
} from "react-feather";
import { IconUser1 } from "components/SVG";
import {
  CalendarBlank,
  Phone,
  IDBox,
  VoiceScan,
  MessageDots,
  Info,
  ThumbUpGreenFilled,
  ThumbDownRedFilled,
  FlowArrow,
  PencilSimple,
  Pencil,
  PlusCircle,
} from "components/newSVG";
import theme from "theme";
import FunCollapse from "components/FunCollapse";
import moment from "moment";
import { gscUriPrefix } from "text-constants/common";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  addAuditDetails,
  editAgentCall as editAgentCallAPI,
  getAllWorkFlow,
  getPlaygroundHistory,
} from "services";
import { useSkillrToast } from "context/toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFormattedFullNameAndIntials, getJSONDecoded } from "utils/utils";
import { useUserContext } from "context/user";
import EditContainer from "pages/Bot/container/HistoryDetail/components/EditContainer";
import Variation from "assets/images/variation.png";
import {
  editVariables as editVariablesAPI,
  getVariables as getVariablesAPI,
} from "services";
import AudioPlayer from "components/AudioPlayer";
import EmptyState from "components/EmptyState";

const CallDetail = (props) => {
  // const callDetails = useLocation().state;
  const [editAnswer, setEditAnswer] = useState(false);
  const [newAnswer, setNewAnswer] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [showValidMenu, setShowValidMenu] = useState(false);
  const [showBlankMenu, setShowBlankMenu] = useState(false);
  const [showInvalidMenu, setShowInvalidMenu] = useState(false);
  const [showJunkMenu, setShowJunkMenu] = useState(false);
  const [callTranscript, setCallTranscript] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [editContainer, setEditContainer] = useState(false);
  const [lastIntentObject, setLastIntentObject] = useState({});
  const [currentAuditLogType, setCurrentAuditLogType] = useState();
  const [currentAuditLogValue, setCurrentAuditLogValue] = useState();
  const [currentAuditLogEmail, setCurrentAuditLogEmail] = useState();
  const [workflowList, setWorkflowList] = useState([]);
  const [
    lastIntentMatchedAsperBotThreshold,
    setLastIntentMatchedAsperBotThreshold,
  ] = useState();
  const [digressionThreshold, setDigressionThreshold] = useState();
  const [callDetails, setCallDetails] = useState();
  const { showSkillrToast } = useSkillrToast();
  const { token, buildMode } = useUserContext();
  const tokenInfo = getJSONDecoded(token ?? null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const voiceCallId = queryParams.get("voiceCallId");
  const [variationToAdd, setVariationToAdd] = useState();
  const [addVariant, setAddVariant] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [variables, setVariables] = useState({});
  const [showCustomVariables, setCustomVariables] = useState(true);
  const [nextCall, setNextCall] = useState({});
  const [previousCall, setPreviousCall] = useState({});
  const [loading, setLoading] = useState(false);

  const [editedValues, setEditedValues] = useState([]);

  const getWorkflow = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlow(params.toString());
      setWorkflowList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditAnswer = () => {
    setEditAnswer(true);
  };

  const handleDiscardChanges = () => {
    setEditAnswer(false);
  };

  const handleSaveAnswer = () => {
    setEditAnswer(false);
    if (newAnswer !== callDetails?.answer) {
      editAgentCall(callDetails?.callId, { endLine: newAnswer });
      callDetails.answer = newAnswer;
    }
  };

  const addAuditLog = async (id, data) => {
    try {
      const response = await addAuditDetails(id, data);
      setCurrentAuditLogType(data.auditedCallType);
      setCurrentAuditLogValue(data.auditedCallTypeSub);
      setCurrentAuditLogEmail(tokenInfo.email);
      showSkillrToast("Audit log added successfully", "success");
      setShowMenu(false);
      // onAuditLogChange();
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const editAgentCall = async (callId, data) => {
    try {
      const response = await editAgentCallAPI(callId, data);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getCallTranscript = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.set("voiceCallId", voiceCallId);
      /**
       * isOnPhoneCall
       * For development build we will show all calls made from playground
       * and for production build we will show all calls made from actual phone number
       */
      params.set("isOnPhoneCall", buildMode === "production" ? true : null);
      const response = await getPlaygroundHistory(params.toString());
      setDigressionThreshold(response?.digressionThreshold);
      setCallDetails(response?.callSessionData[0]?.callId);
      setLastIntentObject(response?.callSessionData[0]?.lastIntentObject);
      setCallTranscript(response.callSessionData[0]);
      setLastIntentMatchedAsperBotThreshold(
        response?.callSessionData[0]?.lastIntentMatchedAsperBotThreshold
      );
      setVariationToAdd(response?.callSessionData[0]?.lastQuestionAsked);
      getVariables(response?.callSessionData[0]?.callId?._id);
      setNextCall({
        id: response?.nextSession.id,
        voiceCallId: response?.nextSession.voiceCallId,
      });
      setPreviousCall({
        id: response?.previousSession.id,
        voiceCallId: response?.previousSession.voiceCallId,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getVariables = async (id) => {
    try {
      const response = await getVariablesAPI(id);
      setVariables(response);
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleonChangeIntent = () => {
    getCallTranscript();
  };

  const editVariables = () => {
    const finalValues = editedValues.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});

    try {
      const response = editVariablesAPI(callDetails._id, finalValues);
      showSkillrToast("Variables updated successfully", "success");
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    setCurrentAuditLogType(callDetails?.auditedCallType);
    setCurrentAuditLogValue(callDetails?.auditedCallTypeSub);
    setCurrentAuditLogEmail(callDetails?.auditUser?.email);
  }, [callDetails?.auditedCallType]);

  useEffect(() => {
    getCallTranscript();
    getWorkflow();
  }, [voiceCallId]);

  useEffect(() => {
    setEditedValues(() => {
      const values = Object.entries(variables)
        .filter(([key]) => key !== "system" && key !== "session")
        .map(([key, value]) => ({
          key: key,
          value: value,
        }));

      return values;
    });
  }, [variables]);

  // const handleOnCloseModal = () => {
  //   onClose();
  //   setEditAnswer(false);
  // };

  return (
    <>
      {loading ? (
        <FlexBox justifyContent="center" alignItems="center" height="100vh">
          <Loader color={theme.palette.grey[400]} width={32} height={32} />
        </FlexBox>
      ) : (
        <Box>
          <FlexBox className={styles.topBar}>
            <Typography variant="body1" fontWeight={500}>
              {callDetails?.voiceCallId}
            </Typography>
            <IconButton onClick={() => navigate(-1)}>
              <X width={20} height={20} />
            </IconButton>
          </FlexBox>

          <FlexBox justifyContent="space-between" mt={4} px={6}>
            <Button
              variant="contained"
              startIcon={<ChevronLeft />}
              sx={{ backgroundColor: theme.palette.grey[50] }}
              color="inherit"
              onClick={() => {
                if (previousCall.id && previousCall.voiceCallId) {
                  navigate(
                    `/calls/${previousCall.id}?voiceCallId=${previousCall.voiceCallId}`,
                    {
                      replace: true,
                    }
                  );
                }
              }}
              disabled={!previousCall.id || !previousCall.voiceCallId}
            >
              Previous Call
            </Button>
            <Button
              variant="contained"
              endIcon={<ChevronRight />}
              color="inherit"
              sx={{ backgroundColor: theme.palette.grey[50] }}
              onClick={() => {
                if (nextCall.id && nextCall.voiceCallId) {
                  navigate(
                    `/calls/${nextCall.id}?voiceCallId=${nextCall.voiceCallId}`,
                    {
                      replace: true,
                    }
                  );
                }
              }}
              disabled={!nextCall.id || !nextCall.voiceCallId}
            >
              Next Call
            </Button>
          </FlexBox>

          <FlexBox padding="16px 24px">
            <Typography variant="body2" color="grey.600">
              Audit log
            </Typography>
            <Box sx={{ position: "relative" }}>
              <Button
                variant="outlined"
                sx={{ borderRadius: 82, padding: "4px 16px", minWidth: 0 }}
                endIcon={<ChevronDown />}
                onMouseOver={() => setShowMenu(true)}
                onMouseLeave={() => setShowMenu(false)}
              >
                {/* {currentAuditLogType
                  ? `${currentAuditLogType} - ${currentAuditLogValue}`
                  : "None"} */}
                {!currentAuditLogType && !currentAuditLogValue && `None`}
                {currentAuditLogType &&
                  !currentAuditLogValue &&
                  `${currentAuditLogType}`}
                {currentAuditLogType &&
                  currentAuditLogValue &&
                  `${currentAuditLogType} - ${currentAuditLogValue}`}
              </Button>
              <Fade in={showMenu} unmountOnExit={true} mountOnEnter={true}>
                <Box
                  className={styles.menuContainer}
                  onMouseOver={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                >
                  <Box
                    sx={{ position: "relative" }}
                    onMouseOver={() => setShowValidMenu(true)}
                    onMouseLeave={() => setShowValidMenu(false)}
                  >
                    <Typography
                      variant="subtitle2"
                      mb={1}
                      sx={{ fontWeight: 500 }}
                    >
                      Valid
                    </Typography>
                    <Fade
                      in={showValidMenu}
                      unmountOnExit={true}
                      mountOnEnter={true}
                    >
                      <Box
                        className={styles.menuContainer}
                        style={{
                          // transform: "translate(0%, 100%)",
                          left: "100%",
                        }}
                        onMouseOver={() => setShowValidMenu(true)}
                        onMouseLeave={() => setShowValidMenu(false)}
                      >
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails?._id, {
                              auditedCallType: "Valid",
                              auditedCallTypeSub: "Call Abandoned",
                            })
                          }
                        >
                          Call Abandoned
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails?._id, {
                              auditedCallType: "Valid",
                              auditedCallTypeSub: "Partially Resolved",
                            })
                          }
                        >
                          Partially Resolved
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails?._id, {
                              auditedCallType: "Valid",
                              auditedCallTypeSub: "Resolved",
                            })
                          }
                        >
                          Resolved
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails?._id, {
                              auditedCallType: "Valid",
                              auditedCallTypeSub: "Inactive Category",
                            })
                          }
                        >
                          Inactive Category
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails?._id, {
                              auditedCallType: "Valid",
                              auditedCallTypeSub: "Incorrect Intent",
                            })
                          }
                        >
                          Incorrect Intent
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails?._id, {
                              auditedCallType: "Valid",
                              auditedCallTypeSub: "Similar Intent",
                            })
                          }
                        >
                          Similar Intent
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails?._id, {
                              auditedCallType: "Valid",
                              auditedCallTypeSub: "Untrained Intent",
                            })
                          }
                        >
                          Untrained Intent
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails?._id, {
                              auditedCallType: "Valid",
                              auditedCallTypeSub: "Distance Exceeded",
                            })
                          }
                        >
                          Distance Exceeded
                        </Box>
                      </Box>
                    </Fade>
                  </Box>
                  <Box
                    onMouseOver={() => setShowBlankMenu(true)}
                    onMouseLeave={() => setShowBlankMenu(false)}
                    sx={{ position: "relative" }}
                  >
                    <Typography
                      variant="subtitle2"
                      mb={1}
                      sx={{ fontWeight: 500 }}
                    >
                      Blank
                    </Typography>
                    <Fade
                      in={showBlankMenu}
                      unmountOnExit={true}
                      mountOnEnter={true}
                    >
                      <Box
                        className={styles.menuContainer}
                        style={{
                          // transform: "translate(-100%, 70%)",
                          left: "100%",
                        }}
                        onMouseOver={() => setShowBlankMenu(true)}
                        onMouseLeave={() => setShowBlankMenu(false)}
                      >
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails._id, {
                              auditedCallType: "Blank",
                              auditedCallTypeSub: "No Speech",
                            })
                          }
                        >
                          No Speech
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails._id, {
                              auditedCallType: "Blank",
                              auditedCallTypeSub: "Unclear Speech",
                            })
                          }
                        >
                          Unclear Speech
                        </Box>
                      </Box>
                    </Fade>
                  </Box>
                  <Box
                    onMouseOver={() => setShowInvalidMenu(true)}
                    onMouseLeave={() => setShowInvalidMenu(false)}
                    sx={{ position: "relative" }}
                  >
                    <Typography
                      variant="subtitle2"
                      mb={1}
                      sx={{ fontWeight: 500 }}
                    >
                      Invalid
                    </Typography>
                    <Fade
                      in={showInvalidMenu}
                      unmountOnExit={true}
                      mountOnEnter={true}
                    >
                      <Box
                        className={styles.menuContainer}
                        style={{
                          // transform: "translate(-100%, 70%)",
                          left: "100%",
                        }}
                        onMouseOver={() => setShowInvalidMenu(true)}
                        onMouseLeave={() => setShowInvalidMenu(false)}
                      >
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails._id, {
                              auditedCallType: "Invalid",
                              auditedCallTypeSub: "Unclear Response",
                            })
                          }
                        >
                          Unclear Response
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails._id, {
                              auditedCallType: "Invalid",
                              auditedCallTypeSub: "Transfer Requested",
                            })
                          }
                        >
                          Transfer Requested
                        </Box>
                      </Box>
                    </Fade>
                  </Box>
                  <Box
                    onClick={() =>
                      addAuditLog(callDetails._id, {
                        auditedCallType: "System Error",
                        auditedCallTypeSub: "",
                      })
                    }
                  >
                    <Typography
                      variant="subtitle2"
                      mb={1}
                      sx={{ fontWeight: 500 }}
                    >
                      System Error
                    </Typography>
                  </Box>
                  {/* commented junk out */}
                  {/* <Box
                    sx={{ position: "relative" }}
                    onMouseOver={() => setShowJunkMenu(true)}
                    onMouseLeave={() => setShowJunkMenu(false)}
                  >
                    <Typography
                      variant="subtitle2"
                      mb={1}
                      sx={{ fontWeight: 500 }}
                    >
                      Junk
                    </Typography>
                    <Fade
                      in={showJunkMenu}
                      unmountOnExit={true}
                      mountOnEnter={true}
                    >
                      <Box
                        className={styles.menuContainer}
                        style={{
                          transform: "translate(-100%, 70%)",
                          left: 0,
                        }}
                        onMouseOver={() => setShowJunkMenu(true)}
                        onMouseLeave={() => setShowJunkMenu(false)}
                      >
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails._id, {
                              auditedCallType: "Junk",
                              auditedCallTypeSub: "Irrelevant",
                            })
                          }
                        >
                          Irrelevant
                        </Box>
                        <Box
                          onClick={() =>
                            addAuditLog(callDetails._id, {
                              auditedCallType: "Junk",
                              auditedCallTypeSub: "Abusive",
                            })
                          }
                        >
                          Abusive
                        </Box>
                      </Box>
                    </Fade>
                  </Box> */}
                </Box>
              </Fade>
            </Box>
            <Tooltip
              title={`Last audited by ${callDetails?.auditUser?.firstName} ${callDetails?.auditUser?.lastName}`}
            >
              <Typography variant="body2" sx={{ textDecoration: "underline" }}>
                {currentAuditLogEmail ? currentAuditLogEmail : ""}
              </Typography>
            </Tooltip>
          </FlexBox>

          <FlexBox alignItems="stretch">
            <Box
              sx={{
                padding: "0px 24px 48px",
                width: "65%",
                maxHeight: "calc(100vh - 300px)",
                overflowY: "auto",
              }}
            >
              {/* <Box
            sx={{
              my: 4,
              padding: "12px",
              border: `1px solid ${theme.palette.borderColor.light}`,
              borderRadius: "8px",
              pr: 16,
            }}
          >
            <Typography variant="body2" color="grey.600">
              Call Details
            </Typography>
            <FlexBox py={5} justifyContent="space-between">
              <FlexBox>
                <Phone style={{ flexShrink: 0 }} width={32} height={32} />
                <Box>
                  <Typography variant="body2">Phone Number</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {callDetails?.phoneNumber}
                  </Typography>
                </Box>
              </FlexBox>

              <FlexBox display="flex" gap={4}>
                <IDBox style={{ flexShrink: 0 }} width={32} height={32} />
                <Box>
                  <Typography variant="body2">ID</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {callDetails?.voiceCallId}
                  </Typography>
                </Box>
              </FlexBox>

              <FlexBox display="flex" gap={4}>
                <VoiceScan style={{ flexShrink: 0 }} width={32} height={32} />
                <Box>
                  <Typography variant="body1">Call Type</Typography>
                  <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                    {callDetails?.callType === "virtualAgent" &&
                      "Virtual Agent"}
                    {callDetails?.callType === "agentHandOff" &&
                      "Agent Handoff"}
                    {callDetails?.callType === "blank" && "Blank"}
                  </Typography>
                </Box>
              </FlexBox>
              <FlexBox display="flex" gap={4}>
                <CalendarBlank
                  style={{ flexShrink: 0 }}
                  width={32}
                  height={32}
                />
                <Box>
                  <Typography variant="body2">Date and time</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {moment
                      .utc(callDetails?.startTime)
                      .local()
                      .format("D MMM, hh:mm A")}
                  </Typography>
                </Box>
              </FlexBox>
            </FlexBox>
          </Box> */}

              <FlexBox mb={4} className={styles.stickyAudioContainer}>
                <AudioPlayer
                  className={styles.audio}
                  style={{ width: "100%" }}
                  controls
                  audioPath={callDetails?.gcsUri}
                  onlyAudio
                />
              </FlexBox>

              {/* transcript of the call */}
              <Typography variant="body2" fontWeight={500}>
                Transcript
              </Typography>
              <Box sx={{ flex: 1, overflowY: "scroll" }}>
                {callTranscript?.nodes.map((item, index) => {
                  return (
                    // this contains a pair of both yara and you message
                    <div key={index} className={styles.historyContainer}>
                      {(item?.question?.length > 0 ||
                        typeof item?.question === "number") &&
                        (item?.vectorObject ? (
                          <FlexBox
                            sx={{
                              padding: "20px 0",
                              alignItems: "flex-start",
                              borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                              "& div": { borderBottom: "none" },
                            }}
                          >
                            <Box className={`${styles.avatar} ${styles.you}`}>
                              Customer
                            </Box>
                            <div style={{ width: "100%" }}>
                              <FunCollapse
                                iconPosition={"start"}
                                header={
                                  <>
                                    <FlexBox
                                      key={index}
                                      className={styles.historyItem}
                                      style={{ padding: 0 }}
                                    >
                                      <Typography
                                        variant="body2"
                                        fontWeight={500}
                                      >
                                        {item?.question}
                                      </Typography>
                                    </FlexBox>
                                  </>
                                }
                              >
                                <Box py={2}>
                                  <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    color="textSecondary"
                                  >
                                    Referenced intent
                                  </Typography>

                                  <Typography variant="body2" mt={1}>
                                    {item?.vectorObject.question}
                                  </Typography>

                                  <Typography
                                    mt={2}
                                    variant="body2"
                                    fontWeight={500}
                                    color="textSecondary"
                                  >
                                    Suggestion
                                  </Typography>
                                  {(item?.vectorObject?.type === "faq" ||
                                    item?.vectorObject?.type ===
                                      "smartPlus") && (
                                    <Typography
                                      variant="body2"
                                      mt={1}
                                      // sx={{ whiteSpace: "break-spaces" }}
                                      sx={{
                                        wordWrap: "break-word",
                                        maxWidth: "80%",
                                      }}
                                    >
                                      {item?.vectorObject.answer}
                                    </Typography>
                                  )}

                                  {item?.vectorObject?.type === "workflow" && (
                                    <FlexBox mt={1} columnGap={1}>
                                      <FlowArrow
                                        className={styles.flowArrow}
                                        width={12}
                                        height={12}
                                      />
                                      <Typography
                                        variant="body2"
                                        color={theme.palette.primary.main}
                                      >
                                        Workflow linked:{" "}
                                        <Link
                                          target="_blank"
                                          to={`/workflows/${
                                            workflowList?.workFlows?.find(
                                              (workflow) =>
                                                workflow._id ===
                                                item?.vectorObject?.workFlowId
                                            )?._id
                                          }`}
                                        >
                                          {
                                            workflowList?.workFlows?.find(
                                              (workflow) =>
                                                workflow._id ===
                                                item?.vectorObject?.workFlowId
                                            )?.name
                                          }
                                        </Link>
                                      </Typography>
                                    </FlexBox>
                                  )}
                                </Box>
                              </FunCollapse>
                            </div>
                            <FlexBox
                              sx={{ ml: "auto", gap: 1 }}
                              className={
                                item?.type === "fallbackWorkflow"
                                  ? styles.redLabel
                                  : digressionThreshold <
                                    item?.vectorObject?._additional.distance
                                  ? styles.label
                                  : styles.greenLabel
                              }
                            >
                              {item?.vectorObject?._additional.distance}
                            </FlexBox>
                          </FlexBox>
                        ) : (
                          <FlexBox key={index} className={styles.historyItem}>
                            <Box className={`${styles.avatar} ${styles.you}`}>
                              Customer
                            </Box>
                            <Typography variant="body2" fontWeight={500}>
                              {item?.question}
                            </Typography>
                          </FlexBox>
                        ))}
                      <FlexBox className={styles.historyItem}>
                        <Box className={`${styles.avatar} ${styles.yara}`}>
                          Yara
                        </Box>
                        <Box
                          sx={{ maxWidth: "70%" }}
                          display="flex"
                          gap={2}
                          flexDirection="column"
                        >
                          {item?.answer.map((ans, index) =>
                            ans?.message ? (
                              <Typography
                                variant="body2"
                                fontWeight={500}
                                key={index}
                                // sx={{
                                //   // "& + &": { mt: 2 },
                                //   // whiteSpace: "break-spaces",
                                // }}
                                sx={{
                                  wordWrap: "break-word",
                                  // maxWidth: "80%",
                                  minWidth: "20ch",
                                }}
                              >
                                {ans?.message}
                              </Typography>
                            ) : (
                              <AudioPlayer
                                controls
                                style={{ mt: 2 }}
                                className={styles.audio}
                                audioPath={ans?.audio}
                              />
                            )
                          )}
                        </Box>
                        {/* {typeof item?.answer === "string" ? (
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        sx={{ maxWidth: "70%", whiteSpace: "break-spaces" }}
                      >
                        {item?.answer}
                      </Typography>
                    ) : (
                      <Box sx={{ maxWidth: "70%" }}>
                        {item?.answer.map((ans, index) => (
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            key={index}
                            sx={{
                              "& + &": { mt: 2 },
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {ans}
                          </Typography>
                        ))}
                      </Box>
                    )} */}
                        {item?.totalTime && (
                          <FlexBox sx={{ ml: "auto", gap: 1 }}>
                            <Zap
                              width={12}
                              height={12}
                              fill={theme.palette.grey[300]}
                              stroke={theme.palette.grey[300]}
                            />
                            <Typography variant="caption" component={"div"}>
                              {item?.totalTime?.toFixed(2)}s
                            </Typography>
                          </FlexBox>
                        )}

                        {item?.createdAt && (
                          <Typography variant="caption" component={"div"}>
                            {/* format "2024-03-14T12:27:52.115Z" to 5:40:01 pm */}
                            {new Date(item?.createdAt).toLocaleString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                              hour12: true,
                            })}
                          </Typography>
                        )}
                      </FlexBox>
                    </div>
                  );
                })}
              </Box>

              <Box className={styles.greyBox}>
                {lastIntentObject ? (
                  <>
                    <FlexBox>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        color="grey.600"
                      >
                        Identified Intent
                      </Typography>

                      <Box
                        className={`
                    ${styles.label}
                    ${
                      lastIntentMatchedAsperBotThreshold === false
                        ? styles.redLabel
                        : styles.greenLabel
                    }
                    `}
                      >
                        Distance: {lastIntentObject?._additional?.distance}
                      </Box>

                      <Box className={styles.categoryContainer}>
                        {callTranscript?.parentCategoryName} &gt;{" "}
                        {callTranscript?.subCategoryName}
                      </Box>

                      {lastIntentObject?.userEditedIntent && (
                        <Box
                          display="flex"
                          gap={2}
                          alignItems="center"
                          padding="4px 8px"
                        >
                          <Eye height={20} width={20} />

                          <Typography variant="caption" color="#0f0f0f">
                            {`Intent Edited - 
                        ${
                          getFormattedFullNameAndIntials(
                            lastIntentObject.userEditedIntent?.firstName,
                            lastIntentObject.userEditedIntent?.lastName
                          ).fullName
                        }, ${moment
                              .utc(
                                lastIntentObject?.userEditedIntentDateAndTime
                              )
                              .local()
                              .format("hh:mm a")}`}
                          </Typography>
                        </Box>
                      )}
                    </FlexBox>

                    <Typography variant="body2" mt={1}>
                      {lastIntentObject?.question}
                    </Typography>

                    <Typography
                      mt={4}
                      variant="body1"
                      fontWeight={500}
                      color="grey.600"
                    >
                      Response
                    </Typography>
                    <Typography variant="body2">
                      {lastIntentMatchedAsperBotThreshold !== false ? (
                        lastIntentObject?.type === "faq" ||
                        lastIntentObject?.type === "smartPlus" ? (
                          <Typography
                            variant="body2"
                            mt={2}
                            sx={{ whiteSpace: "break-spaces" }}
                          >
                            {lastIntentObject?.answer}
                          </Typography>
                        ) : (
                          lastIntentObject?.type === "workflow" && (
                            <FlexBox columnGap={1}>
                              <FlowArrow
                                className={styles.flowArrow}
                                width={12}
                                height={12}
                              />
                              <Typography
                                variant="body2"
                                color={theme.palette.primary.main}
                              >
                                Workflow linked:{" "}
                                <Link
                                  target="_blank"
                                  to={`/workflows/${
                                    workflowList?.workFlows?.find(
                                      (item) =>
                                        item._id ===
                                        lastIntentObject?.workFlowId
                                    )?._id
                                  }`}
                                >
                                  {
                                    workflowList?.workFlows?.find(
                                      (item) =>
                                        item._id ===
                                        lastIntentObject?.workFlowId
                                    )?.name
                                  }
                                </Link>
                              </Typography>
                            </FlexBox>
                          )
                        )
                      ) : (
                        <Typography variant="body2" mt={2}>
                          No recommended solution
                        </Typography>
                      )}
                    </Typography>

                    <FlexBox mt={2}>
                      {lastIntentMatchedAsperBotThreshold ? (
                        <Button
                          className={styles.button}
                          variant="outlined"
                          startIcon={<PencilSimple width={16} height={16} />}
                          onClick={() => {
                            setSelectedQuestion(lastIntentObject.mongoId);
                            setEditContainer(true);
                            setAddVariant(false);
                          }}
                        >
                          Edit Intent
                        </Button>
                      ) : (
                        <>
                          <Button
                            className={styles.button}
                            variant="outlined"
                            startIcon={
                              <img
                                src={Variation}
                                alt="misc"
                                width={16}
                                height={16}
                              />
                            }
                            onClick={() => {
                              setSelectedQuestion(lastIntentObject.mongoId);
                              setEditContainer(true);
                              setAddVariant(true);
                            }}
                          >
                            Add as Variation
                          </Button>
                          <Button
                            className={styles.button}
                            variant="outlined"
                            startIcon={<PlusCircle width={16} height={16} />}
                            onClick={() => {
                              setEditContainer(true);
                              setAddQuestion(true);
                              setSelectedQuestion(variationToAdd);
                            }}
                          >
                            Add Intent
                          </Button>
                        </>
                      )}
                    </FlexBox>
                  </>
                ) : (
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    color="#0f0f0f99"
                  >
                    Blank Call
                  </Typography>
                )}
              </Box>

              <Box mt={5}>
                <Typography variant="body2" mb={5}>
                  Agent Details
                </Typography>

                <FlexBox width={"100%"} alignItems="flex-start">
                  <FlexBox width="25%" alignItems="flex-start">
                    <IconUser1 width={32} height={32} />
                    <Box>
                      <Typography variant="body2" color="grey.600">
                        Agent Name
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {callDetails?.agentName}
                        {getFormattedFullNameAndIntials(
                          callDetails?.auditUser?.firstName,
                          callDetails?.auditUser?.lastName
                        ).fullName || "N/A"}
                      </Typography>
                    </Box>
                  </FlexBox>
                  <FlexBox width="25%" alignItems="flex-start">
                    <Mail color="#21272A" width={32} height={32} />
                    <Box>
                      <Typography variant="body2" color="grey.600">
                        Email
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {callDetails?.auditUser?.email}
                      </Typography>
                    </Box>
                  </FlexBox>
                  <FlexBox width="25%" alignItems="flex-start">
                    <MessageDots width={32} height={32} />
                    <Box>
                      <FlexBox>
                        <Typography variant="body2" color="grey.600">
                          Feedback 1
                        </Typography>
                        <Tooltip title="Is their an intent match?">
                          <Info width={16} height={16} />
                        </Tooltip>
                      </FlexBox>
                      <FlexBox columnGap={1}>
                        {callDetails?.isIntentMatchedUserResponse === true && (
                          <ThumbUpGreenFilled width={16} height={16} />
                        )}
                        {callDetails?.isIntentMatchedUserResponse === false && (
                          <ThumbDownRedFilled width={16} height={16} />
                        )}
                        <Typography
                          variant="body2"
                          // color={
                          //   callDetails?.isIntentMatchedUserResponse === true
                          //     ? "tag.main"
                          //     : callDetails?.isIntentMatchedUserResponse ===
                          //         false && "error.main"
                          // }
                        >
                          {callDetails?.isIntentMatchedUserResponse === false
                            ? "Dissatisfied"
                            : callDetails?.isIntentMatchedUserResponse == null
                            ? "Unrated"
                            : "Satisfied"}
                        </Typography>
                      </FlexBox>

                      {callDetails?.intentMatchedUserResponseExplanation &&
                        (callDetails?.intentMatchedUserResponseExplanation
                          .length > 40 ? (
                          <Tooltip
                            title={
                              callDetails?.intentMatchedUserResponseExplanation
                            }
                          >
                            <Typography
                              variant="caption"
                              fontWeight={400}
                              color="#0F0F0F99"
                            >
                              Comment:{" "}
                              {callDetails?.intentMatchedUserResponseExplanation.slice(
                                0,
                                40
                              ) + "..."}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            variant="caption"
                            fontWeight={400}
                            color="#0F0F0F99"
                          >
                            Comment:{" "}
                            {callDetails?.intentMatchedUserResponseExplanation}
                          </Typography>
                        ))}
                    </Box>
                  </FlexBox>
                  <FlexBox width="25%" alignItems="flex-start">
                    <MessageDots width={32} height={32} />
                    <Box>
                      <FlexBox>
                        <Typography variant="body2" color="grey.600">
                          Feedback 2
                        </Typography>
                        <Tooltip title="Is the answer correct?">
                          <Info width={16} height={16} />
                        </Tooltip>
                      </FlexBox>
                      <FlexBox columnGap={1}>
                        {callDetails?.isAnswerCorrect === true && (
                          <ThumbUpGreenFilled width={16} height={16} />
                        )}
                        {callDetails?.isAnswerCorrect === false && (
                          <ThumbDownRedFilled width={16} height={16} />
                        )}
                        <Typography
                          variant="body2"
                          // color={
                          //   callDetails?.isAnswerCorrect === true
                          //     ? "tag.main"
                          //     : callDetails?.isAnswerCorrect === false &&
                          //       "error.main"
                          // }
                        >
                          {callDetails?.isAnswerCorrect === false
                            ? "Dissatisfied"
                            : callDetails?.isAnswerCorrect == null
                            ? "Unrated"
                            : "Satisfied"}
                        </Typography>
                      </FlexBox>

                      {callDetails?.answerCorrectnessExplanation &&
                        (callDetails?.answerCorrectnessExplanation.length >
                        40 ? (
                          <Tooltip
                            title={callDetails?.answerCorrectnessExplanation}
                          >
                            <Typography
                              variant="caption"
                              fontWeight={400}
                              color="#0F0F0F99"
                            >
                              Comment:{" "}
                              {callDetails?.answerCorrectnessExplanation.slice(
                                0,
                                40
                              ) + "..."}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            fontWeight={400}
                            color="#0F0F0F99"
                            variant="caption"
                          >
                            Comment: {callDetails?.answerCorrectnessExplanation}
                          </Typography>
                        ))}
                    </Box>
                  </FlexBox>
                </FlexBox>
              </Box>

              {/* <FlexBox py={5} justifyContent="space-between">
            <Box display="flex" gap={4}>
              <PersonBoundingBox width={32} height={32} />
              <Box>
                <Typography variant="body2">Call ID</Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                  {callDetails?.callId}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={4}>
              <PhoneCall width={32} height={32} />
              <Box>
                <Typography variant="body2">Phone Number</Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                  {callDetails?.phoneNumber}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={4}>
              <CalendarBlank width={32} height={32} />
              <Box>
                <Typography variant="body2">Timestamp</Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                  {moment(callDetails?.timestamp)
                    .local()
                    .format("D MMM YYYY, hh:mm:ss A")}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={4}>
              <IconUser1 width={32} height={32} />
              <Box>
                <Typography variant="body2">Agent Name</Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                  {callDetails?.agentName}
                </Typography>
              </Box>
            </Box>
          </FlexBox>
          <Divider sx={{ backgroundColor: theme.palette.borderColor.light }} />
          <FlexBox py={5} justifyContent="space-between">
            <Box>
              <Typography variant="body2" mb={1}>
                Audio
              </Typography>
              <audio
                controls
                src={`${gscUriPrefix}${callDetails?.audio?.split("//")[1]}`}
              />
            </Box>
            <Box>
              <Typography variant="body2" mb={1}>
                Call Type
              </Typography>
              <Typography variant="subtitle2" mb={1} sx={{ fontWeight: 500 }}>
                {callDetails?.callType}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" mb={1}>
                Feedback
              </Typography>
              <Box display="flex" alignItems="center" columnGap={2}>
                {callDetails?.reaction === true && (
                  <ThumbsUpFill width={16} height={16} />
                )}
                {callDetails?.reaction === false && (
                  <ThumbsDown width={16} height={16} fill="#0F0F0F" />
                )}
                <Typography variant="subtitle2" mb={1} sx={{ fontWeight: 500 }}>
                  {callDetails?.reaction === null && "unrated"}
                  {callDetails?.reaction === true && "satisfied"}
                  {callDetails?.reaction === false && "disatisfied"}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" mb={1}>
                Total Response Time
              </Typography>
              <Typography variant="subtitle2" mb={1} sx={{ fontWeight: 500 }}>
                {(callDetails?.totalResponseTime / 1000).toFixed(2)} sec(s)
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" mb={1}>
                Audit Log
              </Typography>

              <Box sx={{ position: "relative" }}>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: 82, padding: "4px 16px", minWidth: 0 }}
                  endIcon={<ChevronDown />}
                  onMouseOver={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                >
                  {currentAuditLogValue ? currentAuditLogValue : "None"}
                </Button>
                <Fade in={showMenu} unmountOnExit={true} mountOnEnter={true}>
                  <Box
                    className={styles.menuContainer}
                    onMouseOver={() => setShowMenu(true)}
                    onMouseLeave={() => setShowMenu(false)}
                  >
                    <Box
                      sx={{ position: "relative" }}
                      onMouseOver={() => setShowValidMenu(true)}
                      onMouseLeave={() => setShowValidMenu(false)}
                    >
                      <Typography
                        variant="subtitle2"
                        mb={1}
                        sx={{ fontWeight: 500 }}
                      >
                        Valid
                      </Typography>
                      <Fade
                        in={showValidMenu}
                        unmountOnExit={true}
                        mountOnEnter={true}
                      >
                        <Box
                          className={styles.menuContainer}
                          style={{
                            transform: "translate(-100%, 100%)",
                            left: 0,
                          }}
                          onMouseOver={() => setShowValidMenu(true)}
                          onMouseLeave={() => setShowValidMenu(false)}
                        >
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Valid",
                                auditedCallTypeSub: "Correct QnA Matched",
                              })
                            }
                          >
                            Correct QnA Matched
                          </Box>
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Valid",
                                auditedCallTypeSub: "Incorrect query matched",
                              })
                            }
                          >
                            Incorrect query matched
                          </Box>
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Valid",
                                auditedCallTypeSub: "No matching query",
                              })
                            }
                          >
                            No matching query
                          </Box>
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Valid",
                                auditedCallTypeSub: "Invalid customer question",
                              })
                            }
                          >
                            Invalid customer question
                          </Box>
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Valid",
                                auditedCallTypeSub: "Distance exceeded",
                              })
                            }
                          >
                            Distance exceeded
                          </Box>
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Valid",
                                auditedCallTypeSub: "Incorrect transcription",
                              })
                            }
                          >
                            Incorrect transcription
                          </Box>
                        </Box>
                      </Fade>
                    </Box>
                    <Box
                      onMouseOver={() => setShowBlankMenu(true)}
                      onMouseLeave={() => setShowBlankMenu(false)}
                      sx={{ position: "relative" }}
                    >
                      <Typography
                        variant="subtitle2"
                        mb={1}
                        sx={{ fontWeight: 500 }}
                      >
                        Blank
                      </Typography>
                      <Fade
                        in={showBlankMenu}
                        unmountOnExit={true}
                        mountOnEnter={true}
                      >
                        <Box
                          className={styles.menuContainer}
                          style={{
                            transform: "translate(-100%, 70%)",
                            left: 0,
                          }}
                          onMouseOver={() => setShowBlankMenu(true)}
                          onMouseLeave={() => setShowBlankMenu(false)}
                        >
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Blank",
                                auditedCallTypeSub: "No voice",
                              })
                            }
                          >
                            No voice
                          </Box>
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Blank",
                                auditedCallTypeSub: "Unclear voice",
                              })
                            }
                          >
                            Unclear voice
                          </Box>
                        </Box>
                      </Fade>
                    </Box>

                    <Box
                      sx={{ position: "relative" }}
                      onMouseOver={() => setShowJunkMenu(true)}
                      onMouseLeave={() => setShowJunkMenu(false)}
                    >
                      <Typography
                        variant="subtitle2"
                        mb={1}
                        sx={{ fontWeight: 500 }}
                      >
                        Junk
                      </Typography>
                      <Fade
                        in={showJunkMenu}
                        unmountOnExit={true}
                        mountOnEnter={true}
                      >
                        <Box
                          className={styles.menuContainer}
                          style={{
                            transform: "translate(-100%, 70%)",
                            left: 0,
                          }}
                          onMouseOver={() => setShowJunkMenu(true)}
                          onMouseLeave={() => setShowJunkMenu(false)}
                        >
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Junk",
                                auditedCallTypeSub: "Irrelevant",
                              })
                            }
                          >
                            Irrelevant
                          </Box>
                          <Box
                            onClick={() =>
                              addAuditLog(callDetails._id, {
                                auditedCallType: "Junk",
                                auditedCallTypeSub: "Abusive",
                              })
                            }
                          >
                            Abusive
                          </Box>
                        </Box>
                      </Fade>
                    </Box>
                  </Box>
                </Fade>
              </Box>
            </Box>
          </FlexBox>
          <Divider sx={{ backgroundColor: theme.palette.borderColor.light }} />
          {callDetails?.reaction === false && (
            <FlexBox py={5} justifyContent="flex-start">
              <ChatTeardropDots
                width={32}
                height={32}
                style={{ flexShrink: 0, alignSelf: "flex-start" }}
              />
              <Box>
                <Typography variant="body2">Comments</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {callDetails?.thumbsDownReason}
                </Typography>
              </Box>
            </FlexBox>
          )}
          <Divider sx={{ backgroundColor: theme.palette.borderColor.light }} />
          <FlexBox py={5} justifyContent="flex-start" columnGap={4}>
            <TextAlignLeft style={{ flexShrink: 0, alignSelf: "flex-start" }} />
            <Box width={"100%"}>
              <Box>
                <Typography variant="body2">
                  Customer’s Original Question
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {callDetails?.question}
                </Typography>
              </Box>

              <Box mt={3}>
                <Typography variant="body2">
                  Customer’s Processed Question
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {callDetails?.gptLog?.value?.message || "N/A"}
                </Typography>
              </Box>

              <Box mt={3}>
                <Typography variant="body2">
                  Referenced Question from Database
                </Typography>
                <FlexBox>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {callDetails?.weaviateQuestionPicked || "N/A"}
                  </Typography>

                  {callDetails?.logs &&
                    callDetails?.vectorDbLog?.value?.distance && (
                      <Chip
                        className={styles.chip}
                        label={`Distance: ${callDetails?.vectorDbLog.value.distance.toString()}`}
                      />
                    )}
                </FlexBox>
              </Box>

              <Box mt={3} fullWidth>
                <Typography variant="body2">Answer suggested</Typography>
                {editAnswer ? (
                  <TextField
                    multiline
                    fullWidth
                    autoFocus
                    variant="standard"
                    defaultValue={callDetails?.answer}
                    onChange={(e) => setNewAnswer(e.target.value)}
                    sx={{
                      mt: 2,
                    }}
                    inputProps={{
                      style: {
                        fontSize: 14,
                        lineHeight: "1.25rem",
                      },
                    }}
                    onFocus={(e) => {
                      const val = e.target.value;
                      e.target.value = "";
                      e.target.value = val;
                      setNewAnswer(e.target.value);
                    }}
                  />
                ) : (
                  <Typography
                    variant="subtitle1"
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {callDetails?.answer || "N/A"}
                  </Typography>
                )}
              </Box>
              <RoleBasedAccess>
                {callDetails?.vectorDbLog?.value?.thresholdMatch &&
                  callDetails?.weaviateQuestionPicked && (
                    <Box mt={3}>
                      {editAnswer ? (
                        <FlexBox>
                          <Button
                            variant="outlined"
                            sx={{
                              borderRadius: 82,
                              padding: "6px 14px",
                              fontSize: 12,
                            }}
                            onClick={handleDiscardChanges}
                          >
                            Discard Changes
                          </Button>
                          <Button
                            sx={{
                              fontSize: 12,
                              padding: "6px 14px",
                              minWidth: 0,
                            }}
                            endIcon={
                              <ArrowForwardIos
                                style={{ width: 12, height: 12 }}
                              />
                            }
                            variant="dark"
                            onClick={handleSaveAnswer}
                          >
                            Save Answer
                          </Button>
                        </FlexBox>
                      ) : (
                        <Button
                          startIcon={
                            <PencilSimple style={{ width: 16, height: 16 }} />
                          }
                          variant="outlined"
                          sx={{
                            borderRadius: 82,
                            padding: "6px 14px",
                            fontSize: 12,
                          }}
                          onClick={handleEditAnswer}
                        >
                          Edit Answer
                        </Button>
                      )}
                    </Box>
                  )}
              </RoleBasedAccess>
            </Box>
          </FlexBox>
          {callDetails?.logs?.length > 0 && (
            <>
              <Divider
                sx={{ backgroundColor: theme.palette.borderColor.light }}
              />
              <Box py={5}>
                <FunCollapse
                  iconPosition={"end"}
                  manualOpen={false}
                  header={
                    <FlexBox columnGap={4}>
                      <ArrowsDownUp width={32} height={32} />
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Additional Logs
                      </Typography>
                    </FlexBox>
                  }
                >
                  <Box sx={{ maxWidth: 400, padding: "0px 40px" }}>
                    <Stepper
                      orientation="vertical"
                      sx={{
                        "& .MuiStepContent-root": {
                          borderLeft: "2px solid #0f0f0f",
                        },
                        "& .MuiStep-root:last-of-type .MuiStepContent-root": {
                          borderLeft: 0,
                        },
                        "& .MuiStepConnector-line": {
                          borderLeft: "2px solid #0f0f0f",
                        },
                        "& .MuiStepLabel-label ": {
                          fontSize: 14,
                          fontWeight: 400,
                          padding: 0,
                        },
                      }}
                    >
                      {callDetails?.logs?.map((log) => (
                        <Step key={log?.processId} active={true}>
                          <StepLabel
                            icon={<div className={styles.dot} />}
                            sx={{ fontSize: 12 }}
                            style={{ fontSize: 12 }}
                          >
                            {log?.process}
                          </StepLabel>
                          <StepContent>
                            <Typography fontSize={10}>
                              {(
                                moment(log?.endTime).diff(
                                  moment(log?.startTime)
                                ) / 1000
                              ).toFixed(2)}{" "}
                              sec
                            </Typography>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </FunCollapse>
              </Box>
            </>
          )} */}
            </Box>

            <Box
              sx={{
                width: "35%",
                maxHeight: "calc(100vh - 300px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                sx={{
                  padding: "17px",
                  backgroundColor: theme.palette.grey[50],
                }}
              >
                <Typography variant="body2">Call Metadata</Typography>
              </Box>
              <Box>
                {editedValues && editedValues?.length > 0 && (
                  <>
                    <Box
                      sx={{
                        "& > div:first-of-type": {
                          paddingRight: 8,
                          backgroundColor: theme.palette.grey[50],
                          borderRadius: "12px",
                          marginTop: "16px",
                        },
                      }}
                    >
                      <FunCollapse
                        collapsed={showCustomVariables}
                        iconPosition={"end"}
                        header={
                          <Typography variant="body2" sx={{ padding: "16px" }}>
                            Custom variables
                          </Typography>
                        }
                      >
                        <Table className={styles.table}>
                          <TableBody>
                            {editedValues?.map((value, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  style={{
                                    backgroundColor: theme.palette.grey[50],
                                  }}
                                >
                                  <Typography variant="body2">
                                    {value.key}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <FlexBox>
                                    {editingIndex === index ? (
                                      <TextField
                                        sx={{
                                          flex: 1,
                                        }}
                                        variant="standard"
                                        value={editedValues[index].value}
                                        onChange={(e) => {
                                          const newValues = [...editedValues];
                                          newValues[index].value =
                                            e.target.value;
                                          setEditedValues(newValues);
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Escape") {
                                            setEditingIndex(null);
                                          }

                                          if (e.key === "Enter") {
                                            setEditingIndex(null);
                                            editVariables();
                                          }
                                        }}
                                        autoFocus
                                        inputProps={{
                                          style: {
                                            fontSize: 14,
                                            transitionDuration: 0.4,
                                            paddingLeft: 0,
                                          },
                                        }}
                                      />
                                    ) : (
                                      <Typography
                                        sx={{
                                          flex: 1,
                                          maxWidth: "20ch",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                        }}
                                        variant="body2"
                                      >
                                        {value.value !== undefined &&
                                        value.value !== null
                                          ? "" + value.value
                                          : "--"}
                                      </Typography>
                                    )}

                                    {editingIndex === index ? (
                                      <IconButton
                                        onClick={() => {
                                          setEditingIndex(null);
                                          editVariables();
                                        }}
                                      >
                                        <Check width={16} height={16} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => setEditingIndex(index)}
                                      >
                                        <PencilSimple
                                          width={16}
                                          height={16}
                                          color={theme.palette.text.primary}
                                        />
                                      </IconButton>
                                    )}
                                  </FlexBox>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </FunCollapse>
                    </Box>
                  </>
                )}

                {typeof variables.system === "object" &&
                  Object.keys(variables.system).length > 0 && (
                    <>
                      <Box
                        sx={{
                          "& > div:first-of-type": {
                            backgroundColor: theme.palette.grey[50],
                            borderRadius: "12px",
                            marginTop: "16px",
                            paddingRight: 8,
                          },
                        }}
                      >
                        <FunCollapse
                          iconPosition={"end"}
                          header={
                            <Typography
                              variant="body2"
                              sx={{ padding: "16px" }}
                            >
                              System variables
                            </Typography>
                          }
                        >
                          <Table className={styles.table}>
                            <TableBody>
                              {Object.entries(variables.system).map(
                                ([key, value]) => (
                                  <TableRow key={key}>
                                    <TableCell
                                      style={{
                                        backgroundColor: theme.palette.grey[50],
                                      }}
                                    >
                                      <Typography variant="body2">
                                        system.{key}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {value !== undefined && value !== null
                                          ? "" + value
                                          : "--"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </FunCollapse>
                      </Box>
                    </>
                  )}

                {typeof variables.session === "object" &&
                  Object.keys(variables.session).length > 0 && (
                    <>
                      <Box
                        sx={{
                          "& > div:first-of-type": {
                            paddingRight: 8,
                            backgroundColor: theme.palette.grey[50],
                            borderRadius: "12px",
                            marginTop: "16px",
                          },
                        }}
                      >
                        <FunCollapse
                          iconPosition={"end"}
                          header={
                            <Typography
                              variant="body2"
                              sx={{ padding: "16px" }}
                            >
                              Session variables
                            </Typography>
                          }
                        >
                          <Table className={styles.table}>
                            <TableBody>
                              {Object.entries(variables.session).map(
                                ([key, value]) => (
                                  <TableRow key={key}>
                                    <TableCell
                                      style={{
                                        backgroundColor: theme.palette.grey[50],
                                      }}
                                    >
                                      <Typography variant="body2">
                                        session.{key}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {key === "start_time"
                                          ? moment(value).format("hh:mm:ss A")
                                          : value !== undefined &&
                                            value !== null
                                          ? "" + value
                                          : "--"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </FunCollapse>
                      </Box>
                    </>
                  )}
              </Box>
            </Box>
          </FlexBox>
        </Box>
      )}
      <EditContainer
        show={editContainer}
        onClose={() => {
          setEditContainer(false);
          setSelectedQuestion(null);
          setAddVariant(false);
          setAddQuestion(false);
        }}
        question={selectedQuestion}
        onChangeIntent={handleonChangeIntent}
        questionVariantToAdd={addVariant ? variationToAdd : null}
        addQuestion={addQuestion}
      />
    </>
  );
};

export default CallDetail;
