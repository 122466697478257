import { FolderCopyOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import FundamentoPopup from "components/FundamentoPopup";
import FundamentoTable from "components/FundamentoTable";
import { IconCirclePlus, IconPause, WarningCircle } from "components/SVG";
import { CheckCircle, Circle, MinusCircle, Sparkle } from "components/newSVG";
import { useEffect, useState } from "react";
import { ChevronDown, Download, Loader, MinusSquare } from "react-feather";
import getDataSetQuestionsAnswers from "services/getDataSetQuestionsAnswers";
import { useParams } from "react-router-dom";
import styles from "./index.module.css";
import theme from "theme";
import EmptyState from "components/EmptyState";
import generateAnswers from "services/generateAnswers";
import { useSkillrToast } from "context/toast";
import deleteOrKeepDupliacteQuestion from "services/deleteDuplicateQs";
import duplicateCheck from "services/duplicateCheck";
import {
  downloadQuestionAnswerCsv,
  getDataSetCategory as getDataSetCategoryAPI,
  getQnaDownloadLink,
  createQuesAns as createQuesAnsApi,
  getSingleDataSet as getSingleDataSetApi,
  stopAnswerGeneration as stopAnswerGenerationApi,
  answerGenerationProgressCount as answerGenerationProgressCountApi,
} from "services";
import FlexBox from "components/FlexBox";
import { useDatasetContext } from "context/datasetDashboard";
import LoaderState from "../components/LoaderState";
import { useAdminSocket } from "context/socket";
import { useUserContext } from "context/user";
import { getJSONDecoded } from "utils/utils";
import fileDownload from "js-file-download";
import AddQAManually from "../components/AddQAManually";
import SearchBox from "components/SearchBox";
import _ from "lodash";

const Questions = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const [duplicateCheckProgress, setDuplicateCheckProgress] = useState(false);
  // const [duplicateCheckDone, setDuplicateCheckDone] = useState(false);
  // const [duplicateQuestion, setDuplicateQuestions] = useState();
  // const [duplicatFailureMessage, setDuplicateFailureMessage] = useState();
  const questionsForDuplicateCheck = [];
  let params = useParams();
  const { showSkillrToast } = useSkillrToast();
  const [categories, setCategories] = useState([]);
  // const [answerStatus, setAnswerStatus] = useState();
  // const [selectedCategory, setSelectedCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState();
  // const [selectedAcrossPages, setSelectedAcrossPages] = useState(false);
  // const [selectedAcrossPagesLength,setSelectedAcrossPagesLength]=useState()
  const [manualQA, setManualQA] = useState(false);
  const [search, setSearch] = useState("");
  const [datasetDetails, setDataSetDetails] = useState();
  const [answerGenerationProgress, setAnswerGenerationProgress] = useState();

  const {
    selectedFiles,
    setSelectedFiles,
    duplicateCheckProgress,
    setDuplicateCheckProgress,
    duplicatFailureMessage,
    setDuplicateFailureMessage,
    duplicateCheckDone,
    setDuplicateCheckDone,
    duplicateQuestion,
    setDuplicateQuestions,
    answerStatus,
    setAnswerStatus,
    selectedCategory,
    setSelectedCategory,
    selectedAcrossPages,
    setSelectedAcrossPages,
    selectedAcrossPagesLength,
    setSelectedAcrossPagesLength,
    totalDuplicateCheckDone,
    setTotalDuplicateCheckDone,
    totalPercentageOfDupCheck,
    setTotalPercentageOfDupCheck,
  } = useDatasetContext();
  const { token } = useUserContext();
  const tokenInfo = getJSONDecoded(token ?? null);
  const socket = useAdminSocket(tokenInfo?.companies?.[0]?.id);

  const handleSelectAll = (e) => {
    setSelectedAcrossPages(!selectedAcrossPages);
    if (selectedFiles.length === tableData.length) {
      setSelectedFiles([]);
    } else {
      setSelectedAcrossPages(true);
      setSelectedFiles(tableData.map((item) => item._id));
    }
  };

  const handleSelectOne = (id, checked) => {
    if (checked) {
      setSelectedFiles((prev) => [...prev, id]);
      setSelectedAcrossPages(false);
    } else {
      setSelectedFiles((prev) => prev.filter((fileId) => fileId !== id));
    }
  };
  const columns = [
    { field: "id", header: "" },
    {
      field: "check",
      // header: (
      //   <Checkbox
      //     onChange={handleSelectAll}
      //     icon={<Circle />}
      //     checkedIcon={<CheckCircle />}
      //   />
      // ),
      header: (
        // selectedFiles.length === 0 ? (
        //   <Checkbox onChange={handleSelectAll} />
        // ) : selectedFiles.length === tableData.length ? (
        //   <Checkbox onChange={handleSelectAll} checked />
        // ) : (
        //   <Box
        //     style={{
        //       padding: 9,
        //       cursor: "pointer",
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "center",
        //     }}
        //   >
        //     <MinusSquare
        //       width={20}
        //       height={20}
        //       color="#9D9D9D"
        //       onClick={handleSelectAll}
        //     />
        //   </Box>
        // ),
        <Checkbox
          // checked={selectedFiles.length === tableData.length}
          checked={selectedAcrossPages}
          indeterminate={
            selectedFiles.length > 0 && selectedFiles.length < tableData.length
          }
          onChange={handleSelectAll}
        />
      ),
      render: (rowData) =>
        selectedAcrossPages ? (
          <Checkbox checked={true} />
        ) : (
          <Checkbox
            // icon={<Circle />}
            // checkedIcon={<CheckCircle />}
            checked={selectedFiles.includes(rowData._id)}
            onChange={(e) => handleSelectOne(rowData._id, e.target.checked)}
          />
        ),
      style: { width: 40 },
    },
    { field: "question", header: "Question" },
    {
      field: "answer",
      header: "Answer Status",
      render: (row) => (row.answer ? "Answered" : "Unanswered"),
      style: { width: "15%" },
    },
    {
      field: "category",
      header: "Category",
      render: (row) => row.category?.name,
      style: { width: "15%" },
    },
  ];

  const handleDuplicationCheck = async () => {
    try {
      //  if (selectedFiles.length === 0) {
      //   showSkillrToast(
      //     "Please select questions to check for duplicates",
      //     "error"
      //   );
      //   return;
      // }
      if (duplicateCheckProgress || duplicateCheckDone) {
        showSkillrToast(
          "Duplication progress is still going or action pending",
          "error"
        );
        return;
      }

      // tableData.forEach((e) => {
      //   questionsForDuplicateCheck.push(e._id);
      // });
      setDuplicateCheckProgress(true);
      const dataSetId = params.dataSetId;
      const queryParams = new URLSearchParams(window.location.search);
      const apiData = {
        questions: selectedFiles,
        hasSelectedAll: selectedAcrossPages ? true : false,
      };
      const response = await duplicateCheck(apiData, dataSetId, queryParams);

      // setDuplicateCheckProgress(false);
      // setDuplicateCheckDone(true);
      // setLoading(false);
    } catch (error) {
      showSkillrToast(error.response?.data?.message ?? error?.message, "error");
      setDuplicateCheckProgress(false);
    }
  };

  const getAnswerGenerationCount = async () => {
    try {
      const answerGenerationResponse = await answerGenerationProgressCountApi(
        params.dataSetId
      );
      setAnswerGenerationProgress(answerGenerationResponse);
      return answerGenerationResponse;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const sendAnswerstoGenerate = async () => {
    if (selectedFiles.length === 0) {
      showSkillrToast("Please select questions to generate answers", "error");
      return;
    }
    try {
      const datasetId = params.dataSetId;
      showSkillrToast("Generating answers", "success");
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("hasAnswer", "false");
      setDataSetDetails({
        ...datasetDetails,
        isProcessingAnswerGeneration: true,
      });
      const response = await generateAnswers(
        {
          questionIds: selectedFiles,
          hasSelectedAll: selectedAcrossPages ? true : false,
        },
        datasetId,
        queryParams
      );
      setSelectedFiles([]);
      setSelectedAcrossPages(false);
      if (response === "Answers for these questions are already generated") {
        showSkillrToast(
          "Answers for these questions are already generated",
          "error"
        );
        return;
      } else {
        // showSkillrToast("Generated answers", "success");
      }
      getAnswerGenerationCount();
    } catch (error) {
      if (error.message) {
        showSkillrToast(error.message, "error");
      }
    }
  };

  const handleDuplicatesDelete = async () => {
    // const duplicateQuestionsToDelete = [];

    // duplicateQuestion.forEach((e) => {
    //   duplicateQuestionsToDelete.push(e._id);
    // });
    const datasetId = params.dataSetId;
    const apiData = { action: "delete", datasetId };
    const res = await deleteOrKeepDupliacteQuestion(apiData);
    if (res) {
      setDuplicateCheckProgress(false);
      setDuplicateCheckDone(false);
      setTotalPercentageOfDupCheck(0);
      fetchData();
    }
  };

  const handleKeepAll = async () => {
    try {
      const datasetId = params.dataSetId;
      const apiData = { action: "keepAll", datasetId };
      const res = await deleteOrKeepDupliacteQuestion(apiData);
      setDuplicateQuestions();
      setDuplicateCheckProgress(false);
      setDuplicateCheckDone(false);
      setTotalPercentageOfDupCheck(0);
    } catch (error) {
      console.error(error);
    }
  };

  const handleErrorMessage = (error) => {
    const errorMessage = error?.message
      ? error.messsage
      : "Something went wrong";
    showSkillrToast(errorMessage, "error");
  };

  const handleSocketDuplicateCheckUpdate = () => {
    const dataSetId = params.dataSetId;
    socket.on("duplicate_check", (data) => {
      try {
        if (data.dataSetId == dataSetId) {
          setDuplicateQuestions(data?.countDuplicateQues);
          setTotalDuplicateCheckDone(data?.completedLengthofDupCheck);
          const percentage = (
            (data?.completedLengthofDupCheck / data?.totalQuesInDBForDupCheck) *
            100
          ).toFixed(0);
          const formattedPercentage = parseInt(percentage);
          setTotalPercentageOfDupCheck(formattedPercentage);
          if (
            data?.completedLengthofDupCheck === data?.totalQuesInDBForDupCheck
          ) {
            setDuplicateCheckProgress(false);
            setDuplicateCheckDone(true);
          }
          // setDuplicateCheckProgress(false);
          // setDuplicateCheckDone(true);
          // setLoading(false);
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    });
  };

  const fetchData = async () => {
    try {
      const dataSetId = params.dataSetId;
      setLoading(true);
      // const queryParams = new URLSearchParams();
      const queryParams = new URLSearchParams(window.location.search);

      if (selectedCategory.length) {
        queryParams.set("categoryIds", selectedCategory);
      } else {
        // Unset the parameter if the condition is false
        queryParams.delete("categoryIds");
      }

      if (answerStatus === "answered" || answerStatus === "unanswered") {
        queryParams.set(
          "hasAnswer",
          answerStatus === "answered" ? "true" : "false"
        );
      } else {
        // Unset the parameter if the condition is false
        queryParams.delete("hasAnswer");
      }

      queryParams.set("pageNumber", currentPage);
      queryParams.set("searchByInQuestions", search);

      // console.log(queryParams.toString());
      const response = await getDataSetQuestionsAnswers(dataSetId, queryParams);
      setTotalPageCount(response?.pageCount);
      setTableData(response.data);
      setDuplicateQuestions(response?.countDuplicateQues);
      setSelectedAcrossPagesLength(response?.totalQuesInDBForDupCheck);
      if (
        !response?.countNewQues &&
        !response?.countDuplicateProcessing &&
        !response?.countDuplicateQues
      ) {
        setDuplicateCheckProgress(false);
        setDuplicateCheckDone(false);
      } else {
        if (response?.countDuplicateProcessing) {
          setDuplicateCheckProgress(true);
        } else if (response?.countNewQues || response?.countDuplicateQues) {
          setDuplicateCheckDone(true);
        }
      }

      // if(response?.countDuplicateQues ==0 &&response?.countNewQues == 0&&response?.countDuplicateProcessing==0){
      //   setDuplicateCheckProgress(false)
      //   setDuplicateCheckDone(false)
      // }
      // if(response?.countDuplicateProcessing == 0 && (response?.countNewQues !== 0 || response?.countDuplicateQues !==0)){
      //   setDuplicateCheckDone(true)
      // }
      // if()

      setLoading(false);
      // Create a new URL with the modified search parameters
      const newUrl = `${window.location.origin}${
        window.location.pathname
      }?${queryParams.toString()}`;

      // Update the browser URL
      window.history.replaceState({}, "", newUrl);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const getDatasetCategories = async () => {
    try {
      const response = await getDataSetCategoryAPI(params.dataSetId);
      setCategories(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getDataSetDetails = async () => {
    try {
      const response = await getSingleDataSetApi(params.dataSetId);
      setDataSetDetails(response);
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const stopAnswerGeneration = async (data) => {
    try {
      const response = await stopAnswerGenerationApi(data);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleStopAnswerGeneration = async () => {
    await stopAnswerGeneration({
      dataSetId: params.dataSetId,
    });
    setDataSetDetails({
      ...datasetDetails,
      isProcessingAnswerGeneration: false,
    });
  };

  const handleAnswerFilter = (e) => {
    if (e.target.value === answerStatus) {
      setAnswerStatus();
    } else {
      setAnswerStatus(e.target.value);
    }
    setCurrentPage(1);
  };

  const handleSelectedCategory = (e) => {
    const categoryValue = e.target.value;
    setSelectedCategory((prevSelectedCategories) => {
      if (prevSelectedCategories.includes(categoryValue)) {
        // Category is already in the array, remove it
        return prevSelectedCategories.filter(
          (category) => category !== categoryValue
        );
      } else {
        // Category is not in the array, add it
        return [...prevSelectedCategories, categoryValue];
      }
    });
    setCurrentPage(1);
  };

  const handleManualQuesAns = () => {
    setManualQA(true);
  };

  const addQAManually = async (newQA) => {
    try {
      const apiData = {
        question: newQA?.question,
        answer: newQA?.answer ? newQA.answer : null,
        category: newQA?.category,
        dataSet: params.dataSetId,
        questionVariant: newQA?.questionVariant,
      };
      const response = await createQuesAnsApi(apiData);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleCloseQAManually = () => {
    setManualQA(false);
  };

  const handleSaveQAManually = async (data) => {
    await addQAManually(data);
    handleCloseQAManually();
    fetchData();
  };

  const updateQuestionsBySocket = (data) => {
    if (data.length > 0) {
      setTableData((prevData) => {
        let newTableData = [...prevData];
        newTableData = newTableData.map((nd) => {
          const updatedQuestion = data.find((d) => d._id === nd._id);
          return updatedQuestion
            ? { ...nd, answer: updatedQuestion.answer }
            : nd;
        });
        return newTableData;
      });
    }
  };

  const updateAnswerGenerationProgress = (data) => {
    if (data?.dataSetId === params.dataSetId) {
      // Updating the answer generation progress
      setAnswerGenerationProgress({
        countOfQuestionsGeneratedAnswers:
          data?.numberOfQuestionsAnswersGenerated,
        totalQuestionToGenerateAnswers:
          data?.totalNumberOfQuestionsToGenerateAnswers,
      });
      // Making isProcessingAnswerGeneration to false if all answers are generated
      if (
        data?.numberOfQuestionsAnswersGenerated ===
        data?.totalNumberOfQuestionsToGenerateAnswers
      ) {
        setDataSetDetails((prevDataSetDetails) => ({
          ...prevDataSetDetails,
          isProcessingAnswerGeneration: false,
        }));
      }
    }
  };

  const handleSocketUpdate = () => {
    socket.on("media-answers-generated", (data) => {
      try {
        updateQuestionsBySocket(data);
      } catch (error) {
        handleErrorMessage(error);
      }
    });

    socket.on("question-answer-count", (data) => {
      try {
        updateAnswerGenerationProgress(data);
      } catch (error) {
        handleErrorMessage(error);
      }
    });
  };

  const downloadCSV = async () => {
    try {
      const queryParams = new URLSearchParams();
      if (answerStatus) {
        queryParams.set("answered", answerStatus === "answered" ? true : false);
      }
      queryParams.set("category", selectedCategory.join(","));
      const response = await downloadQuestionAnswerCsv(
        params.dataSetId,
        queryParams,
        {
          responseType: "blob",
        }
      );

      fileDownload(response, "CSV_file.csv");
    } catch (error) {
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const optimizedSearch = _.debounce(handleOnSearch, 500);

  useEffect(() => {
    getDatasetCategories();
    getDataSetDetails();
    handleSocketUpdate();
    handleSocketDuplicateCheckUpdate();
    getAnswerGenerationCount();
  }, []);

  useEffect(() => {
    fetchData();
  }, [answerStatus, selectedCategory, currentPage, search]);

  return (
    <>
      {/* <LoaderState state={"failed"} /> */}

      {/* filters  */}
      <FlexBox
        style={{
          display: "flex",
          alignItems: "center",
        }}
        mb={5}
      >
        {/* answer status filter */}
        <FundamentoPopup
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          triggeringComponent={
            <Button
              variant="outlined"
              className={answerStatus && styles.activeFilter}
              sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
              endIcon={<ChevronDown width={16} height={16} />}
            >
              Answer status
            </Button>
          }
        >
          <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            <FlexBox columnGap={2} component="label">
              <Checkbox
                icon={<Circle />}
                checkedIcon={<CheckCircle />}
                value={"answered"}
                onChange={(e) => handleAnswerFilter(e)}
                checked={answerStatus === "answered" ? true : false}
              />
              <Typography variant="body2">Answered</Typography>
            </FlexBox>
            <FlexBox columnGap={2} component="label">
              <Checkbox
                icon={<Circle />}
                checkedIcon={<CheckCircle />}
                value={"unanswered"}
                onChange={(e) => handleAnswerFilter(e)}
                checked={answerStatus === "unanswered" ? true : false}
              />
              <Typography variant="body2">Unanswered</Typography>
            </FlexBox>
          </Box>
        </FundamentoPopup>

        {/* category filter */}
        <FundamentoPopup
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          disableCloseOnClick={true}
          triggeringComponent={
            <Button
              variant="outlined"
              className={selectedCategory?.length && styles.activeFilter}
              sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
              endIcon={<ChevronDown width={16} height={16} />}
            >
              Category
            </Button>
          }
        >
          <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            {categories?.map((item) => (
              <FlexBox columnGap={2} component="label">
                <Checkbox
                  value={item?._id}
                  onChange={(e) => handleSelectedCategory(e)}
                  checked={selectedCategory.includes(item?._id)}
                  // checked={selectedFiles.includes(item._id)}
                  // onChange={(e) =>
                  //   handleSelectOne(item._id, e.target.checked)
                  // }
                />
                <Typography variant="body2">{item.name}</Typography>
              </FlexBox>
            ))}
          </Box>
        </FundamentoPopup>

        <Box className={styles.searchContainer}>
          <SearchBox
            placeholder={"Search"}
            onChange={optimizedSearch}
            defaultValue={search}
            onClear={handleClearSearch}
          />
        </Box>

        {/* actions dropdown */}
        <Box style={{ marginLeft: "auto" }}>
          <FundamentoPopup
            triggeringComponent={
              <Button
                variant="outlined"
                sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
                endIcon={<ChevronDown width={16} height={16} />}
              >
                Actions
              </Button>
            }
            className={styles.popup}
          >
            <Box>
              <Box
                className={styles.popupItem}
                onClick={handleDuplicationCheck}
              >
                <FolderCopyOutlined width={16} height={16} />
                <Typography variant="body2">Duplication check</Typography>
              </Box>
              {/* <Box className={styles.popupItem} onClick={() => downloadCSV()}>
                <Download width={16} height={16} />
                <Typography variant="body2">Export FAQs as CSV</Typography>
              </Box> */}
              {/* <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 12,
                  cursor: "pointer",
                }}
                onClick={() => sendAnswerstoGenerate()}
              >
                <Sparkle width={16} height={16} />
                <Typography variant="body2">Generate Answers</Typography>
              </Box> */}
              {/* <Box className={styles.popupItem} onClick={handleManualQuesAns}>
                <IconCirclePlus width={16} height={16} />
                <Typography variant="body2">Manual Q&A</Typography>
              </Box> */}
            </Box>
          </FundamentoPopup>
        </Box>

        <Button
          variant="dark"
          disabled={
            selectedFiles.length === 0 ||
            datasetDetails?.isProcessingAnswerGeneration
          }
          style={{
            padding: "6px 14px",
            lineHeight: "24px",
            gap: 0,
            display: "flex",
            fontSize: 12,
            borderRadius: 82,
          }}
          onClick={() => sendAnswerstoGenerate()}
          startIcon={<Sparkle width={16} height={16} />}
        >
          Generate Answers
        </Button>
      </FlexBox>
      {duplicateCheckProgress ? (
        <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
          <Box
            className={styles.duplicateCheck}
            sx={{
              "&:after": {
                width: `${totalPercentageOfDupCheck}% !important`,
              },
            }}
          >
            <Box className={`${styles.iconContainer}`}>
              {/* <CaretDoubleUp width={16} height={16} /> */}
              {/* <WarningCircle fill="white" /> */}
              <FolderCopyOutlined
                width={16}
                height={16}
                style={{ fill: "#fff" }}
              />
            </Box>

            <Typography variant="body1" fontWeight={500}>
              Checking for duplicates
            </Typography>
            <Box ml="auto">{totalPercentageOfDupCheck}%</Box>
          </Box>
        </Fade>
      ) : duplicateCheckDone ? (
        <Box className={styles.duplicateCheck}>
          <Box className={`${styles.iconContainer} ${styles.active}`}>
            {/* <CaretDoubleUp width={16} height={16} /> */}
            <WarningCircle fill="white" />
          </Box>

          <Typography variant="body1" fontWeight={500}>
            {duplicateQuestion} duplicate questions found out of{" "}
            {selectedAcrossPagesLength}
          </Typography>

          {/* Additional content for when the duplicate check is complete */}
          <Box ml="auto">
            <Button
              variant="text"
              color="inherit"
              sx={{ minWidth: 0 }}
              onClick={handleDuplicatesDelete}
            >
              Remove all
            </Button>
            <Button
              variant="text"
              color="inherit"
              sx={{ minWidth: 0 }}
              onClick={handleKeepAll}
            >
              Keep all
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}

      {datasetDetails?.isProcessingAnswerGeneration &&
        answerGenerationProgress?.totalQuestionToGenerateAnswers !==
          answerGenerationProgress?.countOfQuestionsGeneratedAnswers && (
          <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
            <Box
              className={styles.duplicateCheck}
              sx={{
                "&:after": {
                  width: `${Math.floor(
                    (answerGenerationProgress?.countOfQuestionsGeneratedAnswers /
                      answerGenerationProgress?.totalQuestionToGenerateAnswers) *
                      100
                  )}% !important`,
                },
              }}
            >
              <Box className={`${styles.iconContainer}`}>
                <Sparkle width={16} height={16} />
              </Box>

              <Typography variant="body1" fontWeight={500}>
                Generating answers
              </Typography>
              <Box ml="auto">
                {Math.floor(
                  (answerGenerationProgress?.countOfQuestionsGeneratedAnswers /
                    answerGenerationProgress?.totalQuestionToGenerateAnswers) *
                    100
                )}
                %
              </Box>
              <Button
                variant="outline"
                sx={{ marginLeft: 3 }}
                onClick={handleStopAnswerGeneration}
              >
                Stop
              </Button>
            </Box>
          </Fade>
        )}

      {loading ? (
        <Box className={styles.emptyContainer}>
          <Loader color={theme.palette.grey[400]} width={40} height={40} />
        </Box>
      ) : tableData.length === 0 ? (
        <Box height={"50%"}>
          <EmptyState title={"No data found"} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              "& td:first-of-type": {
                padding: 0,
                paddingLeft: 4,
              },
              "& table th": {
                paddingBottom: "6px",
              },
              flex: 1,
              overflowY: "auto",
              "& thead": {
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1000,
              },
            }}
          >
            <FundamentoTable
              columns={columns}
              data={tableData}
              passRowToRender={true}
              pagination={
                duplicateCheckDone || duplicateCheckProgress ? false : true
              }
              totalPageCount={totalPageCount}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </Box>
        </>
      )}
      {/* <AddQAManually
        open={manualQA}
        onClose={handleCloseQAManually}
        onSave={handleSaveQAManually}
        categories={categories}
      /> */}
    </>
  );
};

export default Questions;
