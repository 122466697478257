import { Box, Button, Typography } from "@mui/material";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import { Loader } from "react-feather";
import FlexBox from "components/FlexBox";
import { Prohibit } from "components/newSVG";

const ProgressBar = (props) => {
  const {
    progress,
    startIcon,
    message,
    cancelGeneration,
    handleCancelGeneration,
  } = props;

  return (
    <Box className={styles.container}>
      <Box className={styles.progressBar} sx={{ width: `${progress}%` }}></Box>
      <FlexBox className={styles.content}>
        {startIcon}
        <Typography variant="caption" fontWeight={500}>
          {message}
        </Typography>
        <Typography variant="caption" fontWeight={500} ml="auto">
          {progress}% completed
        </Typography>

        {cancelGeneration && (
          <Button
            variant="text"
            startIcon={<Prohibit width={16} height={16} />}
            sx={{ padding: 1 }}
            onClick={handleCancelGeneration}
          >
            <Typography variant="caption">Cancel generation</Typography>
          </Button>
        )}
      </FlexBox>
    </Box>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  startIcon: PropTypes.element,
  message: PropTypes.string,
  cancelGeneration: PropTypes.bool,
  handleCancelGeneration: PropTypes.func,
};

ProgressBar.defaultProps = {
  progress: 0,
  message: "progress",
  startIcon: <Loader width={16} height={16} />,
  cancelGeneration: false,
  handleCancelGeneration: () => {},
};

export default ProgressBar;
