import Snackbar from '@mui/material/Snackbar';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';

const Notification = props => {
  const { open, transition, handleClose, messageText, status } = props;

  let icons = null;
  if (status === 'success') {
    icons = (
      <CheckCircleIcon
        style={{ color: 'green', fontSize: 20, marginLeft: 20 }}
      />
    );
  }
  if (status === 'error') {
    icons = (
      <CancelIcon style={{ color: 'red', fontSize: 20, marginLeft: 20 }} />
    );
  }

  const spanMsg = <>{messageText}</>;

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        action={icons}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        TransitionComponent={transition}
        message={spanMsg}
        key={transition ? transition.name : ''}
      />
    </div>
  );
};

export default Notification;
