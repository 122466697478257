import { Button } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import getCampaignLogs from "services/getCampaignLogs";
import { useSkillrToast } from "context/toast";
import * as XLSX from "xlsx";

const CampaignLogs = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { showSkillrToast } = useSkillrToast();

  const exportToCSV = (jsonData, fileName) => {
    // Step 1: Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Step 2: Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Step 3: Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Step 4: Write the workbook to CSV format
    const csvOutput = XLSX.write(workbook, {
      bookType: "csv",
      type: "string",
    });

    // Step 5: Trigger the download
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      // Feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${fileName}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getCampaignDetails = async () => {
    try {
      const campaignDetailsResponse = await getCampaignLogs(params.campaignId);
      console.log({ campaignDetailsResponse });
      exportToCSV(campaignDetailsResponse, `logs_${params.campaignId}`);
      navigate(-1);
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      showSkillrToast(errorMessage, "error");
      navigate(-1);
    }
  };

  useEffect(() => {
    getCampaignDetails();
  }, []);

  return (
    <PageWrapper className={styles.container}>
      <Button
        variant="dark"
        sx={{ ml: 2, height: 40, borderRadius: 12 }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
    </PageWrapper>
  );
};

export default CampaignLogs;
