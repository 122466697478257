import { Box, Button, Fade, IconButton, Typography } from "@mui/material";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import { X, Zap } from "react-feather";
import theme from "theme";
import FunCollapse from "components/FunCollapse";
import { FlowArrow, PencilSimple } from "components/newSVG";
import EditContainer from "./components/EditContainer";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gscUriPrefix } from "text-constants/common";
import AudioPlayer from "components/AudioPlayer";

const HistoryDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const [editContainer, setEditContainer] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const token = localStorage.getItem("token");

  return (
    <Fade
      in={true}
      timeout={300}
      exit={false}
      unmountOnExit
      mountOnEnter
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        flex: 1,
      }}
    >
      <Box
        sx={{
          "& > div": {
            height: "100%",
          },
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column", overflowY: "scroll" }}
        >
          <FlexBox className={styles.topBar}>
            <Typography variant="body2" fontWeight={500}>
              {data?.historyDetails?.nodes[1]?.question}
            </Typography>

            <IconButton sx={{ ml: "auto" }} onClick={() => navigate(-1)}>
              <X width={20} height={20} />
            </IconButton>
          </FlexBox>

          <Box p={"8px 48px"} sx={{ flex: 1, overflowY: "scroll" }}>
            {data?.historyDetails?.nodes.map((item, index) => {
              return (
                // this contains a pair of both yara and you message
                <div key={index} className={styles.historyContainer}>
                  {(item?.question?.length > 0 ||
                    typeof item?.question === "number") &&
                    (item?.vectorObject ? (
                      <FlexBox
                        sx={{
                          padding: "20px 0",
                          alignItems: "flex-start",
                          borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                          "& div": { borderBottom: "none" },
                        }}
                      >
                        <Box className={`${styles.avatar} ${styles.you}`}>
                          You
                        </Box>
                        <div style={{ width: "100%" }}>
                          <FunCollapse
                            iconPosition={"start"}
                            header={
                              <>
                                <FlexBox
                                  key={index}
                                  className={styles.historyItem}
                                  style={{ padding: 0 }}
                                >
                                  <Typography variant="body2" fontWeight={500}>
                                    {item?.question}
                                  </Typography>
                                </FlexBox>
                              </>
                            }
                          >
                            <Box py={2}>
                              <Typography
                                variant="body2"
                                fontWeight={500}
                                color="textSecondary"
                              >
                                Referenced intent
                              </Typography>

                              <Typography variant="body2" mt={1}>
                                {item?.vectorObject.question}
                              </Typography>

                              <Typography
                                mt={2}
                                variant="body2"
                                fontWeight={500}
                                color="textSecondary"
                              >
                                Suggestion
                              </Typography>

                              {item?.vectorObject?.workFlowId ? (
                                <FlexBox mt={1} columnGap={1}>
                                  <FlowArrow
                                    className={styles.flowArrow}
                                    width={12}
                                    height={12}
                                  />
                                  <Typography
                                    variant="body2"
                                    color={theme.palette.primary.main}
                                  >
                                    Workflow linked:{" "}
                                    <Link
                                      target="_blank"
                                      to={`/workflows/${
                                        data?.workFlows?.workFlows?.find(
                                          (workflow) =>
                                            workflow._id ===
                                            item?.vectorObject?.workFlowId
                                        )?._id
                                      }`}
                                    >
                                      {
                                        data?.workFlows?.workFlows?.find(
                                          (workflow) =>
                                            workflow._id ===
                                            item?.vectorObject?.workFlowId
                                        )?.name
                                      }
                                    </Link>
                                  </Typography>
                                </FlexBox>
                              ) : (
                                <Typography
                                  variant="body2"
                                  mt={1}
                                  sx={{ whiteSpace: "break-spaces" }}
                                >
                                  {item?.vectorObject.answer}
                                </Typography>
                              )}

                              <Button
                                sx={{
                                  minWidth: 0,
                                  padding: "8px 14px",
                                  mt: 3,
                                  borderRadius: 52,
                                }}
                                variant="outlined"
                                startIcon={
                                  <PencilSimple width={16} height={16} />
                                }
                                onClick={() => {
                                  setSelectedQuestion(
                                    item?.vectorObject.mongoId
                                  );
                                  setEditContainer(true);
                                }}
                              >
                                Edit Intent
                              </Button>
                            </Box>
                          </FunCollapse>
                        </div>
                        <FlexBox
                          sx={{ ml: "auto", gap: 1, whiteSpace: "nowrap" }}
                          className={
                            item?.type === "fallbackWorkflow"
                              ? styles.redLabel
                              : styles.label
                          }
                        >
                          {item.vectorObject._additional.distance}
                        </FlexBox>
                      </FlexBox>
                    ) : (
                      <FlexBox key={index} className={styles.historyItem}>
                        <Box className={`${styles.avatar} ${styles.you}`}>
                          You
                        </Box>
                        <Typography variant="body2" fontWeight={500}>
                          {item?.question}
                        </Typography>
                      </FlexBox>
                    ))}
                  <FlexBox className={styles.historyItem}>
                    <Box className={`${styles.avatar} ${styles.yara}`}>
                      Yara
                    </Box>
                    <Box
                      sx={{ maxWidth: "70%" }}
                      display="flex"
                      gap={2}
                      flexDirection="column"
                    >
                      {item?.answer.map((ans, index) =>
                        ans?.message ? (
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            key={index}
                            sx={{
                              // "& + &": { mt: 2 },
                              wordWrap: "break-word",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {ans.message}
                          </Typography>
                        ) : (
                          <AudioPlayer
                            controls
                            style={{ mt: 2 }}
                            className={styles.audio}
                            audioPath={ans.audio}
                          />
                        )
                      )}
                    </Box>
                    {/* {typeof item?.answer === "string" ? (
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        sx={{ maxWidth: "70%", whiteSpace: "break-spaces" }}
                      >
                        {item?.answer?.message}
                      </Typography>
                    ) : (
                      <Box sx={{ maxWidth: "70%" }}>
                        {item?.answer.map((ans, index) => (
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            key={index}
                            sx={{
                              "& + &": { mt: 2 },
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {ans}
                          </Typography>
                        ))}
                      </Box>
                    )} */}
                    {item?.totalTime && (
                      <FlexBox sx={{ ml: "auto", gap: 1 }}>
                        <Zap
                          width={12}
                          height={12}
                          fill={theme.palette.grey[300]}
                          stroke={theme.palette.grey[300]}
                        />
                        <Typography variant="caption" component={"div"}>
                          {item?.totalTime?.toFixed(2)}s
                        </Typography>
                      </FlexBox>
                    )}

                    {item?.createdAt && (
                      <Typography variant="caption" component={"div"}>
                        {/* format "2024-03-14T12:27:52.115Z" to 5:40:01 pm */}
                        {new Date(item?.createdAt).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                        })}
                      </Typography>
                    )}
                  </FlexBox>
                </div>
              );
            })}
          </Box>

          <EditContainer
            show={editContainer}
            onClose={() => setEditContainer(false)}
            question={selectedQuestion}
          />
        </Box>
      </Box>
    </Fade>
  );
};

export default HistoryDetail;
