import { Box, TextField, Typography } from "@mui/material";
import styles from "./index.module.css";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { RedAlert } from "components/SVG";
import { useEffect } from "react";

const ConfigurePrompt = (props) => {
  const { onSave, prompt } = props;

  const {
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      prompt,
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (prompt) {
      reset({ prompt });
    }
  }, [prompt]);

  return (
    <Box padding="32px 32px 0 32px" width="100%">
      <Box className={styles.cardContainer}>
        <Typography variant="body1" fontWeight={500}>
          System
        </Typography>
        <Controller
          control={control}
          name="prompt"
          rules={{ required: "Prompt is required" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              sx={{ mt: 4 }}
              fullWidth
              multiline
              minRows={14}
              maxRows={14}
              className={styles.textField}
              helperText={
                errors?.prompt ? (
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors.prompt.message}
                  </Box>
                ) : null
              }
              // onChange={onChange}
              onChange={(event) => {
                onChange(event.target.value);
                onSave(event.target.value);
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

ConfigurePrompt.propTypes = {
  OnSave: PropTypes.func,
  prompt: PropTypes.string,
};

export default ConfigurePrompt;
