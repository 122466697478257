import { postRequest } from "./common";

// export default function generateAnswers(data, datasetId, config = {}) {
//   return postRequest(`/media/generateAnswers/${datasetId}`, data, config);
// }

export default function generateAnswers(data, datasetId, queryParams, config = {}) {
  // const queryParams = new URLSearchParams(queryParamsObject).toString();
  const url = `/media/generateAnswers/${datasetId}${queryParams ? `?${queryParams}` : ''}`;
  return postRequest(url, data, config);
}
