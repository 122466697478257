import { useState } from "react";
import styles from "./index.module.css";
import { Box, Fade, IconButton, Typography } from "@mui/material";
import { AlertTriangle, Trash2 } from "react-feather";
import theme from "theme";
import FlexBox from "components/FlexBox";
import { RedAlert } from "components/SVG";

const NodeBase = (props) => {
  const {
    isActive,
    onDelete,
    localizationMode,
    fullyLocalized,
    children,
    error,
  } = props;
  const [isParentHovered, setIsParentHovered] = useState(false);

  return (
    <div
      className={`${styles.messageNode} ${isActive ? styles.active : ""} ${
        localizationMode && !fullyLocalized ? styles.localizationMode : ""
      } ${error ? styles.error : ""}`}
      onMouseEnter={() => setIsParentHovered(true)}
      onMouseLeave={() => setIsParentHovered(false)}
    >
      <Fade in={isParentHovered} mountOnEnter unmountOnExit>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Trash2 color={theme.palette.error.main} width={12} height={12} />
        </IconButton>
      </Fade>

      {localizationMode && !fullyLocalized && !isParentHovered && (
        <AlertTriangle
          color={theme.palette.grey[300]}
          width={12}
          height={12}
          style={{ position: "absolute", top: 18, right: 18 }}
        />
      )}

      {children}

      {error && (
        <Box mt={"auto"}>
          {error?.errorMessages.length > 0 &&
            error?.errorMessages.map((error) => (
              <FlexBox mt={2} columnGap={1} alignItems="flex-start">
                <RedAlert />
                <Typography variant="caption" color="error.main">
                  {error}
                </Typography>
              </FlexBox>
            ))}
        </Box>
      )}
    </div>
  );
};

export default NodeBase;
