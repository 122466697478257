import React from "react";
import PropTypes from "prop-types";
import { Box, Popper, Typography } from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
import { IconCheck, IconInfo } from "components/SVG";
import { styled } from "@mui/material/styles";
import theme from "theme";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: "#122632",
//     color: theme.palette.common.white,
//     padding: theme.spacing(4),
//     boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.31)",
//     borderRadius: 8,

//     [theme.breakpoints.up("sm")]: {
//       marginRight: theme.spacing(4),
//     },
//     [theme.breakpoints.down("sm")]: {
//       marginBottom: theme.spacing(4),
//     },
//   },
//   infoMsg: {
//     display: "flex",
//     alignItems: "center",

//     "&:not(:last-child)": {
//       marginBottom: theme.spacing(3),
//     },
//   },
//   iconCheck: {
//     color: theme.palette.success.main,
//   },
//   iconWarning: {
//     color: "#ffc400",
//   },
// }));

const StyledPopper = styled(Popper)(({ theme }) => ({
  backgroundColor: "#122632",
  color: theme.palette.common.white,
  padding: theme.spacing(4),
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.31)",
  borderRadius: 8,

  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  "&:not(:last-child)": {
    marginBottom: theme.spacing(3),
  },
}));

const PasswordStrength = (props) => {
  // const classes = useStyles();

  const getIcon = (bool) => {
    return bool ? (
      <IconCheck style={{ color: theme.palette.success.main }} />
    ) : (
      <IconInfo style={{ color: "#ffc400" }} />
    );
  };
  return (
    <StyledPopper
      // className={classes.root}
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      placement={props.placement}
    >
      <StyledTypography variant="body1">
        Your password must contain:
      </StyledTypography>
      <StyledTypography variant="body2">
        {getIcon(props.passwordStrength.minChar)}
        <Box pl={3}>Minimum 8 characters</Box>
      </StyledTypography>
      <StyledTypography variant="body2">
        {getIcon(props.passwordStrength.upperLower)}
        <Box pl={3}>Uppercase & Lowercase Letters</Box>
      </StyledTypography>
      <StyledTypography variant="body2">
        {getIcon(props.passwordStrength.number)}
        <Box pl={3}>A number</Box>
      </StyledTypography>
    </StyledPopper>
  );
};

PasswordStrength.propTypes = {
  passwordStrength: PropTypes.any,
  anchorEl: PropTypes.any,
  placement: PropTypes.string,
};

PasswordStrength.defaultProps = {
  placement: "left",
};

export default PasswordStrength;
