import {
  Box,
  Checkbox,
  FormControlLabel,
  Hidden,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { SkillrAlert } from "components";
import { SkillrButton } from "components";
import { SkillrTextField } from "components";
import {
  FundamentoLogoFull,
  IconVisibility,
  IconVisibilityoff,
} from "components/SVG";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { emailRegEx, getJSONDecoded } from "utils/utils";
import { AuthFormGroup, ForgotBox, StyledDivider } from "./index.style";
import { BrandLogo } from "../AuthCard/index.style";
import { Link } from "react-router-dom";
import { logIn as logInApi } from "services";
import { useUserContext } from "context/user";

const LoginForm = (props) => {
  const { setToken, initialLoad } = useUserContext();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const navigate = useNavigate();
  const [status, setStatus] = useState({ message: "" });
  const [loading, setLoading] = useState(false);

  const [showPassword, toggleShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    toggleShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginSuccess = (loginDetails, loginType) => {
    if (props.candidateAuth) {
      // start mix panel call
      // mixpanel.track("USER_LOGIN", {
      //   email: loginDetails?.email,
      //   userId: loginDetails?.id,
      //   userType: "employee",
      //   loginType,
      // });
      // end mix panel call
      localStorage.setItem("candidateToken", loginDetails.token);
      // candidateContext.setCandidateToken(loginDetails.token);
      // candidateContext.setCandidateInfo({ ...loginDetails });
      // navigate("/tests");
      // props.history.push("/tests");
    } else {
      // start mix panel call
      // mixpanel.track("USER_LOGIN", {
      //   email: loginDetails?.email,
      //   userId: loginDetails?.id,
      //   userType: "employer",
      //   companyId: loginDetails?.tenant && loginDetails?.tenant[0]?.id,
      //   companyName: loginDetails?.tenant && loginDetails?.tenant[0]?.name,
      //   loginType,
      // });
      // end mix panel call
      // setAuthTokens(loginDetails.token);
      localStorage.setItem("token", loginDetails.token);
      // LandingPageSignIn(loginDetails);
      // updateDetails(loginDetails);
      if (loginDetails?.tenants.length > 0) {
        // navigate("/dashboard");
        // props.history.push("/dashboard");
      } else {
        // navigate("/onboarding");
        // props.history.push("/onboarding");
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const loginData = await logInApi(data);
      setLoading(false);
      localStorage.setItem("token", loginData?.token);
      setToken(loginData?.token);
      initialLoad();
      const tokenInfo = getJSONDecoded(loginData?.token);
      if (tokenInfo?.companies?.length > 0) {
        navigate("/ai-labs");
      } else {
        navigate("/create-company");
      }
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error?.response?.data?.message ?? "Something went wrong";
      setStatus({
        status: "error",
        message: errorMessage,
      });
    }
  };

  return (
    <Box>
      {!props.candidateAuth && (
        <BrandLogo>
          <FundamentoLogoFull width={170} />

          {/* <img src={brandLogo} alt="Logo" /> */}
        </BrandLogo>
      )}
      {props.candidateAuth && (
        <Box style={{ textAlign: "center", marginBottom: 20 }}>
          <Typography variant="h4" gutterBottom>
            Welcome back!
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Don’t have an account?{" "}
            <Box color="primary.main" clone>
              {/* <Link to="/assessment/create-account">Create an account</Link> */}
            </Box>
          </Typography>
        </Box>
      )}
      {/* <GoogleLogin
        loginSuccess={loginSuccess}
        classes={classes}
        isSquad={!props.candidateAuth}
        isButton={false}
      /> */}
      <StyledDivider>OR</StyledDivider>
      {status.status && (
        <Box mb={6}>
          <SkillrAlert variant={status.status}>{status.message}</SkillrAlert>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthFormGroup>
          <SkillrTextField
            name="email"
            id="login"
            label="Official Email Id"
            defaultValue=""
            control={control}
            rules={{
              required: "Email field is required",
              pattern: {
                value: emailRegEx,
                message: "Please enter valid email id",
              },
            }}
            error={errors && errors.email}
          />
          <SkillrTextField
            id="password"
            name="password"
            control={control}
            label="Password"
            defaultValue=""
            type={showPassword ? "text" : "password"}
            rules={{
              required: "Password field is required",
            }}
            error={errors && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <IconVisibility /> : <IconVisibilityoff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </AuthFormGroup>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={-2}
          mb={4}
        >
          <FormControlLabel
            control={<Checkbox color="primary" name="checkedA" />}
            label="Keep me logged in"
            style={{ color: "#9d9d9d" }}
          />
          <Hidden mdDown>
            <Box color="primary.main" clone>
              <Link to="/forgot-password">Forgot password?</Link>
            </Box>
          </Hidden>
        </Box>
        <SkillrButton size="large" type="submit" loading={loading} fullWidth>
          Sign in
        </SkillrButton>
        <Hidden smUp>
          <ForgotBox color="primary.main" clone>
            <Link to="/forgot-password">Forgot password?</Link>
          </ForgotBox>
        </Hidden>
      </form>
    </Box>
  );
};

LoginForm.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  candidateAuth: PropTypes.bool,
};

export default LoginForm;
