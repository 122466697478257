import { IconButton, TextField, Typography } from "@mui/material";
import FlexBox from "components/FlexBox";
import React, { useEffect, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "react-feather";

const FundamentoPagination = ({
  currentPage,
  onPageChange,
  totalPageCount,
}) => {
  const [page, setPage] = useState();

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  return (
    <FlexBox justifyContent="center" sx={{ position: "relative" }}>
      <IconButton
        onClick={() => onPageChange(1)}
        disabled={parseInt(currentPage) === 1}
      >
        <ChevronsLeft width={20} height={20} />
      </IconButton>
      <IconButton
        onClick={() => onPageChange(parseInt(currentPage) - 1)}
        disabled={parseInt(currentPage) === 1}
      >
        <ChevronLeft width={20} height={20} />
      </IconButton>
      <FlexBox style={{ margin: "0 10px" }}>
        <TextField
          sx={{ width: 65, "& div": { height: 40 } }}
          value={page}
          onChange={(e) => setPage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && page > 0 && page <= totalPageCount) {
              e.preventDefault();
              onPageChange(page);
            }
          }}
          inputProps={{
            min: 1,
            max: totalPageCount,
          }}
          error={page < 1 || page > totalPageCount}
        />
        of {totalPageCount}
      </FlexBox>
      <IconButton
        onClick={() => onPageChange(parseInt(currentPage) + 1)}
        disabled={parseInt(currentPage) === totalPageCount}
      >
        <ChevronRight width={20} height={20} />
      </IconButton>
      <IconButton
        onClick={() => onPageChange(totalPageCount)}
        disabled={parseInt(currentPage) === totalPageCount}
      >
        <ChevronsRight width={20} height={20} />
      </IconButton>

      <Typography
        color="error.main"
        fontSize={12}
        sx={{ position: "absolute", top: 40 }}
      >
        {(page > totalPageCount || page < 1) &&
          "Please enter a valid page number"}
      </Typography>
    </FlexBox>
  );
};

export default FundamentoPagination;
