import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { ChevronDown, RedAlert } from "components/SVG";
import { botConfig } from "utils/botConfig";
import { Controller, get, useForm } from "react-hook-form";
import _ from "lodash";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Minus, Plus, X } from "react-feather";
import AddExampleModal from "../AddExampleModal";

const models = [
  { name: "Vertex", value: "vertex" },
  { name: "Gemini flash", value: "gemini" },
  // { name: "ChatGpt", value: "azure_gpt" },
];

const inputNodeValidationTypes = [
  { name: "Name", value: "name" },
  { name: "Number", value: "number" },
  { name: "Phone Number", value: "phoneNumber" },
  { name: "Date", value: "date" },
  { name: "Time", value: "time" },
  { name: "Date & Time", value: "dateTime" },
  { name: "Alphanumeric", value: "alphanumeric" },
];
// const conversationTypes = ["True", "Wait", "Repeat", "No"];

const ConfigureModel = (props) => {
  const { onSave, type, workflow, data } = props;

  const [exampleModal, setExampleModal] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      tab: "modelConfiguration",
      model: models[0].value,
      distance: 1,
      temperature: 0.1,
      outputTokens: 1,
      topP: 1,
      candidateCount: 0.1,
      promptExample: "",
      inputNodeValidationType: "name",
      conversationType: "",
      examples: {
        true: [],
        wait: [],
        repeat: [],
      },
    },
    mode: "onChange",
  });

  const handleOnChangeConversationalType = (
    onChange,
    oldValue,
    currentValue
  ) => {
    if (currentValue === oldValue) {
      onChange("");
    } else {
      onChange(currentValue);
    }
  };

  const handleOnSaveExample = (data) => {
    const ct = getValues("conversationType");
    setValue(`examples.${ct.toLowerCase()}`, data.examples, {
      shouldValidate: true,
    });
    setExampleModal(false);
  };

  const getExamples = () => {
    const converationType = getValues("conversationType").toLowerCase();
    return { examples: getValues(`examples.${converationType}`) };
  };

  const watchOnAllFields = () => {
    const subscribe = watch((value, fields) => {
      // if (value[fields.name]) {
      if (get(value, fields.name)) {
        onSave(value);
      }
    });

    return subscribe;
  };

  const handleExampleClick = (event) => {
    const parent = event.target.closest("[class*='deleteChipButton']");
    if (parent?.id) {
      const [, type, deleteExample] = parent?.id.split("-");
      const allExamples = getValues(`examples.${type.toLowerCase()}`);
      const filteredExamples = allExamples.filter((e) => e !== deleteExample);
      setValue(`examples.${type.toLowerCase()}`, filteredExamples, {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    const subscription = watchOnAllFields();

    return () => {
      return subscription.unsubscribe();
    };
  }, [onSave]);

  useEffect(() => {
    // Need to hide the example data tab for smart+
    if (type === "Smart+") {
      reset({ ...getValues(), tab: "modelConfiguration" });
    }
  }, [type]);

  useEffect(() => {
    if (data) {
      reset({ ...getValues(), ...data.modelConfig, ...data.additionalConfig });
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Controller
        control={control}
        name="tab"
        render={({ field: { onChange, value } }) => (
          <Tabs
            value={value}
            onChange={(event, selectedTab) => {
              onChange(selectedTab);
            }}
            aria-label="tabs"
            className={styles.tabs}
            TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
            sx={{
              "& span.MuiTabs-indicator": {
                borderRadius: "10px 10px 0 0",
                height: 3,
              },
            }}
          >
            <Tab
              className={styles.tab}
              label="Model Configuration"
              value="modelConfiguration"
            />
            <Tab
              className={styles.tab}
              label="Example Data"
              value="exampleData"
              disabled={type === "Smart+"}
            />
          </Tabs>
        )}
      />

      <Box
        padding="0 16px 16px 16px"
        display="flex"
        flexDirection="column"
        gap={4}
      >
        {watch("tab") === "modelConfiguration" && (
          <>
            <Box>
              <Typography variant="caption" color="#0f0f0f">
                Model
              </Typography>
              <Box className={styles.modelDropDown}>
                <Controller
                  control={control}
                  name="model"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      IconComponent={ChevronDown}
                      onChange={onChange}
                      value={value}
                      placeholder="Select workflow"
                      className={styles.dropDown}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "40vh",
                            width: "20vw",
                          },
                        },
                      }}
                    >
                      {models.map((model) => (
                        <MenuItem
                          value={model.value}
                          sx={{ gap: 3 }}
                          key={model.value}
                        >
                          <Typography variant="body2">{model.name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>
            </Box>

            {botConfig[watch("model")].map((config) => (
              <Box key={config.name}>
                <Typography variant="caption" color="#0f0f0f">
                  {config.name}
                </Typography>
                <Box display="flex" gap={2} alignItems="center">
                  <Controller
                    control={control}
                    name={
                      config.name === "Top P"
                        ? _.snakeCase(config.name)
                        : _.camelCase(config.name)
                    }
                    render={({ field: { onChange, value } }) => (
                      <Slider
                        disabled={config.disabled}
                        min={config.min}
                        max={config.max}
                        valueLabelDisplay="auto"
                        step={config.stepValue}
                        sx={{
                          flex: "1 1 80%",
                          height: "2.5px",
                          "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                          "& .MuiSlider-track": {
                            backgroundColor: "#0F0F0F",
                            border: 0,
                          },
                          "& .MuiSlider-thumb": {
                            backgroundColor: "#0F0F0F",
                            width: 15,
                            height: 15,
                          },
                          marginBottom: 0,
                        }}
                        marks={[
                          {
                            value: 0,
                          },
                          {
                            value: 15,
                          },
                        ]}
                        value={+value}
                        onChange={(e, value) => {
                          onChange(value);
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={
                      config.name === "Top P"
                        ? _.snakeCase(config.name)
                        : _.camelCase(config.name)
                    }
                    rules={{
                      required: `${config.name} is required`,
                      min: {
                        value: config.min,
                        message: `Minimum required value is ${config.min}`,
                      },
                      max: {
                        value: config.max,
                        message: `Maximum required value is ${config.max}`,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled={config.disabled}
                        className={styles.modelTextField}
                        variant="standard"
                        type="number"
                        onChange={(e) => {
                          onChange(+e.target.value);
                        }}
                        value={value}
                        InputProps={{
                          inputProps: { min: config.min, max: config.max },
                        }}
                      />
                    )}
                  />
                </Box>
                {errors[_.camelCase(config.name)] && (
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    <Typography variant="caption">
                      {errors[_.camelCase(config.name)].message}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </>
        )}

        {watch("tab") === "exampleData" && (
          <>
            {type === "Workflow" && workflow === "Input Node" && (
              <Box className={styles.modelDropDown}>
                <Controller
                  control={control}
                  name="inputNodeValidationType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      IconComponent={ChevronDown}
                      onChange={onChange}
                      value={value}
                      className={styles.dropDown}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "40vh",
                            width: "20vw",
                          },
                        },
                      }}
                    >
                      {inputNodeValidationTypes.map((vt) => (
                        <MenuItem
                          value={vt.value}
                          sx={{ gap: 3 }}
                          key={vt.value}
                        >
                          <Typography variant="body2">{vt.name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>
            )}

            {(["Question Generation", "Smart+"].indexOf(type) > -1 ||
              workflow !== "Conversational Node") && (
              <Controller
                control={control}
                name="promptExample"
                rules={{ required: "Prompt example is required" }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    minRows={4}
                    className={styles.exampleInput}
                    placeholder="Example data here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            alignSelf: "flex-end",
                            mb: 4,
                            mr: 2,
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          {`${watch("promptExample")?.length} / 750`}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 750 }}
                    helperText={
                      errors?.promptExample ? (
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                        >
                          <RedAlert style={{ marginRight: 8 }} />
                          {errors.promptExample.message}
                        </Box>
                      ) : null
                    }
                  />
                )}
              />
            )}

            {type === "Workflow" && workflow === "Conversational Node" && (
              <>
                <Box>
                  <Typography variant="caption" color="#0f0f0f">
                    Distance
                  </Typography>
                  <Box display="flex" gap={2} alignItems="center">
                    <Controller
                      control={control}
                      name="distance"
                      render={({ field: { onChange, value } }) => (
                        <Slider
                          min={0}
                          max={0.25}
                          valueLabelDisplay="auto"
                          step={0.01}
                          sx={{
                            flex: "1 1 80%",
                            height: "2.5px",
                            "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                            "& .MuiSlider-track": {
                              backgroundColor: "#0F0F0F",
                              border: 0,
                            },
                            "& .MuiSlider-thumb": {
                              backgroundColor: "#0F0F0F",
                              width: 15,
                              height: 15,
                            },
                            marginBottom: 0,
                          }}
                          marks={[
                            {
                              value: 0,
                            },
                            {
                              value: 0.25,
                            },
                          ]}
                          value={+value}
                          onChange={(e, value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="distance"
                      rules={{
                        required: `Distance is required`,
                        min: {
                          value: 0,
                          message: `Minimum required value is 0`,
                        },
                        max: {
                          value: 0.25,
                          message: `Maximum required value is 0.25`,
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          className={styles.modelTextField}
                          variant="standard"
                          type="number"
                          onChange={onChange}
                          value={value}
                          InputProps={{
                            inputProps: { min: 1, max: 15 },
                          }}
                        />
                      )}
                    />
                  </Box>
                  {errors?.distance && (
                    <Box component="span" display="flex" alignItems="center">
                      <RedAlert style={{ marginRight: 8 }} />
                      <Typography variant="caption">
                        {errors.distance.message}
                      </Typography>
                    </Box>
                  )}
                </Box>
                {Object.keys(getValues("examples")).map((ct) => (
                  <Box key={ct}>
                    <Box className={styles.conversationExample}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {ct}
                        </Typography>
                        <Controller
                          control={control}
                          name="conversationType"
                          render={({ field: { onChange, value } }) => (
                            <IconButton
                              sx={{ padding: 0 }}
                              onClick={() =>
                                handleOnChangeConversationalType(
                                  onChange,
                                  value,
                                  ct
                                )
                              }
                            >
                              {watch("conversationType") === ct ? (
                                <Minus width={20} height={20} />
                              ) : (
                                <Plus width={20} height={20} />
                              )}
                            </IconButton>
                          )}
                        />
                      </Box>
                      <Collapse
                        in={watch("conversationType") === ct}
                        unmountOnExit={true}
                      >
                        <Box
                          onClick={handleExampleClick}
                          className={styles.chipParent}
                        >
                          {getValues(`examples.${ct.toLowerCase()}`).map(
                            (example) => (
                              <Box className={styles.chip} key={example}>
                                <Tooltip
                                  title={example?.length > 40 ? example : ""}
                                >
                                  <Typography variant="caption" fontSize={10}>
                                    {example}
                                  </Typography>
                                </Tooltip>

                                <IconButton
                                  sx={{ padding: 0 }}
                                  id={`delete-${ct}-${example}`}
                                  className={styles.deleteChipButton}
                                >
                                  <X width={16} height={16} />
                                </IconButton>
                              </Box>
                            )
                          )}
                        </Box>
                      </Collapse>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "#0f0f0f", mt: 1, padding: 4 }}
                      endIcon={<Plus width={20} height={20} />}
                      onClick={() => {
                        setValue("conversationType", ct);
                        setExampleModal(true);
                      }}
                    >
                      Add Example
                    </Button>
                  </Box>
                ))}
              </>
            )}

            <AddExampleModal
              open={exampleModal}
              onClose={() => setExampleModal(false)}
              handleSave={handleOnSaveExample}
              data={getExamples()}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

ConfigureModel.propTypes = {
  onSave: PropTypes.func,
  type: PropTypes.string,
  workflow: PropTypes.string,
  data: PropTypes.object,
};

export default ConfigureModel;
