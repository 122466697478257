import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import { Loader, PlusCircle, Search } from "react-feather";
import theme from "theme";
import FundamentoTable from "components/FundamentoTable";
import getDictionary from "services/getDictionary";
import createDictionary from "services/createDictionary";
import { useEffect, useState } from "react";
import EmptyState from "components/EmptyState";
import { PencilSimple, TrashSimple } from "components/newSVG";
import CreateDictionaryModal from "../components/CreateDictionaryModal";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSkillrToast } from "context/toast";
import editDictionary from "services/editDictionary";
import DeleteModal from "components/DeleteModal";
import deleteDictionary from "services/deleteDictionary";
import RoleBasedAccess from "components/RoleBasedAccess";

const Dictionary = () => {
  const { searchBoxValue: searchBarValue } = useOutletContext();
  // const [searchBarValue, setSearchBarValue] = useState("");
  const [dictionaryData, setDictionaryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [createDictionaryModal, setCreateDictionaryModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const { showSkillrToast } = useSkillrToast();
  const [previousValue, setPreviousValue] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editDictionaryID, setEditDictionaryID] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalID, setDeleteModalID] = useState(null);

  const fetchSynonyms = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("page", currentPage);
    queryParams.set("search", searchBarValue || "");

    try {
      const response = await getDictionary(queryParams.toString());
      if (response) {
        setDictionaryData(response.dictionaryData);
        setTotalPages(Math.ceil(response.totalDataLength / 10));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const createNewWord = async (data) => {
    try {
      const response = await createDictionary(data);
      if (response) {
        setCreateDictionaryModal(false);
        setDictionaryData((prev) => {
          return [...prev, response];
        });
        showSkillrToast("Synonym created successfully", "success");
      }
    } catch (error) {
      setCreateDictionaryModal(false);

      if (error.response?.status === 400) {
        showSkillrToast("Word already exists", "error");
      }
      console.log(error);
    }
  };

  const editDictionaryItem = async (data) => {
    try {
      const response = await editDictionary(editDictionaryID, data);
      if (response) {
        setCreateDictionaryModal(false);
        showSkillrToast("Synonym edited successfully", "success");
        const newDictionaryData = [...dictionaryData];
        const index = newDictionaryData.findIndex(
          (item) => item._id === editDictionaryID
        );
        newDictionaryData[index] = {
          ...newDictionaryData[index],
          ...(data.key ? { key: data.key } : {}),
          ...(data.value ? { value: data.value } : {}),
        };
        setDictionaryData(newDictionaryData);
        setEditMode(false);
        setEditDictionaryID(null);
      }
    } catch (error) {
      setCreateDictionaryModal(false);

      if (error.response?.status === 400) {
        showSkillrToast("Word already exists", "error");
      }
      console.log(error);
    }
  };

  const deleteDictionaryItem = async () => {
    try {
      const response = await deleteDictionary(deleteModalID);
      if (response) {
        showSkillrToast("Root word deleted successfully", "success");
        setDictionaryData((prev) => {
          const index = prev.findIndex((item) => item._id === deleteModalID);
          prev.splice(index, 1);
          return [...prev];
        });
        setDeleteModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "id",
      header: "",
    },
    {
      field: "key",
      header: "Group Title",
      render: (row) => {
        return (
          <Typography variant="body2" fontWeight={500}>
            {row.key}
          </Typography>
        );
      },
    },
    {
      field: "value",
      header: "Synonyms",
      render: (row) => {
        const newRow = { ...row };
        newRow.value = newRow.value.slice(0, 9);

        return (
          <FlexBox columnGap={2}>
            {newRow.value.map((synonym, index) => (
              <Chip key={index} label={synonym} className={styles.chip} />
            ))}
            {row.value.length > 10 && (
              <Tooltip title={row.value.slice(10).join(", ")}>
                <Chip
                  label={`+${row.value.length - 10}`}
                  className={styles.chip}
                />
              </Tooltip>
            )}
          </FlexBox>
        );
      },
      style: { width: "70%" },
    },
    {
      field: "actions",
      header: "Actions",
      style: { width: "15%", textAlign: "right" },
      render: (row) => {
        return (
          <FlexBox columnGap={0} justifyContent="flex-end">
            <IconButton
              sx={{ "& path": { fill: "#0f0f0f" } }}
              onClick={() => {
                setEditMode(true);
                setEditDictionaryID(row._id);
                setPreviousValue({
                  id: row._id,
                  key: row.key,
                  value: row.value,
                });
                setCreateDictionaryModal(true);
              }}
            >
              <PencilSimple width={20} height={20} />
            </IconButton>

            <RoleBasedAccess>
              <IconButton
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteModalID(row._id);
                }}
              >
                <TrashSimple width={20} height={20} />
              </IconButton>
            </RoleBasedAccess>
          </FlexBox>
        );
      },
    },
  ];

  useEffect(() => {
    fetchSynonyms();
  }, []);

  useEffect(() => {
    fetchSynonyms();
  }, [currentPage]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchSynonyms();
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchBarValue]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchBarValue]);

  return (
    <Box className={styles.container}>
      {/* <Typography textAlign="center" variant="h3" fontWeight={500} mb={4}>
        Dictionary
      </Typography> */}
      <CreateDictionaryModal
        show={createDictionaryModal}
        onClose={() => {
          setEditMode(false);
          setCreateDictionaryModal(false);
        }}
        editMode={editMode}
        onConfirm={(data) => {
          editMode ? editDictionaryItem(data) : createNewWord(data);
        }}
        defaultValue={previousValue}
      />

      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={() => deleteDictionaryItem()}
        title="Delete Group?"
        list={[
          "All the synonyms will be permanently deleted",
          "This action cannot be undone",
        ]}
      />

      {/* <Typography variant="h3" align="center" fontWeight={500} gutterBottom>
            Manage synonyms
          </Typography>
  
          <Typography variant="body2" align="center" mb={4}>
            Create synonyms to get more accurate results from the chatbot & avoid
            any errors something something something
          </Typography> */}
      <FlexBox width="100%">
        {/* <Box className={styles.autoCompleteInput} width={"100%"}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search to find synonyms"
            className={styles.input}
            value={searchBarValue}
            onChange={(e) => setSearchBarValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search width={20} height={20} />
                </InputAdornment>
              ),
            }}
          />
        </Box> */}
        <Button
          variant="dark"
          sx={{ minWidth: 0, padding: "10px 20px", ml: "auto" }}
          onClick={() => setCreateDictionaryModal(true)}
          startIcon={<PlusCircle width={16} height={16} />}
        >
          Create
        </Button>
      </FlexBox>

      {loading ? (
        <Box className={styles.emptyContainer}>
          <Loader width={32} height={32} color={theme.palette.grey[400]} />
        </Box>
      ) : dictionaryData.length === 0 ? (
        <Box className={styles.emptyContainer}>
          <EmptyState
            title="No synonyms found"
            description={"Click on create to add a new word"}
          />
        </Box>
      ) : (
        <Box
          mt={6}
          width="100%"
          sx={{
            "& th": {
              backgroundColor: "transparent !important",
            },
            "& th span": {
              color: theme.palette.grey[800],
            },
          }}
        >
          <FundamentoTable
            columns={columns}
            data={dictionaryData}
            passRowToRender={true}
            pagination={totalPages > 1}
            totalPageCount={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Box>
      )}
    </Box>
  );
};

export default Dictionary;
