import { useEffect, useRef } from "react";
import socketIOClient from "socket.io-client";

let socketInstance;

export const connectSocket = (agentId, token) => {
  const socket = socketIOClient(process.env.REACT_APP_BACKEND_API_ENDPOINT, {
    query: `agentId=${agentId}&token=${token}`,
  }); // Replace with your server's URL

  return socket;
};

export const useSocket = (agentId, token) => {
  const socketRef = useRef(socketInstance);

  if (!socketRef.current) {
    socketRef.current = connectSocket(agentId, token);
  }

  useEffect(() => {
    // Use the socket connection as needed
    socketRef.current.on("connect", () => {
      console.log("Connected to the server");
    });

    // Cleanup when the component unmounts
    return () => {
      socketRef.current.disconnect();
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return socketRef.current;
};

export const adminSocketConnection = (companyId) => {
  const socket = socketIOClient(
    `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/media-namespace`,
    {
      query: `companyId=${companyId}`,
    }
  );

  return socket;
};

export const useAdminSocket = (companyId) => {
  const adminSocketRef = useRef();

  if (!adminSocketRef.current) {
    adminSocketRef.current = adminSocketConnection(companyId);
  }

  useEffect(() => {
    // Use the socket connection as needed
    adminSocketRef.current.on("connect", () => {
      console.log("Connected to the server");
    });

    // Cleanup when the component unmounts
    return () => {
      console.log("disconnected");
      adminSocketRef.current.disconnect();
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return adminSocketRef.current;
};
