import {
  Box,
  Button,
  Divider,
  IconButton,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import {
  ChevronRight,
  IconYellowExclamation,
  WarningCircle,
} from "components/SVG";
import theme from "theme";
import { ChevronDown, Loader } from "react-feather";
import { useEffect, useState } from "react";
import { getAgentCall } from "services";
import { useUserContext } from "context/user";
import moment from "moment";
import EmptyState from "components/EmptyState";
import {
  PencilSimple,
  ThumbDownRed,
  ThumbDownRedFilled,
  ThumbUpGreen,
  ThumbUpGreenFilled,
} from "components/newSVG";
import editAgentCallReaction from "services/editAgentCallReaction";
import { useSocket } from "../../../context/socket";
import getAgentCallDetail from "services/getAgentCallDetail";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNav from "../BottomNav";
import { getJSONDecoded } from "utils/utils";
import FlexBox from "components/FlexBox";
import FunCollapse from "components/FunCollapse";
import editAgentFeedbackApi from "services/editAgentFeedback";
import getSingleWorkflowApi from "services/getSingleWorkflow";
import { gscUriPrefix } from "text-constants/common";
import AudioPlayer from "components/AudioPlayer";

const Call = ({ callDetails }) => {
  const {
    callId,
    startLine,
    startTime,
    inProgress,
    callType,
    reasonForBotFailure,
  } = callDetails;

  // const state =
  //   !startLine && !inProgress ? "blank" : inProgress && "processing";
  const getState = () => {
    if (callType === "junk") {
      return (
        <>
          <Typography
            variant="caption"
            fontWeight={500}
            color={theme.palette.warning.main}
          >
            Junk call
          </Typography>
          <WarningCircle width={16} height={16} />
        </>
      );
    } else if (callType === "blank") {
      return (
        <>
          <Typography
            variant="caption"
            fontWeight={500}
            color={theme.palette.warning.main}
          >
            Blank call
          </Typography>
          <WarningCircle width={16} height={16} />
        </>
      );
    }
    return "";
  };

  return (
    <Box
      className={`${styles.call}`}
      // ${callType === "blank" ? styles.blank : ""}
    >
      <Box>
        <Typography variant="body1" mb={1.5} fontWeight={500} color="#0F0F0FB2">
          {callDetails?.phoneNumber}
        </Typography>
        {callType !== "blank" &&
          callDetails?.callSessionAllLogs?.parentCategoryName && (
            <Typography
              variant="body2"
              fontWeight={500}
              style={{ display: "flex", alignItems: "center", gap: 8 }}
              color="rgb(15, 15, 15,0.4)"
            >
              {/* {getState() ? getState() : startLine} */}
              {callDetails?.callSessionAllLogs?.parentCategoryName} &gt;{" "}
              {callDetails?.callSessionAllLogs?.subCategoryName}
            </Typography>
          )}
        {callType === "blank" && (
          <Box display="flex" gap={2} alignItems="center">
            <Typography
              variant="body2"
              fontWeight={500}
              style={{ display: "flex", alignItems: "center", gap: 8 }}
              color="#FFA200"
            >
              Blank call
            </Typography>
            <IconYellowExclamation
              width={16}
              height={16}
              style={{ alignSelf: "center" }}
            />
          </Box>
        )}
      </Box>

      <Box>
        <Typography
          style={{ marginLeft: "auto" }}
          variant="body2"
          fontWeight={500}
          color="rgb(15, 15, 15,0.4)"
        >
          {moment(startTime).format("DD MMM hh:mm")}
        </Typography>
        <ChevronRight width={16} height={16} color={theme.palette.grey[600]} />
      </Box>
    </Box>
  );
};

const CallSummary = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [callsData, setData] = useState([]);
  const { companyDetails, getCompanyDetails } = useUserContext();
  const companyName =
    companyDetails?.name.charAt(0).toUpperCase() +
    companyDetails?.name.slice(1);
  const [currentCall, setCurrentCall] = useState({});
  const [startLineReaction, setStartLineReaction] = useState(null);
  const [endLineReaction, setEndLineReaction] = useState(null);
  const [loader, setLoader] = useState(false);
  const [dislikeReason, setDislikeReason] = useState("");
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = localStorage.getItem("agentToken");
  const tokenInfo = getJSONDecoded(token ? token : null);
  const socket = useSocket(tokenInfo.agentId, token);
  const [singleWorkFlowDetails, setSingleWorkFlowDetails] = useState();
  const [callTranscript, setCallTranscript] = useState([
    {
      type: "yara",
      message: "how may I help you",
    },
    {
      type: "user",
      message: "I want to book a ticket",
    },
    {
      type: "yara",
      message: "This is your answer",
    },
    {
      type: "user",
      message:
        "This is your reply here you can test all the things and we can figure it out",
    },
  ]);

  const getData = async () => {
    setLoader(true);
    try {
      const response = await getAgentCall();
      setData(response);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      console.error(errorMessage);
    }
  };

  const getCallData = async (call_id) => {
    setLoader(true);
    try {
      const response = await getAgentCallDetail(call_id);
      setCurrentCall(response);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      console.error(errorMessage);
    }
  };

  const sendStartLineReaction = async (call_id, startLineReaction) => {
    try {
      const response = await editAgentCallReaction(call_id, {
        startLineReaction: startLineReaction,
      });
      setCurrentCall({ ...currentCall, startLineReaction: startLineReaction });
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      console.error(errorMessage);
    }
  };

  const sendEndLineReaction = async (call_id, endLineReaction) => {
    try {
      const response = await editAgentCallReaction(call_id, {
        endLineReaction: endLineReaction,
      });
      setCurrentCall({ ...currentCall, endLineReaction: endLineReaction });
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      console.error(errorMessage);
    }
  };

  const sendDislikeReason = async (call_id, dislikeReason) => {
    try {
      const response = await editAgentCallReaction(call_id, {
        endLineReaction: false,
        thumbsDownReason: dislikeReason,
      });
      setReasonModalOpen(false);
      setCurrentCall({ ...currentCall, thumbsDownReason: dislikeReason });
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      console.error(errorMessage);
    }
  };

  const editAgentFeedback = async (callId, apiData) => {
    try {
      await editAgentFeedbackApi(callId, apiData);
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      console.error(errorMessage);
    }
  };

  const handleReferencedIntent = (matched) => {
    editAgentFeedback(currentCall?._id, {
      isIntentMatchedUserResponse: matched,
    });
    setCurrentCall({
      ...currentCall,
      isIntentMatchedUserResponse: matched,
      feedbackType: "referencedIntent",
    });
    const newData = [...callsData];
    const selectedCallIndex = newData.findIndex(
      (c) => c._id === currentCall?._id
    );
    newData[selectedCallIndex].isIntentMatchedUserResponse = matched;
    setData(newData);
    if (!matched) {
      setReasonModalOpen(true);
      setDislikeReason(currentCall?.intentMatchedUserResponseExplanation);
    }
  };

  const handleAnswerCorrect = (correct) => {
    editAgentFeedback(currentCall?._id, {
      isAnswerCorrect: correct,
    });
    setCurrentCall({
      ...currentCall,
      isAnswerCorrect: correct,
      feedbackType: "answer",
    });
    const newData = [...callsData];
    const selectedCallIndex = newData.findIndex(
      (c) => c._id === currentCall?._id
    );
    newData[selectedCallIndex].isAnswerCorrect = correct;
    setData(newData);
    if (!correct) {
      setReasonModalOpen(true);
      setDislikeReason(currentCall?.answerCorrectnessExplanation);
    }
  };

  const handleOnSaveFeedback = () => {
    if (dislikeReason && dislikeReason.trim() === "") return;

    const newData = [...callsData];
    const selectedCallIndex = newData.findIndex(
      (c) => c._id === currentCall?._id
    );

    if (currentCall?.feedbackType === "answer") {
      editAgentFeedback(currentCall?._id, {
        answerCorrectnessExplanation: dislikeReason,
      });
      newData[selectedCallIndex].answerCorrectnessExplanation = dislikeReason;
      currentCall.answerCorrectnessExplanation = dislikeReason;
    } else {
      editAgentFeedback(currentCall?._id, {
        intentMatchedUserResponseExplanation: dislikeReason,
      });
      newData[selectedCallIndex].intentMatchedUserResponseExplanation =
        dislikeReason;
      currentCall.intentMatchedUserResponseExplanation = dislikeReason;
    }
    setData(newData);
    setCurrentCall(currentCall);
    setReasonModalOpen(false);
  };

  const handleReferenceIntentEdit = () => {
    setReasonModalOpen(true);
  };

  const handleEditComment = (type) => {
    const updatedCurrentCall = { ...currentCall };
    if (type === "answer") {
      updatedCurrentCall.feedbackType = "answer";
      setDislikeReason(currentCall?.answerCorrectnessExplanation);
    } else {
      updatedCurrentCall.feedbackType = "referencedIntent";
      setDislikeReason(currentCall?.intentMatchedUserResponseExplanation);
    }
    setCurrentCall(updatedCurrentCall);
    setReasonModalOpen(true);
  };

  const getWorkFlowDetails = async (workFlowId) => {
    try {
      const singleWorkFlowDetailsResponse = await getSingleWorkflowApi(
        workFlowId
      );
      setSingleWorkFlowDetails(singleWorkFlowDetailsResponse);
      return singleWorkFlowDetailsResponse;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      console.error(errorMessage);
    }
  };

  useEffect(() => {
    getCompanyDetails();
    getData();
    socket.on("new-call-initialized", (newCall) => {
      // console.log("new call initialized", newCall);
      setData((prev) => [newCall, ...prev]);
      setModalOpen(true);
      setCurrentCall(newCall);
    });
    socket.on("new-call-processed", (newCall) => {
      // console.log("new-call-processed", newCall);
      setData((prev) => {
        const index = prev.findIndex((ele) => ele.agentId === newCall.agentId);
        // const index = prev.findIndex((ele) => ele._id === newCall._id);
        prev[index] = newCall;
        setCurrentCall((cc) => {
          // if (cc._id === newCall._id) {
          if (cc.agentId === newCall.agentId) {
            return newCall;
          } else {
            return cc;
          }
        });
        return [...prev];
      });
    });
  }, []);

  useEffect(() => {
    if (currentCall) {
      setStartLineReaction(currentCall.startLineReaction);
      setEndLineReaction(currentCall.endLineReaction);
    }
  }, [currentCall]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Box
        className={`${styles.container} ${
          companyName === "BUSY" ? styles.busy : ""
        }`}
        style={modalOpen ? { height: "100%", maxHeight: "100vh" } : {}}
      >
        {modalOpen && <Box className={styles.faded} />}
        {companyName ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            mb={companyName !== "BUSY" && 9}
          >
            <Typography variant="body2" fontWeight={500}>
              {companyName}
            </Typography>
            <Typography fontSize={10} fontWeight={500} color={"grey.600"}>
              &gt; Powered by Fundamento
            </Typography>
          </Box>
        ) : null}
        {loader ? (
          <Box
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader color={theme.palette.grey[400]} width={32} height={32} />
          </Box>
        ) : callsData?.length > 0 ? (
          <>
            {companyName?.toLowerCase?.().search("busy") < 0 && (
              <Typography variant="h3" mb={5} align="center" fontWeight={500}>
                Call Summary
              </Typography>
            )}
            <Box
              style={{ overflow: "auto", flex: 1 }}
              className={styles.callContainer}
            >
              {callsData.map((item, index) => (
                <Box
                  onClick={() => {
                    // if (item.callType === "blank") return null;
                    setModalOpen(true);
                    // getCallData(item._id);
                    setCurrentCall(item);
                    // If the workflow is there we need to fetch workflow details
                    item?.callSessionAllLogs?.lastIntentObject?.type ===
                      "workflow" &&
                      getWorkFlowDetails(
                        item?.callSessionAllLogs?.lastIntentObject?.workFlowId
                      );
                  }}
                >
                  <Call callDetails={item} key={index} />
                </Box>
              ))}
            </Box>

            <Slide direction="up" in={modalOpen} mountOnEnter unmountOnExit>
              <Box
                style={{
                  height: "90%",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box className={styles.summaryContainer}>
                  {reasonModalOpen && <Box className={styles.faded} />}

                  <Box className={styles.summaryHeader}>
                    <Box style={{ minWidth: 0 }}>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        color="#0F0F0FB2"
                      >
                        {currentCall?.phoneNumber}
                      </Typography>
                      {/* <Typography
                        variant="body2"
                        fontWeight={500}
                        mt={2}
                        className={styles.query}
                      >
                        {currentCall?.startLine}
                      </Typography> */}
                      {currentCall?.callType === "blank" ? (
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          mt={2}
                          className={styles.query}
                          color="#FFA200"
                        >
                          Blank call
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          mt={2}
                          className={styles.query}
                        >
                          {currentCall?.callSessionAllLogs?.parentCategoryName}{" "}
                          &gt;{" "}
                          {currentCall?.callSessionAllLogs?.subCategoryName}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      className={styles.arrow}
                      onClick={() => setModalOpen(false)}
                    >
                      <ChevronDown
                        width={18}
                        color="rgba(15, 15, 15, 0.6)"
                        height={18}
                      />
                    </Box>

                    {/* <Box
                    onClick={() => {
                      setModalOpen(true);
                      getCallData(item._id);
                    }}
                  >
                    <Call callDetails={item} key={index} />
                  </Box> */}
                  </Box>

                  {/* <Box
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                > */}
                  {/* {!currentCall?.endLine &&
                    !currentCall?.startLine &&
                    currentCall?.inProgress && (
                      <Box className={styles.processing}>
                        <Loader
                          color="rgba(15,15,15,0.7)"
                          width={32}
                          height={32}
                        />

                        <Typography
                          mt={6}
                          variant="body2"
                          px={10}
                          color={theme.palette.grey[400]}
                        >
                          Hang in there. Your call summary is almost ready.
                        </Typography>
                      </Box>
                    )} */}

                  {/* {currentCall?.endLine && currentCall?.startLine && ( */}
                  <>
                    <Box
                      px={8}
                      py={4}
                      style={{
                        flexGrow: 1,
                        maxHeight: "calc(100% - 14%)",
                        overflowY: "auto",
                      }}
                    >
                      <Box>
                        <FunCollapse
                          iconPosition="end"
                          header={
                            <Typography variant="body1" fontWeight={500}>
                              Call Transcript
                            </Typography>
                          }
                        >
                          <Box className={styles.chatContainer}>
                            {currentCall?.callSessionAllLogs?.nodes?.map(
                              (item, index) => (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={2}
                                >
                                  {item?.question && (
                                    <Box>
                                      <Typography
                                        variant="caption"
                                        color="#72777A"
                                        textAlign={"left"}
                                        mb={2}
                                        display="block"
                                      >
                                        Customer
                                      </Typography>
                                      <Box
                                        key={index}
                                        className={`${styles.chat} ${styles.user}`}
                                      >
                                        <Typography
                                          variant="body2"
                                          style={{
                                            color: "#303437",
                                            whiteSpace: "pre-line",
                                          }}
                                        >
                                          {item?.question}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )}
                                  {item?.answer.map((ans) => (
                                    <Box>
                                      <Typography
                                        variant="caption"
                                        color="#72777A"
                                        textAlign="end"
                                        mb={2}
                                        display="block"
                                      >
                                        Virtual Agent
                                      </Typography>
                                      {ans?.message ? (
                                        <Box
                                          key={index}
                                          className={`${styles.chat} ${styles.yara}`}
                                        >
                                          <Typography
                                            variant="body2"
                                            style={{
                                              color: "#fff",
                                              whiteSpace: "pre-line",
                                            }}
                                          >
                                            {ans?.message}
                                          </Typography>
                                        </Box>
                                      ) : (
                                        <AudioPlayer
                                          controls
                                          style={{ width: "100%" }}
                                          className={styles.audio}
                                          audioPath={ans.audio}
                                        />
                                      )}
                                    </Box>
                                  ))}
                                </Box>
                              )
                            )}
                          </Box>
                          <Divider
                            sx={{
                              backgroundColor: theme.palette.borderColor.light,
                            }}
                          />
                        </FunCollapse>
                      </Box>

                      <Box display="flex" alignItems="center" columnGap={2}>
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          mb={1}
                          mt={4}
                        >
                          Customer Intent
                        </Typography>
                      </Box>

                      <Typography
                        variant="body1"
                        fontSize={18}
                        color="rgba(15,15,15,0.7)"
                      >
                        {currentCall?.callSessionAllLogs?.lastQuestionAsked ||
                          "N/A"}
                      </Typography>

                      <Box display="flex" alignItems="center" columnGap={2}>
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          mb={1}
                          mt={4}
                        >
                          Primary Intent
                        </Typography>
                      </Box>

                      <Typography
                        variant="body1"
                        fontSize={18}
                        color="rgba(15,15,15,0.7)"
                      >
                        {currentCall?.callSessionAllLogs?.lastIntentObject
                          ?.question || "N/A"}
                      </Typography>

                      {/* {currentCall?.weaviateQuestionPicked && ( */}
                      <>
                        <Typography variant="body1" fontWeight={500} mt={4}>
                          Recommended Solution
                        </Typography>

                        {currentCall?.callSessionAllLogs
                          ?.lastIntentMatchedAsperBotThreshold !== false ? (
                          <Typography
                            variant="body1"
                            fontSize={18}
                            color="rgba(15,15,15,0.7)"
                            whiteSpace="pre-line"
                          >
                            {currentCall?.callSessionAllLogs?.lastIntentObject
                              ?.type === "workflow"
                              ? `Workflow: ${singleWorkFlowDetails?.name ?? ""}`
                              : currentCall?.callSessionAllLogs
                                  ?.lastIntentObject?.answer || "N/A"}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            fontSize={18}
                            color="rgba(15,15,15,0.7)"
                            whiteSpace="pre-line"
                          >
                            No recommended solution
                          </Typography>
                        )}

                        {/* <FlexBox columnGap={3}>
                              <Typography fontSize={16} sx={{ opacity: 0.7 }}>
                                Does the referenced intent match the customer's
                                intent?
                              </Typography>
                              <IconButton
                                disabled={
                                  currentCall?.startLineReaction !== null
                                }
                                sx={{ ml: "auto" }}
                                className={styles.button}
                                onClick={() => {
                                  sendStartLineReaction(currentCall?._id, true);
                                  setStartLineReaction(true);
                                }}
                              >
                                {currentCall?.startLineReaction === true ||
                                startLineReaction === true ? (
                                  <ThumbsUpFilled />
                                ) : (
                                  <ThumbsUp />
                                )}
                              </IconButton>
                              <IconButton
                                className={styles.button}
                                disabled={
                                  currentCall?.startLineReaction !== null
                                }
                                onClick={() => {
                                  setReasonModalOpen(!reasonModalOpen);
                                  setStartLineReaction(false);
                                  sendStartLineReaction(
                                    currentCall?._id,
                                    false
                                  );
                                }}
                              >
                                {currentCall?.startLineReaction === false ||
                                startLineReaction === false ? (
                                  <ThumbsDownFilled />
                                ) : (
                                  <ThumbsDown />
                                )}
                              </IconButton>
                            </FlexBox> */}
                      </>
                      {/* )} */}

                      {currentCall?.reasonForBotFailure && (
                        <>
                          <Typography variant="body1" fontWeight={500} mt={4}>
                            Handoff Reason
                          </Typography>

                          <Typography
                            variant="body1"
                            fontSize={18}
                            color="rgba(15,15,15,0.7)"
                            whiteSpace="pre-line"
                          >
                            {currentCall?.reasonForBotFailure}
                          </Typography>
                        </>
                      )}

                      {/* <Box
                          display="flex"
                          mt={4}
                          alignItems="center"
                          columnGap={2}
                        >
                          <Typography variant="body1" fontWeight={500} mb={1}>
                            Suggested solution
                          </Typography>
                        </Box>

                        <Typography
                          variant="body1"
                          fontSize={18}
                          color="rgba(15,15,15,0.7)"
                          whiteSpace="pre-line"
                        >
                          {currentCall?.endLine}
                        </Typography>

                        <FlexBox mt={3} columnGap={3}>
                          <Typography fontSize={16} sx={{ opacity: 0.7 }}>
                            Is the answer correct?
                          </Typography>
                          <IconButton
                            sx={{ ml: "auto" }}
                            className={styles.button}
                            disabled={currentCall?.endLineReaction !== null}
                            onClick={() => {
                              sendEndLineReaction(currentCall?._id, true);
                              setEndLineReaction(true);
                            }}
                          >
                            {currentCall?.endLineReaction === true ||
                            endLineReaction === true ? (
                              <ThumbsUpFilled />
                            ) : (
                              <ThumbsUp />
                            )}
                          </IconButton>
                          <IconButton
                            className={styles.button}
                            disabled={currentCall?.endLineReaction !== null}
                            onClick={() => {
                              setReasonModalOpen(!reasonModalOpen);
                              sendEndLineReaction(currentCall?._id, false);
                              setEndLineReaction(false);
                            }}
                          >
                            {currentCall?.endLineReaction === false ||
                            endLineReaction === false ? (
                              <ThumbsDownFilled />
                            ) : (
                              <ThumbsDown />
                            )}
                          </IconButton>
                        </FlexBox> */}
                    </Box>
                  </>
                  {/* )} */}

                  {/* {
                    // !currentCall?.endLine &&
                    //   !currentCall?.startLine &&
                    //   !loader &&
                    //   !currentCall?.inProgress &&
                    currentCall?.callType?.toLowerCase() === "blank" && (
                      <>
                        <EmptyState
                          title={"Blank call"}
                          buttonIcon={<WarningCircle width={32} height={32} />}
                        />
                      </>
                    )
                  } */}

                  <Box className={styles.bottomSection}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body2"
                        className={styles.referencedIntentMatchText}
                      >
                        Does the referenced intent match the customer’s intent?
                      </Typography>

                      <Box display="flex" ml="auto" gap={3}>
                        <Button
                          className={`${styles.button} ${
                            currentCall?.isIntentMatchedUserResponse === true &&
                            styles.selectedGreen
                          }`}
                          startIcon={
                            currentCall?.isIntentMatchedUserResponse ? (
                              <ThumbUpGreenFilled width={16} height={16} />
                            ) : (
                              <ThumbUpGreen width={16} height={16} />
                            )
                          }
                          style={{ color: theme.palette.tag.main }}
                          onClick={() => handleReferencedIntent(true)}
                        >
                          Yes
                        </Button>
                        <Button
                          className={`${styles.button} ${
                            currentCall?.isIntentMatchedUserResponse ===
                              false && styles.selectedRed
                          }`}
                          startIcon={
                            currentCall?.isIntentMatchedUserResponse === null ||
                            currentCall?.isIntentMatchedUserResponse ===
                              true ? (
                              <ThumbDownRed width={16} height={16} />
                            ) : (
                              <ThumbDownRedFilled width={16} height={16} />
                            )
                          }
                          style={{ color: theme.palette.error.main }}
                          onClick={() => handleReferencedIntent(false)}
                        >
                          No
                        </Button>
                      </Box>

                      <IconButton
                        sx={{ ml: 1 }}
                        onClick={() => handleEditComment("intent")}
                      >
                        <PencilSimple width={16} height={16} />
                      </IconButton>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body2"
                        className={styles.referencedIntentMatchText}
                      >
                        Is the answer correct?
                      </Typography>

                      <Box display="flex" ml="auto" gap={3}>
                        <Button
                          className={`${styles.button} ${
                            currentCall?.isAnswerCorrect === true &&
                            styles.selectedGreen
                          }`}
                          startIcon={
                            currentCall?.isAnswerCorrect ? (
                              <ThumbUpGreenFilled width={16} height={16} />
                            ) : (
                              <ThumbUpGreen width={16} height={16} />
                            )
                          }
                          style={{ color: theme.palette.tag.main }}
                          onClick={() => handleAnswerCorrect(true)}
                        >
                          Yes
                        </Button>
                        <Button
                          className={`${styles.button} ${
                            currentCall?.isAnswerCorrect === false &&
                            styles.selectedRed
                          }`}
                          startIcon={
                            currentCall?.isAnswerCorrect === null ||
                            currentCall?.isAnswerCorrect === true ? (
                              <ThumbDownRed width={16} height={16} />
                            ) : (
                              <ThumbDownRedFilled width={16} height={16} />
                            )
                          }
                          style={{ color: theme.palette.error.main }}
                          onClick={() => handleAnswerCorrect(false)}
                        >
                          No
                        </Button>
                      </Box>

                      <IconButton
                        sx={{ ml: 1 }}
                        onClick={() => handleEditComment("answer")}
                      >
                        <PencilSimple width={16} height={16} />
                      </IconButton>
                    </Box>
                  </Box>

                  <Slide
                    in={reasonModalOpen}
                    unmountOnExit
                    mountOnEnter
                    direction="up"
                  >
                    <Box className={styles.reasonModal}>
                      <Button
                        onClick={() => setReasonModalOpen(false)}
                        color="inherit"
                        endIcon={<ChevronDown width={16} height={16} />}
                        className={styles.closeButton}
                      >
                        Close
                      </Button>
                      <Typography variant="body1" fontWeight={500}>
                        {currentCall?.feedbackType === "answer"
                          ? "What’s wrong with the answer?"
                          : "What’s wrong with the referenced intent?"}
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        rows={5}
                        defaultValue={dislikeReason}
                        inputProps={{
                          style: {
                            padding: "0 6px",
                          },
                        }}
                        placeholder="Message..."
                        style={{ margin: "16px 0" }}
                        onChange={(e) => {
                          setDislikeReason(e.target.value);
                        }}
                      />

                      <Box display={"flex"} justifyContent={"center"}>
                        <Button
                          variant="dark"
                          endIcon={<ChevronRight />}
                          disabled={dislikeReason?.length === 0}
                          onClick={
                            handleOnSaveFeedback
                            // sendDislikeReason(currentCall?._id, dislikeReason)
                          }
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Slide>
                  {/* </Box> */}
                </Box>

                {/* {currentCall?.endLine && currentCall?.startLine && (
                  <Box className={styles.dislikeButtonContainer}>
                    <Typography
                      color="#0f0f0f"
                      variant="body2"
                      fontWeight={500}
                    >
                      Did you find this suggestion helpful?
                    </Typography>
                    <div
                      style={{ marginLeft: "auto", display: "flex", gap: 12 }}
                    >
                      <Button
                        style={
                          currentCall?.thumbsDownReason || endLineReaction
                            ? { pointerEvents: "none", boxShadow: "none" }
                            : {}
                        }
                        onClick={() => {
                          sendEndLineReaction(currentCall?._id, true);
                          setEndLineReaction(true);
                        }}
                        startIcon={
                          currentCall?.endLineReaction ? (
                            <ThumbsUpFilled />
                          ) : (
                            <ThumbsUp />
                          )
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        style={
                          currentCall?.thumbsDownReason
                            ? { pointerEvents: "none", boxShadow: "none" }
                            : {}
                        }
                        disabled={currentCall?.endLineReaction}
                        onClick={() => setReasonModalOpen(!reasonModalOpen)}
                        startIcon={
                          currentCall?.thumbsDownReason ? (
                            <ThumbsDownFilled />
                          ) : (
                            <ThumbsDown />
                          )
                        }
                      >
                        No
                      </Button>
                    </div>
                  </Box>
                )} */}
              </Box>
            </Slide>
          </>
        ) : (
          <>
            <EmptyState
              title={"No calls found"}
              description={
                "All your call summaries will be available here. Check back again later."
              }
            />
          </>
        )}
      </Box>
      <BottomNav hide={modalOpen} activeState="callSummary" />
    </div>
  );
};

export default CallSummary;
