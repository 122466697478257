import {
  Dialog,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  LinearProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { IconClose, IconDownload, IconUpload2 } from "components/SVG";
import { CSV, Duplicate, WarningRed, Webhook } from "components/newSVG";
import theme from "theme";
import { getNewFileName } from "utils/utils";
import {
  getSignedMediaUrl as getSignedMediaUrlApi,
  updateCampaignContact as updateCampaignContactApi,
} from "services";
import moment from "moment";
import { useSkillrToast } from "context/toast";
import axios from "axios";
import UploadCallDataList from "../UploadCallDataList";
import getCampaignWebhookContacts from "services/getCampaignWebhookContacts";
import * as XLSX from "xlsx";

const tabs = ["CSV", "Webhook"];
const supportedFileTypes = ["text/csv"];
const maxUploadLimit = 2 * 1024 * 1024; // 2 MB in bytes

const UploadCallData = (props) => {
  const {
    open,
    onClose,
    onSave,
    campaignDetails,
    getCampaignContactDetails,
    getCampaignDetails,
  } = props;
  const { showSkillrToast } = useSkillrToast();

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [uploadedData, setUploadedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef();

  const handleTabChange = (event, selectedTab) => {
    setActiveTab(selectedTab);
  };

  const checkFileSizeAndType = (file) => {
    const regexPattern = supportedFileTypes.join("|");
    const regex = new RegExp(regexPattern, "i");

    if (!regex.test(file.type)) {
      return `Only ${regexPattern} file type can be uploaded`;
    }

    if (file.size > maxUploadLimit) {
      return "File size exceeded";
    }

    return "";
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newUploadedData = Object.keys(files).map((filekey) => {
      const renamedFileName = getNewFileName(files[filekey]);
      return {
        fileName: renamedFileName.name,
        file: renamedFileName,
        progress: 0,
        gscUrl: "",
        error: checkFileSizeAndType(files[filekey]),
        size: files[filekey].size,
        dateTime: moment().utc().format(),
      };
    });
    setUploadedData([...uploadedData, ...newUploadedData]);
    fileInputRef.current.value = "";
  };

  const handleBrowse = () => {
    fileInputRef.current.click();
  };

  const handleOnDeleteFile = (selectedData) => {
    const newUploadedFiles = uploadedData.filter(
      (uploadedData) => uploadedData.fileName !== selectedData.fileName
    );

    setUploadedData(newUploadedFiles);
  };

  const handleUploadProgress = (progressEvent, file) => {
    if (progressEvent?.loaded > 0) {
      const uploadProgress = Math.floor(
        (progressEvent?.loaded / progressEvent?.total) * 100
      );

      const uploadedFileIndex = uploadedData.findIndex(
        (data) => data.fileName === file.name
      );
      const newUploadedData = [...uploadedData];
      newUploadedData[uploadedFileIndex].progress = uploadProgress;
      setUploadedData(newUploadedData);
    }
  };

  const createSignedUrl = async (file) => {
    try {
      const params = new URLSearchParams();
      params.set("contentType", file.type);
      const response = await getSignedMediaUrlApi(file.name, params.toString());
      return response;
    } catch (error) {
      throw error;
    }
  };

  const uploadFileToBucket = async (url, file) => {
    try {
      const config = {
        onUploadProgress: (event) => handleUploadProgress(event, file),
      };

      const response = await axios.put(url, file, config);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getFormattedData = (uploadedDataToFormat) => {
    return uploadedDataToFormat.map((uploadedDetails) => ({
      gscUrl: uploadedDetails.gscUrl
        ?.split("?")[0]
        ?.replace("https://storage.googleapis.com/", "gs://"),
      fileName: uploadedDetails?.fileName,
      size: uploadedDetails?.size,
      dateTime: moment().utc().format(),
    }));
  };

  const handleOnUpload = async () => {
    try {
      const containsError = uploadedData?.some(
        (uploadedDetails) => uploadedDetails?.error
      );

      if (containsError) {
        showSkillrToast("Please fix all errors", "error");
        return;
      }

      if (uploadedData?.length > 0) {
        setLoading(true);
        const alreadyUploadedData = uploadedData?.filter(
          (uploadedDataToFilter) => uploadedDataToFilter?.gscUrl
        );

        let newUploadedData = uploadedData?.filter(
          (uploadedDataToFilter) => !uploadedDataToFilter?.gscUrl
        );

        if (newUploadedData?.length > 0) {
          // Creating signedUrl for all the files in one go (doing concurrently)
          const signedUrlPromises = newUploadedData.map((uploadedDetails) =>
            createSignedUrl(uploadedDetails.file)
          );
          const signedUrlResponses = await Promise.all(signedUrlPromises);

          // uploading files by using signedUrl for all the files in one go (doing concurrently)
          const uploadPromises = signedUrlResponses.map((signedUrl, index) =>
            uploadFileToBucket(
              signedUrl?.signedUrl,
              newUploadedData[index].file
            )
          );

          await Promise.all(uploadPromises);

          // Adding the signedUrl once the file uploaded to the bucket to the newUploadedData array
          newUploadedData = newUploadedData.map(
            (newUploaded, newUploadedIndex) => ({
              ...newUploaded,
              gscUrl: signedUrlResponses[newUploadedIndex]?.signedUrl,
            })
          );
        }

        const formattedData = getFormattedData([
          ...alreadyUploadedData,
          ...newUploadedData,
        ]);

        const updateCampignResponse = await updateCampaignContactApi(
          campaignDetails?._id,
          {
            csvFiles: formattedData,
          }
        );
        setLoading(false);
        showSkillrToast(updateCampignResponse?.message, "success");
        getCampaignContactDetails();
        getCampaignDetails();
        onClose();
      } else {
        showSkillrToast("No files found", "error");
      }
    } catch (error) {
      setLoading(false);
      if (
        ["initialCheckFail", "partial"].includes(
          error?.response?.data?.responseType
        )
      ) {
        setUploadedData(error?.response?.data?.csvFiles);
      } else {
        const errorMessage = error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong";
        showSkillrToast(errorMessage, "error");
      }
    }
  };

  const handleOnMouseEnterButton = (UploadedDataIndex) => {
    const newUploadedData = [...uploadedData];
    newUploadedData[UploadedDataIndex].hovered = true;
    setUploadedData(newUploadedData);
  };

  const handleOnMouseLeaveButton = (UploadedDataIndex) => {
    const newUploadedData = [...uploadedData];
    newUploadedData[UploadedDataIndex].hovered = false;
    setUploadedData(newUploadedData);
  };

  const handleOnCopyWebHookIdentifier = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}
      /api/campaign/webhook?importId=
      ${campaignDetails?.webhookIdentifier}`);
    showSkillrToast("Copied to clipboard", "success");
  };

  const exportToCSV = (jsonData, fileName) => {
    // Step 1: Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Step 2: Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Step 3: Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Step 4: Write the workbook to CSV format
    const csvOutput = XLSX.write(workbook, {
      bookType: "csv",
      type: "string",
    });

    // Step 5: Trigger the download
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      // Feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${fileName}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownloadWebHookContacts = async () => {
    try {
      const campaignDetailsResponse = await getCampaignWebhookContacts(
        campaignDetails._id
      );
      exportToCSV(campaignDetailsResponse, `${campaignDetails._id}_contacts`);
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      showSkillrToast(errorMessage, "error");
    }
  };

  const calculateTimeAgo = (serverTime) => {
    const now = new Date();
    const updatedTime = new Date(serverTime);

    // Calculate the difference in milliseconds
    const diff = now - updatedTime;

    // Convert the difference to seconds, minutes, hours, etc.
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      return `${days} days ago`;
    }
  };

  useEffect(() => {
    if (!open) {
      setUploadedData([]);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 690,
          minHeight: 400,
        },
      }}
    >
      <Box padding="32px 24px" display="flex" flexDirection="column" gap={8}>
        <Box>
          <Typography
            fontSize={24}
            fontWeight={500}
            color="#0f0f0f"
            textAlign="center"
          >
            Upload Call Data
          </Typography>
          <Typography variant="body2" textAlign="center" py={2}>
            Upload your file that contains the list of numbers to be called
            during the campaign.
          </Typography>

          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="tabs"
            className={styles.tabs}
            TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
            sx={{
              "& span.MuiTabs-indicator": {
                borderRadius: "10px 10px 0 0",
                height: 3,
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab className={styles.tab} label={tab} value={tab} key={index} />
            ))}
          </Tabs>

          <Box className={styles.horizontalBorder} />
        </Box>

        {/* When CSV tab is active */}
        {activeTab === tabs[0] && (
          <>
            {campaignDetails?.csvFiles?.length > 0 && (
              <UploadCallDataList campaignDetails={campaignDetails} />
            )}

            <Box>
              <Box className={styles.uploadSection}>
                <IconUpload2 width={40} height={40} />

                <Button
                  variant="outline"
                  className={styles.browse}
                  onClick={handleBrowse}
                >
                  Browse
                </Button>
              </Box>
              <Box className={styles.uploadDetails}>
                <Typography variant="body2" color="#797979">
                  Only support CSV files
                </Typography>
                <Typography variant="body2" color="#797979">
                  Max Size :2MB
                </Typography>
              </Box>

              <Box mt={4} display="flex" flexDirection="column" gap={4}>
                {uploadedData?.map((uploadedFile, uploadedFileIndex) => (
                  <Box
                    className={styles.uploadedFile}
                    key={uploadedFile.fileName + uploadedFileIndex}
                  >
                    <Box display="flex" gap="14px" alignItems="center">
                      <CSV width={24} height={32} style={{ flexShrink: 0 }} />

                      <Tooltip
                        title={
                          uploadedFile.fileName?.length > 50
                            ? uploadedFile.fileName
                            : ""
                        }
                        placement="top"
                      >
                        <Typography variant="body2" className={styles.fileName}>
                          {uploadedFile.fileName}
                        </Typography>
                      </Tooltip>

                      {!loading && (
                        <IconButton
                          sx={{ ml: "auto" }}
                          onClick={() => handleOnDeleteFile(uploadedFile)}
                          onMouseEnter={() =>
                            handleOnMouseEnterButton(uploadedFileIndex)
                          }
                          onMouseLeave={() =>
                            handleOnMouseLeaveButton(uploadedFileIndex)
                          }
                        >
                          {uploadedFile?.hovered ? (
                            <IconClose width={24} height={24} />
                          ) : uploadedFile?.error ? (
                            <WarningRed width={24} height={24} />
                          ) : (
                            <IconClose width={24} height={24} />
                          )}
                        </IconButton>
                      )}
                    </Box>
                    <LinearProgress
                      value={uploadedFile?.progress}
                      color="primary"
                      variant="determinate"
                      sx={{
                        width: "100%",
                        marginTop: 1,
                        height: 3,
                        borderRadius: 5,
                        bgcolor: theme.palette.grey[100],
                        "& .MuiLinearProgress-bar": {
                          borderRadius: 5,
                          bgcolor: theme.palette.background.primary,
                        },
                      }}
                    />
                    {uploadedFile?.error && (
                      <Typography variant="caption" color="error">
                        {uploadedFile.error}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={styles.buttonContainer}>
              <Button
                variant="outline"
                className={styles.button}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="dark"
                className={styles.button}
                onClick={handleOnUpload}
                disabled={loading || activeTab === tabs[1]}
              >
                Upload
              </Button>
            </Box>
          </>
        )}

        {/* When webhook tab is active */}
        {activeTab === tabs[1] && (
          <>
            <Box>
              <Box className={styles.webhookContainer}>
                <Webhook width={48} height={48} />

                <Box display="flex" flexDirection="column" gap={3}>
                  {campaignDetails?.lastWebhookNumberCalled ? (
                    <Typography variant="caption" color="#555">
                      {calculateTimeAgo(
                        campaignDetails?.lastWebhookNumberCalled
                      )}
                    </Typography>
                  ) : null}

                  <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="body2" whiteSpace="nowrap">
                      URL:
                    </Typography>
                    <Tooltip
                      placement="top"
                      title={`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/campaign/webhook?importId=${campaignDetails?.webhookIdentifier}`}
                    >
                      <Typography
                        href="https://www.fundamento.ai/"
                        className={styles.webhookUrl}
                      >
                        {`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/campaign/webhook?importId=${campaignDetails?.webhookIdentifier}`}
                      </Typography>
                    </Tooltip>
                    <IconButton onClick={handleOnCopyWebHookIdentifier}>
                      <Duplicate width={16} height={16} />
                    </IconButton>
                  </Box>

                  <Typography variant="caption" color="#555">
                    Contacts: {campaignDetails?.totalWebhookNumbers ?? 0}
                  </Typography>
                </Box>

                {campaignDetails?.totalWebhookNumbers ?? 0 > 0 ? (
                  <IconButton
                    sx={{ ml: "auto" }}
                    onClick={handleDownloadWebHookContacts}
                  >
                    <IconDownload width={20} height={20} />
                  </IconButton>
                ) : null}
              </Box>
            </Box>
            <Box className={styles.buttonContainer}>
              <Button
                variant="outline"
                className={styles.button}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Box>
      <input
        type="file"
        multiple
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={supportedFileTypes.join(",")}
      />
    </Dialog>
  );
};

UploadCallData.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  campaignId: PropTypes.string,
};

export default UploadCallData;
