import {
  Avatar,
  Box,
  Button,
  Fade,
  FormControl,
  InputAdornment,
  Radio,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { RadioButton, Speaker } from "components/newSVG";
import botVoices from "utils/botVoices";
import FlexBox from "components/FlexBox";
import { useUserContext } from "context/user";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createBot, editBot } from "services";
import { useSkillrToast } from "context/toast";
import { getAudio as getAudioApi } from "services";
import { SkillrTextField } from "components";
import { HelpCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import PageWrapper from "components/PageWrapper";

const CreateBot = () => {
  const { bot, setBot } = useUserContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const { showSkillrToast } = useSkillrToast();
  const [matchThreshold, setMatchThreshold] = useState(0);
  const navigate = useNavigate();
  const [playingVoice, setPlayingVoice] = useState(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    // reset,
  } = useForm();

  const createNewBot = async (data) => {
    try {
      const getLanguageCode = botVoices.find(
        (item) => item.name === data.botVoice
      );
      const newData = {
        ...data,
        botVoice: {
          name: data.botVoice,
          languageCode: getLanguageCode.languageCode,
        },
        threshold: matchThreshold,
      };
      const response = await createBot(newData);
      // const response = await editBot(newData);
      if (response) {
        setBot(response);
        navigate("/bot/playground");
      }
    } catch (error) {
      console.error("error", error);
      showSkillrToast("Something went wrong", "error");
    }
  };

  // const resetBot = () => {
  //   if (bot) {
  //     reset({
  //       personality: bot.personality,
  //       instruction: bot.instruction,
  //       welcomeMessage: bot.welcomeMessage,
  //       failureMessage: bot.failureMessage,
  //       botVoice: bot.botVoice.name,
  //       threshold: bot.threshold,
  //     });
  //     setMatchThreshold(bot.threshold);
  //   }
  // };

  const getAudio = async (apiData) => {
    setIsPlaying(true);
    const response = await getAudioApi(apiData);
    const base64AudioContent = response;
    const audio = new Audio("data:audio/wav;base64," + base64AudioContent);
    audio.play();

    audio.onended = () => {
      setIsPlaying(false);
    };
  };

  // useEffect(() => {
  //   resetBot();
  // }, [bot]);

  return (
    <PageWrapper className={styles.container}>
      <Box>
        <Typography textAlign={"center"} mb={8} variant="h3" fontWeight={500}>
          Setup Assistant
        </Typography>

        <Box>
          <Typography variant="subtitle2" gutterBottom paddingLeft={3}>
            Assistant Prompt
          </Typography>
          <Controller
            name="instruction"
            control={control}
            error={errors.instruction}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                multiline
                fullWidth
                rows={5}
                value={value}
                onChange={onChange}
                sx={{
                  "& div": { borderRadius: "20px" },
                  "& textarea": { padding: 0 },
                }}
                InputProps={{
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <Button
                  //       variant="dark"
                  //       className={styles.saveButton}
                  //       onClick={() => {
                  //         updateInstruction(value);
                  //       }}
                  //     >
                  //       Save
                  //     </Button>
                  //   </InputAdornment>
                  // ),
                  sx: {
                    alignItems: "flex-end",
                  },
                }}
              />
            )}
          />
        </Box>

        <Box mt={8} mb={6}>
          <Typography variant="subtitle2" gutterBottom paddingLeft={3}>
            Assistant Voice
          </Typography>

          <Controller
            name="botVoice"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box>
                <FormControl className={styles.voiceContainer}>
                  {botVoices.map((voice) => (
                    <Box
                      key={voice.name}
                      className={`${styles.voice}`}
                      onClick={(e) => {
                        setPlayingVoice(voice.name);
                        !isPlaying &&
                          getAudio({
                            text: "Hi, I'm Yara, how can I assist you?",
                            botVoice: {
                              name: voice.name,
                              languageCode: voice.languageCode,
                            },
                          });
                      }}
                    >
                      <Radio
                        value={voice.name}
                        onChange={onChange}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        checked={value === voice.name}
                        checkedIcon={<RadioButton width={20} height={20} />}
                      />
                      <Avatar>
                        {isPlaying && playingVoice === voice.name ? (
                          <div class={styles.boxContainer}>
                            <div class={`${styles.box} ${styles.box1}`}></div>
                            <div class={`${styles.box} ${styles.box2}`}></div>
                            <div class={`${styles.box} ${styles.box3}`}></div>
                          </div>
                        ) : (
                          <Speaker color="#555" />
                        )}
                      </Avatar>
                      <div>
                        <Typography variant="body2" fontWeight={500}>
                          {voice.displayName}
                        </Typography>
                        <Typography fontSize={10} color="textSecondary">
                          {voice.languageCode}
                        </Typography>
                      </div>
                    </Box>
                  ))}
                </FormControl>
              </Box>
            )}
          />
        </Box>

        <FlexBox mb={6} style={{ gap: 32, alignItems: "center" }}>
          <Box flex={1}>
            <FlexBox columnGap={2}>
              <Typography variant="subtitle2">Set match threshold</Typography>
              <Tooltip title="Set the semantic match threshold radius to set the rigidity of FAQ search. A higher radius value will result in greater flexibility while matching FAQs. ">
                <HelpCircle
                  fill="rgba(15, 15, 15, 0.4)"
                  stroke="#fff"
                  width={16}
                  height={16}
                  style={{ marginRight: 8 }}
                />
              </Tooltip>
            </FlexBox>
            <Slider
              min={0}
              max={0.25}
              valueLabelDisplay="auto"
              step={0.01}
              sx={{
                "& .MuiSlider-rail": { backgroundColor: "#EFEFEF" },
                "& .MuiSlider-track": {
                  backgroundColor: "#00C8C2",
                  border: 0,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#fff",
                  width: 20,
                  height: 20,
                },
                marginTop: 4,
                marginBottom: 0,
              }}
              marks={[
                {
                  value: 0,
                  label: (
                    <Typography variant="body2" fontWeight={500}>
                      0
                    </Typography>
                  ),
                },
                {
                  value: 0.25,
                  label: (
                    <Typography variant="body2" fontWeight={500}>
                      0.25
                    </Typography>
                  ),
                },
              ]}
              value={matchThreshold}
              onChange={(e, value) => {
                setMatchThreshold(value);
              }}
            />
          </Box>

          <Box flex={1}>
            <Typography variant="subtitle2" gutterBottom paddingLeft={3}>
              Failure message
            </Typography>

            <SkillrTextField
              control={control}
              name="failureMessage"
              defaultValue={bot?.failureMessage ? bot?.failureMessage : ""}
              error={errors.failureMessage}
              sx={{ "& div": { borderRadius: 20 } }}
              fullWidth
              placeholder="Answer not found"
            />
          </Box>
        </FlexBox>

        <FlexBox justifyContent="flex-end" mb={10}>
          {/* <Fade
            in={isDirty || matchThreshold !== bot?.threshold}
            mountOnEnter
            unmountOnExit
          >
            <Button
              className={styles.button}
              variant="outlined"
              onClick={resetBot}
            >
              Cancel
            </Button>
          </Fade> */}
          <Button
            variant="dark"
            onClick={handleSubmit(createNewBot)}
            // onClick={() => console.log("hi")}
          >
            Create Assistant
          </Button>
        </FlexBox>
      </Box>
    </PageWrapper>
  );
};

export default CreateBot;
