import { Outlet } from "react-router";
import { useEffect, useState } from "react";
import { useUserContext } from "context/user";
import Header from "components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";

const Bot = () => {
  const { playgroundCurrentChat } = useUserContext();
  const tabs = ["assistant", "history"];
  const currentRoute = useLocation().pathname;
  const navigate = useNavigate();

  const getInitialActiveTab = () => {
    return currentRoute.includes("history") ? "history" : "assistant";
  };
  const [activeTab, setActiveTab] = useState(getInitialActiveTab);

  useEffect(() => {
    /**
     * We are storing the user current chat in playground object and
     * We are deleting the playground chat because user is moving
     * to another screen, so we are clearing the recorded chat.
     */
    const newPlayGroundChat = playgroundCurrentChat.current;
    delete newPlayGroundChat?.current?.playgroundChat;
    return () => {
      playgroundCurrentChat.current = newPlayGroundChat;
    };
  }, []);

  useEffect(() => {
    setActiveTab(getInitialActiveTab);

    if (currentRoute === "/bot/playground") {
      setActiveTab("assistant");
    } else {
      setActiveTab("history");
    }
  }, [currentRoute]);

  return (
    <>
      <PageWrapper className={styles.pageWrapper}>
        <Header
          title="Playground"
          subTitle="Test your virtual agent in the playground before you deploy."
          tabs={tabs}
          defaultActiveTab={activeTab}
          onTabChange={(tab) => {
            tab === "assistant"
              ? navigate("/bot/playground")
              : navigate("/bot/history");
            // setActiveTab(tab);
            // setShowHistoryDetail(false);
          }}
        />

        <Outlet />
      </PageWrapper>
    </>
  );
};

export default Bot;
