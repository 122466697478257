import { Box, LinearProgress, Typography } from "@mui/material";
import Sidebar from "components/Sidebar";
import styles from "./index.module.css";
import { useUserContext } from "context/user";
import DataSetsTable from "pages/AllDatasets/components/DataSetsTable";
import InformationSection from "./components/InfoSection";
import FileUpload from "./components/FileUpload";

const Dashboard = () => {
  const { companyDetails } = useUserContext();
  const storageUsed = companyDetails
    ? (companyDetails?.usedStorageCapacity /
        companyDetails?.maxStorageCapacityInMB) *
      100
    : 0;

  return (
    <Box className={styles.container}>
      <Sidebar />
      <Box className={styles.main}>
        {/* info boxes section comes here */}
        <InformationSection />
        {/* info boxes section ends here */}

        <Box className={styles.content}>
          <Typography variant="h4" align="center" fontWeight={500} mb={2}>
            Console
          </Typography>
          <Typography variant="body2" align="center" color="grey.600">
            Monitor all your data uploads and keep track of your datasets
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              justifyContent: "center",
            }}
            mt={12}
          >
            <Typography variant="body2" color="grey.600">
              Storage used
            </Typography>
            <Box style={{ color: "#A2DAFC" }}></Box>
            <LinearProgress
              variant="determinate"
              value={storageUsed}
              color="inherit"
              style={{ flexGrow: 1, color: "#A2DAFC" }}
            />
            <Typography variant="body2" color="grey.600">
              {`${Math.round(companyDetails?.usedStorageCapacity) ?? 0} of ${
                companyDetails?.maxStorageCapacityInMB ?? 500
              } mb`}
            </Typography>
          </Box>

          {/* upload section */}
          <FileUpload />

          <Box
            sx={{
              maxHeight: "25vh",
              overflowY: "auto",
              mt: 12,
            }}
          >
            <DataSetsTable />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
