import PageWrapper from "components/PageWrapper";
import Header from "components/Header";
import styles from "./index.module.css";
import { Box } from "@mui/material";
import { Outlet } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";

const AILabs = () => {
  const navigate = useNavigate();
  const tabs = ["process", "configure"];
  const location = useLocation();
  const [, parentPath, childPath] = location?.pathname.split("/");

  const handleTabChange = (selectedTab) => {
    navigate(selectedTab);
  };

  return (
    <PageWrapper className={styles.container}>
      <Box>
        <Header
          title="AI Labs"
          subTitle="Use generative AI to create your intent library."
          tabs={tabs}
          defaultActiveTab={childPath ? childPath : tabs[0]}
          onTabChange={handleTabChange}
        />
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <Outlet />
      </Box>
    </PageWrapper>
  );
};

export default AILabs;
