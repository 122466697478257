import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  FormControl,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import classes from "./index.module.css";
import { ArrowForwardIos } from "@mui/icons-material";
import { Circle, X } from "react-feather";
import PropTypes from "prop-types";
import { RedAlert } from "components/SVG";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import QuestionVariant from "../QuestionVariant";
import FlexBox from "components/FlexBox";
import { Eye, EyeClose, RadioButton } from "components/newSVG";
import AutoComplete from "components/AutoComplete";
import { useUserContext } from "context/user";
import { languages as defaultLanguages } from "text-constants/common";
import theme from "theme";
import { createNewTag, getTags } from "services";

const EditFaqModal = (props) => {
  const {
    open,
    onClose,
    onSave,
    question,
    workflows,
    loading,
    categories,
    categoryKey,
    questionIdKey,
    variantToAdd,
    addQuestion, // this prop only exists when pre filling a question to add on call details page
  } = props;

  const methods = useForm({
    defaultValues: {
      category: "",
      question: "",
      answer: "",
      questionVariant: [
        {
          question: "",
        },
      ],
      answerType: "textAnswer",
      workflow: "",
      showMore: null,
      languageVariations: {},
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isDirty },
  } = methods;

  const [languages, setLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [showDefault, setShowDefault] = useState(false);
  const prevBotVoicesRef = useRef();
  const { getBot, bot } = useUserContext();
  const [allTags, setAllTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleSave = async (data) => {
    const mainQuestion = data.questionVariant[0];
    const questionVariant = data.questionVariant.slice(1);

    // Ensure we're using the latest answers for all languages
    const finalLanguageVariations = {
      ...data.languageVariations,
      [currentLanguage]: { answer: data.answer },
    };

    // Ensure the default language answer is set correctly
    const defaultAnswer =
      finalLanguageVariations[defaultLanguage]?.answer || data.answer;

    const finalData = {
      ...data,
      answer: defaultAnswer,
      languageVariations: finalLanguageVariations,
      category: data?.category?._id,
      workflow: data?.workflow?._id,
      question: mainQuestion,
      questionVariant,
      type:
        data.answerType === "smartPlus"
          ? "smartPlus"
          : data.answerType === "textAnswer"
          ? "faq"
          : "workflow",
      ...(question && { id: question[questionIdKey] }),
      ...(data.tags.length > 0 && { tags: data.tags }),
    };

    if (data.answerType === "textAnswer" || data.answerType === "smartPlus") {
      finalData.workflow = "";
    } else {
      finalData.answer = "";
      finalData.languageVariations = {};
    }

    await onSave(finalData);
    handleOnClose();
  };

  const handleLanguageChange = (newLanguage) => {
    const currentAnswer = getValues("answer");
    const currentLanguageVariations = getValues("languageVariations") || {};

    // Save current language answer
    setValue(
      "languageVariations",
      {
        ...currentLanguageVariations,
        [currentLanguage]: { answer: currentAnswer },
      },
      { shouldDirty: true }
    );

    // Set new language answer
    setCurrentLanguage(newLanguage);
    const newAnswer =
      newLanguage === defaultLanguage
        ? currentLanguageVariations[defaultLanguage]?.answer ||
          getValues("answer")
        : currentLanguageVariations[newLanguage]?.answer || "";
    setValue("answer", newAnswer, { shouldDirty: true });
  };

  const handleOnClose = () => {
    setCurrentLanguage(defaultLanguage);
    onClose();
  };

  const isLocalized = (language) => {
    // check if the answer is present in the language in data.languageVariations
    return Boolean(
      getValues("languageVariations") &&
        getValues("languageVariations")[language] &&
        getValues("languageVariations")[language].answer
    );
  };

  const createTag = async (tag) => {
    try {
      const response = await createNewTag(tag);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTags = async () => {
    try {
      const response = await getTags();
      setAllTags(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleTagChange = async (event, newValue) => {
    const processedTags = newValue.map((tag) => {
      if (typeof tag === "string") {
        const existingTag = allTags.find(
          (t) => t.tag.toLowerCase() === tag.toLowerCase()
        );
        return existingTag ? existingTag._id : tag;
      }
      return tag._id;
    });

    const lastTag = processedTags[processedTags.length - 1];
    if (
      typeof lastTag === "string" &&
      !allTags.some((t) => t._id === lastTag)
    ) {
      try {
        const createdTag = await createNewTag({ tag: lastTag });
        if (createdTag) {
          processedTags[processedTags.length - 1] = createdTag._id;
          setAllTags((prevTags) => [...prevTags, createdTag]);
        }
      } catch (error) {
        console.error("Error creating new tag:", error);
      }
    }

    setValue("tags", processedTags, { shouldDirty: true });

    if (processedTags.length > 0) {
      clearErrors("tags");
    }
  };

  const getTagLabel = (tagId) => {
    const tag = allTags.find((t) => t._id === tagId);
    return tag ? tag.tag : tagId;
  };

  const getFilteredTags = () => {
    const selectedTags = watch("tags") || [];
    return allTags.filter((tag) => !selectedTags.includes(tag._id));
  };

  useEffect(() => {
    if (!open) {
      reset({
        category: "",
        question: "",
        answer: "",
        questionVariant: [{ question: "" }],
        answerType: "textAnswer",
        workflow: "",
        showMore: null,
        tags: "",
      });
      setInputValue("");
    }
    getBot();
    getAllTags();
  }, [open]);

  useEffect(() => {
    if (bot) {
      const voicesChanged =
        prevBotVoicesRef.current === undefined ||
        prevBotVoicesRef.current.length !== bot.botVoices.length ||
        !prevBotVoicesRef.current.every(
          (voice, index) =>
            voice.languageCode === bot.botVoices[index].languageCode
        );

      if (voicesChanged) {
        setLanguages([]); // Reset languages before setting new ones to ensure uniqueness
        const botLanguages = bot.botVoices.map((voice) => voice.languageCode);
        setLanguages(botLanguages);
        const defaultLang =
          bot.botVoices.find((voice) => voice.default)?.languageCode ||
          botLanguages[0];
        setDefaultLanguage(defaultLang);
        setCurrentLanguage(defaultLang);

        prevBotVoicesRef.current = bot.botVoices.map((voice) => ({ ...voice }));
      }
    }
  }, [bot]);

  useEffect(() => {
    if (question) {
      const questionVariant = question?.questionVariant || [];
      const languageVariations = question.languageVariations || {};
      const defaultAnswer =
        languageVariations[defaultLanguage]?.answer || question?.answer || "";

      reset({
        answer: defaultAnswer,
        questionVariant: [
          ...(addQuestion
            ? [{ question: question }]
            : [{ question: question?.question }]),
          ...questionVariant,
          ...(variantToAdd ? [{ question: variantToAdd }] : []),
        ],
        answerType:
          question?.type === "smartPlus"
            ? "smartPlus"
            : question?.answer
            ? "textAnswer"
            : "triggerWorkflow",
        workflow: question.workFlow,
        category: question[categoryKey],
        languageVariations: question.languageVariations || {},
        tags: question.tags || [],
      });
    }
  }, [question]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 800,
          height: 630,
          overflowY: "scroll",
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" position="relative">
        <IconButton
          sx={{ position: "absolute", top: 16, right: 16 }}
          onClick={handleOnClose}
        >
          <X width={20} height={20} />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        <Typography textAlign="center" variant="h4" fontWeight={500} mb={1}>
          {addQuestion ? "Add" : question ? "Edit " : "Add "} Intent
        </Typography>
        <Typography variant="body2" textAlign="center">
          Use FAQ, LLM or AI driven workflows to respond to Intents
        </Typography>

        <Typography variant="caption" className={classes.label}>
          Questions *
        </Typography>

        <FormProvider {...methods}>
          <QuestionVariant path="questionVariant" />
        </FormProvider>

        <Typography variant="caption" className={classes.label}>
          Category *
        </Typography>
        <Controller
          name="category"
          control={control}
          rules={{
            required: "Category is required",
          }}
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <AutoComplete
                value={value}
                renderOptionKey="name"
                autoComplete
                error={errors?.category}
                optionList={categories}
                onChange={(cat) => {
                  onChange(cat);
                }}
                closeIcon={false}
                ListboxProps={{ style: { maxHeight: "12rem" } }}
                className={classes.formField}
                PaperComponent={({ children }) => (
                  <Grow in={true} mountOnEnter unmountOnExit>
                    <div className={classes.autoCompleteList}>{children}</div>
                  </Grow>
                )}
              />
            </FormControl>
          )}
        />

        <Typography variant="caption" className={classes.label}>
          Tags
        </Typography>

        <Controller
          name="tags"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              freeSolo
              // options={allTags}
              options={getFilteredTags()}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return getTagLabel(option);
                }
                return option.tag || "";
              }}
              value={field.value || []}
              onChange={handleTagChange}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((tagId, index) => (
                  <Chip
                    key={index}
                    label={getTagLabel(tagId)}
                    {...getTagProps({ index })}
                    className={classes.chip}
                    deleteIcon={<X style={{ height: 16, width: 16 }} />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    "& > div": {
                      padding: 4,
                      borderRadius: "20px",
                    },
                  }}
                  variant="outlined"
                  placeholder="press enter to add"
                />
              )}
              isOptionEqualToValue={(option, value) => option._id === value}
              PaperComponent={({ children }) => (
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "12px",
                    backgroundColor: theme.palette.grey[50],
                    "& .MuiAutocomplete-listbox": {
                      "& .MuiAutocomplete-option": {
                        borderRadius: "8px",
                        margin: "4px 0",
                        '&[aria-selected="true"]': {
                          backgroundColor: theme.palette.primary.light,
                        },
                        '&[data-focus="true"]': {
                          backgroundColor: theme.palette.action.hover,
                        },
                      },
                    },
                  }}
                >
                  {children}
                </Paper>
              )}
            />
          )}
        />

        {errors?.tags && (
          <FlexBox columnGap={0} mt={2} ml={1}>
            <RedAlert style={{ marginRight: 8 }} />
            <Typography variant="caption" color="error.main">
              {errors?.tags?.message}
            </Typography>
          </FlexBox>
        )}

        <Controller
          control={control}
          name="answerType"
          render={({ field: { onChange, value } }) => (
            <FlexBox className={classes.controlBox}>
              <FlexBox columnGap={3} onClick={() => onChange("textAnswer")}>
                <Radio
                  checked={value === "textAnswer"}
                  icon={<Circle width={16} height={16} />}
                  checkedIcon={<RadioButton width={16} height={16} />}
                  sx={{ "& > span": { padding: "0 !important" } }}
                />
                <Box>
                  <Typography variant="body2" fontWeight={500}>
                    FAQs
                  </Typography>
                  <Typography variant="caption" color="grey.600">
                    Reply with a direct answer.{" "}
                  </Typography>
                </Box>
              </FlexBox>
              <FlexBox columnGap={3} onClick={() => onChange("smartPlus")}>
                <Radio
                  checked={value === "smartPlus"}
                  icon={<Circle width={16} height={16} />}
                  checkedIcon={<RadioButton width={16} height={16} />}
                  sx={{ "& > span": { padding: "0 !important" } }}
                />
                <Box>
                  <Typography variant="body2" fontWeight={500}>
                    Smart +
                  </Typography>
                  <Typography variant="caption" color="grey.600">
                    Drive the conversation with LLMs.
                  </Typography>
                </Box>
              </FlexBox>
              <FlexBox
                columnGap={3}
                onClick={() => onChange("triggerWorkflow")}
              >
                <Radio
                  checked={value === "triggerWorkflow"}
                  icon={<Circle width={16} height={16} />}
                  checkedIcon={<RadioButton width={16} height={16} />}
                />
                <Box>
                  <Typography variant="body2" fontWeight={500}>
                    Workflow
                  </Typography>
                  <Typography variant="caption" color="grey.600">
                    Reply with an AI-enabled workflow.
                  </Typography>
                </Box>
              </FlexBox>
            </FlexBox>
          )}
        />

        {watch("answerType") !== "triggerWorkflow" && (
          <>
            <Typography variant="caption" className={classes.label}>
              Language
            </Typography>

            <Select
              className={classes.select}
              value={currentLanguage}
              onChange={(e) => handleLanguageChange(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    borderRadius: 0,
                    boxShadow: "none",
                    backgroundColor: theme.palette.grey[50],
                    maxHeight: "40vh",
                  },
                },
              }}
              renderValue={(selected) => (
                <Typography variant="caption" color={theme.palette.grey[800]}>
                  {defaultLanguages.find((lang) => lang.value === selected)
                    ?.name || selected}
                </Typography>
              )}
            >
              {languages.map((language) => (
                <MenuItem
                  key={language}
                  value={language}
                  classes={{
                    selected: classes.selected,
                    root: isLocalized(language)
                      ? classes.menuItem
                      : `${classes.menuItem} ${classes.yellow}`,
                  }}
                >
                  {defaultLanguages.find((lang) => lang.value === language)
                    ?.name || language}
                </MenuItem>
              ))}
            </Select>
          </>
        )}

        {currentLanguage !== defaultLanguage && (
          <>
            <Button
              variant="text"
              endIcon={
                showDefault ? (
                  <Eye width={20} height={20} />
                ) : (
                  <EyeClose width={20} height={20} />
                )
              }
              color="inherit"
              sx={{
                minWidth: 0,
                width: "fit-content",
                mt: 4,
              }}
              onClick={() => setShowDefault(!showDefault)}
            >
              View Default
            </Button>

            <Grow in={showDefault} mountOnEnter unmountOnExit>
              <Box
                fullWidth
                sx={{
                  backgroundColor: theme.palette.grey[50],
                  borderRadius: 3,
                  border: `1px solid ${theme.palette.grey[100]}`,
                  padding: `16px 20px`,
                  mt: 4,
                }}
              >
                <Typography variant="body2">
                  {getValues("languageVariations")[defaultLanguage]?.answer}
                </Typography>
              </Box>
            </Grow>
          </>
        )}

        {(watch("answerType") === "textAnswer" ||
          watch("answerType") === "smartPlus") && (
          <>
            {/* <Typography variant="caption" className={classes.label}>
              {watch("answerType") === "textAnswer" ? `Answer *` : `Prompt *`}
            </Typography> */}
            <Controller
              name="answer"
              control={control}
              rules={{
                required: "Answer is required",
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  rows={3}
                  value={value}
                  onChange={onChange}
                  sx={{ mt: 4, "&>div": { borderRadius: "12px", padding: 0 } }}
                  fullWidth
                  placeholder="e.g. Your answer"
                  helperText={
                    errors?.answer && (
                      <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                        color={"error.main"}
                      >
                        <RedAlert style={{ marginRight: 8 }} />
                        {errors?.answer?.message}
                      </Box>
                    )
                  }
                />
              )}
            />
          </>
        )}

        {watch("answerType") === "triggerWorkflow" && (
          <>
            <Typography variant="caption" className={classes.label}>
              Trigger Workflow *
            </Typography>

            <Controller
              control={control}
              name="workflow"
              rules={{
                required: "WorkFlow is required",
              }}
              render={({ field: { onChange, value } }) => (
                <AutoComplete
                  value={value}
                  renderOptionKey="name"
                  autoComplete
                  error={errors?.workflow}
                  optionList={workflows}
                  onChange={onChange}
                  closeIcon={false}
                  ListboxProps={{ style: { maxHeight: "12rem" } }}
                  className={classes.formField}
                  PaperComponent={({ children }) => (
                    <Grow in={true} mountOnEnter unmountOnExit>
                      <div className={classes.autoCompleteList}>{children}</div>
                    </Grow>
                  )}
                />
              )}
            />
          </>
        )}

        <Box className={classes.buttonContainer}>
          <Button
            variant="outline"
            className={classes.button}
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            className={classes.button}
            endIcon={<ArrowForwardIos style={{ width: 16, height: 16 }} />}
            onClick={handleSubmit(handleSave)}
            disabled={!isDirty || loading}
          >
            {question ? "Save" : "Add"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

EditFaqModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  categoryKey: PropTypes.string,
  questionIdKey: PropTypes.string,
};

EditFaqModal.defaultProps = {
  categoryKey: "categoryDetails",
  questionIdKey: "id",
};

export default EditFaqModal;
