import { getRequest } from "./common";

const answerGenerationProgressCount = (dataSetId, config = {}) => {
  return getRequest(
    `/quesAndAns/answerGenerationProgressCount/${dataSetId}`,
    config
  );
};

export default answerGenerationProgressCount;
