import PropsTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { getJSONDecoded } from "utils/utils";

const ProtectedRoute = ({ children, redirect }) => {
  const token = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(token);

  if (
    token &&
    tokenInfo?.companies?.length > 0
    //  && tokenInfo.isAgent === false
  ) {
    return children;
  }

  return <Navigate to={redirect} />;
};

ProtectedRoute.propTypes = {
  children: PropsTypes.any,
  redirect: PropsTypes.string,
};

ProtectedRoute.defaultProps = {
  redirect: "/",
};

export default ProtectedRoute;
