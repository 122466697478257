import { Box, Fade } from "@mui/material";
import Chat from "./component/chat";
import { useUserContext } from "context/user";

const Playground = () => {
  const { playgroundCurrentChat } = useUserContext();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Fade
        in={true}
        timeout={300}
        exit={false}
        unmountOnExit
        mountOnEnter
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          flex: 1,
        }}
      >
        <Box
          sx={{
            "& > div": {
              height: "100%",
            },
          }}
        >
          <Chat
            singleChatHistoryDetails={
              playgroundCurrentChat?.current?.playgroundChat ?? null
            }
          />
        </Box>
      </Fade>
    </Box>
  );
};

export default Playground;
