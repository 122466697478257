import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Root = styled(Box)({
  height: "100vh",
  width: "100vw",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default Root;
