import {
  Box,
  Button,
  Checkbox,
  Fade,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FunCollapse from "components/FunCollapse";
import { useEffect, useState } from "react";
import { Download, Trash2 } from "react-feather";
import { useParams } from "react-router-dom";
import getDataSetQuestionsAnswers from "services/getDataSetQuestionsAnswers";
import {
  deleteAnswer as deleteAnswerAPI,
  downloadQuestionAnswerCsv,
} from "services";
import styles from "./index.module.css";
import EmptyState from "components/EmptyState";
import FlexBox from "components/FlexBox";
import FundamentoPopup from "components/FundamentoPopup";
import { ChevronDown, IconCirclePlus } from "components/SVG";
import {
  CaretDoubleUp,
  PencilSimple,
  TreeStructure,
  CheckCircle,
  Circle,
} from "components/newSVG";
import {
  getDataSetCategory,
  updateQuesAns,
  trainBot as trainBotApi,
  createQuesAns as createQuesAnsApi,
  getAllWorkFlow as getAllWorkFlowApi,
} from "services";
import { useSkillrToast } from "context/toast";
import DeleteModal from "components/DeleteModal";
import { getJSONDecoded } from "utils/utils";
import { useAdminSocket } from "context/socket";
import { useURLState } from "custom-hooks/useUrlState";
import SearchBox from "components/SearchBox";
import _ from "lodash";
import fileDownload from "js-file-download";
import EditFaqModal from "../components/EditFaqModal";

const Answers = () => {
  const params = useParams();
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useURLState([], "category");
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [editAnswerIndex, setEditAnswerIndex] = useState(null);
  const { showSkillrToast } = useSkillrToast();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [trainBotInfo, setTrainBotInfo] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const token = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(token ?? null);
  const socket = useAdminSocket(tokenInfo?.companies?.[0]?.id);
  const trainStatus = [{ type: "Trained" }, { type: "Not Trained" }];
  const [selectedTrainStatus, setSelectedTrainStatus] = useURLState(
    "",
    "trainedStatus"
  );
  const [search, setSearch] = useURLState("", "search");
  const [trainProgress, setTrainProgress] = useState();
  const [manualQA, setManualQA] = useState(false);
  const [editFaqModal, setEditFaqModal] = useState(false);
  const [workflows, setWorkFlows] = useState([]);

  const columns = [
    {
      field: "check",
      header: (
        <Checkbox
          style={{ padding: 0 }}
          onChange={handleHeaderCheckbox}
          checked={
            (selectedFiles.length > 0 &&
              selectedFiles.length === tableData?.length) ||
            selectedAll
          }
          indeterminate={
            selectedFiles.length > 0 &&
            selectedFiles.length !== tableData?.length
          }
        />
      ),
      style: { textAlign: "center" },
    },
    { field: "answer", header: "Answer" },
    {
      field: "actions",
      header: "Actions",
    },
  ];

  const sortDataForTable = (data) => {
    const sortedData = data.map((item) => {
      return {
        ...item,
        id: item._id,
        question: item.question,
        answer: item.answer,
        category: item.category.name,
        status: item.trained,
        questionVariant: item.questionVariant,
        categoryDetails: item.category,
      };
    });
    setTableData(sortedData);
  };

  function handleHeaderCheckbox(event) {
    if (event.target.checked) {
      const rowIds = tableData?.map((d) => d.id);
      setSelectedFiles(rowIds);
      setSelectedAll(true);
    } else {
      setSelectedFiles([]);
      setSelectedAll(false);
    }
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const dataSetId = params.dataSetId;

      const queryParams = new URLSearchParams();

      if (selectedCategory.length) {
        queryParams.set("categoryIds", selectedCategory);
      } else {
        // Unset the parameter if the condition is false
        queryParams.delete("categoryIds");
      }

      selectedTrainStatus &&
        queryParams.set("trained", selectedTrainStatus === trainStatus[0].type);
      queryParams.set("pageNumber", currentPage);
      queryParams.set("hasAnswer", true);
      queryParams.set("searchByInQandA", search);
      const response = await getDataSetQuestionsAnswers(dataSetId, queryParams);
      setTotalPageCount(response?.pageCount);
      sortDataForTable(response?.data);
      setLoading(false);
      // Create a new URL with the modified search parameters
      // const newUrl = `${window.location.origin}${
      //   window.location.pathname
      // }?${queryParams.toString()}`;

      // Update the browser URL
      // window.history.replaceState({}, "", newUrl);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getDatasetCategories = async () => {
    try {
      const response = await getDataSetCategory(params.dataSetId);
      setCategories(response);
    } catch (error) {
      console.error(error);
    }
  };

  const trainBot = async (data) => {
    try {
      const response = await trainBotApi(data);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleTrainBot = async () => {
    setLoading(true);
    setTrainBotInfo();
    let apiData = {};
    if (selectedAll) {
      apiData.categoryIds =
        selectedCategory.length > 0
          ? [...selectedCategory]
          : categories.map((c) => c._id);
      apiData.dataSetId = params.dataSetId;
      apiData.search = search ? search : null;
      if (selectedTrainStatus) {
        apiData.trained = selectedTrainStatus === trainStatus[0].type;
      }
    } else {
      apiData.questionIds = [...selectedFiles];
    }
    const response = await trainBot(apiData);
    setTrainBotInfo(response);
    setSelectedAll(false);
    setSelectedFiles([]);
    fetchData();
  };

  const handleSelectedCategory = (e) => {
    const categoryValue = e.target.value;
    const newCategory = [...selectedCategory];
    const selectedCategoryIndex = selectedCategory.indexOf(categoryValue);
    if (selectedCategoryIndex > -1) {
      newCategory.splice(selectedCategoryIndex, 1);
    } else {
      newCategory.push(categoryValue);
    }
    setSelectedCategory(newCategory);
    setCurrentPage(1);
  };

  const updateAnswer = async (data) => {
    try {
      let apiData = {
        _id: data.id,
        question: data.question?.question,
        primaryQuestionReplacedWithVariantId:
          data?.question === tableData?.[editAnswerIndex]?.question
            ? null
            : data.question?.id,
        questionVariant: data.questionVariant,
        category: data.category,
      };

      if (data?.answerType === "textAnswer") {
        apiData.answer = data.answer;
      } else {
        apiData.workFlow = data.workflow;
      }

      const response = await updateQuesAns(params.dataSetId, apiData);

      if (response) {
        // setEditAnswerIndex(null);
        // setNewAnswer("");
        showSkillrToast("Answer updated successfully", "success");
      }
      // setTableData((prevData) => {
      //   const newData = prevData.map((item) => {
      //     if (item.id === data.id) {
      //       return {
      //         ...item,
      //         answer: data.answer,
      //         question: response?.question,
      //         questionVariant: response?.questionVariant,
      //       };
      //     }
      //     return item;
      //   });
      //   return newData;
      // });
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const deleteAnswer = async (id) => {
    try {
      const response = await deleteAnswerAPI(id);
      if (response) {
        setDeleteModal(false);
        showSkillrToast("Answer deleted successfully", "success");

        setTableData((prevData) => {
          const newData = prevData.filter((item) => item.id !== id);
          return newData;
        });
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const optimizedSearch = _.debounce(handleOnSearch, 500);

  const handleFilterByTrainStatus = (event) => {
    if (selectedTrainStatus === event.target.value) {
      setSelectedTrainStatus("");
    } else {
      setSelectedTrainStatus(event.target.value);
    }
    setCurrentPage(1);
  };

  const handleErrorMessage = (error) => {
    const errorMessage = error?.message
      ? error.messsage
      : "Something went wrong";
    showSkillrToast(errorMessage, "error");
  };

  const updateAnswerBySocket = (data) => {
    try {
      if (data.length > 0) {
        setTableData((prevData) => {
          const newData = prevData.map((oldData) => {
            const updatedAnswer = data.find((d) => d._id === oldData.id);
            return updatedAnswer
              ? { ...oldData, status: updatedAnswer?.trained }
              : oldData;
          });
          return newData;
        });
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const updateTrainProgress = _.throttle((data) => {
    try {
      setTrainProgress(data);
    } catch (error) {
      handleErrorMessage(error);
    }
  }, 100);

  const handleSocketUpdate = () => {
    socket.on("media-questionAnswer-trained", (data) => {
      updateAnswerBySocket(data);
    });

    socket.on("media-questionAnswer-trained-progressBar", (data) => {
      updateTrainProgress(data);
    });
  };

  const downloadCSV = async () => {
    try {
      const queryParams = new URLSearchParams();
      // if (answerStatus) {
      // queryParams.set("answered", answerStatus === "answered" ? true : false);
      // }
      queryParams.set("answered", true);
      queryParams.set("category", selectedCategory.join(","));
      const response = await downloadQuestionAnswerCsv(
        params.dataSetId,
        queryParams,
        {
          responseType: "blob",
        }
      );

      fileDownload(response, "CSV_file.csv");
    } catch (error) {
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleManualQuesAns = (index) => {
    // setManualQA(true);
    setEditFaqModal(true);
    if (index !== null) {
      setEditAnswerIndex(index);
    }
  };

  const addQAManually = async (newQA) => {
    try {
      setLoading(true);
      let apiData = {
        question: newQA?.question?.question,
        category: newQA?.category,
        dataSet: params.dataSetId,
        questionVariant: newQA?.questionVariant,
      };
      if (newQA?.answerType === "textAnswer") {
        apiData.answer = newQA.answer;
      } else {
        apiData.workFlow = newQA.workflow;
      }
      const response = await createQuesAnsApi(apiData);
      setLoading(false);
      showSkillrToast("Question added successfully", "success");
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getAllWorkFlow = async () => {
    try {
      const response = await getAllWorkFlowApi();
      setWorkFlows(response);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleCloseQAManually = () => {
    setManualQA(false);
    setEditAnswerIndex(null);
  };

  const handleSaveQAManually = async (data) => {
    if (editAnswerIndex !== null) {
      await updateAnswer(data);
    } else {
      await addQAManually(data);
    }
    handleCloseQAManually();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [selectedCategory, currentPage, selectedTrainStatus, search]);

  useEffect(() => {
    getDatasetCategories();
    handleSocketUpdate();
    getAllWorkFlow();
  }, []);

  return (
    <>
      <FlexBox mb={5}>
        {/* category filter */}
        <FundamentoPopup
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          triggeringComponent={
            <Button
              variant="outlined"
              className={selectedCategory?.length && styles.active}
              sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
              endIcon={<ChevronDown width={16} height={16} />}
            >
              Category
            </Button>
          }
        >
          <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            {categories?.map((item) => (
              <FlexBox columnGap={2} component="label">
                <Checkbox
                  value={item?._id}
                  onChange={(e) => handleSelectedCategory(e)}
                  checked={selectedCategory.includes(item?._id)}
                />
                <Typography variant="body2">{item.name}</Typography>
              </FlexBox>
            ))}
          </Box>
        </FundamentoPopup>

        <FundamentoPopup
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          triggeringComponent={
            <Button
              variant="outlined"
              className={selectedTrainStatus && styles.active}
              sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
              endIcon={<ChevronDown width={16} height={16} />}
            >
              Train Status
            </Button>
          }
        >
          <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            {trainStatus?.map((item) => (
              <FlexBox columnGap={2} component="label">
                <Checkbox
                  icon={<Circle />}
                  checkedIcon={<CheckCircle />}
                  value={item.type}
                  onChange={(e) => handleFilterByTrainStatus(e)}
                  checked={selectedTrainStatus === item.type ? true : false}
                />
                <Typography variant="body2">{item.type}</Typography>
              </FlexBox>
            ))}
          </Box>
        </FundamentoPopup>

        <Box className={styles.searchContainer}>
          <SearchBox
            // disabled={true}
            placeholder={"Search"}
            onChange={optimizedSearch}
            defaultValue={search}
            onClear={handleClearSearch}
          />
        </Box>

        {/* actions dropdown */}
        <Box style={{ marginLeft: "auto" }}>
          <FundamentoPopup
            triggeringComponent={
              <Button
                variant="outlined"
                sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
                endIcon={<ChevronDown width={16} height={16} />}
              >
                Actions
              </Button>
            }
            className={styles.popup}
          >
            <Box>
              <Box className={styles.popupItem} onClick={() => downloadCSV()}>
                <Download width={16} height={16} />
                <Typography variant="body2">Export FAQs as CSV</Typography>
              </Box>

              <Box
                className={styles.popupItem}
                onClick={() => handleManualQuesAns(null)}
              >
                <IconCirclePlus width={16} height={16} />
                <Typography variant="body2">Add FAQ</Typography>
              </Box>
            </Box>
          </FundamentoPopup>
        </Box>
        <Button
          variant="dark"
          disabled={selectedFiles?.length === 0 || loading}
          style={{
            padding: "6px 14px",
            lineHeight: "24px",
            marginLeft: "auto",
            fontSize: 12,
            minWidth: 0,
          }}
          onClick={handleTrainBot}
          startIcon={<TreeStructure width={16} height={16} />}
        >
          Train Assistant
        </Button>
      </FlexBox>

      {trainBotInfo &&
        trainProgress?.currentTrainedQuestionCount !==
          trainProgress?.totalQuestionsToTrainCount && (
          <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
            <Box className={styles.uploadAlert}>
              <Box className={styles.iconContainer}>
                <CaretDoubleUp width={16} height={16} />
              </Box>

              <Typography variant="body1" fontWeight={500}>
                {trainBotInfo?.numOfQuestionsToTrain > 0
                  ? `Training ${
                      trainBotInfo?.numOfQuestionsToTrain ||
                      trainProgress?.totalQuestionsToTrainCount
                    } questions and answers`
                  : "Questions are already trained"}
              </Typography>

              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ marginLeft: "auto" }}
              >
                {Math.floor(
                  (trainProgress?.currentTrainedQuestionCount /
                    trainProgress?.totalQuestionsToTrainCount) *
                    100
                )}
                % complete
              </Typography>
            </Box>
          </Fade>
        )}

      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={() => deleteAnswer(deleteId)}
        title={"Delete Answer?"}
        subtitle={
          "This action can't be undone and the data will be deleted from your account permanently"
        }
        list={[
          "This will delete the answer permanently",
          "You will have to regenerate the answer",
        ]}
      />

      {tableData?.length > 0 && (
        <Box
          style={{
            position: "relative",
            flex: 1,
            overflowY: "auto",
          }}
        >
          <Table className={styles.table}>
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1000,
              }}
            >
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    style={
                      column.header === "Actions" ? { textAlign: "right" } : {}
                    }
                    key={index}
                  >
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      color="#262626"
                    >
                      {column.header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    sx={{ "& span": { padding: 0 }, width: 0 }}
                  >
                    <Checkbox
                      checked={selectedFiles.includes(row.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedFiles([...selectedFiles, row.id]);
                        } else {
                          setSelectedFiles(
                            selectedFiles.filter((item) => item !== row.id)
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ width: "80%" }}>
                    {/* {row.answer && row.answer.length > 0 ? ( */}
                    <FunCollapse
                      iconPosition={"start"}
                      // collapsed={editAnswerIndex === index}
                      header={
                        <FlexBox columnGap={2}>
                          <Typography variant="body2" fontWeight={500}>
                            {row.question}
                          </Typography>

                          <Box className={styles.categoryLabel}>
                            {row.category}
                          </Box>

                          <Box className={styles.categoryLabel}>
                            {row.status ? "Trained" : "Not Trained"}
                          </Box>
                        </FlexBox>
                      }
                    >
                      {/* {editAnswerIndex === index ? (
                        <TextField
                          multiline
                          fullWidth
                          autoFocus
                          variant="standard"
                          defaultValue={row.answer}
                          onChange={(e) => setNewAnswer(e.target.value)}
                          sx={{
                            paddingLeft: "22px",
                            mt: 2,
                          }}
                          inputProps={{
                            style: {
                              fontSize: 14,
                              lineHeight: "1.25rem",
                            },
                          }}
                          onFocus={(e) => {
                            var val = e.target.value;
                            e.target.value = "";
                            e.target.value = val;
                          }}
                        />
                      ) : ( */}
                      <Typography
                        variant="body2"
                        color="#262626"
                        mt={2}
                        fontWeight={400}
                        style={{
                          paddingLeft: 22,
                          whiteSpace: "pre-line",
                        }}
                      >
                        {row.answer}
                      </Typography>
                      {/* )} */}
                    </FunCollapse>
                    {/* ) : (
                        <FlexBox columnGap={2}>
                          <Typography
                            variant="body2"
                            color="#262626"
                            fontWeight={400}
                          >
                            {row.question}
                          </Typography>

                          <Box className={styles.categoryLabel}>
                            {row.category}
                          </Box>
                        </FlexBox>
                      )} */}
                  </TableCell>

                  <TableCell align="right">
                    {/* {editAnswerIndex === index ? (
                      <FlexBox justifyContent="flex-end">
                        <Button
                          className={styles.button}
                          variant="contained"
                          color="inherit"
                          onClick={() => {
                            setEditAnswerIndex(null);
                            setNewAnswer("");
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className={styles.button}
                          variant="dark"
                          endIcon={<ChevronRight width={16} height={16} />}
                          onClick={() => {
                            updateAnswer(row.id);
                          }}
                        >
                          Save
                        </Button>
                      </FlexBox>
                    ) : ( */}
                    <>
                      <FlexBox columnGap={0} width="max-content" ml="auto">
                        <IconButton onClick={() => handleManualQuesAns(index)}>
                          <PencilSimple
                            color="#0f0f0f"
                            width={16}
                            height={16}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(row.id);
                            setDeleteModal(true);
                          }}
                        >
                          <Trash2 width={16} height={16} color="#eb5757" />
                        </IconButton>
                      </FlexBox>
                    </>
                    {/* )} */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* pagination */}

          <Box className={styles.pagination}>
            <Pagination
              page={currentPage}
              count={totalPageCount}
              shape="rounded"
              onChange={(e, pageCount) => {
                setCurrentPage(pageCount);
              }}
            />
          </Box>
        </Box>
      )}

      <EditFaqModal
        open={editFaqModal}
        onClose={() => {
          setEditFaqModal(false);
          setEditAnswerIndex(null);
        }}
        question={tableData?.[editAnswerIndex]}
        onSave={handleSaveQAManually}
        workflows={workflows?.workFlows}
        loading={loading}
        categories={categories}
      />

      {!loading && tableData?.length === 0 && (
        <Box height={"50%"}>
          <EmptyState title={"No answers found"} />
        </Box>
      )}
    </>
  );
};

export default Answers;
