import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { X } from "react-feather";
import { ArrowForwardIos } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

const AddExampleModal = (props) => {
  const { open, onClose, handleSave, data } = props;
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      examples: [],
    },
  });

  const resetForm = () => {
    reset({
      examples: [],
    });
  };

  useEffect(() => {
    if (open && data?.examples?.length) {
      reset({
        examples: data?.examples,
      });
    }
    if (!open) {
      resetForm();
    }
  }, [open, data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 500,
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" position="relative">
        <IconButton onClick={onClose} className={styles.closeButton}>
          <X />
        </IconButton>
      </Box>
      <Box className={styles.container}>
        <Typography variant="h3" className={styles.heading}>
          Add Examples
        </Typography>
        {/* <Controller
          name="examples"
          control={control}
          rules={{ required: "Examples are required" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              autoFocus
              className={styles.examples}
              placeholder="Add examples"
              onChange={onChange}
              value={value}
              helperText={
                errors?.examples && (
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors?.examples?.message}
                  </Box>
                )
              }
            />
          )}
        /> */}

        <Controller
          control={control}
          name="examples"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              disableClearable={true}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    deleteIcon={
                      <X color="#575757" style={{ height: 16, width: 16 }} />
                    }
                    // className={styles.deleteChip}
                    sx={{
                      background: "#e2ecf5",
                      borderRadius: 2,
                    }}
                  />
                ))
              }
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              value={value}
              options={[]}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    sx={{
                      "& > div": {
                        padding: 4,
                        borderRadius: "20px",
                      },
                    }}
                    variant="outlined"
                    placeholder="press enter to add"
                  />
                );
              }}
              sx={{
                marginTop: 10,
                "& span": {
                  color: "#555",
                  fontSize: 10,
                  fontWeight: 500,
                  lineHeight: "130%",
                },
              }}
              //   className={styles.examples}
            />
          )}
        />

        <Box className={styles.buttonContainer}>
          <Button variant="outline" className={styles.button} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dark"
            className={styles.button}
            endIcon={<ArrowForwardIos style={{ width: 16, height: 16 }} />}
            onClick={handleSubmit(handleSave)}
            disabled={!isDirty}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

AddExampleModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
  data: PropTypes.object,
};

export default AddExampleModal;
