import { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { PlusCircle } from "react-feather";
import theme from "theme";
import styles from "./index.module.css";
import {
  getDataSetCategory as getDataSetCategoryApi,
  createDataSetCategory as createDataSetCategoryApi,
} from "services";
import { useSkillrToast } from "context/toast";

const CategoryModal = (props) => {
  const { show, onClose, onSave, dataSetId } = props;
  const { showSkillrToast } = useSkillrToast();
  const [categoryList, setCategoryList] = useState([]);
  const [newCategoryStatus, setNewCategoryStatus] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCancel = () => {
    onClose();
    setSelectedCategory("");
    setNewCategoryStatus(false);
  };

  const handleSaveAndUpload = () => {
    if (selectedCategory) {
      onSave(selectedCategory);
      setSelectedCategory("");
      setNewCategoryStatus(false);
    }
  };

  const getDataSetCategory = async () => {
    try {
      const response = await getDataSetCategoryApi(dataSetId);
      setCategoryList(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const createNewCategory = async () => {
    try {
      const apiData = {
        name: newCategoryName,
        dataSet: dataSetId,
      };
      const response = await createDataSetCategoryApi(apiData);
      if (response?._id) {
        setCategoryList([response, ...categoryList]);
        setNewCategoryName("");
        setNewCategoryStatus(false);
      } else {
        showSkillrToast(response?.message, "error");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  useEffect(() => {
    getDataSetCategory();
  }, []);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          width: 650,
          borderRadius: 20,
          maxHeight: "80vh",
          height: "100%",
        },
      }}
    >
      <Box className={styles.modalContainer}>
        <Typography textAlign="center" variant="h3" fontWeight={500} mb={8}>
          Assign Category
        </Typography>

        {newCategoryStatus ? (
          <TextField
            autoFocus
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            sx={{ "&>div": { borderRadius: 82 } }}
            fullWidth
            placeholder="e.g. Invoice issues"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={createNewCategory}
                    disabled={newCategoryName?.length > 0 ? false : true}
                    style={{ minWidth: 0 }}
                    variant="text"
                    startIcon={
                      <PlusCircle
                        width={16}
                        height={16}
                        fill={
                          newCategoryName?.length > 0
                            ? theme.palette.primary.main
                            : theme.palette.primary.light
                        }
                        stroke="white"
                      />
                    }
                  >
                    Create
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <Button
            variant="outlined"
            fullWidth
            startIcon={<PlusCircle width={16} height={16} />}
            sx={{ justifyContent: "flex-start", borderRadius: 82 }}
            onClick={() => setNewCategoryStatus(true)}
          >
            Create new category
          </Button>
        )}

        <Typography variant="subtitle2" mt={5}>
          Select from existing
        </Typography>
        <Box style={{ flex: 1, overflowY: "auto" }}>
          <RadioGroup
            name="category-selection"
            value={selectedCategory}
            onChange={(event) => {
              setSelectedCategory(event.target.value);
            }}
          >
            {categoryList.map((category) => (
              <FormControlLabel
                className={styles.categoryItem}
                key={category._id}
                value={category._id}
                control={<Radio />}
                label={category?.name}
              />
            ))}
          </RadioGroup>
        </Box>

        <Box className={styles.buttonContainer}>
          <Button
            className={styles.button}
            color="inherit"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            onClick={handleSaveAndUpload}
            disabled={!selectedCategory}
          >
            Save & upload
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CategoryModal;
