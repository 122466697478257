import { Box, Button, IconButton, TextField } from "@mui/material";
import {
  Controller,
  get,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import PropTypes from "prop-types";
import { RedAlert } from "components/SVG";
import { Plus, Trash2 } from "react-feather";
import theme from "theme";
import VariableDropdown from "../Sidebars/VariableDropdown";

const AddFields = (props) => {
  const { path, buttonText, keyPlaceholder, valuePlaceholder } = props;
  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: path,
  });

  const handleOnAddKey = () => {
    append({ key: "", value: "" });
  };

  return (
    <>
      {fields.map(({ id }, fieldIndex) => (
        <Box
          key={id}
          display="flex"
          gap={3}
          alignItems="center"
          sx={{
            "&:not(:first-of-type)": {
              mt: 2,
            },
          }}
        >
          <Controller
            control={control}
            name={`${path}.${fieldIndex}.key`}
            rules={{ required: "key is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                placeholder={keyPlaceholder ? keyPlaceholder : "key"}
                value={value}
                onChange={onChange}
                sx={{
                  "& div": {
                    borderRadius: 3,
                  },
                }}
                helperText={
                  get(errors, `${path}.${fieldIndex}.key`) && (
                    <Box component="span" display="flex" alignItems="center">
                      <RedAlert style={{ marginRight: 8 }} />
                      {get(errors, `${path}.${fieldIndex}.key`)?.message}
                    </Box>
                  )
                }
              />
            )}
          />

          <Controller
            control={control}
            name={`${path}.${fieldIndex}.value`}
            rules={{ required: "value is required" }}
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  placeholder={valuePlaceholder ? valuePlaceholder : "value"}
                  sx={{
                    "& div": {
                      borderRadius: 3,
                    },
                  }}
                  helperText={
                    get(errors, `${path}.${fieldIndex}.value`) && (
                      <Box component="span" display="flex" alignItems="center">
                        <RedAlert style={{ marginRight: 8 }} />
                        {get(errors, `${path}.${fieldIndex}.value`)?.message}
                      </Box>
                    )
                  }
                />
              </VariableDropdown>
            )}
          />

          <IconButton onClick={() => remove(fieldIndex)}>
            <Trash2 width={16} height={16} />
          </IconButton>
        </Box>
      ))}

      <Button
        variant="text"
        color="inherit"
        startIcon={
          <Plus
            fill={theme.palette.primary.main}
            stroke="#0F0F0F"
            width={16}
            height={16}
          />
        }
        onClick={handleOnAddKey}
        sx={{
          my: 2,
          justifyContent: "flex-start",
          minWidth: 0,
          width: "max-content",
        }}
      >
        {buttonText ? buttonText : "Add key"}
      </Button>
    </>
  );
};

AddFields.propTypes = {
  path: PropTypes.string,
};

export default AddFields;
