import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { X } from "react-feather";
import classes from "./index.module.css";
import { FlowArrow } from "components/newSVG";

const WorkFlowLinked = (props) => {
  const { open, onClose, title } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 500,
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" position="relative">
        <IconButton onClick={onClose} className={classes.closeButton}>
          <X />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        <FlowArrow width={40} height={40} />
        <Typography variant="h5" fontWeight={500} mt={4}>
          {title}
        </Typography>

        <Button
          variant="outlined"
          sx={{ marginTop: 8, marginLeft: "auto" }}
          onClick={onClose}
        >
          Ok
        </Button>
      </Box>
    </Dialog>
  );
};

WorkFlowLinked.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default WorkFlowLinked;
