/*
 * InputModal Component
 * this component can be used in places where we need to take single input
 * from user e.g. asking for a name for process/workflow etc.
 * props:
 * open: boolean
 * onClose: function
 * onConfirm: function
 * title: string
 * subtitle: string
 * placeholder: string
 * submitText: string
 */

import FlexBox from "components/FlexBox";
import { useState } from "react";
import { X } from "react-feather";

const {
  Dialog,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
} = require("@mui/material");

const InputModal = ({
  open,
  onClose,
  onConfirm,
  title,
  subtitle,
  submitText,
  placeholder,
}) => {
  const [value, setValue] = useState("");

  const handleClose = () => {
    setValue("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      PaperProps={{
        sx: {
          borderRadius: 5,
          padding: 10,
          maxWidth: 500,
          position: "relative",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 20, right: 20 }}
      >
        <X width={20} height={20} />
      </IconButton>

      <Box>
        <Typography textAlign={"center"} variant="h4" fontWeight={500}>
          {title}
        </Typography>
        {subtitle && (
          <Typography textAlign={"center"} variant="body1" fontWeight={500}>
            {subtitle}
          </Typography>
        )}
      </Box>

      <Box mt={8}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={placeholder ? placeholder : "Enter value"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onConfirm(value);
              handleClose();
            }
          }}
        />
      </Box>

      <FlexBox mt={8} justifyContent="center">
        <Button
          variant="outlined"
          sx={{ borderRadius: 3, height: 40, minWidth: 0 }}
          color="inherit"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          sx={{ borderRadius: 3, height: 40, minWidth: 0 }}
          onClick={() => {
            onConfirm(value);
            handleClose();
          }}
        >
          {submitText ? submitText : "Submit"}
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default InputModal;
