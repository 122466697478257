export const botConfig = {
  vertex: [
    {
      name: "Temperature",
      min: 0.1,
      max: 1,
      defaultValue: 0.1,
      stepValue: 0.1,
    },
    {
      name: "Output Tokens",
      min: 1,
      max: 1024,
      defaultValue: 1000,
      stepValue: 1,
    },
    {
      name: "Top P",
      min: 0,
      max: 1,
      defaultValue: 1,
      stepValue: 0.1,
    },
    {
      name: "Candidate Count",
      min: 1,
      max: 4,
      defaultValue: 1,
      stepValue: 1,
      disabled: true,
    },
  ],
  azure_gpt: [
    {
      name: "Temperature",
      min: 0.1,
      max: 1,
      defaultValue: 0.3,
      stepValue: 0.1,
    },
    {
      name: "Output Tokens",
      min: 1,
      max: 4690,
      defaultValue: 1000,
      stepValue: 1,
    },
    {
      name: "Top P",
      min: 1,
      max: 2560,
      defaultValue: 1000,
      stepValue: 1,
    },
    {
      name: "Candidate Count",
      min: 0.1,
      max: 1,
      defaultValue: 0.3,
      stepValue: 0.1,
    },
  ],
  gemini: [
    {
      name: "Temperature",
      min: 0.1,
      max: 1,
      defaultValue: 0.1,
      stepValue: 0.1,
    },
    {
      name: "Output Tokens",
      min: 1,
      max: 2048,
      defaultValue: 1000,
      stepValue: 1,
    },
    {
      name: "Top P",
      min: 0,
      max: 1,
      defaultValue: 1,
      stepValue: 0.1,
    },
    {
      name: "Candidate Count",
      min: 1,
      max: 4,
      defaultValue: 1,
      stepValue: 1,
      disabled: true,
    },
  ],
};
