import { Button, Grow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import theme from "theme";
import { ChevronRight, Plus } from "react-feather";
import AutoComplete from "components/AutoComplete";
import { Controller, useForm } from "react-hook-form";
import FlexBox from "components/FlexBox";
import InputModal from "components/InputModal";
import { useSkillrToast } from "context/toast";
import { createWorkFlow } from "services";

const TriggerWorkflowProperties = (props) => {
  const { data, onSave, workflows } = props;
  const [showModal, setShowModal] = useState(false);
  const { showSkillrToast } = useSkillrToast();
  const [optionsList, setOptionsList] = useState();

  const { control, reset, getValues, handleSubmit } = useForm({
    defaultValues: {
      workflow: {
        name: "",
        _id: "",
      },
    },
  });

  const handleSave = () => {
    const workflowName = getValues().workflow.name;
    const workflowId = getValues().workflow._id;

    if (!workflowName || !workflowId) {
      // set error
      showSkillrToast("Please select a workflow", "error");
      return;
    }

    onSave({
      workflowName: workflowName,
      workflowId: workflowId,
    });
  };

  // const optionsList = [
  //   { name: "Create Workflow", _id: "create_workflow" },
  //   ...(workflows?.workFlows || []),
  // ];

  const createNewWorkFlow = async (data) => {
    try {
      const response = await createWorkFlow(data);
      setOptionsList((prev) => [
        // set the new item at the second position after "Create Workflow"
        prev[0],
        { name: response.name, _id: response._id },
        ...prev.slice(1),
      ]);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };
  const handleCreateWorkflow = (name) => {
    setShowModal(false);
    createNewWorkFlow({ name }).then((response) => {
      if (response) {
        const newWorkflow = {
          name: response.name,
          _id: response._id,
        };
        reset({ workflow: newWorkflow });
      }
    });
  };

  useEffect(() => {
    if (data) {
      reset({
        workflow: {
          name: data?.workflowName || "",
          _id: data?.workflowId || "",
        },
      });
    }
  }, []);

  useEffect(() => {
    setOptionsList([
      { name: "Create Workflow", _id: "create_workflow" },
      ...(workflows?.workFlows || []),
    ]);
  }, [workflows]);

  return (
    <>
      <Typography variant="body2" fontWeight={500} mb={2}>
        Trigger Workflow
      </Typography>

      <Controller
        name="workflow"
        control={control}
        rules={{ required: "This field is required" }}
        render={({ field: { onChange, value } }) => (
          <AutoComplete
            value={value}
            defaultValue={value}
            renderOptionKey="name"
            autoComplete
            // optionList={workflows?.workFlows}
            optionList={optionsList}
            // onChange={onChange}
            onChange={(newValue) => {
              if (newValue && newValue._id === "create_workflow") {
                setShowModal(true);
              } else {
                onChange(newValue);
              }
            }}
            closeIcon={false}
            ListboxProps={{ style: { maxHeight: "12rem" } }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Typography variant="body2">
                  {option._id === "create_workflow" ? (
                    <FlexBox style={{ backgroundColor: `white !important` }}>
                      <Plus size={16} />
                      {option.name}
                    </FlexBox>
                  ) : (
                    option.name
                  )}
                </Typography>
              </li>
            )}
            PaperComponent={({ children }) => (
              <Grow in={true} mountOnEnter unmountOnExit>
                <div
                  style={{
                    backgroundColor: `#efefef !important`,
                    color: theme.palette.text.primary,
                  }}
                >
                  {children}
                </div>
              </Grow>
            )}
          />
        )}
      />

      <InputModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={(name) => {
          handleCreateWorkflow(name);
        }}
        title={"Create Workflow"}
        submitText={"Create"}
        placeholder="Enter workflow name"
      />

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          mt: "auto",
          width: "max-content",
        }}
        onClick={handleSubmit(handleSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </>
  );
};

export default TriggerWorkflowProperties;
