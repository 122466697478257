import { Box, Typography } from "@mui/material";
import styles from "./index.module.css";

const TabSwitcher = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <>
      <Box className={styles.tabs}>
        {tabs.map((tab, index) => {
          return (
            <Typography
              variant="caption"
              color={activeTab === index ? "text.primary" : "text.secondary"}
              fontWeight={500}
              key={index}
              className={`${styles.tab}  ${
                activeTab === index ? styles.active : ""
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default TabSwitcher;
