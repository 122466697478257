import { Box, TextField } from "@mui/material";
// import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import React from "react";
import { useController } from "react-hook-form";
import { RedAlert } from "components/SVG";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& + .styledTextField, & + .skillr-autoComplete": {
    marginTop: theme.spacing(6),
  },
}));

const SkillrTextField = (props) => {
  // const classes = useStyles();
  const {
    field: { ref, onChange, onBlur, ...inputProps },
  } = useController(props);

  return (
    <StyledTextField
      onClick={props.onClick}
      type={props.type}
      className={"styledTextField"}
      autoComplete="new-password"
      classes={{ root: "skillr-textField" }}
      inputRef={ref}
      inputProps={{
        autoComplete: "off",
      }}
      {...props}
      onChange={(e) => {
        props.onChange(e);
        onChange(e);
      }}
      onBlur={(e) => {
        props.onBlur(e);
        onBlur(e);
      }}
      {...inputProps}
      {...props.other}
      error={Object.keys(props.error).length !== 0}
      helperText={
        (Object.keys(props.error).length !== 0 && (
          <Box component="span" display="flex" alignItems="center">
            <RedAlert style={{ marginRight: 8 }} />
            {props.error && props.error.message}
          </Box>
        )) ||
        props.helperText
      }
      fullWidth={props.noFullWidth ? false : true}
    />
  );
};

SkillrTextField.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  control: PropTypes.any.isRequired,
  defaultValue: PropTypes.string,
  error: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  helperText: PropTypes.string,
  other: PropTypes.any,
  noFullWidth: PropTypes.bool,
};

SkillrTextField.defaultProps = {
  type: "text",
  variant: "outlined",
  defaultValue: "",
  error: {},
  onChange: () => {},
  onBlur: () => {},
};

export default SkillrTextField;
