import { Card } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import LoginForm from "../Login";
import SignupForm from "../Signup/index";
import { BrandLogo, CardContentdiv, Root } from "./index.style";
import brandLogo from "assets/images/skillr_logo.png";
import { FundamentoLogoFull } from "components/SVG";

function AuthCard({ children, showLogin, showSignup, candidateAuth }) {
  // const classes = useStyles();
  return (
    <Root>
      {candidateAuth && (
        <BrandLogo>
          <FundamentoLogoFull />
        </BrandLogo>
      )}
      <Card
      // className={classes.authCard}
      >
        <CardContentdiv>
          {showLogin && <LoginForm candidateAuth={candidateAuth} />}
          {showSignup && <SignupForm candidateAuth={candidateAuth} />}
          {children}
        </CardContentdiv>
      </Card>
    </Root>
  );
}

export default AuthCard;

AuthCard.propTypes = {
  children: PropTypes.element,
  showLogin: PropTypes.bool,
  showSignup: PropTypes.bool,
  candidateAuth: PropTypes.bool,
};
