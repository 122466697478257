import styles from "./index.module.css";
import FileColors from "utils/fileColors";

const FileLabel = ({ type, ...props }) => {
  return (
    <div
      {...props}
      className={`${styles.fileLabel}`}
      style={{
        backgroundColor: FileColors[type]?.background || "#FFF8E1",
        color: FileColors[type]?.color || "#E99A01",
      }}
    >
      {type === "empty" ? "abc" : type}
    </div>
  );
};

export default FileLabel;
