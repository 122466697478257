import { Box, Typography } from "@mui/material";
import styles from "./index.module.css";
import { Link, matchPath, useLocation } from "react-router-dom";

const Tabs = () => {
  const currentRoute = useLocation().pathname;

  const tabs = [
    {
      name: "Assistant",
      route: "/bot/playground",
      activeRoutePath: ["/bot/playground"],
    },
    // {
    //   name: "Logs",
    //   route: "/bot/logs",
    //   activeRoutePath: ["/bot/logs"],
    // },
    {
      name: "History",
      route: "/bot/history",
      activeRoutePath: ["/bot/history", "/bot/historyDetail/:historyId"],
    },
    // {
    //   name: "Settings",
    //   route: "/bot/settings",
    //   activeRoutePath: ["/bot/settings"],
    // },
  ];

  const compareRouteWithPath = (paths) => {
    return paths.some((path) => matchPath(path, currentRoute) !== null);
  };

  return (
    <>
      <Box className={styles.tabs}>
        {/* {tabs.map((tab) => (
          <Link to={tab.route} key={tab.name}>
            <Typography
              variant="caption"
              color={
                compareRouteWithPath(tab.activeRoutePath)
                  ? "text.primary"
                  : "text.secondary"
              }
              fontWeight={500}
              className={`${styles.tab}  ${
                compareRouteWithPath(tab.activeRoutePath) ? styles.active : ""
              }`}
            >
              {tab.name}
            </Typography>
          </Link>
        ))} */}
      </Box>

      <Box className={styles.tabsContainer}>
        {tabs.map((tab) => (
          <Link to={tab.route} key={tab.name}>
            <Box
              className={`${styles.tab} ${
                currentRoute === tab.route ? styles.active : ""
              }
              ${
                tab.name === "History" && currentRoute.includes("historyDetail")
                  ? styles.active
                  : ""
              }
              `}
            >
              <Typography variant="body2" fontWeight={500} color="grey.600">
                {tab.name}
              </Typography>
            </Box>
          </Link>
        ))}
      </Box>
    </>
  );
};

export default Tabs;
