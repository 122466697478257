import {
  Box,
  Button,
  Typography,
  IconButton,
  LinearProgress,
  Grow,
} from "@mui/material";
import { IconUpload2 } from "components/SVG";
import { useEffect, useRef, useState } from "react";
import { ChevronRight, Eye, Trash2 } from "react-feather";
import { useSkillrToast } from "context/toast";
import uploadAudioFileApi from "services/uploadAudioFile";
import { gscUriPrefix } from "text-constants/common";
import { getAudioDuration, getNewFileName } from "utils/utils";
import AudioPlayer from "components/AudioPlayer";
import theme from "theme";
import { EyeClose } from "components/newSVG";

const supportedFileTypes = ["audio/wav", "audio/mpeg"];
const MAX_AUDIO_SIZE = 2 * 1024 * 1024; // 2MB
const MAX_AUDIO_TIME = 20; // 20 sec

const AudioProperties = ({
  data,
  onSave,
  workFlowId,
  localizationMode,
  currentLanguage,
}) => {
  const fileInputRef = useRef();
  const { showSkillrToast } = useSkillrToast();
  const [uploadedDetails, setUploadedDetails] = useState();
  const [uploadProgress, setUploadProgress] = useState();
  const [showDefault, setShowDefault] = useState();
  const [localizedAudio, setLocalizedAudio] = useState();

  const handleOnSave = () => {
    if (uploadedDetails?.gcsUri || localizedAudio) {
      if (localizationMode && currentLanguage) {
        data.languageVariations = {
          ...(data?.languageVariations || {}),
          [currentLanguage]: {
            ...(data?.languageVariations?.[currentLanguage] || {}),
            gcsUri: localizedAudio,
          },
        };

        onSave(data);
      } else {
        onSave(uploadedDetails);
      }
    } else {
      showSkillrToast("Please upload file", "error");
    }
  };

  const handleUploadProgress = (progressEvent) => {
    if (progressEvent?.loaded > 0) {
      setUploadProgress(
        Math.floor((progressEvent?.loaded / progressEvent?.total) * 100)
      );
    }
  };

  const uploadFile = async (file, isLocalized = false) => {
    try {
      const formData = new FormData();
      formData.append("audioFile", file);
      const config = {
        onUploadProgress: handleUploadProgress,
      };
      const uploadResponse = await uploadAudioFileApi(
        workFlowId,
        formData,
        config
      );

      if (isLocalized) {
        setLocalizedAudio(uploadResponse.gcsUri);
      } else {
        setUploadedDetails(uploadResponse);
      }
      fileInputRef.current.value = "";
    } catch (error) {
      fileInputRef.current.value = "";
      setUploadProgress();
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleFileChange = async (event, isLocalized = false) => {
    try {
      const file = event.target.files[0];
      const renamedFile = getNewFileName(file);
      const audioDuration = await getAudioDuration(renamedFile);
      if (audioDuration < MAX_AUDIO_TIME) {
        uploadFile(renamedFile, isLocalized);
      } else {
        showSkillrToast("Audio duration should not exceed 20 seconds", "error");
      }
    } catch (error) {
      showSkillrToast(error?.message, "error");
    }
  };

  const renderAudioSelector = () => {
    return uploadedDetails?.gcsUri ? (
      <div style={{ marginBottom: 16 }}>
        {localizedAudio ? (
          <Box display="flex" gap={3} alignItems="center">
            <audio controls>
              <source
                src={localizedAudio.replace("gs://", gscUriPrefix)}
                type={"audio/" + localizedAudio.split(".").pop()}
              ></source>
            </audio>

            <IconButton onClick={() => handleOnRemoveAudio(true)}>
              <Trash2 width={16} height={16} />
            </IconButton>
          </Box>
        ) : (
          <Box
            display="flex"
            sx={{
              border: `1px dashed #0f0f0f`,
              background: "#fff",
              gap: 2,
              padding: 4,
              borderRadius: 3,
              cursor: "pointer",
            }}
            onClick={() => handleFileUpload(true)}
          >
            {uploadProgress ? (
              <LinearProgress
                value={uploadProgress}
                color="primary"
                variant="determinate"
                style={{ width: "100%" }}
                sx={{
                  height: 5,
                  borderRadius: 5,
                  bgcolor: "#E9E9E9",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 5,
                    bgcolor: "#0F0F0F",
                  },
                }}
              />
            ) : (
              <>
                <IconUpload2 width={16} height={16} />
                <Typography variant="caption" color="#0f0f0f">
                  Upload File
                </Typography>

                <Typography variant="caption" sx={{ fontSize: 10, ml: "auto" }}>
                  Max time: 20 Sec
                </Typography>
              </>
            )}
          </Box>
        )}

        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          accept={supportedFileTypes.join(",")}
        />
      </div>
    ) : (
      <Typography variant="body2" sx={{ fontStyle: "italic", mb: 4 }}>
        Please upload audio in default language first
      </Typography>
    );
  };

  const handleOnRemoveAudio = (isLocalized = false) => {
    if (isLocalized) {
      setLocalizedAudio();
    } else {
      setUploadedDetails(null);
      data.gcsUri = null;
    }
    setUploadProgress();
  };

  const handleFileUpload = (isLocalized = false) => {
    fileInputRef.current.click();
    fileInputRef.current.onchange = (event) =>
      handleFileChange(event, isLocalized);
  };

  useEffect(() => {
    if (data?.languageVariations?.[currentLanguage]?.gcsUri) {
      setLocalizedAudio(data?.languageVariations?.[currentLanguage]?.gcsUri);
    } else {
      setLocalizedAudio();
    }
  }, [data, currentLanguage]);

  useEffect(() => {
    setUploadedDetails(data);
  }, [data]);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {localizationMode ? (
        <>
          <Button
            variant="text"
            endIcon={
              showDefault ? (
                <Eye width={20} height={20} />
              ) : (
                <EyeClose width={20} height={20} />
              )
            }
            color="inherit"
            sx={{ minWidth: 0, width: "fit-content", mb: 4 }}
            onClick={() => setShowDefault(!showDefault)}
          >
            View Default
          </Button>

          <Grow in={showDefault} mountOnEnter unmountOnExit>
            <Box
              fullWidth
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: 3,
                border: `1px solid ${theme.palette.grey[100]}`,
                padding: `16px 16px 12px`,
                mb: 4,
              }}
            >
              <AudioPlayer audioPath={uploadedDetails?.gcsUri} controls />
            </Box>
          </Grow>

          {renderAudioSelector(true)}
        </>
      ) : (
        <>
          {uploadedDetails?.gcsUri && (
            <Box display="flex" gap={3} alignItems="center">
              <AudioPlayer audioPath={uploadedDetails?.gcsUri} controls />

              <IconButton onClick={() => handleOnRemoveAudio()}>
                <Trash2 width={16} height={16} />
              </IconButton>
            </Box>
          )}

          {!uploadedDetails?.gcsUri && (
            <Box
              display="flex"
              sx={{
                border: `1px dashed #0f0f0f`,
                background: "#fff",
                gap: 2,
                padding: 4,
                borderRadius: 3,
                cursor: "pointer",
              }}
              onClick={handleFileUpload}
            >
              {uploadProgress ? (
                <LinearProgress
                  value={uploadProgress}
                  color="primary"
                  variant="determinate"
                  style={{ width: "100%" }}
                  sx={{
                    height: 5,
                    borderRadius: 5,
                    bgcolor: "#E9E9E9",
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 5,
                      bgcolor: "#0F0F0F",
                    },
                  }}
                />
              ) : (
                <>
                  <IconUpload2 width={16} height={16} />
                  <Typography variant="caption" color="#0f0f0f">
                    Upload File
                  </Typography>

                  <Typography
                    variant="caption"
                    sx={{ fontSize: 10, ml: "auto" }}
                  >
                    Max time: 20 Sec
                  </Typography>
                </>
              )}
            </Box>
          )}

          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
            accept={supportedFileTypes.join(",")}
          />
        </>
      )}
      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          marginTop: "auto",
          width: "max-content",
          mt: "auto",
        }}
        onClick={handleOnSave}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </div>
  );
};

export default AudioProperties;
