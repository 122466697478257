import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import Sidebar from "components/Sidebar";
import {
  ArrowBack,
  ArrowUp,
  IconCheck,
  IconClose,
  IconEdit2,
  IconFiles,
  IconFilter2,
} from "components/SVG";
import { Launch, Sort } from "@mui/icons-material";
import theme from "theme";
import FunCollapse from "components/FunCollapse";
// import { ThumbsDown, ThumbsUp } from "react-feather";
import FundamentoPopup from "components/FundamentoPopup";
import FileLabel from "components/FileLabel";
import { useNavigate, useParams } from "react-router-dom";
import { useSkillrToast } from "context/toast";
import {
  getDataSetQuesAns as getDataSetQuesAnsApi,
  updateQuesAns as updateQuesAnsApi,
} from "services";
import { useEffect, useState } from "react";
import SearchBox from "components/SearchBox";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  Files,
  PencilSimple,
  ThumbsUpFilled,
  ThumbsDown,
  ThumbsUp,
  ThumbsDownFilled,
  ArrowSquareOut,
  ArrowsDownUp,
  Info,
} from "components/newSVG";
import EmptyState from "components/EmptyState";

const DatasetDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dataSetId = params?.datasetId;
  const { showSkillrToast } = useSkillrToast();
  const [dataSetDetails, setDataSetDetails] = useState();
  const [dataSetQuesAns, setDataSetQuesAns] = useState();
  const [searchValue, setSearchValue] = useState();
  const [answer, setAnswer] = useState();
  const [sortOrder, setSortOrder] = useState("DESC");
  const [relevance, setRelevance] = useState();
  const defaultParameters = {
    relevance: "",
    page: 1,
    sortOrder: "DESC",
    searchQuery: "",
  };
  const { search } = useLocation();
  const gcsUrlPrefix = "https://storage.cloud.google.com";

  const handleOnBack = () => {
    navigate(-1);
  };

  const highlightWord = (longString, search) => {
    // const wordIndex = longString.indexOf(search);
    // if (wordIndex !== -1) {
    // const startIndex = Math.max(0, wordIndex - 50);
    // const endIndex = Math.min(
    //   longString.length,
    //   wordIndex + search.length + 50
    // );
    // const resultString = longString.slice(startIndex, endIndex);

    const regex = new RegExp(search, "i");
    if (search) {
      return longString.replace(
        regex,
        `<b style="background-color: #E4F3F8;color: #0094FF;padding: 0 4px;">$&</b>`
      );
    } else {
      return longString;
    }
    // }
  };

  const getDataSetDetails = async (parameters = { ...defaultParameters }) => {
    try {
      const params = new URLSearchParams();
      for (let key in parameters) {
        params.set(key, parameters[key]);
      }
      const response = await getDataSetQuesAnsApi(dataSetId, params.toString());
      const formattedResponse = response?.data.map((quesAns) => {
        const url = new URL(quesAns?.gcsUri);
        const fileExtension = url.pathname.split(".").pop();
        const link = gcsUrlPrefix + url.pathname.replace("//", "/");
        return {
          ...quesAns,
          fileExtension,
          link,
        };
      });
      setDataSetDetails(response);
      setDataSetQuesAns(formattedResponse);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const handleSort = (sortOrder) => {
    setSortOrder(sortOrder);
    getDataSetDetails({ ...defaultParameters, sortOrder: sortOrder });
  };

  const handleRelevance = (newRelevance) => {
    setRelevance(newRelevance);
    getDataSetDetails({ ...defaultParameters, relevance: newRelevance });
  };

  const handleOnSearch = (value) => {
    setSearchValue(value);
    getDataSetDetails({ ...defaultParameters, searchQuery: value });
  };

  const optimizedSearch = _.debounce(handleOnSearch, 500);

  const handleOnSearchClear = () => {
    setSearchValue("");
    getDataSetDetails({ ...defaultParameters, searchQuery: "" });
  };

  const getRelevance = (faq) => {
    if (faq?.questionsAndAnswers?.relevance === null) {
      return (
        <ThumbsUp
          width={18}
          height={18}
          // className={styles.ratingTrigger}
          color={theme.palette.grey[600]}
        />
      );
    } else if (faq?.questionsAndAnswers?.relevance) {
      return (
        <ThumbsUpFilled
          width={18}
          height={18}
          // className={styles.ratingTrigger}
        />
      );
    } else {
      return (
        <ThumbsDownFilled
          width={18}
          height={18}
          // className={styles.ratingTrigger}
        />
      );
    }
  };

  const updateQuesDetails = async (faq, apiData) => {
    try {
      const response = await updateQuesAnsApi(
        faq?.questionsAndAnswers?._id,
        apiData
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateRelevance = async (relevance, faqIndex) => {
    try {
      const faq = dataSetQuesAns[faqIndex];
      await updateQuesDetails(faq, { relevance });
      let newDataSetQuesAns = [...dataSetQuesAns];
      newDataSetQuesAns[faqIndex].questionsAndAnswers.relevance = relevance;
      setDataSetQuesAns(newDataSetQuesAns);
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleQuickActions = (event) => {
    // handle edit
    const editAnswerParent = event.target.closest('[id*="edit-answer"]');
    if (editAnswerParent) {
      const [, index] = editAnswerParent.id.split("_");
      const newDataSetQuesAns = dataSetQuesAns.map((faq, faqIndex) => {
        if (Number(index) === faqIndex) {
          return {
            ...faq,
            editAnswer: true,
            collapsed: true,
          };
        } else {
          return {
            ...faq,
            editAnswer: false,
          };
        }
      });

      setDataSetQuesAns(newDataSetQuesAns);
      // return;
    }

    // handle relevance (like or dislike)
    const relevanceParent = event.target.closest('[id*="relevance"]');
    if (relevanceParent) {
      const [, relevance, index] = relevanceParent.id.split("_");
      const relevanceInBoolean = relevance === "like" ? true : false;
      handleUpdateRelevance(relevanceInBoolean, index);
    }

    event.stopPropagation();
  };

  const handleUpdateAnswer = async (type) => {
    try {
      const editedFaqIndex = dataSetQuesAns.findIndex((faq) => faq.editAnswer);
      const newAnswer =
        type === "update"
          ? answer
          : dataSetQuesAns[editedFaqIndex].questionsAndAnswers.answer;
      await updateQuesDetails(dataSetQuesAns[editedFaqIndex], {
        answer: newAnswer,
      });
      let newDataSetQuesAns = [...dataSetQuesAns];
      newDataSetQuesAns[editedFaqIndex].editAnswer = false;
      newDataSetQuesAns[editedFaqIndex].questionsAndAnswers.answer = newAnswer;
      setDataSetQuesAns(newDataSetQuesAns);
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const onLoad = () => {
    const queryParams = new URLSearchParams(`${search}`);
    const searchParams = queryParams.get("search");
    if (searchParams) {
      return;
    }
    getDataSetDetails();
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    console.log(dataSetQuesAns?.length);
  }, [dataSetQuesAns]);

  return (
    <Box className={styles.container}>
      <Sidebar />
      <Box className={styles.main}>
        <Button
          startIcon={<ArrowBack />}
          variant="text"
          color="inherit"
          className={styles.back}
          onClick={handleOnBack}
        >
          Back
        </Button>
        <Box className={styles.content}>
          {/* <Breadcrumbs
            separator={<ChevronRight />}
            className={styles.breadcrumb}
          >
            <Typography variant="h3" fontWeight={500}>
              Training Data
            </Typography> */}
          <Typography variant="h3" fontWeight={500} align="center">
            {dataSetDetails?.dataSetName}
          </Typography>
          {/* </Breadcrumbs> */}

          {/* <Typography
            mt={2}
            mb={8}
            variant="body2"
            color="grey.600"
            align="center"
          >
            The list below shows a auto generated questions that can be use to
            train organizations.
          </Typography> */}

          <Box className={styles.searchContainer}>
            <SearchBox
              placeholder={"Search"}
              onChange={optimizedSearch}
              onClear={handleOnSearchClear}
              defaultValue={""}
            />

            <FundamentoPopup
              triggeringComponent={
                <Button
                  variant="text"
                  color="inherit"
                  startIcon={<ArrowsDownUp />}
                >
                  Sort
                </Button>
              }
            >
              <Box>
                <Typography variant="body2" fontWeight={500} mb={4}>
                  Sort by
                </Typography>

                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    sortOrder === "DESC"
                      ? handleSort("ASC")
                      : handleSort("DESC");
                  }}
                >
                  {/* <Checkbox
                    value={"asc"}
                    checked={sortOrder === "ASC"}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={() => handleSort("ASC")}
                  /> */}
                  <ArrowUp
                    style={
                      ({ transition: "all 0.3s ease" },
                      sortOrder === "DESC"
                        ? { transform: "rotate(180deg)" }
                        : {})
                    }
                    className={styles.sortIcon}
                  />
                  <Typography variant="body2">Created at</Typography>
                </label>
                {/* <label
                  style={{ display: "flex", alignItems: "center", gap: 16 }}
                >
                  <Checkbox
                    value={"asc"}
                    checked={sortOrder === "ASC"}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={() => handleSort("ASC")}
                  />
                  <Typography variant="body2">Ascending</Typography>
                </label>
                <label
                  style={{ display: "flex", alignItems: "center", gap: 16 }}
                >
                  <Checkbox
                    value={"desc"}
                    checked={sortOrder === "DESC"}
                    onClick={() => handleSort("DESC")}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                  <Typography variant="body2">Descending</Typography>
                </label> */}
              </Box>
            </FundamentoPopup>

            <FundamentoPopup
              triggeringComponent={
                <Button
                  variant="text"
                  color="inherit"
                  startIcon={<IconFilter2 />}
                >
                  Filter
                </Button>
              }
            >
              <Box>
                <Typography variant="body2" fontWeight={500}>
                  Filter by
                </Typography>

                <label
                  style={{ display: "flex", alignItems: "center", gap: 16 }}
                >
                  <Checkbox
                    checked={relevance === true}
                    onClick={() => {
                      relevance === true
                        ? handleRelevance("")
                        : handleRelevance(true);
                    }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                  <Typography variant="body2">Liked</Typography>
                </label>
                <label
                  style={{ display: "flex", alignItems: "center", gap: 16 }}
                >
                  <Checkbox
                    checked={relevance === false}
                    onClick={() => {
                      relevance === false
                        ? handleRelevance("")
                        : handleRelevance(false);
                    }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                  <Typography variant="body2">Disliked</Typography>
                </label>
              </Box>
            </FundamentoPopup>
          </Box>

          {/* <Typography
            variant="subtitle2"
            fontFamily={theme.typography.fontFamily.inter}
          >
            Auto Generated FAQs
          </Typography> */}

          <Box sx={{ flexGrow: 1, overflowY: "auto", paddingRight: 4 }}>
            {dataSetQuesAns?.map((faq, faqIndex) => (
              <Box className={styles.collapsibleContainer} key={faqIndex}>
                <FunCollapse
                  collapsed={faq?.collapsed}
                  header={
                    <Box className={styles.collapseHeader}>
                      <Typography
                        variant="subtitle2"
                        fontFamily={"Inter"}
                        dangerouslySetInnerHTML={{
                          __html: highlightWord(
                            faq?.questionsAndAnswers?.question,
                            searchValue
                          ),
                        }}
                      />
                      <Box
                        className={styles.actionsContainer}
                        onClick={handleQuickActions}
                      >
                        <IconButton id={`edit-answer_${faqIndex}_${faq._id}`}>
                          <PencilSimple
                            width={18}
                            height={18}
                            color={theme.palette.grey[600]}
                          />
                        </IconButton>
                        <FundamentoPopup
                          triggeringComponent={
                            <IconButton>
                              <Info width={18} height={18} />
                            </IconButton>
                          }
                        >
                          <Box className={styles.popupContainer}>
                            <Typography variant="body2" fontWeight={500}>
                              Files associated
                            </Typography>
                            <Box>
                              <FileLabel type={faq?.fileExtension} />
                              <Typography
                                className={styles.fileName}
                                variant="body2"
                                fontSize={12}
                              >
                                {faq?.name}
                              </Typography>
                              <IconButton
                                style={{
                                  marginLeft: "auto",
                                }}
                                onClick={() =>
                                  window.open(`${faq?.link}?authuser=1`)
                                }
                              >
                                <ArrowSquareOut
                                  width={20}
                                  height={20}
                                  style={{
                                    flexShrink: 0,
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </FundamentoPopup>
                        <IconButton className={styles.ratingTrigger}>
                          {getRelevance(faq)}
                        </IconButton>
                        <Box className={styles.ratingContainer}>
                          {faq?.questionsAndAnswers?.relevance === true ? (
                            <ThumbsUpFilled
                              width={18}
                              height={18}
                              id={`relevance_like_${faqIndex}_${faq._id}`}
                            />
                          ) : (
                            <ThumbsUp
                              width={18}
                              height={18}
                              id={`relevance_like_${faqIndex}_${faq._id}`}
                            />
                          )}
                          {/* <ThumbsUp
                            width={18}
                            height={18}
                            color={theme.palette.grey[600]}
                            // fill={
                            //   faq?.questionsAndAnswers?.relevance === true
                            //     ? "#0F0F0FB2"
                            //     : "none"
                            // }
                            id={`relevance_like_${faqIndex}_${faq._id}`}
                          /> */}
                          {faq?.questionsAndAnswers?.relevance === false ? (
                            <ThumbsDownFilled
                              width={18}
                              height={18}
                              id={`relevance_dislike_${faqIndex}_${faq._id}`}
                            />
                          ) : (
                            <ThumbsDown
                              width={18}
                              height={18}
                              id={`relevance_dislike_${faqIndex}_${faq._id}`}
                            />
                          )}

                          {/* <ThumbsDown
                            width={18}
                            height={18}
                            color={theme.palette.grey[600]}
                            fill={
                              faq?.questionsAndAnswers?.relevance === false
                                ? "#0F0F0FB2"
                                : "none"
                            }
                            id={`relevance_dislike_${faqIndex}_${faq._id}`}
                          /> */}
                          <Typography
                            variant="body2"
                            color="grey.600"
                            fontSize={8}
                          >
                            Rate
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  }
                  iconPosition={"start"}
                >
                  <Box mt={2}>
                    {faq.editAnswer ? (
                      <TextField
                        fullWidth
                        className={styles.answerInput}
                        multiline
                        variant="standard"
                        minRows={2}
                        defaultValue={faq?.questionsAndAnswers?.answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        onFocus={(e) => setAnswer(e.target.value)}
                        autoFocus
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleUpdateAnswer("update")}
                              >
                                <IconCheck
                                  width={18}
                                  height={18}
                                  color={theme.palette.grey[600]}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => handleUpdateAnswer("cancel")}
                              >
                                <IconClose
                                  width={18}
                                  height={18}
                                  color={theme.palette.grey[600]}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                          classes: {
                            adornedEnd: styles.answerAdornment,
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        fontFamily={"Inter"}
                        dangerouslySetInnerHTML={{
                          __html: highlightWord(
                            faq?.questionsAndAnswers?.answer,
                            searchValue
                          ),
                        }}
                      />
                    )}
                  </Box>
                </FunCollapse>
              </Box>
            ))}
            {searchValue && dataSetQuesAns?.length === 0 && (
              <EmptyState title={"No results found"} />
            )}
            {relevance !== "" && dataSetQuesAns?.length === 0 && (
              <EmptyState title={"No results found"} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DatasetDetail;
