import { Box, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDivider = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(20),
  color: theme.palette.borderColor.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "&:not(:first-child)": {
    paddingTop: theme.spacing(6),
  },

  "&:not(:last-child)": {
    paddingBottom: theme.spacing(6),
  },

  "&:after, &:before": {
    content: '""',
    display: "block",
    width: 195,
    height: 1,
    backgroundColor: theme.palette.borderColor.main,
  },
}));

const AuthFormGroup = styled(Box)(({ theme }) => ({
  "&:not(:last-child)": {
    paddingBottom: theme.spacing(6),
  },
}));

const ForgotBox = styled(Box)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  marginTop: theme.spacing(4),
  display: "block",
}));

export {
  StyledDivider,
  // BrandLogo,
  AuthFormGroup,
  ForgotBox,
};
