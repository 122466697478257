import PropTypes from "prop-types";
import { getJSONDecoded } from "utils/utils";

const RoleBasedAccess = (props) => {
  const { children } = props;
  const token = localStorage.getItem("token");
  const { role } = getJSONDecoded(token);
  const roleIdsToBlock = [
    "6581337dd89708205d107a4e", // PreProd
    "658563f330b5c726f09d8a09", // Yara
    "65856435a838ca82d2343136", // Busy
  ]; // Manager roleId

  if (roleIdsToBlock.indexOf(role?.id) > -1) {
    return null;
  } else {
    return <>{children}</>;
  }
};

RoleBasedAccess.propTypes = {
  children: PropTypes.element,
};

export default RoleBasedAccess;
