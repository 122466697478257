import {
  Box,
  Button,
  Divider,
  Drawer,
  Fade,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { ChevronRight } from "components/SVG";
import { PencilSimple, TrashSimple } from "components/newSVG";
import { useEffect, useState } from "react";
import theme from "theme";
import Personality from "../components/Personality";
import BasicSettings from "../components/BasicSettings";
import AdvancedSettings from "../components/AdvancedSettings";
import { X } from "react-feather";
import { editBot, getAllWorkFlow as getAllWorkFlowApi } from "services";
import YaraImage from "assets/images/yara-bot.png";
import { useSkillrToast } from "context/toast";
// import Configure from "../components/Configure";
import ConversationManagement from "../components/ConversationManagement";

const ConfigureBot = (props) => {
  const { open, onClose, botData, onSave } = props;
  const tabs = [
    "Conversation Management",
    "Personality",
    "Basic Settings",
    "Advanced Settings",
    // "Configure",
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [botDetails, setBotDetails] = useState({});
  const [workFlows, setWorkFlows] = useState([]);
  const { showSkillrToast } = useSkillrToast();
  const [editName, setEditName] = useState(false);
  const [editNameValue, setEditNameValue] = useState("");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUpdate = (updatedData) => {
    setBotDetails({ ...botDetails, ...updatedData });
  };

  const handleSave = async () => {
    let finalData = {
      name: editNameValue,
      ...botDetails,
    };

    if (
      finalData?.basicSettingsError &&
      Object.keys(finalData.basicSettingsError).length > 0
    ) {
      showSkillrToast(`Please fix basic settings errors`, "error");
      return;
    }
    // Removing basicSettingsError as its not required
    delete finalData.basicSettingsError;

    // if (!/\$\$$/.test(finalData?.instruction)) {
    //   showSkillrToast("$$ is required in prompt", "error");
    //   return;
    // }

    if (
      finalData?.fillersConfig.intermittentLatencyDuration < 5 ||
      finalData?.fillersConfig.intermittentLatencyDuration > 60
    ) {
      showSkillrToast(
        "Latency duration should be between 5 to 60 seconds",
        "error"
      );
      return;
    }
    onClose();

    try {
      const response = await editBot(botData._id, finalData);
      if (response) {
        showSkillrToast("Bot details updated successfully", "success");
        // setBotDetails(response);
        onSave(finalData);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
    setSelectedTab(tabs[0]);
  };

  const getAllWorkFlow = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlowApi(params.toString());
      setWorkFlows(response);
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleClose = () => {
    setBotDetails(botData);
    onClose();
  };

  useEffect(() => {
    getAllWorkFlow();
  }, []);

  useEffect(() => {
    if (botData?.name) {
      setEditNameValue(botData?.name);
      setBotDetails(botData);
    }
  }, [botData]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "45vw",
        },
      }}
    >
      <Box className={styles.container}>
        <Box className={styles.closeButton}>
          <IconButton onClick={handleClose}>
            <X width={20} height={20} />
          </IconButton>
        </Box>
        <Box className={styles.header}>
          <Box className={styles.imageBox}>
            <img src={YaraImage} alt="Yara bot" />
          </Box>
          {/* <TextField variant="standard" /> */}
          {editName ? (
            <TextField
              autoFocus
              variant="standard"
              value={editNameValue}
              onChange={(e) => setEditNameValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setEditName(false);
                  handleUpdate({ name: editNameValue });
                }
                if (e.key === "Escape") {
                  setEditName(false);
                  setEditNameValue(botData.name);
                }
              }}
              onBlur={() => {
                setEditName(false);
                setEditNameValue(botData.name);
              }}
              inputProps={{
                style: {
                  fontSize: 16,
                  fontWeight: 500,
                },
                maxLength: 10,
              }}
            />
          ) : (
            <Typography variant="body1" fontWeight={500}>
              {editNameValue}
            </Typography>
          )}
          {!editName && !botData?.default && (
            <IconButton onClick={() => setEditName(true)}>
              <PencilSimple width={16} height={16} color="#0f0f0f" />
            </IconButton>
          )}
          {editName && (
            <IconButton
              onClick={() => {
                setEditName(false);
                setEditNameValue(botData.name);
              }}
            >
              <X width={16} height={16} />
            </IconButton>
          )}
          <Button
            variant="dark"
            endIcon={<ChevronRight width={12} height={12} />}
            className={styles.saveButton}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
        <Box mt={4} px={8}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="tabs"
            className={styles.tabs}
            TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
            sx={{
              "& span.MuiTabs-indicator": {
                borderRadius: "10px 10px 0 0",
                height: 3,
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab className={styles.tab} label={tab} value={tab} key={index} />
            ))}
          </Tabs>
          <Divider
            sx={{
              backgroundColor: theme.palette.borderColor.light,
              mx: "-32px",
            }}
          />
        </Box>
        <Box className={styles.contentContainer}>
          <Fade unmountOnExit mountOnEnter in={selectedTab === tabs[0]}>
            <div>
              {selectedTab === tabs[0] && botDetails?.name && (
                <ConversationManagement
                  data={botDetails?.fillersConfig}
                  onSave={handleUpdate}
                />
              )}
            </div>
          </Fade>

          <Fade unmountOnExit mountOnEnter in={selectedTab === tabs[1]}>
            <div>
              {selectedTab === tabs[1] && botDetails?.name && (
                <Personality
                  data={botDetails}
                  onSave={handleUpdate}
                  defaultLanguage={botData?.botVoice?.languageCode}
                  defaultVoice={botData?.botVoice?.name}
                />
              )}
            </div>
          </Fade>

          <Fade unmountOnExit mountOnEnter in={selectedTab === tabs[2]}>
            <div>
              {selectedTab === tabs[2] && (
                <BasicSettings
                  data={botDetails}
                  onSave={handleUpdate}
                  workFlows={workFlows?.workFlows}
                  apiData={botData}
                />
              )}
            </div>
          </Fade>

          <Fade unmountOnExit mountOnEnter in={selectedTab === tabs[3]}>
            <div>
              {selectedTab === tabs[3] && (
                <AdvancedSettings
                  workFlows={workFlows?.workFlows}
                  data={botDetails}
                  onSave={handleUpdate}
                />
              )}
            </div>
          </Fade>

          {/* <Fade unmountOnExit mountOnEnter in={selectedTab === tabs[3]}>
            <div>
              {selectedTab === tabs[3] && (
                <Configure data={botDetails} onSave={handleUpdate} />
              )}
            </div>
          </Fade> */}
        </Box>
      </Box>
    </Drawer>
  );
};

ConfigureBot.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  botData: PropTypes.object,
};

export default ConfigureBot;
