import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { Outlet } from "react-router-dom";

const Integrations = () => {
  return (
    <PageWrapper className={styles.container}>
      <Outlet />
    </PageWrapper>
  );
};

export default Integrations;
