import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const AuthCardHeader = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "&:not(:last-child)": {
    paddingBottom: theme.spacing(6),
  },

  "& .MuiTypography-gutterBottom": {
    marginBottom: theme.spacing(2),
  },
}));

const Divider = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(20),
  color: theme.palette.borderColor.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "&:not(:first-child)": {
    paddingTop: theme.spacing(6),
  },

  "&:not(:last-child)": {
    paddingBottom: theme.spacing(6),
  },

  "&:after, &:before": {
    content: '""',
    display: "block",
    width: 195,
    height: 1,
    backgroundColor: theme.palette.borderColor.main,
  },
}));

const AuthFormGroup = styled(Box)(({ theme }) => ({
  "&:not(:last-child)": {
    paddingBottom: theme.spacing(6),
  },
}));

export { AuthCardHeader, Divider, AuthFormGroup };
