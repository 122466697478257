import React from "react";
import { Box, Container, Grid } from "@mui/material";
// import AuthLeftPanel from "./AuthLeftPanel";
import PropTypes from "prop-types";
import { Root } from "./index.style";
// import useStyles from "./index.style";

const Auth = ({ leftPanelVisible, centered, children }) => {
  // const classes = useStyles();
  return (
    <Root>
      <Container maxWidth="lg">
        {!centered ? (
          <Grid container>
            <Grid item md={7}>
              {/* {leftPanelVisible && <AuthLeftPanel />} */}
            </Grid>
            <Grid item md={5}>
              {children}
            </Grid>
          </Grid>
        ) : (
          centered && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {children}
            </Box>
          )
        )}
      </Container>
    </Root>
  );
};

Auth.propTypes = {
  leftPanelVisible: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.element,
};

export default Auth;
