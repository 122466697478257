import {
  Box,
  Button,
  Grow,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { RedAlert } from "components/SVG";
import { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import VariableDropdown from "../VariableDropdown";
import FlexBox from "components/FlexBox";
import theme from "theme";
import { languages as defaultLanguages } from "text-constants/common";
import { Eye, EyeClose } from "components/newSVG";

const MessageProperties = ({
  data,
  onSave,
  localizationMode,
  currentLanguage,
}) => {
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: "",
      localizedMessage: "",
    },
  });

  const [showDefault, setShowDefault] = useState(false);

  const handleOnSave = (formData) => {
    const updatedData = { ...formData };
    if (localizationMode && currentLanguage) {
      updatedData.languageVariations = {
        ...(data?.languageVariations || {}),
        [currentLanguage]: {
          ...(data?.languageVariations?.[currentLanguage] || {}),
          message: formData.localizedMessage,
        },
      };
    }
    onSave(updatedData);
  };

  useEffect(() => {
    if (data) {
      reset({
        ...data,
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        localizedMessage:
          data.languageVariations?.[currentLanguage]?.message || "",
      });
    }
  }, [data, currentLanguage]);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {localizationMode ? (
        <>
          <Button
            variant="text"
            endIcon={
              showDefault ? (
                <Eye width={20} height={20} />
              ) : (
                <EyeClose width={20} height={20} />
              )
            }
            color="inherit"
            sx={{ minWidth: 0, width: "fit-content", mb: 4 }}
            onClick={() => setShowDefault(!showDefault)}
          >
            View Default
          </Button>

          <Grow in={showDefault} mountOnEnter unmountOnExit>
            <Box
              fullWidth
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: 3,
                border: `1px solid ${theme.palette.grey[100]}`,
                padding: `16px 20px`,
                mb: 4,
              }}
            >
              <Typography variant="body2">{data?.message}</Typography>
            </Box>
          </Grow>

          <Controller
            control={control}
            name="localizedMessage"
            rules={{
              required: "Localized message is required",
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                multiline
                rows={5}
                fullWidth
                placeholder={`Add message for ${
                  defaultLanguages.find((l) => l.value === currentLanguage)
                    ?.name
                }`}
                value={value}
                onChange={onChange}
                sx={{
                  "& div": {
                    borderRadius: "12px",
                    padding: 0,
                  },
                  "& textarea": {
                    marginBottom: "32px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        alignSelf: "flex-end",
                        mb: 4,
                        mr: 2,
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      {`${value?.length || 0} / 500`}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 500 }}
              />
            )}
          />
          {errors?.localizedMessage && (
            <FlexBox
              mt={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {errors?.localizedMessage?.message}
            </FlexBox>
          )}
        </>
      ) : (
        <>
          <Controller
            control={control}
            name="message"
            rules={{
              required: "Message is required",
            }}
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder={"Add Message"}
                  sx={{
                    "& div": {
                      // overflow: "hidden",
                      borderRadius: "12px",
                      padding: 0,
                    },
                    "& textarea": {
                      marginBottom: "32px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignSelf: "flex-end",
                          mb: 4,
                          mr: 2,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {`${watch("message")?.length} / 500`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 500 }}
                />
              </VariableDropdown>
            )}
          />
          {errors?.message && (
            <FlexBox
              mt={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {errors?.message?.message}
            </FlexBox>
          )}
        </>
      )}

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          marginTop: "auto",
          width: "max-content",
          mt: "auto",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </div>
  );
};

export default MessageProperties;
