import PageWrapper from "components/PageWrapper";
import Tabs from "pages/Bot/components/Tabs";
import styles from "./index.module.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import FundamentoTable from "components/FundamentoTable";
import { ChatTeardrop, PencilSimple, Trash } from "components/newSVG";
import { ChevronRight, IconTrash } from "components/SVG";
import { ChevronDown, X, XCircle } from "react-feather";
import FundamentoPopup from "components/FundamentoPopup";
import theme from "theme";
import { useState } from "react";

const Logs = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [answerModal, setAnswerModal] = useState(false);

  const columns = [
    { field: "queries", header: "Queries", style: { width: "40%" } },
    {
      field: "bot",
      header: "Bot",
      render: (botAnswer) => (
        <Tooltip arrow title={botAnswer} placement="bottom">
          <ChatTeardrop style={{ cursor: "pointer" }} width={20} height={20} />
        </Tooltip>
      ),
    },
    {
      field: "faq",
      header: "FAQs Referenced",
      render: (faqs) => (
        <Box style={{ display: "flex", alignItems: "center", columnGap: 12 }}>
          {faqs.map((faq) => (
            <Tooltip title={`Relevancy: ${faq.relevancy}%`} placement="bottom">
              <Box onClick={() => setAnswerModal(true)} className={styles.faq}>
                {faq.text}
              </Box>
            </Tooltip>
          ))}

          <FundamentoPopup
            triggeringComponent={
              <>
                <Box className={styles.faq}>
                  +3 more <ChevronDown width={10} height={10} color="grey" />
                </Box>
              </>
            }
            style={{ width: 300 }}
          >
            <Box>
              <Typography variant="body2" fontWeight={500} marginBottom={2}>
                Referenced from
              </Typography>

              {faqs.map((faq) => (
                <Box display="flex" alignItems="center" py={3}>
                  <Box>
                    <Typography variant="body2">{faq.text}</Typography>
                    <Typography fontSize={10} fontWeight={500} color="grey.600">
                      Relevancy: {faq.relevancy}%
                    </Typography>
                  </Box>
                  <ChevronRight
                    style={{ flexShrink: 0, marginLeft: "auto" }}
                    width={12}
                    height={12}
                    color="grey"
                  />
                </Box>
              ))}
            </Box>
          </FundamentoPopup>
        </Box>
      ),
    },
    { field: "time", header: "Time" },
    {
      field: "delete",
      header: "",
      render: () => {
        return (
          <IconButton onClick={() => setDeleteModal(true)}>
            <IconTrash color="#0f0f0f" width={16} height={16} />
          </IconButton>
        );
      },
    },
  ];

  const tableData = [
    {
      queries: "Can you please share your PNR number?",
      bot: "No, Adobe PDF Reader is not integrated with Busy accounting software. It is important to ensure that the digital signature is properly configured and enabled in Busy accounting software to ensure it appears in printouts.",
      faq: [
        { text: "Text here for FAQ text here", relevancy: 78 },
        { text: "lorem ipsum dolor sit amet", relevancy: 82 },
      ],
      time: "0.9s",
    },
  ];

  const DeleteModal = ({ open, onClose }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            width: 500,
            borderRadius: 20,
          },
        }}
      >
        <Box sx={{ textAlign: "center", position: "relative", padding: 10 }}>
          <IconButton className={styles.close} onClick={onClose}>
            <X width={20} height={20} />
          </IconButton>
          <Box className={styles.iconContainer}>
            <Trash width={40} height={40} />
          </Box>

          <Typography variant="h3" fontWeight={500} mt={4}>
            Delete Forever?
          </Typography>

          <Typography variant="body2" my={2} color="grey.600">
            All your collection data gets processed and auto-categorized here
            for quick reference
          </Typography>

          <Box className={styles.modalList}>
            <XCircle color={theme.palette.error.main} width={20} height={20} />

            <Typography variant="body2" align="left">
              Any progress made will not be saved
            </Typography>
          </Box>

          <Box className={styles.modalList}>
            <XCircle color={theme.palette.error.main} width={20} height={20} />

            <Typography variant="body2" align="left">
              All the FAQs will cannot be recovered once deleted
            </Typography>
          </Box>

          <Box
            mt={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "22px",
            }}
          >
            <Button
              variant="outlined"
              onClick={onClose}
              className={styles.modalButton}
            >
              Cancel
            </Button>
            <Button
              className={styles.modalButton}
              startIcon={<Trash color="white" width={16} height={16} />}
              variant="contained"
              color={"error"}
              onClick={() => {
                // deleteDataSet(selectedDataSet?._id);
                // setDeleteModal(false);
              }}
            >
              Confirm & Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    );
  };

  const AnswerModal = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5" fontWeight={500} gutterBottom>
            Tell Customer: Can you please share your PNR number?
          </Typography>
          <Typography variant="body2" color="grey.400" fontWeight={500} mb={4}>
            Relevancy: 78.2%
          </Typography>
          <Typography
            variant="body1"
            style={{ overflowY: "auto", paddingRight: 16 }}
          >
            I hope this email finds you well. I wanted to personally follow up
            regarding your recent interaction with our customer support team
            regarding your flight ticket cancellation request. We understand
            that you were not completely satisfied with the solution provided,
            and I sincerely apologize for any inconvenience this may have caused
            you. I understand that you were looking to cancel your flight
            tickets, but due to certain policy restrictions, we were unable to
            fulfill your request. We genuinely regret any frustration this may
            have caused you. Our policies are designed to ensure fair and
            consistent service to all our customers, but we understand that in
            some cases, they may not align with individual preferences. I would
            like to assure you that we are actively reviewing our policies and
            procedures to see if there are any potential changes or improvements
            that can be made to better serve our customers in situations like
            yours. Thank you for choosing us, and we look forward to welcoming
            you on board in the future. Warm regards, Nitya Mehra Customer
            Support Rep I hope this email finds you well. I wanted to personally
            follow up regarding your recent interaction with our customer
            support team regarding your flight ticket cancellation request. We
            understand that you were not completely satisfied with the solution
            provided, and I sincerely apologize for any inconvenience this may
            have caused you. I understand that you were looking to cancel your
            flight tickets, but due to certain policy restrictions, we were
            unable to fulfill your request. We genuinely regret any frustration
            this may have caused you. Our policies are designed to ensure fair
            and consistent service to all our customers, but we understand that
            in some cases, they may not align with individual preferences. I
            would like to assure you that we are actively reviewing our policies
            and procedures to see if there are any potential changes or
            improvements that can be made to better serve our customers in
            situations like yours. Thank you for choosing us, and we look
            forward to welcoming you on board in the future. Warm regards, Nitya
            Mehra Customer Support Rep
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={4}
            columnGap={4}
          >
            <Button
              variant="outlined"
              className={`${styles.roundedButton} ${styles.modalButton}`}
              startIcon={<PencilSimple />}
            >
              Edit Answer
            </Button>
            <Button
              className={styles.modalButton}
              variant="dark"
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <PageWrapper
      className={styles.pageWrapper}
      content={
        <>
          <Box mb={6}>
            <Tabs />
          </Box>

          <AnswerModal
            open={answerModal}
            onClose={() => setAnswerModal(false)}
          />

          <DeleteModal
            open={deleteModal}
            onClose={() => {
              setDeleteModal(false);
            }}
          />
          <Box sx={{ "& th:nth-of-type(3)": { textAlign: "center" } }}>
            <FundamentoTable columns={columns} data={tableData} />
          </Box>
        </>
      }
    />
  );
};

export default Logs;
