import { Box, CardContent, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

const Root = styled(Box)({
  maxWidth: 513,
  marginLeft: "auto",
  marginRight: "auto",
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(10),

  "&:last-child": {
    paddingBottom: theme.spacing(10),
  },
}));

const ReturnButtonSection = styled(Box)({
  textAlign: "center",
  borderBottom: `1px solid ${(theme) => theme.palette.borderColor.main}`,
});

const ReturnButton = styled(Link)(({ theme }) => ({
  display: "inline-flex",
  padding: theme.spacing(3),
  alignItems: "center",
  textAlign: "center",
  color: theme.palette.primary.main,
}));

const AuthCardHeader = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "&:not(:last-child)": {
    paddingBottom: theme.spacing(6),
  },

  "& .MuiTypography-gutterBottom": {
    marginBottom: theme.spacing(2),
  },
}));

const AuthFormGroup = styled(Box)(({ theme }) => ({
  "&:not(:last-child)": {
    paddingBottom: theme.spacing(6),
  },
}));

export {
  Root,
  // BrandLogo,
  StyledCardContent,
  ReturnButtonSection,
  ReturnButton,
  AuthCardHeader,
  AuthFormGroup,
};
