import { Box, Typography } from "@mui/material";
import { Handle } from "reactflow";
import { Minus, Plus } from "react-feather";
import FlexBox from "components/FlexBox";
import theme from "theme";
import CustomHandle from "../../CustomHandle";
import NodeBase from "../../NodeBase";
import { gscUriPrefix } from "text-constants/common";

const ConversationNode = (props) => {
  const data = props.data;

  // const [showPlus, setShowPlus] = useState();
  // const [isParentHovered, setIsParentHovered] = useState(false);

  const handleClick = () => {
    if (!data.isLastNode) {
      data.onMinusClick();
    } else {
      data.onPlusClick({ x: props.xPos, y: props.yPos });
    }
  };

  // useEffect(() => {
  //   setShowPlus(data.isLastNode);
  // }, [data.isLastNode]);

  return (
    <NodeBase
      isActive={data.isActive}
      onDelete={data.onDelete}
      localizationMode={data.localizationMode}
      fullyLocalized={data.fullyLocalized}
      error={data.error}
    >
      <Typography variant="body2" fontWeight={500}>
        {data.name
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())
          .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography variant="body2" fontSize={10} color="text.secondary">
        {data.type ? (data?.message ? data.message : "configure") : "configure"}

        {data.type === "audio" && data.gcsUri && (
          <Box mt={4}>
            <audio controls style={{ width: "100%" }} key={data.gcsUri}>
              <source
                src={data.gcsUri.replace("gs://", gscUriPrefix)}
                type={"audio/" + data.gcsUri.split(".").pop()}
              ></source>
            </audio>
          </Box>
        )}
      </Typography>

      {data.defaultType === "addNode" && (
        <FlexBox
          sx={{
            p: "8px 16px",
            backgroundColor: theme.palette.grey[100],
            borderRadius: "8px",
            mt: 4,
            minHeight: 51,
            position: "relative",
          }}
        >
          <Typography variant="caption">Default</Typography>

          <CustomHandle
            id={"default"}
            position={"right"}
            style={{ right: 8 }}
            clickHandler={() => {
              data.selectedOption
                ? data.onOptionMinusClick("default")
                : data.onOptionPlusClick("default", {
                    x: props.xPos,
                    y: props.yPos,
                  });
            }}
            icon={
              data.selectedOption ? (
                <Minus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              ) : (
                <Plus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              )
            }
          />
        </FlexBox>
      )}

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />

      <CustomHandle
        id="source"
        position={"bottom"}
        clickHandler={handleClick}
        icon={
          data.isLastNode ? (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: "0%",
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      />
    </NodeBase>
  );
};

export default ConversationNode;
