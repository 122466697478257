import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Grow,
  Tab,
  Tabs,
  Typography,
  Checkbox,
  Slider,
} from "@mui/material";
import styles from "./index.style";
import FlexBox from "components/FlexBox";
import theme from "theme";

const FilterModal = ({
  open,
  onClose,
  filters,
  parentCategories,
  childCategories,
  statusFilterList,
  maxDurationOfCalls,
  applyFilters,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tempFilters, setTempFilters] = useState(filters);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFilterChange = (filterName, value) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleApply = () => {
    // Convert object arrays back to string arrays for category filters
    const appliedFilters = {
      ...tempFilters,
      parentCategoryFilter: (tempFilters.parentCategoryFilter || [])
        .map((cat) => cat?.name)
        .filter(Boolean),
      childCategoryFilter: (tempFilters.childCategoryFilter || [])
        .map((cat) => cat?.name)
        .filter(Boolean),
    };
    applyFilters(appliedFilters);
    onClose();
  };

  useEffect(() => {
    if (open) {
      const updatedFilters = {
        ...filters,
        parentCategoryFilter: (filters.parentCategoryFilter || [])
          .map(
            (name) =>
              parentCategories.find((cat) => cat.name === name) || { name }
          )
          .filter(Boolean),
        childCategoryFilter: (filters.childCategoryFilter || [])
          .map(
            (name) =>
              childCategories.find((cat) => cat.name === name) || { name }
          )
          .filter(Boolean),
      };
      setTempFilters(updatedFilters);
    }
  }, [open, filters, parentCategories, childCategories]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <>
            {["satisfied", "dissatisfied", "unrated"].map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters.feedback1Filter.includes(option)}
                  onChange={() => {
                    const updatedFeedback1 =
                      tempFilters.feedback1Filter.includes(option)
                        ? tempFilters.feedback1Filter.filter(
                            (item) => item !== option
                          )
                        : [...tempFilters.feedback1Filter, option];
                    handleFilterChange("feedback1Filter", updatedFeedback1);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case 1:
        return (
          <>
            {["satisfied", "dissatisfied", "unrated"].map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters.feedback2Filter.includes(option)}
                  onChange={() => {
                    const updatedFeedback2 =
                      tempFilters.feedback2Filter.includes(option)
                        ? tempFilters.feedback2Filter.filter(
                            (item) => item !== option
                          )
                        : [...tempFilters.feedback2Filter, option];
                    handleFilterChange("feedback2Filter", updatedFeedback2);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case 2:
        return (
          <>
            {parentCategories.map((option) => (
              <FlexBox
                component="label"
                key={option.id}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={(tempFilters.parentCategoryFilter || []).some(
                    (cat) => cat?.name === option.name
                  )}
                  onChange={() => {
                    const updatedCategories = (
                      tempFilters.parentCategoryFilter || []
                    ).some((cat) => cat?.name === option.name)
                      ? (tempFilters.parentCategoryFilter || []).filter(
                          (cat) => cat?.name !== option.name
                        )
                      : [...(tempFilters.parentCategoryFilter || []), option];
                    handleFilterChange(
                      "parentCategoryFilter",
                      updatedCategories
                    );
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.name}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case 3:
        return (
          <>
            {childCategories.map((option) => (
              <FlexBox
                component="label"
                key={option.id}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={(tempFilters.childCategoryFilter || []).some(
                    (cat) => cat?.name === option.name
                  )}
                  onChange={() => {
                    const updatedSubCategories = (
                      tempFilters.childCategoryFilter || []
                    ).some((cat) => cat?.name === option.name)
                      ? (tempFilters.childCategoryFilter || []).filter(
                          (cat) => cat?.name !== option.name
                        )
                      : [...(tempFilters.childCategoryFilter || []), option];
                    handleFilterChange(
                      "childCategoryFilter",
                      updatedSubCategories
                    );
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.name}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case 4:
        return (
          <Box pt={8} sx={{ textAlign: "center" }}>
            <Slider
              value={[
                tempFilters.minDurationFilter || 0,
                tempFilters.maxDurationFilter || maxDurationOfCalls,
              ]}
              onChange={(event, value) => {
                handleFilterChange("minDurationFilter", value[0]);
                handleFilterChange("maxDurationFilter", value[1]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={maxDurationOfCalls}
              marks={[{ value: 0 }, { value: maxDurationOfCalls }]}
              sx={{
                width: "80%",
                "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                "& .MuiSlider-track": { backgroundColor: "#0F0F0F", border: 0 },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#0F0F0F",
                  width: 15,
                  height: 15,
                },
              }}
            />
          </Box>
        );
      case 5:
        return (
          <>
            {statusFilterList.map((option) => (
              <FlexBox
                component="label"
                key={option.value}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={(tempFilters.statusFilter || []).includes(
                    option.value
                  )}
                  onChange={() => {
                    const updatedStatuses = (
                      tempFilters.statusFilter || []
                    ).includes(option.value)
                      ? (tempFilters.statusFilter || []).filter(
                          (status) => status !== option.value
                        )
                      : [...(tempFilters.statusFilter || []), option.value];
                    handleFilterChange("statusFilter", updatedStatuses);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.name}
                </Typography>
              </FlexBox>
            ))}
          </>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Grow}
      PaperProps={{
        sx: styles.dialogPaper,
      }}
    >
      <Box
        sx={{
          p: "16px 24px",
          borderBottom: `1px solid ${theme.palette.borderColor.light}`,
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          Filter
        </Typography>
      </Box>

      <FlexBox alignItems="flex-start">
        <Box
          width={"50%"}
          sx={{ borderRight: `1px solid ${theme.palette.borderColor.light}` }}
        >
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <Tab label="Feedback 1" sx={styles.tab} />
            <Tab label="Feedback 2" sx={styles.tab} />
            <Tab label="Category" sx={styles.tab} />
            <Tab label="Sub Category" sx={styles.tab} />
            <Tab label="Duration" sx={styles.tab} />
            <Tab label="Status" sx={styles.tab} />
          </Tabs>
        </Box>
        <Box width={"50%"} p={2} sx={styles.rightContainer}>
          {renderTabContent()}
        </Box>
      </FlexBox>

      <FlexBox
        sx={{
          p: "16px 24px",
          borderTop: `1px solid ${theme.palette.borderColor.light}`,
          justifyContent: "center",
        }}
      >
        <Button variant="outlined" sx={styles.button} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="dark" sx={styles.button} onClick={handleApply}>
          Apply
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default FilterModal;
