const { Handle, Position } = require("reactflow");

const TargetHandle = () => {
  return (
    <Handle
      type="target"
      position={Position.Top}
      style={{
        background: "transparent",
        border: `1px solid #666`,
        top: 5,
      }}
    />
  );
};

export default TargetHandle;
