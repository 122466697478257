const botVoices = [
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-I",
    provider: "Google",
    subCategory: "Wavenet",
    premium: true,
    displayName: "Dave",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Neural2-A",
    premium: true,
    displayName: "Payal",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "hi-IN",
    name: "hi-IN-Standard-B",
    premium: true,
    displayName: "Rahul",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Neural2-D",
    premium: true,
    displayName: "Aisha",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Standard-D",
    displayName: "Shreya",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Wavenet-B",
    premium: true,
    displayName: "Dev",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Wavenet-D",
    premium: true,
    displayName: "Soumya",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Neural2-B",
    displayName: "Arjun",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Standard-A",
    displayName: "Kriti",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Standard-C",
    displayName: "Prateek",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "hi-IN",
    name: "hi-IN-Wavenet-A",
    displayName: "Pooja",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "hi-IN",
    name: "hi-IN-Wavenet-B",
    displayName: "Ishaan",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "en-US",
    name: "en-US-News-K",
    premium: true,
    displayName: "Laura",
    provider: "Google",
    subCategory: "News",
  },
  {
    languageCode: "en-US",
    name: "en-US-News-L",
    premium: true,
    displayName: "Suzanne",
    provider: "Google",
    subCategory: "News",
  },
  {
    languageCode: "en-US",
    name: "en-US-Polyglot-1",
    premium: true,
    displayName: "Ken",
    provider: "Google",
    subCategory: "Polyglot",
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-C",
    displayName: "Karen",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-I",
    displayName: "Ryan",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "en-US",
    name: "en-US-Studio-M",
    premium: true,
    displayName: "Mitch",
    provider: "Google",
    subCategory: "Studio",
  },
  {
    languageCode: "en-US",
    name: "en-US-Studio-O",
    premium: true,
    displayName: "Bradley",
    provider: "Google",
    subCategory: "Studio",
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-C",
    premium: true,
    displayName: "Julia",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-F",
    premium: true,
    displayName: "Ellen",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-D",
    premium: true,
    displayName: "Hector",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "en-US",
    name: "en-US-Journey-D",
    premium: true,
    displayName: "Aaron",
    provider: "Google",
    subCategory: "Journey",
  },
  {
    languageCode: "en-US",
    name: "en-US-Journey-F",
    premium: true,
    displayName: "Brooke",
    provider: "Google",
    subCategory: "Journey",
  },
  // {
  //   languageCode: "es-US",
  //   name: "es-US-Polyglot-1",
  //   premium: true,
  //   displayName: "Marshall",
  // },
  {
    languageCode: "es-ES",
    name: "es-ES-Standard-D",
    displayName: "Lucía",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Neural2-F",
    displayName: "Leo",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "es-US",
    name: "es-US-News-D",
    displayName: "Alberto",
    provider: "Google",
    subCategory: "News",
  },
  {
    languageCode: "es-US",
    name: "es-US-Wavenet-B",
    displayName: "Héctor",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "es-US",
    name: "es-US-News-F",
    displayName: "Ana",
    provider: "Google",
    subCategory: "News",
  },
  {
    languageCode: "id-ID",
    name: "id-ID-Wavenet-D",
    displayName: "Dewi",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "id-ID",
    name: "id-ID-Standard-C",
    displayName: "Wahyu",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "id-ID",
    name: "id-ID-Wavenet-C",
    displayName: "Banyu",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "mr-IN",
    name: "mr-IN-Standard-B",
    displayName: "Sarthak",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "ml-IN",
    name: "ml-IN-Wavenet-B",
    displayName: "Unni",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "ml-IN",
    name: "ml-IN-Wavenet-C",
    displayName: "Geetha",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "pa-IN",
    name: "pa-IN-Standard-D",
    displayName: "Sukhveer",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "ta-IN",
    name: "ta-IN-Wavenet-C",
    displayName: "Aadhya",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "bn-IN",
    name: "bn-IN-Standard-C",
    displayName: "Poulomi",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "te-IN",
    name: "te-IN-Standard-B",
    displayName: "Girish",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "te-IN",
    name: "te-IN-Standard-A",
    displayName: "Priya",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "ar-XA",
    name: "ar-XA-Wavenet-D",
    displayName: "Nour",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "ar-XA",
    name: "ar-XA-Wavenet-C",
    displayName: "Abdel",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "ar-XA",
    name: "ar-XA-Wavenet-B",
    displayName: "Asad",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "ar-XA",
    name: "ar-XA-Standard-D",
    displayName: "Arwa",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "de-DE",
    name: "de-DE-Neural2-F",
    displayName: "Amalia",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "de-DE",
    name: "de-DE-Neural2-D",
    displayName: "Klaus",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "de-DE",
    name: "de-DE-Neural2-C",
    displayName: "Emilia",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "de-DE",
    name: "de-DE-Neural2-B",
    displayName: "Friedrich",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "ja-JP",
    name: "ja-JP-Wavenet-A",
    displayName: "Fumiko",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "ja-JP",
    name: "ja-JP-Wavenet-C",
    displayName: "Haruto",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "cmn-CN",
    name: "cmn-CN-Wavenet-D",
    displayName: "Chen",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "cmn-CN",
    name: "cmn-CN-Wavenet-B",
    displayName: "Li",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "en-IN",
    name: "hi-IN-Neural2-A",
    displayName: "Radha",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "hi-IN",
    name: "hi-IN-Neural2-A",
    displayName: "Radha",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "hi-IN",
    name: "hi-IN-Neural2-D",
    displayName: "Naina",
    provider: "Google",
    subCategory: "Neural2",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Wavenet-A",
    displayName: "Riya",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Standard-D",
    displayName: "Nidhi",
    provider: "Google",
    subCategory: "Standard",
  },
  {
    languageCode: "gu-IN",
    name: "gu-IN-Wavenet-C",
    displayName: "Maitri",
    provider: "Google",
    subCategory: "Wavenet",
  },
  {
    languageCode: "gu-IN",
    name: "gu-IN-Wavenet-D",
    displayName: "Harsh",
    provider: "Google",
    subCategory: "Wavenet",
  },

  // ElevenLab voices
  {
    languageCode: "en-IN",
    name: "en-IN-Priyanka",
    displayName: "Priyanka",
    elevenLab: "true",
    provider: "ElevenLab",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Deepak",
    displayName: "Deepak",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Bobby",
    displayName: "Bobby",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Sana",
    displayName: "Sana",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-MonikaSogam",
    displayName: "Monika Sogam",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Ruchika",
    displayName: "Ruchika",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Priya",
    displayName: "Priya",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "en-IN",
    name: "en-IN-Raju",
    displayName: "Raju",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "hi-IN",
    name: "en-IN-Priyanka",
    displayName: "Priyanka",
    elevenLab: "true",
    provider: "ElevenLab",
  },
  {
    languageCode: "hi-IN",
    name: "en-IN-Deepak",
    displayName: "Deepak",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "hi-IN",
    name: "en-IN-Bobby",
    displayName: "Bobby",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "hi-IN",
    name: "en-IN-Sana",
    displayName: "Sana",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "hi-IN",
    name: "en-IN-MonikaSogam",
    displayName: "Monika Sogam",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "hi-IN",
    name: "en-IN-Ruchika",
    displayName: "Ruchika",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "hi-IN",
    name: "en-IN-Priya",
    displayName: "Priya",
    provider: "ElevenLab",
    elevenLab: "true",
  },
  {
    languageCode: "hi-IN",
    name: "en-IN-Raju",
    displayName: "Raju",
    provider: "ElevenLab",
    elevenLab: "true",
  },
];

export default botVoices;
