import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import classes from "./index.module.css";
import { X } from "react-feather";
import { ArrowForwardIos } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { RedAlert } from "components/SVG";

const CreateWorkFlowModal = (props) => {
  const { open, onClose, handleSave, edit, data } = props;
  const [isClicked, setIsClicked] = useState(false);
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const resetForm = () => {
    reset({
      name: "",
    });
  };

  useEffect(() => {
    if (open && data?.name) {
      reset({
        name: data?.name,
      });
    }
    if (!open) {
      resetForm();
    }
  }, [open, data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 500,
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" position="relative">
        <IconButton onClick={onClose} className={classes.closeButton}>
          <X />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        <Typography variant="h3" className={classes.heading}>
          {edit ? "Edit" : "Create"} workflow
        </Typography>
        <Typography variant="caption" component="p" className={classes.label}>
          Workflow name *
        </Typography>
        <Controller
          name="name"
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              autoFocus
              className={classes.name}
              placeholder="Enter workflow name"
              onChange={onChange}
              value={value}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsClicked(true);
                  handleSubmit(handleSave)();
                }
              }}
              helperText={
                errors?.name && (
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors?.name?.message}
                  </Box>
                )
              }
            />
          )}
        />
        <Box className={classes.buttonContainer}>
          <Button
            variant="outline"
            className={classes.button}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            className={classes.button}
            endIcon={<ArrowForwardIos style={{ width: 16, height: 16 }} />}
            onClick={() => {
              setIsClicked(true);
              handleSubmit(handleSave)();
            }}
            disabled={!isDirty || isClicked}
          >
            {edit ? "Save" : "Create"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

CreateWorkFlowModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
};

export default CreateWorkFlowModal;
