import { Slide, Snackbar } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState, createContext } from "react";

export const SkillrSnackbarContext = createContext();

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const SnackbarProvider = (props) => {
  const [open, setOpen] = useState();
  const [message, setMessage] = useState();
  const [variant, setVariant] = useState("info");

  const showSkillrToast = (messageArg, variantArg) => {
    setOpen(true);
    setMessage(messageArg);
    setVariant(variantArg);
  };

  return (
    <SkillrSnackbarContext.Provider
      value={{
        showSkillrToast,
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={open}
        onClose={() => {
          setOpen();
          setMessage();
          setVariant("info");
        }}
        TransitionComponent={SlideTransition}
        className={variant === "success" ? "success" : "error"}
        style={{ minWidth: 250, left: 90, bottom: 90 }}
        autoHideDuration={3000}
        message={message}
      />
      {props.children}
    </SkillrSnackbarContext.Provider>
  );
};

export const useSkillrToast = () => {
  return useContext(SkillrSnackbarContext);
};

SnackbarProvider.propTypes = {
  children: PropTypes.any,
};

export default SnackbarProvider;
