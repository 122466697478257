import { Box } from "@mui/material";
import styles from "./index.module.css";
import { SpinnerGap } from "components/newSVG";

const Loader = () => {
  return (
    <Box className={styles.loader}>
      <SpinnerGap width={48} height={48} />
    </Box>
  );
};

export default Loader;
