import {
  Box,
  Button,
  Dialog,
  Grow,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import { ArrowBack, IconTrash, UploadCloudWhite } from "components/SVG";
import FileUpload from "pages/Dashboard/components/FileUpload";
import { useEffect, useState } from "react";
import { ChevronRight, Upload, X } from "react-feather";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import AutoComplete from "components/AutoComplete";
import {
  getCategoriesByCompany,
  createCategory as createCategoryAPI,
} from "services";

const UploadFilesModal = (props) => {
  const {
    open,
    onClose,
    onSave,
    mode,
    onBack,
    triggerFileInput,
    fileInputRef,
    handleFileChange,
    // acceptedFileTypes,
    handleAddFiles,
    removeFile,
    selectedFiles,
    completeFileUpload,
  } = props;
  const acceptedFileTypeAudio = ["audio/*"];
  const acceptedFileTypeDocs = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const onAddFiles = () => {
    fileInputRef.current.accept =
      mode === "calls"
        ? acceptedFileTypeAudio.join(",")
        : acceptedFileTypeDocs.join(",");
    triggerFileInput();
  };

  const handleUpload = (data) => {
    handleAddFiles(data);
  };

  const getCategories = async () => {
    const response = await getCategoriesByCompany();
    setCategoryList(response);
  };

  const createCategory = async (name) => {
    try {
      const response = await createCategoryAPI({ name: name });
      // Add the new category to the category list
      setCategoryList((prevState) => [...prevState, response]);
      // Set the new category as the selected category
      setSelectedCategory(response._id);
      onSave(response._id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNewCategory = (name) => {
    if (typeof name === "string" || name?.inputValue) {
      const newCategory = name?.inputValue ?? name;
      createCategory(newCategory);
    } else {
      setSelectedCategory(name?._id);
      onSave(name?._id);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Grow}
        PaperProps={{
          sx: {
            width: 650,
            height: 650,
            padding: 10,
            borderRadius: 5,
            position: "relative",
          },
        }}
      >
        <Button
          variant="text"
          color="inherit"
          startIcon={<ArrowBack />}
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            minWidth: 0,
            "& span": { fontSize: 12 },
          }}
          onClick={() => onBack()}
        >
          Back
        </Button>

        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={onClose}
        >
          <X width={20} height={20} />
        </IconButton>

        <Typography align="center" variant="h4" fontWeight={500}>
          Generate from {mode}
        </Typography>

        <Typography variant="body2" color="#0f0f0fb3" textAlign="center" mb={4}>
          {mode === "calls"
            ? `Upload mp3 and wav files to generate intents`
            : `Upload pdf, doc and docx files to generate intents`}
        </Typography>

        {selectedFiles?.length > 0 ? (
          <Box className={styles.uploadedFileContainer}>
            {selectedFiles?.map((file, fileIndex) => (
              <Box className={styles.uploadedFile} key={fileIndex}>
                <Typography variant="body2">{file?.name}</Typography>
                <IconButton onClick={() => removeFile(fileIndex)}>
                  <IconTrash />
                </IconButton>
              </Box>
            ))}
          </Box>
        ) : (
          <FlexBox className={styles.uploadContainer}>
            <Box className={`${styles.uploadIcon}`}>
              <UploadCloudWhite />
            </Box>

            <Box>
              <Typography variant="body1" fontWeight={500}>
                Select file to upload from computer
              </Typography>
              <Typography variant="body2" color="text.secondary">
                You can upload {mode === "calls" ? "audio files" : "documents"}
              </Typography>

              <Button
                variant="outlined"
                sx={{ borderRadius: 82, mt: 4 }}
                startIcon={<Upload width={16} height={16} />}
                onClick={onAddFiles}
              >
                Upload file
              </Button>
            </Box>
          </FlexBox>
        )}
        <Box my={4}>
          <Typography
            variant="caption"
            color={"grey.600"}
            component={"div"}
            ml={2}
            mb={1}
          >
            Category
          </Typography>

          <AutoComplete
            renderOptionKey="name"
            autoComplete
            freeSolo
            // error={errors?.team}
            optionList={categoryList}
            onChange={(value) => {
              handleCreateNewCategory(value);
            }}
            closeIcon={false}
            ListboxProps={{ style: { maxHeight: "12rem" } }}
            className={styles.formField}
            PaperComponent={({ children }) => (
              <Grow in={true} mountOnEnter unmountOnExit>
                <div className={styles.autoCompleteList}>{children}</div>
              </Grow>
            )}
          />
        </Box>

        <FlexBox justifyContent="center" mt={8}>
          <Button variant="whiteRound" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dark"
            endIcon={<ChevronRight />}
            onClick={() => {
              handleUpload();
              setSelectedCategory(null);
              onClose();
            }}
            disabled={selectedFiles?.length === 0 || !selectedCategory}
          >
            Upload
          </Button>
        </FlexBox>
      </Dialog>
      <input
        type="file"
        multiple
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </>
  );
};
UploadFilesModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAddFiles: PropTypes.func,
  triggerFileInput: PropTypes.func,
  fileInputRef: PropTypes.object,
  handleFileChange: PropTypes.func,
  acceptedFileTypes: PropTypes.array,
  fileUploadStatus: PropTypes.object,
};

UploadFilesModal.defaultProps = {
  triggerFileInput: () => {},
  fileInputRef: {},
  handleFileChange: () => {},
  acceptedFileTypes: [],
  fileUploadStatus: {},
};

export default UploadFilesModal;
