export const statusFilter = [
  { name: "Pending", value: "Pending" },
  // { name: "Audited", value: "Audited" },
  { name: "Call Abandoned", value: "Call Abandoned" },
  { name: "Partially Resolved", value: "Partially Resolved" },
  { name: "Resolved", value: "Resolved" },
  { name: "Inactive Category", value: "Inactive Category" },
  { name: "Incorrect Intent", value: "Incorrect Intent" },
  { name: "Similar Intent", value: "Similar Intent" },
  { name: "Untrained Intent", value: "Untrained Intent" },
  { name: "Distance Exceeded", value: "Distance Exceeded" },
  { name: "No Speech", value: "No Speech" },
  { name: "Unclear Speech", value: "Unclear Speech" },
  { name: "Unclear Response", value: "Unclear Response" },
  { name: "Transfer Requested", value: "Transfer Requested" },
  { name: "System Error", value: "System Error" },
];
