import { getRequest } from "./common";

const getCampaignDetails = (campaignId, params, config = {}) => {
  if (params) {
    return getRequest(`/campaign/${campaignId}?${params}`, config);
  }
  return getRequest(`/campaign/${campaignId}`, config);
};

export default getCampaignDetails;
