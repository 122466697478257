import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const ConfigureWorkFlowNodes = (props) => {
  const { tabs, selectedTab, onTabChange } = props;

  return (
    <Box className={styles.container}>
      <Tabs
        value={selectedTab}
        onChange={onTabChange}
        aria-label="tabs"
        className={styles.tabs}
        TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
        sx={{
          "& span.MuiTabs-indicator": {
            borderRadius: "10px 10px 0 0",
            height: 3,
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            className={styles.tab}
            label={tab.name}
            value={tab.name}
            key={tab.value}
          />
        ))}
      </Tabs>
    </Box>
  );
};

ConfigureWorkFlowNodes.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.string,
  handleTabChange: PropTypes.func,
};

export default ConfigureWorkFlowNodes;
