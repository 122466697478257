import { Box, Button, Typography } from "@mui/material";
import { useUserContext } from "context/user";
import { useFileUpload } from "custom-hooks/useFileUpload";
import { useEffect } from "react";
import {
  CaretDoubleUp,
  CheckFat,
  EggCrack,
  IconUpload2,
  UploadCloudWhite,
} from "components/SVG";
import FundamentoPopup from "components/FundamentoPopup";
import { ChevronDown, ChevronRight } from "react-feather";
import moment from "moment";
import styles from "./index.module.css";

const supportedFileTypes = [
  "audio/wav",
  "audio/mpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const FileUpload = () => {
  const { companyDetails, getCompanyDetails, dataSets, getDataSets } =
    useUserContext();
  const { fileInputRef, handleFileChange, triggerFileInput, uploadStatus } =
    useFileUpload({
      fileTypes: supportedFileTypes,
      maxUploadLimit: companyDetails?.maxStorageCapacityInMB,
      sizeAlreadyConsumed: companyDetails?.usedStorageCapacity,
      processUploadedFiles: true,
    });

  const handleCreateNewDataSet = () => {
    triggerFileInput({ dataSet: {} });
  };

  const getExistingDataSetId = (element) => {
    const parent = element.target.closest("[class*=popoverItem__]");
    if (parent) {
      const datasetIndex = parent.id;
      handleExistingDataSet(datasetIndex);
    }
  };

  const handleExistingDataSet = (datasetIndex) => {
    const selectedDataSet = dataSets[datasetIndex];
    triggerFileInput({ dataSet: selectedDataSet });
  };

  const getUploadIcon = () => {
    if (
      uploadStatus?.status === "started" ||
      uploadStatus?.status === "processing"
    ) {
      return <CaretDoubleUp />;
    } else if (uploadStatus.status === "storageLimitError") {
      return <EggCrack />;
    } else if (uploadStatus.status === "completed") {
      return <CheckFat />;
    } else {
      return <UploadCloudWhite />;
    }
  };

  const getUploadText = () => {
    let title;
    let subtitle;
    if (
      uploadStatus?.status === "started" ||
      uploadStatus?.status === "processing"
    ) {
      title = "Your files are uploading";
      subtitle = "";
      // subtitle = `${uploadStatus?.processedFiles} of ${uploadStatus?.totalFiles} file are processed`;
    } else if (uploadStatus.status === "completed") {
      title = `All ${uploadStatus.totalFiles} files have been uploaded successfully!`;
      subtitle = "You can now check you dataset";
    } else if (uploadStatus.status === "storageLimitError") {
      title = "File upload failed due to storage limit";
      subtitle = "You can try uploading the files again upto 500 mb";
    } else if (uploadStatus.status === "error") {
      title = "Something went wrong";
      subtitle = "Please try again";
    } else {
      title = "Select files to upload from computer";
      subtitle = "You can upload upto 500 mb in one go";
    }
    return (
      <>
        <Typography variant="subtitle1" fontWeight={500}>
          {title}
        </Typography>
        <Typography variant="body2" color={"grey.400"}>
          {subtitle}
        </Typography>
      </>
    );
  };

  const trackUploadStatus = () => {
    if (uploadStatus?.status === "completed") {
      getCompanyDetails();
      getDataSets();
    }
  };

  const handleBeforeUnload = (event) => {
    if (["started", "processing"].indexOf(uploadStatus?.status) > -1) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  useEffect(() => {
    trackUploadStatus();
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploadStatus]);

  return (
    <>
      <Box className={styles.uploadContainer}>
        <Box className={styles.uploadContent}>
          <Box
            className={`${styles.uploadIcon} ${
              ["error", "storageLimitError"].indexOf(uploadStatus.status) >
                -1 && styles.errorState
            } ${
              uploadStatus.status === "processing" ||
              uploadStatus.status === "started"
                ? styles.animate
                : ""
            }`}
          >
            {getUploadIcon()}
          </Box>
          <Box sx={{ zIndex: 1 }}>
            {getUploadText()}

            {(!uploadStatus?.status ||
              ["completed", "storageLimitError", "error"].indexOf(
                uploadStatus.status
              ) > -1) && (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 4 }}
                mt={4}
              >
                <Button
                  startIcon={<IconUpload2 color={"grey.600"} />}
                  variant="outlined"
                  sx={{ borderRadius: 82 }}
                  onClick={handleCreateNewDataSet}
                >
                  Create new data set
                </Button>
                {dataSets.length > 0 && (
                  <FundamentoPopup
                    disabled={dataSets.length === 0}
                    triggeringComponent={
                      <Button
                        disabled={dataSets.length === 0}
                        variant="outlined"
                        sx={{ borderRadius: 82 }}
                        endIcon={<ChevronDown width={16} height={16} />}
                      >
                        Add to existing
                      </Button>
                    }
                    className={styles.popoverContainer}
                  >
                    <Box onClick={getExistingDataSetId}>
                      <Typography variant="subtitle2" fontWeight={500}>
                        Existing Data Sets
                      </Typography>
                      {dataSets.map((dataset, datasetIndex) => (
                        <Box
                          className={styles.popoverItem}
                          id={datasetIndex}
                          key={dataset._id}
                        >
                          <Box>
                            <Typography variant="body2">
                              {dataset.name}
                            </Typography>
                            <Typography
                              fontSize={10}
                              color="grey.400"
                              fontWeight={500}
                            >
                              created on{" "}
                              {moment(dataset.createdAt).format("D MMM")}
                            </Typography>
                          </Box>
                          <ChevronRight width={15} height={15} />
                        </Box>
                      ))}
                    </Box>
                  </FundamentoPopup>
                )}
              </Box>
            )}
          </Box>
          <input
            type="file"
            multiple
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            accept={supportedFileTypes.join(",")}
          />
        </Box>
      </Box>
    </>
  );
};

export default FileUpload;
