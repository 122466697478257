const FileColors = {
  pdf: {
    background: "#FEEBEF",
    color: "#EB5757",
  },
  jpg: {
    background: "#FFF8E1",
    color: "#E99A01",
  },
  doc: {
    background: "#EFF0FB",
    color: "#4B56D2",
  },
  docx: {
    background: "#EFF0FB",
    color: "#4B56D2",
  },
  csv: {
    color: "#43d26c",
    background: "#eef9ec",
  },
  ppt: {
    color: "#4da3a5",
    background: "#d4e8eb",
  },
  png: {
    background: "#FFF8E1",
    color: "#E99A01",
  },
  link: {
    color: "#fc9512",
    background: "#fff1de",
  },
  mp3: {
    color: "#f849ea",
    background: "#fff2fe",
  },
  mp4: {
    color: "#0094ff",
    background: "#e9f6ff",
  },
  xls: {
    color: "#43d26c",
    background: "#eef9ec",
  },
  xlsx: {
    color: "#43d26c",
    background: "#eef9ec",
  },
  gif: {
    background: "#FFF8E1",
    color: "#E99A01",
  },
};
export default FileColors;
