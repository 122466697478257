import { Button, CircularProgress } from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { styled } from "@mui/material/styles";

// const useStyles = makeStyles((theme) => {
//   return {
//     buttonProgress: {
//       color: theme.palette.primary.main,
//       position: "absolute",
//       top: "50%",
//       left: "50%",
//       marginTop: -12,
//       marginLeft: -12,
//     },
//   };
// });

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12,
}));

export default function SkillrButton(props) {
  const {
    variant,
    color,
    className,
    loading,
    disabled,
    onClick,
    onFocus,
    toggle,
    fn,
    ...other
  } = props;
  // const classes = useStyles();
  let finalOnClickFn = () => {};
  if (fn) {
    finalOnClickFn = fn;
  } else {
    finalOnClickFn = onClick;
  }
  return (
    <>
      <Button
        className={`${className} skillr-btn`}
        color={color}
        variant={variant}
        onClick={finalOnClickFn}
        onFocus={onFocus}
        disabled={loading || disabled}
        {...toggle}
        {...other}
      >
        {props.children}
        {loading && <StyledCircularProgress size={24} />}
      </Button>
    </>
  );
}

SkillrButton.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.any,
  toggle: PropTypes.object,
  fn: PropTypes.any,
};

SkillrButton.defaultProps = {
  color: "primary",
  variant: "contained",
  className: "",
  loading: false,
  disabled: false,
  toggle: {},
};
