import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { ChevronRight, DollarSign, Play, PlayCircle } from "react-feather";
import { useEffect, useState } from "react";
import PageWrapper from "components/PageWrapper";
import FundamentoTable from "components/FundamentoTable";
import { Help } from "@mui/icons-material";
import theme from "theme";
import Tabs from "pages/Bot/components/Tabs";
import { editBot } from "services";
import { SkillrTextField } from "components";
import { Controller, useForm } from "react-hook-form";
import { useSkillrToast } from "context/toast";
import { useUserContext } from "context/user";
import FundamentoPopup from "components/FundamentoPopup";
import { ChevronDown, IconCrown } from "components/SVG";
import { getAudio as getAudioApi } from "services";
import botVoices from "utils/botVoices";

const Settings = () => {
  const columns = [
    { field: "name", header: "Data Set", style: { width: "50%" } },
    { field: "faqs", header: "FAQs" },
    { field: "files", header: "Files" },
    {
      field: "checkbox",
      header: "",
      style: { width: "5%" },
      render: () => (
        <Box textAlign="right">
          <ChevronRight width={16} height={16} />
        </Box>
      ),
    },
  ];

  const tableData = [
    {
      name: (
        <Box display={"flex"} alignItems="center">
          <Checkbox />
          Invoicing Issues
        </Box>
      ),
      faqs: 16,
      files: 2,
    },
    {
      name: (
        <Box display={"flex"} alignItems="center">
          <Checkbox />
          Invoicing Issues
        </Box>
      ),
      faqs: 16,
      files: 2,
    },
    {
      name: (
        <Box display={"flex"} alignItems="center">
          <Checkbox />
          Invoicing Issues
        </Box>
      ),
      faqs: 16,
      files: 2,
    },
  ];

  const [sourceFromInternet, setSourceFromInternet] = useState(false);
  const { showSkillrToast } = useSkillrToast();
  const { bot, setBot } = useUserContext();
  const [isPlaying, setIsPlaying] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm();

  const handleEditBot = async (data) => {
    try {
      const getLanguageCode = botVoices.find(
        (item) => item.name === data.botVoice
      );
      const newData = {
        ...data,
        botVoice: {
          name: data.botVoice,
          languageCode: getLanguageCode.languageCode,
        },
      };
      const response = await editBot(newData);
      if (response) {
        setBot(response.data);
        showSkillrToast("Successfully edited bot", "success");
      }
    } catch (error) {
      console.error("error", error);
      showSkillrToast("Something went wrong", "error");
    }
  };

  const resetBot = () => {
    if (bot) {
      reset({
        personality: bot.personality,
        instruction: bot.instruction,
        welcomeMessage: bot.welcomeMessage,
        failureMessage: bot.failureMessage,
        botVoice: bot.botVoice.name,
      });
    }
  };

  const getAudio = async (apiData) => {
    setIsPlaying(true);
    const response = await getAudioApi(apiData);
    const base64AudioContent = response;
    const audio = new Audio("data:audio/wav;base64," + base64AudioContent);
    audio.play();

    audio.onended = () => {
      setIsPlaying(false);
    };
  };

  useEffect(() => {
    resetBot();
  }, [bot]);

  return (
    <PageWrapper
      className={styles.pageWrapper}
      content={
        <>
          <Tabs />

          <Box mt={6}>
            <Typography variant="h3" align="center" fontWeight={500}>
              Edit Yara
            </Typography>
            {/* <Typography mb={8} variant="body2" color="grey.600" align="center">
              All your collection data gets processed and auto-categorized here
              for quick reference
            </Typography> */}
          </Box>

          {/* <Typography
            mb={2}
            variant="caption"
            color="text.primary"
            fontWeight={500}
          >
            Personality
          </Typography>

          <SkillrTextField
            fullWidth
            defaultValue={bot?.personality ? bot?.personality : ""}
            control={control}
            name="personality"
            error={errors.personality}
            rules={{ required: "This field is required" }}
            className={styles.roundedInput}
            placeholder="Eg: 'Friendly colleague', 'Professional, yet relatable customer success agent'"
          /> */}

          <Typography
            mb={2}
            mt={8}
            variant="caption"
            color="text.primary"
            fontWeight={500}
          >
            Prompt
          </Typography>

          <SkillrTextField
            sx={{
              "& > div": { padding: 0 },
            }}
            placeholder="Eg: 'Your goal is to help customers get any information they are looking for about Dunder Mifflin'"
            fullWidth
            inputProps={{ style: { padding: "14px 17px" } }}
            multiline
            defaultValue={bot?.instruction ? bot?.instruction : ""}
            rows={4}
            control={control}
            name="instruction"
            error={errors.instruction}
            rules={{ required: "This field is required" }}
          />

          <Typography
            mb={2}
            mt={8}
            variant="caption"
            color="text.primary"
            fontWeight={500}
          >
            Voice Setting
          </Typography>
          <Box width={"100%"}>
            {/* <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              style={{ width: "25vw" }}
              triggeringComponent={
                <Box className={styles.dropdown}>
                  <Typography variant="body1">Select your voice</Typography>
                  <ChevronDown
                    width={16}
                    height={16}
                    style={{ marginLeft: "auto" }}
                  />
                </Box>
              }
            >
            </FundamentoPopup> */}
            <Controller
              name="botVoice"
              control={control}
              defaultValue={bot?.botVoice ? bot?.botVoice.name : ""}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <Select
                    sx={{
                      "& > div": {
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "40px !important",
                      },
                      "& .MuiInputBase-input > button": {
                        display: "none",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "40vw",
                        },
                      },
                    }}
                    value={value}
                    onChange={onChange}
                  >
                    {botVoices.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          my={2}
                          value={item.name}
                          className={styles.voiceItem}
                        >
                          <Typography
                            variant="body1"
                            style={{ marginRight: "auto" }}
                          >
                            {item.name}
                          </Typography>
                          {item.premium && <IconCrown width={16} height={16} />}

                          <IconButton
                            style={{ marginLeft: 8 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              !isPlaying &&
                                getAudio({
                                  text: "Hi, I'm Yara, how can I assist you?",
                                  botVoice: {
                                    name: item.name,
                                    languageCode: item.languageCode,
                                  },
                                });
                            }}
                          >
                            <PlayCircle
                              color="#0f0f0fb2"
                              style={{ cursor: "pointer" }}
                              width={16}
                              height={16}
                            />
                          </IconButton>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Box>

          <Box display="flex" mt={5} mb={8} columnGap={6}>
            {/* <Box flexGrow={1}>
              <Typography
                variant="caption"
                color="text.primary"
                fontWeight={500}
              >
                Welcome message
              </Typography>

              <SkillrTextField
                sx={{ marginTop: 2 }}
                fullWidth
                control={control}
                defaultValue={bot?.welcomeMessage ? bot?.welcomeMessage : ""}
                name="welcomeMessage"
                error={errors.welcomeMessage}
                rules={{ required: "This field is required" }}
                className={styles.roundedInput}
                placeholder="Eg: 'Hi 👋 How can I assist you today?'"
              />
            </Box>
            <Box flexGrow={1}>
              <Typography
                variant="caption"
                color="text.primary"
                fontWeight={500}
              >
                Failure message
              </Typography>

              <SkillrTextField
                control={control}
                name="failureMessage"
                error={errors.failureMessage}
                rules={{ required: "This field is required" }}
                sx={{ marginTop: 2 }}
                fullWidth
                className={styles.roundedInput}
                placeholder="Eg: 'Sorry from Yara :('"
                defaultValue={bot?.failureMessage ? bot?.failureMessage : ""}
              />
            </Box> */}
          </Box>
          {/* <Box
            style={{ transition: "0.4s" }}
            className={sourceFromInternet && styles.disabled}
          >
            <Typography variant="caption" color="text.primary" fontWeight={500}>
              Connect data sets *
            </Typography>

            <Box className={styles.tableContainer}>
              <FundamentoTable
                className={styles.datasetList}
                columns={columns}
                data={tableData}
              />
            </Box>
          </Box> */}
          <Box display="flex" justifyContent={"center"} alignItems="center">
            {/* <Box display="flex" alignItems="center">
              <Switch
                focusVisibleClassName=".Mui-focusVisible"
                disableRipple
                checked={sourceFromInternet}
                onChange={() => setSourceFromInternet(!sourceFromInternet)}
              />
              <Typography variant="caption" fontWeight={500}>
                Source from internet
              </Typography>
              <Tooltip title="Source from internet" placement="top">
                <Help
                  sx={{
                    "& path": { color: theme.palette.grey[400] },
                    marginLeft: 2,
                  }}
                />
              </Tooltip>
            </Box> */}

            <Fade in={isDirty} mountOnEnter unmountOnExit>
              <Button
                className={styles.button}
                variant="outlined"
                onClick={resetBot}
              >
                Cancel
              </Button>
            </Fade>

            <Button
              disabled={!isDirty}
              variant="dark"
              endIcon={<ChevronRight color="white" />}
              onClick={handleSubmit(handleEditBot)}
            >
              {isDirty ? "Save" : "Edit"}
            </Button>
          </Box>
        </>
      }
    />
  );
};
export default Settings;
