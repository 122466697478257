// import { useMutation } from "@apollo/client";
import { Box, Card, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SkillrAlert from "components/SkillrAlert";
import SkillrButton from "components/SkillrButton";
import SkillrTextField from "components/SkillrTextField";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
// import { SEND_RESET_EMAIL } from "services/graphql";
import { emailRegEx, getErrorMessage } from "utils/utils";
import brandLogo from "assets/images/skillr_logo.png";
import {
  AuthCardHeader,
  AuthFormGroup,
  ReturnButtonSection,
  Root,
  StyledCardContent,
} from "./index.style";
import { FundamentoLogoFull } from "components/SVG";
import { BrandLogo } from "../AuthCard/index.style";

const EmailVerifiedPage = () => {
  // const classes = useStyles();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [status, setStatus] = useState({ message: "" });
  // const [
  //   sendResetEmail,
  //   {
  //     data: forgotPasswordData,
  //     error: forgotPasswordError,
  //     loading: forgotPasswordLoading,
  //   },
  // ] = useMutation(SEND_RESET_EMAIL);
  const [loading, setLoading] = useState(false);

  // const onSubmit = (data) => {
  //   sendResetEmail({ variables: data });
  // };

  // useEffect(() => {
  //   if (forgotPasswordLoading) {
  //     setLoading(true);
  //   }
  //   if (forgotPasswordData && forgotPasswordData.forgotPassword) {
  //     setLoading(false);
  //     setStatus({
  //       status: "success",
  //       message: "Password reset link sent to the registered email",
  //     });
  //   }
  //   if (forgotPasswordError) {
  //     setLoading(false);
  //     setStatus({
  //       status: "error",
  //       message: getErrorMessage(forgotPasswordError),
  //     });
  //   }
  // }, [forgotPasswordData, forgotPasswordError, forgotPasswordLoading]);
  return (
    <Root>
      <BrandLogo>
        <FundamentoLogoFull width={170} />
        {/* <img src={brandLogo} alt="Logo" /> */}
      </BrandLogo>
      <Card>
        <ReturnButtonSection>
          {/* <Link to="/" className={classes.returnButton}>
            <ArrowBackIosIcon />
            <Box>Return to login</Box>
          </Link> */}
        </ReturnButtonSection>
        <StyledCardContent>
          {status.status !== "success" ? (
            <AuthCardHeader component={"div"}>
              <Typography variant="h4" gutterBottom>
                Forgot password?
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Enter your business email and if that matches our records,
                you'll get instructions to reset your password.
              </Typography>
            </AuthCardHeader>
          ) : (
            <AuthCardHeader component={"div"}>
              <Typography variant="h4" gutterBottom>
                Check your mail
              </Typography>
              <Typography variant="body1" color="textSecondary">
                You are just a click away from resetting your password. Check
                your business email for further instructions.
              </Typography>
            </AuthCardHeader>
          )}
          {status.status && (
            <Box mb={6}>
              <SkillrAlert variant={status.status}>
                {status.message}
              </SkillrAlert>
            </Box>
          )}
          {status.status !== "success" && (
            <form
            // onSubmit={handleSubmit(onSubmit)}
            >
              <AuthFormGroup>
                <SkillrTextField
                  control={control}
                  error={errors.email}
                  rules={{
                    required: "Email field is required",
                    pattern: {
                      value: emailRegEx,
                      message: "Please enter valid email id",
                    },
                  }}
                  name="email"
                  id="login"
                  label="Business Email"
                />
              </AuthFormGroup>

              <SkillrButton
                size="large"
                type="submit"
                loading={loading}
                fullWidth
              >
                Send Reset Link
              </SkillrButton>
            </form>
          )}
          <Box mt={6} mx="auto" maxWidth={320} textAlign="center">
            <Typography variant="body2">
              Trouble logging in?{" "}
              <Box color="primary.main" clone>
                <a href="mailto:hello@fundamento.ai">Contact support</a>
              </Box>
            </Typography>
          </Box>
        </StyledCardContent>
      </Card>
    </Root>
  );
};
export default EmailVerifiedPage;
