import { Box, Button } from "@mui/material";
import { ChevronLeft } from "components/SVG";
import PropTypes from "prop-types";
import theme from "theme";

const Footer = (props) => {
  const {
    onBack,
    onSave,
    OnNext,
    backButtonText,
    SaveButtonText,
    NextButtonText,
    className,
    nextButtonDisabled,
    saveButtonDisbaled,
  } = props;

  return (
    <Box
      className={className}
      sx={{ padding: 6, borderTop: `1px solid ${theme.palette.grey[100]}` }}
      display="flex"
      justifyContent="space-between"
    >
      {backButtonText && (
        <Button
          startIcon={<ChevronLeft width={20} height={20} />}
          onClick={onBack}
          variant="outline"
        >
          {backButtonText}
        </Button>
      )}
      <Box display="flex" gap={2}>
        {SaveButtonText && (
          <Button
            disabled={saveButtonDisbaled}
            variant="outlined"
            onClick={onSave}
            sx={{
              border: "1px solid #505050",
              "&:hover": {
                border: "1px solid #505050",
              },
              height: 40,
            }}
          >
            {SaveButtonText}
          </Button>
        )}
        {NextButtonText && (
          <Button
            disabled={nextButtonDisabled}
            variant="dark"
            sx={{
              padding: "16px 24px",
              fontSize: 12,
              height: 40,
              minWidth: 0,
            }}
            style={{ borderRadius: 12 }}
            onClick={OnNext}
          >
            {NextButtonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  onBack: PropTypes.func,
  onSave: PropTypes.func,
  OnNext: PropTypes.func,
  backButtonText: PropTypes.string,
  SaveButtonText: PropTypes.string,
  NextButtonText: PropTypes.string,
  nextButtonDisabled: PropTypes.bool,
  saveButtonDisbaled: PropTypes.bool,
  className: PropTypes.string,
};

Footer.defaultProps = {
  onBack: () => {},
  onSave: () => {},
  OnNext: () => {},
  nextButtonDisabled: false,
  saveButtonDisbaled: false,
  className: "",
};

export default Footer;
