import { getRequest } from "./common";

const exportCampaign = (campaignId, params, config = {}) => {
  return getRequest(
    `/campaign/export-campaigns/${campaignId}?${params}`,
    config
  );
};

export default exportCampaign;
