import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Root = styled(Box)(({ theme }) => ({
  minHeight: "100vh",

  backgroundColor: theme.palette.primary.light,

  paddingTop: theme.spacing(16),

  paddingBottom: theme.spacing(16),
}));

export { Root };
