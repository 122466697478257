import PropTypes from "prop-types";

const typesColorMapping = {
  string: "#a11",
  number: "#164",
  boolean: "#219",
  object: "#708", // null as well
};

const CustomJsonRenderer = (props) => {
  const { json, className, handleOnClick } = props;

  const renderJson = (json, parentKey = "", level = 1, keyReference = "") => {
    const elements = [];
    const indentation = { marginLeft: `${level * 12}px` };

    const addKeyValue = (
      key,
      value,
      uniqueId,
      isArrayItem = false,
      keyReference
    ) => {
      return (
        <div
          key={uniqueId}
          id={uniqueId}
          style={{ ...indentation, whiteSpace: "nowrap" }}
          className="main"
        >
          {!isArrayItem && (
            <span
              style={{ color: "black" }}
              data-keyReference={keyReference}
              data-keyName={key}
            >{`"${key}": `}</span>
          )}
          <span
            style={{
              color: typesColorMapping[typeof value],
              paddingLeft: "2px",
            }}
          >
            {typeof value === "string" && `"${value}"`}
            {typeof value === "boolean" && value.toString()}
            {typeof value === "number" && value}
            {value === null && "null"}
          </span>
          ,
        </div>
      );
    };

    for (const [key, value] of Object.entries(json)) {
      const uniqueId = `${parentKey}-${key}`;

      if (Array.isArray(value)) {
        elements.push(
          <div key={uniqueId} id={uniqueId} style={indentation}>
            <span
              style={{ color: "black" }}
              data-keyReference={`${keyReference}.${key}`}
              data-keyName={key}
            >{`"${key}": [`}</span>
            {value.map((item, index) => {
              const itemUniqueId = `${uniqueId}-${index}`;
              return (
                <div
                  key={itemUniqueId}
                  id={itemUniqueId}
                  style={{ marginLeft: `${(level + 1) * 12}px` }}
                >
                  {typeof item === "object" && item !== null ? (
                    <div key={uniqueId} id={uniqueId} style={indentation}>
                      <div>
                        <span
                          style={{ color: "black" }}
                          id={`${keyReference}.${key}`}
                        >
                          {`{`}
                        </span>
                      </div>
                      {renderJson(
                        item,
                        itemUniqueId,
                        level + 1,
                        `${keyReference}.${key}[${index}]`
                      )}
                      <div>
                        <span>{"},"}</span>
                      </div>
                    </div>
                  ) : (
                    addKeyValue(
                      index,
                      item,
                      itemUniqueId,
                      true,
                      `${keyReference}[${key}]`
                    )
                  )}
                </div>
              );
            })}
            <div style={indentation}>
              <span>]</span>
            </div>
          </div>
        );
      } else if (typeof value === "object" && value !== null) {
        elements.push(
          <div key={uniqueId} id={uniqueId} style={indentation}>
            {!Array.isArray(json) ? (
              <div>
                <span
                  style={{ color: "black" }}
                  data-keyReference={
                    Array.isArray(json)
                      ? `${keyReference}[${key}]`
                      : `${keyReference}.${key}`
                  }
                  data-keyName={key}
                >
                  {`"${key}": {`}
                </span>
              </div>
            ) : (
              <span>{"{"}</span>
            )}

            {renderJson(
              value,
              uniqueId,
              level + 1,
              Array.isArray(json)
                ? `${keyReference}[${key}]`
                : `${keyReference}.${key}`
            )}
            <div>
              <span>{"},"}</span>
            </div>
          </div>
        );
      } else {
        elements.push(
          addKeyValue(key, value, uniqueId, false, `${keyReference}.${key}`)
        );
      }
    }

    return elements;
  };

  return (
    <div className={className} onClick={handleOnClick}>
      <div>{Array.isArray(json) ? "[" : "{"}</div>
      {renderJson(json)}
      <div>{Array.isArray(json) ? "]" : "}"}</div>
    </div>
  );
};

CustomJsonRenderer.propTypes = {
  json: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleOnClick: PropTypes.func,
};

CustomJsonRenderer.defaultProps = {
  handleOnClick: () => {},
};

export default CustomJsonRenderer;
