import {
  Box,
  Button,
  Checkbox,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import {
  getDataSetCategory as getDataSetCategoryApi,
  getStatusValues as getStatusValuesApi,
  getUniqueExtensions as getUniqueExtensionsApi,
  transcriptAndTranslateMedia as transcriptAndTranslateMediaApi,
  preProcessBeforeTranscription as preProcessBeforeTranscriptionApi,
  quesAndAnsProcessCsv,
} from "services";
import getMedia from "services/getMedia";
import { useEffect, useState } from "react";
import { Loader, XCircle } from "react-feather";
import {
  CaretDoubleUp,
  CheckFat,
  EggCrack,
  IconUpload2,
  UploadCloudWhite,
} from "components/SVG";
import { useParams } from "react-router-dom";
import CategoryModal from "../components/CategoryModal";
import { useUserContext } from "context/user";
import { useFileUpload } from "custom-hooks/useFileUpload";
import theme from "theme";
import { useURLState } from "custom-hooks/useUrlState";
import { useSkillrToast } from "context/toast";
import generateQuestions from "services/generateQuestions";
import FundamentoTable from "components/FundamentoTable";
import FundamentoPopup from "components/FundamentoPopup";
import { ChevronDown } from "react-feather";
import { IconCirclePlus, IconDownload } from "components/SVG";
import { Sparkle } from "components/newSVG";
import EmptyState from "components/EmptyState";
import FlexBox from "components/FlexBox";
import { getJSONDecoded } from "utils/utils";
import { useAdminSocket } from "context/socket";
import { sampleCSVLink } from "text-constants/common";
import { FolderCopyOutlined } from "@mui/icons-material";

const otherFileTypes = [
  "audio/wav",
  "audio/mpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const csvFileTypes = ["text/csv"];

const Files = () => {
  const params = useParams();
  const dataSetId = params.dataSetId;
  const { companyDetails, getCompanyDetails } = useUserContext();
  const [mediaInfo, setMediaInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState();
  const [supportedFileTypes, setSupportedFileTypes] = useState(otherFileTypes);
  const {
    fileInputRef,
    handleFileChange,
    triggerFileInput,
    uploadStatus,
    uploadFiles,
    cancelFileUpload,
  } = useFileUpload({
    fileTypes: supportedFileTypes,
    maxUploadLimit: companyDetails?.maxStorageCapacityInMB,
    sizeAlreadyConsumed: companyDetails?.usedStorageCapacity,
    additionalProcessing: additionalProcessing,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { showSkillrToast } = useSkillrToast();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [categoryList, setCategoryList] = useState([]);
  const [uniqueExtensions, setUniqueExtensions] = useState([]);
  const [fileStatus, setFileStatus] = useState([]);
  const [categoryFilter, setCategoryFilter] = useURLState([], "category");
  const [extensionFilter, setExtensionFilter] = useURLState([], "fileType");
  const [statusFilter, setStatusFilter] = useURLState([], "status");
  const [selectedAll, setSelectedAll] = useState(false);
  const [csvUploadStatus, setCsvUploadStatus] = useState();
  const token = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(token ?? null);
  const companyInfo = tokenInfo?.companies?.[0];
  const socket = useAdminSocket(tokenInfo?.companies?.[0]?.id);

  const handleHeaderCheckbox = (event) => {
    if (event.target.checked) {
      const rowIds = mediaInfo?.medias?.map((media) => media._id);
      setSelectedFiles(rowIds);
      setSelectedAll(true);
    } else {
      setSelectedFiles([]);
      setSelectedAll(false);
    }
  };

  const columns = [
    { field: "id", header: "" },
    {
      field: "check",
      header: (
        <Checkbox
          style={{ padding: 0 }}
          checked={
            (selectedFiles.length > 0 &&
              selectedFiles.length === mediaInfo?.medias.length) ||
            selectedAll
          }
          indeterminate={
            selectedFiles.length > 0 &&
            selectedFiles.length !== mediaInfo?.medias.length
          }
          onChange={handleHeaderCheckbox}
          // icon={getHeaderCheckBoxIcon()}
          // checkedIcon={getHeaderCheckBoxIcon()}
        />
      ),
      render: (row) => {
        return selectedAll ? (
          <Checkbox style={{ padding: 0 }} checked={true} />
        ) : (
          <Tooltip
            title={row?.status === "Processing" ? "File is processing" : ""}
          >
            <div>
              {selectedAll ? (
                <Checkbox style={{ padding: 0 }} checked={true} />
              ) : (
                <Checkbox
                  disabled={row?.status === "Processing"}
                  checked={selectedFiles.includes(row.id)}
                  style={{ padding: 0 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedFiles([...selectedFiles, row.id]);
                    } else {
                      setSelectedFiles(
                        selectedFiles.filter((item) => item !== row.id)
                      );
                    }
                  }}
                />
              )}
            </div>
          </Tooltip>
        );
      },
      style: { width: 40 },
    },
    {
      field: "file",
      header: "File",
      render: (row) => (
        <Box
          sx={{
            "& p": {
              maxWidth: "60ch",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          }}
        >
          {row.file}
        </Box>
      ),
    },
    { field: "category", header: "Category", style: { width: "15%" } },
    {
      field: "size",
      header: "Size",
      style: { width: "10%" },
      render: (rowInfo) => {
        const oneMb = 1024 * 1024;
        let fileSize;
        if (rowInfo.size >= oneMb) {
          fileSize = (rowInfo.size / oneMb).toFixed(2) + " MB";
        } else {
          fileSize = (rowInfo.size / 1024).toFixed(2) + " KB";
        }
        return <Typography variant="body2">{fileSize}</Typography>;
      },
    },
    { field: "status", header: "Status", style: { width: "15%" } },
  ];

  const getExtension = (extension) => {
    switch (extension) {
      case "pdf":
        return (
          <Typography variant="caption" fontWeight={500} color="#eb5757">
            pdf
          </Typography>
        );
      case "wav":
        return (
          <Typography variant="caption" fontWeight={500} color="#4B56D2">
            wav
          </Typography>
        );
      case "mp3":
        return (
          <Typography variant="caption" fontWeight={500} color="#FF3EB9">
            mp3
          </Typography>
        );
      case "mp4":
        return (
          <Typography variant="caption" fontWeight={500} color="#E99A01">
            mp4
          </Typography>
        );
      case "csv":
        return (
          <Typography variant="caption" fontWeight={500} color="#00D087">
            csv
          </Typography>
        );
      default:
        return (
          <Typography variant="caption" fontWeight={500}>
            {extension}
          </Typography>
        );
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "processed":
        return "Processed";
      case "processing":
        return "Processing";
      case "answersGenerated":
        return "Answers generated";
      case "uploaded":
        return "Uploaded";
      case "trained":
        return "Trained";
      case "questionsGenerated":
        return "Questions generated";
      default:
        return status;
    }
  };

  const sendQuestionstoGenerate = async () => {
    try {
      if (selectedFiles?.length > 0 || selectedAll) {
        const urlParams = new URLSearchParams(window.location.search);
        await generateQuestions(dataSetId, urlParams.toString(), {
          mediaIds: selectedFiles,
          hasSelectedAll: selectedAll,
        });
        setSelectedFiles([]);
        setSelectedAll(false);
        showSkillrToast("Generating questions", "success");
      } else {
        showSkillrToast("Select file to generate questions", "error");
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";

      if (error?.response.status === 500) {
        showSkillrToast(
          "Can only generate questions for 'Processed' files",
          "error"
        );
      } else {
        showSkillrToast(errorMessage, error);
      }
    }
  };

  const updateTableData = (media) => {
    const newData = media?.map((item) => {
      return {
        id: item?._id,
        file: (
          <Box display="flex" alignItems="center" columnGap={3}>
            {getExtension(item?.extension)}

            <Typography variant="body2">{item?.name}</Typography>
          </Box>
        ),
        category: item?.category?.name,
        size: item?.size,
        status: getStatus(item?.status),
      };
    });
    setTableData(newData);
  };

  const getUploadIcon = () => {
    if (
      uploadStatus?.status === "started" ||
      uploadStatus?.status === "processing"
    ) {
      return <CaretDoubleUp />;
    } else if (uploadStatus.status === "storageLimitError") {
      return <EggCrack />;
    } else if (uploadStatus.status === "completed") {
      return <CheckFat />;
    } else {
      return <UploadCloudWhite />;
    }
  };

  const getUploadText = () => {
    let title;
    let subtitle;
    if (
      uploadStatus?.status === "started" ||
      uploadStatus?.status === "processing"
    ) {
      title = "Your files are uploading";
      // subtitle = "";
      subtitle = `${uploadStatus?.processedFiles} of ${
        uploadStatus?.totalFiles
      } ${uploadStatus?.totalFiles > 1 ? "files are" : "file is"} processed`;
    } else if (uploadStatus.status === "completed") {
      title = `All ${uploadStatus.totalFiles} files have been uploaded successfully!`;
      subtitle = "You can now check you dataset";
    } else if (uploadStatus.status === "storageLimitError") {
      title = "File upload failed due to storage limit";
      subtitle = "You can try uploading the files again upto 500 mb";
    } else if (uploadStatus.status === "error") {
      title = "Something went wrong";
      subtitle = "Please try again";
    } else {
      title = "Select files to upload from computer";
      subtitle = "You can upload mp3 · mp4 · wav · pdf";
    }
    return (
      <>
        <Typography variant="subtitle1" fontWeight={500}>
          {title}
        </Typography>
        <Typography variant="body2" color={"grey.400"}>
          {subtitle}
        </Typography>
      </>
    );
  };

  const showCategoryModal = () => {
    setCategoryModal(true);
  };

  // const processAudioFile = async (file) => {
  //   try {
  //     const gcsUri = `gs://${process.env.REACT_APP_GCS_BUCKET_NAME}/${companyInfo.id}/${file.newFileName}`;
  //     const apiData = {
  //       gcsUri,
  //       dataSetId,
  //       categoryInfo,
  //       fileSize: file.size,
  //     };
  //     const response = await transcriptAndTranslateMediaApi(apiData);
  //     return response;
  //   } catch (error) {
  //     const errorMessage = error?.message
  //       ? error.message
  //       : "Something went wrong";
  //     showSkillrToast(errorMessage, "error");
  //   }
  // };

  async function additionalProcessing(info) {
    await preProcessBeforeTranscription([info?.file]);
  }

  const preProcessBeforeTranscription = async (files) => {
    try {
      const apiData = files.map((file) => ({
        dataSetId,
        category: categoryInfo,
        gcsUri: `gs://${process.env.REACT_APP_GCS_BUCKET_NAME}/${companyInfo.id}/${file.newFileName}`,
        name: file.newFileName,
        size: file.size,
        extension: file.extension,
      }));
      const response = await preProcessBeforeTranscriptionApi(apiData);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const processCsvFile = async (file) => {
    try {
      const apiData = {
        dataSetId,
        gcsUri: `gs://${process.env.REACT_APP_GCS_BUCKET_NAME}/${companyInfo.id}/${file.newFileName}`,
        fileSize: file.size,
      };
      const response = await quesAndAnsProcessCsv(apiData);
      showSkillrToast(
        <>
          Question created {response?.questionsCreated} <br />
          Question deleted {response?.questionsDeleted} <br />
          Question updated {response?.questionsUpdated}
        </>,
        "success"
      );
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const trackUploadStatus = async () => {
    if (
      uploadStatus?.status === "filesValidated" &&
      !categoryInfo &&
      supportedFileTypes.length === otherFileTypes.length
    ) {
      // trigger the category selection modal
      showCategoryModal();
    }
    if (
      uploadStatus?.status === "filesValidated" &&
      uploadStatus?.files?.length > 0 &&
      (categoryInfo || supportedFileTypes.length === csvFileTypes.length)
    ) {
      uploadFiles(uploadStatus?.files);
    }

    // Handling other files when all files get uploaded to bucket and pre processed
    if (
      uploadStatus?.status === "completed" &&
      categoryInfo &&
      supportedFileTypes.length === otherFileTypes.length
    ) {
      fileUploadedSuccessfully();
    }

    // Handling csv upload when all files get uploaded to bucket
    if (
      uploadStatus?.status === "completed" &&
      supportedFileTypes.length === csvFileTypes.length
    ) {
      for (let file of uploadStatus?.files) {
        if (csvFileTypes.indexOf(file.type) > -1) {
          processCsvFile(file);
        }
      }
      fileUploadedSuccessfully();
    }
  };

  const handleBeforeUnload = (event) => {
    if (["started", "processing"].indexOf(uploadStatus?.status) > -1) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  const getFilesOfCompany = async () => {
    try {
      setLoading(true);
      const urlParams = new URLSearchParams();
      urlParams.set("page", currentPage);
      urlParams.set("categories", categoryFilter);
      urlParams.set("extensions", extensionFilter);
      urlParams.set("status", statusFilter);
      const response = await getMedia(dataSetId, urlParams.toString());
      if (response) {
        setMediaInfo(response);
        updateTableData(response?.medias);
        setSelectedFiles([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onSaveCategory = (info) => {
    setCategoryInfo(info);
    setCategoryModal(false);
  };

  const onCancelCategory = () => {
    setCategoryModal(false);
    cancelFileUpload();
  };

  const fileUploadedSuccessfully = () => {
    getFilesOfCompany();
    setCategoryInfo();
    getCompanyDetails();
    setCategoryModal(false);
  };

  const handleUploadFiles = () => {
    fileInputRef.current.accept = otherFileTypes.join(",");
    fileInputRef.current.setAttribute("multiple", "multiple");
    triggerFileInput();
    setSupportedFileTypes(otherFileTypes);
  };

  const handleUploadCsvFiles = () => {
    fileInputRef.current.accept = csvFileTypes.join(",");
    fileInputRef.current.removeAttribute("multiple");
    triggerFileInput();
    setSupportedFileTypes(csvFileTypes);
  };

  const handleTablePageChange = (page) => {
    setCurrentPage(page);
  };

  const getDataSetCategory = async () => {
    try {
      const response = await getDataSetCategoryApi(dataSetId);
      setCategoryList(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const getUniqueExtensions = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.set("dataSetId", dataSetId);
      const response = await getUniqueExtensionsApi(queryParams.toString());
      setUniqueExtensions(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const getFileStatus = async () => {
    try {
      const response = await getStatusValuesApi();
      setFileStatus(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const handleSelectedCategory = (e) => {
    const selectedCategory = e.target.value;
    const newCategoryFilter = [...categoryFilter];
    const index = categoryFilter.indexOf(selectedCategory);
    if (index > -1) {
      newCategoryFilter.splice(index, 1);
    } else {
      newCategoryFilter.push(selectedCategory);
    }
    setCategoryFilter(newCategoryFilter);
  };

  const handleSelectedFileType = (e) => {
    const selectedFileType = e.target.value;
    const newExtensionFilter = [...extensionFilter];
    const index = extensionFilter.indexOf(selectedFileType);
    if (index > -1) {
      newExtensionFilter.splice(index, 1);
    } else {
      newExtensionFilter.push(selectedFileType);
    }
    setExtensionFilter(newExtensionFilter);
  };

  const handleSelectedStatus = (e) => {
    const selectedStatus = e.target.value;
    const newStatusFilter = [...statusFilter];
    const index = statusFilter.indexOf(selectedStatus);
    if (index > -1) {
      newStatusFilter.splice(index, 1);
    } else {
      newStatusFilter.push(selectedStatus);
    }
    setStatusFilter(newStatusFilter);
  };

  const updateMediaBySocket = (updatedMediaInfo) => {
    if (updatedMediaInfo.length > 0) {
      setMediaInfo((prevMedia) => {
        const newMedias = prevMedia?.medias.map((m) => {
          const updatedMedia = updatedMediaInfo.find((d) => d._id === m._id);
          return updatedMedia ? { ...m, status: updatedMedia.status } : m;
        });
        updateTableData(newMedias);
        return { ...prevMedia, medias: newMedias };
      });
    }
  };

  const handleCsvDuplicationCheck = (csvUploadData) => {
    setCsvUploadStatus(csvUploadData);
  };

  const handleErrorMessage = (error) => {
    const errorMessage = error?.message
      ? error.messsage
      : "Something went wrong";
    showSkillrToast(errorMessage, "error");
  };

  const handleSocketUpdate = () => {
    socket.on("media-processed", (data) => {
      try {
        updateMediaBySocket(data);
      } catch (error) {
        handleErrorMessage(error);
      }
    });

    socket.on("media-questions-generated", (data) => {
      try {
        updateMediaBySocket(data);
      } catch (error) {
        handleErrorMessage(error);
      }
    });

    socket.on("duplicate-check-status", (data) => {
      try {
        if (data?.dataSetId === dataSetId) {
          handleCsvDuplicationCheck(data);
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    });
  };

  const clearFilters = () => {
    setCategoryFilter([]);
    setExtensionFilter([]);
    setStatusFilter([]);
  };

  useEffect(() => {
    getDataSetCategory();
    getUniqueExtensions();
    getFileStatus();
    handleSocketUpdate();
  }, []);

  useEffect(() => {
    getFilesOfCompany();
  }, [currentPage, categoryFilter, extensionFilter, statusFilter]);

  useEffect(() => {
    trackUploadStatus();
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploadStatus, categoryInfo]);

  return (
    <>
      {/* uploading alert */}
      {tableData?.length > 0 &&
        ["started", "processing"].indexOf(uploadStatus?.status) > -1 && (
          <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
            <Box className={styles.uploadAlert}>
              <Box className={styles.iconContainer}>
                <CaretDoubleUp width={16} height={16} />
              </Box>

              <Typography variant="body1" fontWeight={500}>
                Uploading files {uploadStatus?.processedFiles} of{" "}
                {uploadStatus?.totalFiles}
              </Typography>
            </Box>
          </Fade>
        )}

      {csvUploadStatus &&
        csvUploadStatus?.processed !== csvUploadStatus?.total && (
          <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
            <Box
              className={styles.csvDuplicateAlert}
              sx={{
                "&:after": {
                  width: `${Math.floor(
                    (csvUploadStatus?.processed / csvUploadStatus?.total) * 100
                  )}% !important`,
                },
              }}
            >
              <Box className={styles.iconContainer}>
                <FolderCopyOutlined
                  width={16}
                  height={16}
                  style={{ fill: "#fff" }}
                />
              </Box>

              <Typography variant="body1" fontWeight={500}>
                Checking duplicate in csv {csvUploadStatus?.processed} of{" "}
                {csvUploadStatus?.total}
              </Typography>
            </Box>
          </Fade>
        )}

      {(categoryFilter ||
        extensionFilter ||
        statusFilter ||
        mediaInfo?.medias?.length > 0) && (
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          marginBottom={5}
        >
          <Box display="flex" columnGap={2}>
            <FundamentoPopup
              disableCloseOnClick={true}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              triggeringComponent={
                <Button
                  variant="outlined"
                  className={categoryFilter.length > 0 && styles.active}
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Category
                </Button>
              }
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 12,
                }}
              >
                {categoryList?.map((item) => (
                  <Box
                    columnGap={2}
                    display="flex"
                    alignItems="center"
                    component={"label"}
                  >
                    <Checkbox
                      value={item?._id}
                      onChange={(e) => handleSelectedCategory(e)}
                      checked={categoryFilter.indexOf(item?._id) > -1}
                    />
                    <Typography variant="body2">{item.name}</Typography>
                  </Box>
                ))}
              </Box>
            </FundamentoPopup>
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              disableCloseOnClick={true}
              triggeringComponent={
                <Button
                  variant="outlined"
                  className={extensionFilter?.length > 0 && styles.active}
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  File Type
                </Button>
              }
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 12,
                }}
              >
                {uniqueExtensions?.map((item) => (
                  <Box
                    columnGap={2}
                    display="flex"
                    alignItems="center"
                    component={"label"}
                  >
                    <Checkbox
                      // icon={<Circle />}
                      // checkedIcon={<CheckCircle />}
                      value={item}
                      onChange={(e) => handleSelectedFileType(e)}
                      checked={extensionFilter.indexOf(item) > -1}
                    />
                    <Typography variant="body2">{item}</Typography>
                  </Box>
                ))}
              </Box>
            </FundamentoPopup>
            <FundamentoPopup
              disableCloseOnClick={true}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              triggeringComponent={
                <Button
                  variant="outlined"
                  className={statusFilter.length > 0 && styles.active}
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Status
                </Button>
              }
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 12,
                }}
              >
                {fileStatus?.map((item) => (
                  <Box
                    columnGap={2}
                    display="flex"
                    alignItems="center"
                    component={"label"}
                  >
                    <Checkbox
                      // icon={<Circle />}
                      // checkedIcon={<CheckCircle />}
                      value={item}
                      onChange={(e) => handleSelectedStatus(e)}
                      checked={statusFilter.indexOf(item) > -1}
                    />
                    <Typography variant="body2">{getStatus(item)}</Typography>
                  </Box>
                ))}
              </Box>
            </FundamentoPopup>

            {(categoryFilter.length > 0 ||
              extensionFilter.length > 0 ||
              statusFilter.length > 0) && (
              <Tooltip title="Clear filters">
                <IconButton onClick={clearFilters}>
                  <XCircle width={18} height={18} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <FlexBox display="flex">
            <FundamentoPopup
              triggeringComponent={
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Actions
                </Button>
              }
              className={styles.popup}
            >
              <Box>
                <Box className={styles.popupItem} onClick={handleUploadFiles}>
                  <IconCirclePlus width={16} height={16} />
                  <Typography variant="body2">Upload files</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={handleUploadCsvFiles}
                >
                  <IconCirclePlus width={16} height={16} />
                  <Typography variant="body2">Upload csv</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  component={"a"}
                  href={sampleCSVLink}
                  download
                  sx={{ color: theme.palette.text.primary }}
                >
                  <IconDownload width={16} height={16} />
                  <Typography variant="body2">Download sample csv</Typography>
                </Box>
              </Box>
            </FundamentoPopup>
            <Button
              variant="dark"
              disabled={selectedFiles?.length === 0 && !selectedAll}
              style={{
                padding: "6px 14px",
                lineHeight: "24px",
                fontSize: 12,
                borderRadius: 82,
              }}
              onClick={() => sendQuestionstoGenerate()}
              startIcon={<Sparkle width={16} height={16} />}
            >
              Generate Questions
            </Button>
          </FlexBox>
        </Box>
      )}

      {loading && (
        <Box className={styles.emptyContainer}>
          <Loader color={theme.palette.grey[400]} width={40} height={40} />
        </Box>
      )}

      {!loading && mediaInfo?.medias?.length > 0 && (
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            "& thead": {
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 1000,
            },
          }}
        >
          <FundamentoTable
            columns={columns}
            data={tableData}
            passRowToRender={true}
            pagination={mediaInfo?.totalMediaCount > 10}
            totalPageCount={Math.ceil(mediaInfo?.totalMediaCount / 10)}
            currentPage={currentPage}
            onPageChange={handleTablePageChange}
          />
        </Box>
      )}

      {!loading &&
        mediaInfo?.medias?.length === 0 &&
        (categoryFilter.length > 0 ||
          extensionFilter.length > 0 ||
          statusFilter.length > 0) && (
          <EmptyState title="No File Found" style={{ height: "50%" }} />
        )}

      {!loading &&
        mediaInfo?.medias?.length === 0 &&
        categoryFilter.length === 0 &&
        extensionFilter.length === 0 &&
        statusFilter.length === 0 && (
          <Box width={"80%"} className={styles.fileUploadContainer}>
            <>
              <Box className={styles.uploadContainer}>
                <Box className={styles.uploadContent}>
                  <Box
                    className={`${styles.uploadIcon} ${
                      ["error", "storageLimitError"].indexOf(
                        uploadStatus.status
                      ) > -1 && styles.errorState
                    } ${
                      uploadStatus.status === "processing" ||
                      uploadStatus.status === "started"
                        ? styles.animate
                        : ""
                    }`}
                  >
                    {getUploadIcon()}
                  </Box>
                  <Box sx={{ zIndex: 1 }}>
                    {getUploadText()}

                    {(!uploadStatus?.status ||
                      [
                        "completed",
                        "storageLimitError",
                        "error",
                        "filesValidated",
                      ].indexOf(uploadStatus.status) > -1) && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 4 }}
                        mt={4}
                      >
                        <Button
                          startIcon={<IconUpload2 color={"grey.600"} />}
                          variant="outlined"
                          sx={{ borderRadius: 82 }}
                          onClick={handleUploadFiles}
                        >
                          Upload Files
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          </Box>
        )}

      <CategoryModal
        show={categoryModal}
        onClose={onCancelCategory}
        onSave={onSaveCategory}
        dataSetId={dataSetId}
      />

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={supportedFileTypes.join(",")}
      />
    </>
  );
};

export default Files;
