import { Box, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./index.module.css";
import getMedia from "services/getMedia";
import { useEffect, useState } from "react";
import { useSkillrToast } from "context/toast";
import { Loader } from "react-feather";
import theme from "theme";
import DatasetDashboardContextProvider from "context/datasetDashboard";
import { useAdminSocket } from "context/socket";
import { getJSONDecoded } from "utils/utils";

const DatasetDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [showTabs, setShowTabs] = useState(true);
  const currentRoute = location.pathname.split("/")[3];
  const [loading, setLoading] = useState(true);
  const [dataSetInfo, setDataSetInfo] = useState();
  const { showSkillrToast } = useSkillrToast();
  const params = useParams();
  const dataSetId = params.dataSetId;
  const token = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(token ?? null);
  const socket = useAdminSocket(tokenInfo?.companies?.[0]?.id, "parent");

  const getFilesOfCompany = async () => {
    try {
      setLoading(true);
      const response = await getMedia(dataSetId);
      setDataSetInfo(response);
      if (response?.answersTotalCount > 0) {
        navigate(`answers`);
      } else if (response?.questionsTotalCount > 0) {
        navigate(`questions`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const handleErrorMessage = (error) => {
    const errorMessage = error?.message
      ? error.messsage
      : "Something went wrong";
    showSkillrToast(errorMessage, "error");
  };

  const handleSocketUpdate = () => {
    socket.on("media-count", (data) => {
      try {
        if (data?.dataSetId === dataSetId) {
          setDataSetInfo((prevData) => {
            return { ...prevData, ...data };
          });
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    });
  };

  useEffect(() => {
    getFilesOfCompany();
    handleSocketUpdate();
  }, []);

  return (
    <DatasetDashboardContextProvider>
      <PageWrapper className={styles.container}>
        {loading && (
          <Box className={styles.emptyContainer}>
            <Loader color={theme.palette.grey[400]} width={40} height={40} />
          </Box>
        )}

        {!loading && (
          <>
            <Box className={styles.tabsContainer}>
              <Box
                className={`${styles.tab} ${
                  currentRoute === "files" ? styles.active : ""
                }`}
                onClick={() => navigate(`files`)}
              >
                <Typography variant="body2" fontWeight={500} color="grey.600">
                  {`Files Uploaded ${dataSetInfo?.totalMediaCount || 0}`}
                </Typography>
              </Box>
              <Box
                className={`${styles.tab} ${
                  currentRoute === "questions" ? styles.active : ""
                }`}
                onClick={() => navigate(`questions`)}
              >
                <Typography variant="body2" fontWeight={500} color="grey.600">
                  {`Questions ${dataSetInfo?.questionsTotalCount || 0}`}
                </Typography>
              </Box>
              <Box
                className={`${styles.tab} ${
                  currentRoute === "answers" ? styles.active : ""
                }`}
                onClick={() => navigate(`answers`)}
              >
                <Typography variant="body2" fontWeight={500} color="grey.600">
                  {`Answers ${dataSetInfo?.answersTotalCount || 0}`}
                </Typography>
              </Box>
            </Box>

            <Outlet />
          </>
        )}
      </PageWrapper>
    </DatasetDashboardContextProvider>
  );
};

export default DatasetDashboard;
