import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { ChevronRight, X } from "react-feather";
import FlexBox from "components/FlexBox";
import { useEffect, useState } from "react";

const CreateDictionaryModal = ({
  show,
  onClose,
  onConfirm,
  defaultValue,
  editMode,
}) => {
  const [chipData, setChipData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [title, setTitle] = useState("");
  const [finalData, setFinalData] = useState({});

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  useEffect(() => {
    if (defaultValue && defaultValue.value) {
      setFinalData({ id: defaultValue.id });
      setTitle(defaultValue.key);
      setChipData(defaultValue.value);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (title !== defaultValue.key) {
      setFinalData({ ...finalData, key: title });
    }
  }, [title]);

  useEffect(() => {
    if (chipData !== defaultValue.value) {
      setFinalData({ ...finalData, value: chipData });
    }
  }, [chipData]);

  return (
    <Dialog
      open={show}
      onClose={() => {
        onClose();
        setTitle("");
        setChipData([]);
      }}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 650,
          borderRadius: 20,
        },
      }}
    >
      <Box className={styles.container}>
        <IconButton
          className={styles.close}
          onClick={() => {
            setTitle("");
            setChipData([]);
            onClose();
          }}
        >
          <X width={20} height={20} />
        </IconButton>
        <Typography variant="h3" textAlign={"center"} fontWeight={500}>
          New synonym group
        </Typography>

        <Box mt={8}>
          <Typography
            variant="caption"
            fontWeight={500}
            color="text.primary"
            style={{ paddingLeft: 14 }}
          >
            Root *
          </Typography>
          <TextField
            fullWidth
            mt={2}
            sx={{ "& div": { borderRadius: "82px" } }}
            value={title}
            required
            onChange={(event) => setTitle(event.target.value)}
            placeholder="Enter title here"
          />
        </Box>

        <Box mt={5}>
          <Typography
            variant="caption"
            fontWeight={500}
            style={{ paddingLeft: 14 }}
            color="text.primary"
          >
            Synonyms *
          </Typography>

          <Autocomplete
            multiple
            selectOnFocus={false}
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            autoSelect={true}
            renderTags={(tagValue) => (
              <FlexBox flexWrap="wrap" sx={{ gap: 1 }}>
                {chipData.map((chip, index) => (
                  <Chip
                    key={index}
                    label={chip}
                    className={styles.chip}
                    onDelete={handleDelete(chip)}
                    deleteIcon={<X style={{ height: 16, width: 16 }} />}
                  />
                ))}
              </FlexBox>
            )}
            onChange={(event, newValue) => {
              if (newValue.length > chipData.length) {
                const newChip = newValue[newValue.length - 1];
                setChipData([...chipData, newChip]);
              } else {
                const chipToDelete = chipData.find(
                  (chip) => !newValue.includes(chip)
                );
                setChipData(chipData.filter((chip) => chip !== chipToDelete));
              }
            }}
            inputValue={inputValue}
            value={chipData}
            options={[]}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    "& > div": {
                      padding: 4,
                      borderRadius: "20px",
                    },
                  }}
                  variant="outlined"
                  placeholder="press enter to add"
                />
              );
            }}
          />
        </Box>

        <FlexBox justifyContent="center" mt={8}>
          <Button
            variant="outlined"
            className={styles.modalButton}
            onClick={() => {
              setTitle("");
              setChipData([]);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            disabled={title === "" || chipData?.length === 0}
            endIcon={<ChevronRight width={16} height={16} />}
            onClick={() => {
              editMode
                ? onConfirm(finalData)
                : onConfirm({
                    key: title,
                    value: chipData,
                  });
              setTitle("");
              setChipData([]);
            }}
          >
            {editMode ? "Save changes" : "Create group"}
          </Button>
        </FlexBox>
      </Box>
    </Dialog>
  );
};

export default CreateDictionaryModal;
