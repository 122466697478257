import { useUserContext } from "context/user";
import AgentLogin from "pages/Agent/LogIn";
import LoginRequired from "pages/LoginRequired";
import PropsTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { getJSONDecoded } from "utils/utils";

const AgentRoute = ({ children }) => {
  const { setToken } = useUserContext();
  const [params] = useSearchParams();
  const token = params.get("token")
    ? params.get("token")
    : localStorage.getItem("agentToken");
  const tokenInfo = getJSONDecoded(token ? token : null);

  if (tokenInfo && tokenInfo.exp * 1000 < Date.now()) {
    localStorage.removeItem("agentToken");
    return <AgentLogin />;
  } else if (token) {
    localStorage.setItem("agentToken", token);
    setTimeout(() => setToken(token));
    return children;
  } else {
    return <AgentLogin />;
  }
};

AgentRoute.propTypes = {
  children: PropsTypes.any,
  redirect: PropsTypes.string,
};

AgentRoute.defaultProps = {
  redirect: "/",
};

export default AgentRoute;
