import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  Grow,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { emailRegEx } from "utils/utils";
import { ChevronRight, RedAlert } from "components/SVG";
import theme from "theme";
import { useEffect } from "react";
import AutoComplete from "components/AutoComplete";
import { useSkillrToast } from "context/toast";
import FlexBox from "components/FlexBox";
import { X } from "react-feather";

const AddUser = (props) => {
  const {
    show,
    onClose,
    add,
    roles,
    teams,
    createNewUser,
    editUserDetails,
    updateUserDetails,
    getAllUsers,
    createNewTeam,
  } = props;
  const { showSkillrToast } = useSkillrToast();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      team: "",
      role: "",
    },
  });

  const getDisabledStateForName = () => {
    let disableNameForManagerRole = false;
    if (!editUserDetails) {
      disableNameForManagerRole =
        roles.find((role) => role.role.toLowerCase() === "manager")?._id ===
        watch("role");
    }

    return disableNameForManagerRole;
  };

  const handleCreateNewUser = async (newUserInfo) => {
    /* If new team is getting created using drop down then we need to do below check */
    if (
      typeof newUserInfo?.team === "string" ||
      newUserInfo?.team?.inputValue
    ) {
      const newTeam = newUserInfo?.team?.inputValue
        ? newUserInfo?.team?.inputValue
        : newUserInfo?.team;
      const teamDetails = teams.find((t) => t.team === newTeam);
      newUserInfo.team = teamDetails?._id;
    } else {
      newUserInfo.team = newUserInfo?.team?._id;
    }
    if (getDisabledStateForName()) {
      newUserInfo.name = "";
    }
    onClose();
    try {
      await createNewUser(newUserInfo);
      getAllUsers();
      showSkillrToast("User invited successfully", "success");
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
    reset({
      name: "",
      email: "",
      team: "",
      role: "",
    });
  };

  const handleEditUser = async (updatedUserInfo) => {
    /* If new team is getting created using drop down then we need to do below check */
    if (
      typeof updatedUserInfo?.team === "string" ||
      updatedUserInfo?.team?.inputValue
    ) {
      const newTeam = updatedUserInfo?.team?.inputValue
        ? updatedUserInfo?.team?.inputValue
        : updatedUserInfo?.team;
      const teamDetails = teams.find((t) => t.team === newTeam);
      updatedUserInfo.team = teamDetails?._id;
    } else {
      updatedUserInfo.team = updatedUserInfo?.team?._id;
    }
    onClose();
    await updateUserDetails(updatedUserInfo);
    getAllUsers();
    reset({
      name: "",
      email: "",
      team: "",
      role: "",
    });
  };

  const handleCreateNewTeam = (teamInfo) => {
    if (typeof teamInfo === "string") {
      createNewTeam(teamInfo);
    } else if (teamInfo?.inputValue) {
      createNewTeam(teamInfo?.inputValue);
    }
  };

  const handleOnSubmit = (data) => {
    if (add) {
      handleCreateNewUser(data);
    } else {
      handleEditUser(data);
    }
  };

  const handleOnCancel = () => {
    reset({
      name: "",
      email: "",
      team: "",
      role: "",
    });
    onClose();
  };

  const populateUserDetails = () => {
    reset({
      name: editUserDetails?.name,
      email: editUserDetails?.email,
      role: editUserDetails?.role,
      team: editUserDetails?.team,
    });
  };

  const handleOnClose = () => {
    reset({
      name: "",
      email: "",
      team: "",
      role: "",
    });
    onClose();
  };

  useEffect(() => {
    if (editUserDetails?.type === "edit") {
      populateUserDetails();
    }
  }, [editUserDetails]);

  return (
    <Dialog
      open={show}
      onClose={handleOnClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          width: 650,
          borderRadius: 20,
          // height: 400,
        },
      }}
    >
      <Box className={styles.modalContainer}>
        <FlexBox justifyContent="flex-end">
          <IconButton
            onClick={handleOnClose}
            style={{ position: "absolute", top: 20, right: 20 }}
          >
            <X width={20} height={20} />
          </IconButton>
        </FlexBox>
        <Typography
          textAlign="center"
          variant="h3"
          fontWeight={500}
          mb={8}
          className={styles.title}
        >
          {add ? "Add " : "Edit "}
          User
        </Typography>
        <Box className={styles.form}>
          <Box display="flex" gap={4} flexWrap="wrap">
            <Box flexGrow={0} flexBasis="48%">
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.grey[800],
                  padding: "0 14px",
                }}
              >
                User Email *
              </Typography>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "Email field is required",
                  pattern: {
                    value: emailRegEx,
                    message: "Please enter valid email id",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl sx={{ display: "flex", marginTop: "6px" }}>
                    <TextField
                      disabled={!add}
                      value={value}
                      onChange={onChange}
                      className={styles.formField}
                      helperText={
                        errors?.email && (
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <RedAlert style={{ marginRight: 8 }} />
                            {errors?.email?.message}
                          </Box>
                        )
                      }
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box flexGrow={0} flexBasis="48%">
              <Typography
                variant="caption"
                sx={{ color: theme.palette.grey[800], padding: "0 14px" }}
              >
                Name
              </Typography>
              <Controller
                control={control}
                name="name"
                // rules={{
                //   required: "Name is required",
                // }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControl sx={{ display: "flex", marginTop: "6px" }}>
                      <TextField
                        disabled={getDisabledStateForName()}
                        value={value}
                        onChange={onChange}
                        className={styles.formField}
                        helperText={
                          errors?.name && (
                            <Box
                              component="span"
                              display="flex"
                              alignItems="center"
                            >
                              <RedAlert style={{ marginRight: 8 }} />
                              {errors?.name?.message}
                            </Box>
                          )
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Box>

            <Box flexGrow={0} flexBasis="48%">
              <Typography
                variant="caption"
                sx={{ color: theme.palette.grey[800], padding: "0 14px" }}
              >
                Select Team
              </Typography>
              <Controller
                control={control}
                name="team"
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth sx={{ marginTop: "6px" }}>
                    <AutoComplete
                      renderOptionKey="team"
                      autoComplete
                      freeSolo
                      error={errors?.team}
                      optionList={teams}
                      onChange={(value) => {
                        onChange(value);
                        handleCreateNewTeam(value);
                      }}
                      value={value}
                      closeIcon={false}
                      ListboxProps={{ style: { maxHeight: "12rem" } }}
                      className={styles.formField}
                      PaperComponent={({ children }) => (
                        // <Box style={{ fontSize: 14, backgroundColor: "white" }}>
                        //   {children}
                        // </Box>
                        <Grow in={true} mountOnEnter unmountOnExit>
                          <div className={styles.autoCompleteList}>
                            {children}
                          </div>
                        </Grow>
                      )}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box flexGrow={0} flexBasis="48%">
              <Typography
                variant="caption"
                sx={{ color: theme.palette.grey[800], padding: "0 14px" }}
              >
                Permission *
              </Typography>
              <Controller
                control={control}
                name="role"
                rules={{
                  required: "Permission is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth sx={{ marginTop: "6px" }}>
                    {roles.find((role) => role?.role === "Admin")?._id ===
                    editUserDetails?.role ? (
                      <TextField
                        value="Admin"
                        disabled={true}
                        className={styles.formField}
                      />
                    ) : (
                      <Select
                        onChange={onChange}
                        value={value}
                        className={styles.formField}
                        disabled={editUserDetails}
                      >
                        {roles
                          ?.filter((role) => role?.role !== "Admin")
                          .map((role) => (
                            <MenuItem key={role?._id} value={role?._id}>
                              {role?.role}
                            </MenuItem>
                          ))}
                      </Select>
                    )}

                    {errors?.role && (
                      <FormHelperText>
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                        >
                          <RedAlert style={{ marginRight: 8 }} />
                          {errors?.role?.message}
                        </Box>
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
          </Box>

          <Box display="flex" gap={4} mt={8} justifyContent="center">
            <Button
              variant="outline"
              className={styles.cancelButton}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
            <Button
              variant="dark"
              endIcon={<ChevronRight />}
              onClick={handleSubmit(handleOnSubmit)}
            >
              {add ? "Add" : "Save Changes"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

AddUser.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  add: PropTypes.bool,
  roles: PropTypes.array,
  teams: PropTypes.array,
  createNewUser: PropTypes.func,
  updateUserDetails: PropTypes.func,
  getAllUsers: PropTypes.func,
  createNewTeam: PropTypes.func,
};

AddUser.defaultProps = {
  add: true,
};

export default AddUser;
