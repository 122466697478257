import {
  Box,
  Button,
  Dialog,
  Grow,
  IconButton,
  Radio,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import { FileText, RadioButton, SpeakerSimple } from "components/newSVG";
import { ChevronRight, Circle, X } from "react-feather";
import styles from "./index.module.css";
import { useState } from "react";
import { useSkillrToast } from "context/toast";

const GenerateQuestionsModal = ({ open, onClose, onSave }) => {
  const [mode, setMode] = useState("");
  const { showSkillrToast } = useSkillrToast();

  const handleSave = () => {
    if (mode === "") {
      showSkillrToast("Please select a mode to continue", "error");
      return;
    }
    onSave(mode);
    setMode("");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      TransitionComponent={Grow}
      PaperProps={{
        sx: {
          width: 650,
          borderRadius: 5,
          padding: 10,
          position: "relative",
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", right: 20, top: 20 }}
      >
        <X width={20} height={20} />
      </IconButton>

      <Typography align="center" variant="h3" fontWeight={500}>
        Generate Intents
      </Typography>
      <Typography align="center" variant="body2">
        Create Intents from unstuctured data sources
      </Typography>

      <FlexBox
        className={styles.optionContainer}
        onClick={() => setMode("calls")}
      >
        <Radio
          checked={mode === "calls"}
          onChange={() => setMode("calls")}
          checkedIcon={<RadioButton width={20} height={20} />}
          unCheckedIcon={<Circle width={20} height={20} />}
        />
        <SpeakerSimple width={32} height={32} />
        <Box>
          <Typography variant="body1" fontWeight={500}>
            Generate from Call Recordings
          </Typography>
          <Typography variant="body2" color="grey.600">
            Upload mp3 and wav files to generate intents
          </Typography>
        </Box>
      </FlexBox>
      <FlexBox
        className={styles.optionContainer}
        onClick={() => setMode("documents")}
      >
        <Radio
          checked={mode === "documents"}
          onChange={() => setMode("documents")}
          checkedIcon={<RadioButton width={20} height={20} />}
          unCheckedIcon={<Circle width={20} height={20} />}
        />
        <FileText width={32} height={32} />
        <Box>
          <Typography variant="body1" fontWeight={500}>
            Generate from Documents
          </Typography>
          <Typography variant="body2" color="grey.600">
            Upload pdf, doc and docx files to generate intents
          </Typography>
        </Box>
      </FlexBox>

      <FlexBox justifyContent="center" mt={8}>
        <Button
          variant="whiteRound"
          onClick={() => {
            onClose();
            setMode("");
          }}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          endIcon={<ChevronRight />}
          onClick={() => {
            handleSave();
          }}
        >
          Continue
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default GenerateQuestionsModal;
