import { Popover } from "@mui/material";
import { useState } from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types";

const FundamentoPopup = ({
  triggeringComponent,
  children,
  className,
  style,
  onParentClick,
  disabled,
  anchorOrigin,
  transformOrigin,
  disableCloseOnClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div onClick={onParentClick}>
      <div onClick={handleClick}>{triggeringComponent}</div>

      <Popover
        open={disabled ? false : open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: "bottom",
                horizontal: "right",
              }
        }
        transformOrigin={
          transformOrigin
            ? transformOrigin
            : {
                vertical: "top",
                horizontal: "right",
              }
        }
        slotProps={{
          paper: {
            className: `${styles.popoverContainer} ${className} `,
            style: style,
          },
        }}
      >
        <div onClick={() => !disableCloseOnClick && handleClose()}>
          {children}
        </div>
      </Popover>
    </div>
  );
};

FundamentoPopup.propTypes = {
  onParentClick: PropTypes.func,
};

FundamentoPopup.defaultProps = {
  onParentClick: () => {},
};

export default FundamentoPopup;
