import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import assetsAPI from "services/assetAPI";
import { Loader } from "react-feather";
import FlexBox from "components/FlexBox";
import theme from "theme";

const AudioPlayer = ({ controls, style, className, audioPath, onlyAudio }) => {
  const [loading, setLoading] = useState(true);
  const [signedUrl, setSignedUrl] = useState(null);
  const token = localStorage.getItem("token");
  const params = new URLSearchParams();
  const audioRef = useRef();
  params.set("authToken", token);

  useEffect(() => {
    (async () => {
      try {
        const signedURLResult = await assetsAPI(
          audioPath?.split("//")?.[1],
          params.toString()
        );
        // console.log("Signed URL Result:", signedURLResult);
        const url = signedURLResult?.url;
        setSignedUrl(url);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching signed URL:", error);
        setLoading(false);
      }
    })();
  }, [audioPath]);

  useEffect(() => {
    // set loading to false after 3 second
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (signedUrl && !loading && audioRef.current) {
      audioRef.current.load();
    }
  }, [signedUrl]);

  const fileType = audioPath?.split(".")?.pop();

  if (!signedUrl && loading) {
    return (
      <FlexBox>
        <Loader width={16} height={16} color={theme.palette.grey[600]} />
      </FlexBox>
    );
  }

  if (onlyAudio) {
    return signedUrl && !loading ? (
      <audio
        controls={controls}
        style={style}
        className={className}
        src={signedUrl}
        key={signedUrl}
        ref={audioRef}
      >
        Your browser does not support the audio element.
      </audio>
    ) : (
      loading && (
        <FlexBox>
          <Loader width={16} height={16} color={theme.palette.grey[600]} />
        </FlexBox>
      )
    );
  }

  return signedUrl && !loading ? (
    <audio
      ref={audioRef}
      controls={controls}
      style={style}
      className={className}
      key={signedUrl}
      src={signedUrl}
      type={`audio/${fileType}`}
    >
      {/* <source /> */}
      Your browser does not support the audio element.
    </audio>
  ) : (
    loading && (
      <FlexBox>
        <Loader width={16} height={16} color={theme.palette.grey[600]} />
      </FlexBox>
    )
  );
};

AudioPlayer.propTypes = {
  onPlay: PropTypes.func,
  onEnded: PropTypes.func,
  controls: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  audioPath: PropTypes.string,
};

AudioPlayer.defaultProps = {
  onPlay: () => {},
  onEnded: () => {},
};

export default AudioPlayer;
