import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import FundamentoTable from "components/FundamentoTable";
import moment from "moment";
import styles from "./index.module.css";
import { IconDownload } from "components/SVG";
import { CSV } from "components/newSVG";
import assetsAPI from "services/assetAPI";

const UploadCallDataList = (props) => {
  const { campaignDetails } = props;
  const token = localStorage.getItem("token");

  const handleDownload = async (url) => {
    try {
      const signedUrl = await assetsAPI(
        url?.split("//")?.[1],
        `authToken=${token}`
      );

      const link = document.createElement("a");
      link.href = signedUrl.url;
      link.download = url.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file", error);
    }
  };

  const columns = [
    {
      field: "fileName",
      header: "File",
      render: (row) => {
        return (
          <Box display="flex" alignItems="center">
            <CSV width={20} height={32} style={{ flexShrink: 0 }} />
            <Tooltip
              title={row.fileName?.length > 50 ? row.fileName : ""}
              placement="top"
            >
              <Typography variant="body2" className={styles.fileName}>
                {row?.fileName}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "size",
      header: "Size",
      render: (row) => {
        return row?.size;
      },
    },
    {
      field: "dateTime",
      header: "Date and Time",
      render: (row) => {
        return (
          <Typography variant="body2">
            {moment(row?.dateTime).format("DD-MM-YY hh:mm A")}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      header: "Actions",
      render: (row) => {
        return (
          <IconButton onClick={() => handleDownload(row.gscUrl)}>
            <IconDownload />
          </IconButton>
        );
      },
    },
  ];

  const handlePageOnChange = (pageNumber) => {};

  return (
    <FundamentoTable
      columns={columns}
      data={campaignDetails?.csvFiles}
      passRowToRender={true}
      pagination={false}
      currentPage={1}
      onPageChange={handlePageOnChange}
      totalPageCount={10}
      tableOnClick={() => {}}
      className={styles.table}
      rowIdKey="fileName"
    />
  );
};

export default UploadCallDataList;
