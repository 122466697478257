import {
  ChatsCircle,
  ChatsCircleGradient,
  PhoneCall,
  PhoneCallGradient,
} from "components/newSVG";
import { Box, Typography } from "@mui/material";
import styles from "./index.module.css";
import { useLocation, useNavigate } from "react-router-dom";

const BottomNav = ({ activeState, hide }) => {
  const navigate = useNavigate();
  const search = useLocation().search;

  return (
    !hide && (
      <Box className={styles.container}>
        <Box
          className={styles.navItem}
          onClick={() => navigate("/agent" + search)}
        >
          {activeState === "callSummary" ? (
            <PhoneCallGradient width={24} height={24} />
          ) : (
            <PhoneCall width={24} height={24} />
          )}
          <Typography
            variant="caption"
            className={activeState === "callSummary" && styles.gradientText}
          >
            Call Summary
          </Typography>
        </Box>
        <Box
          className={styles.navItem}
          onClick={() => navigate("/agent/chat" + search)}
        >
          {activeState === "chat" ? (
            <ChatsCircleGradient width={24} height={24} />
          ) : (
            <ChatsCircle width={24} height={24} />
          )}
          <Typography
            variant="caption"
            className={activeState === "chat" && styles.gradientText}
          >
            Yara Helpdesk
          </Typography>
        </Box>
      </Box>
    )
  );
};

export default BottomNav;
