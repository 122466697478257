import {
  Box,
  Button,
  Dialog,
  Grow,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  LinearProgress,
  Checkbox,
} from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { ChevronRight, Loader, PlusCircle, Trash2, X } from "react-feather";
import {
  Stack,
  PencilSimple,
  CheckCircle,
  Circle,
  RadioButton,
  SortAscending,
} from "components/newSVG";
import { ChevronDown, IconDots } from "components/SVG";
import { useUserContext } from "context/user";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyState from "components/EmptyState";
import {
  createDataSet as createDatasetAPI,
  deleteDataSet as deleteDatasetAPI,
  editDataSet,
} from "services";
import { useSkillrToast } from "context/toast";
import FundamentoPopup from "components/FundamentoPopup";
import DeleteModal from "components/DeleteModal";
import theme from "theme";
import FlexBox from "components/FlexBox";
import RoleBasedAccess from "components/RoleBasedAccess";
import { useURLState } from "custom-hooks/useUrlState";

const sortList = [
  {
    name: "Date created",
    value: "dateCreated",
  },
  {
    name: "Last modified",
    value: "lastModified",
  },
  {
    name: "A to Z",
    value: "atoz",
  },
  {
    name: "Z to A",
    value: "ztoa",
  },
];

const DatasetsLanding = () => {
  const { getDataSets, dataSets, dataSetsLoading, setDataSets } =
    useUserContext();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [datasetName, setDatasetName] = useState("");
  const { showSkillrToast } = useSkillrToast();
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [datasetIdToDelete, setDatasetIdToDelete] = useState(null);
  const [renameDataset, setRenameDataset] = useState();
  const [sortby, setSortBy] = useURLState("dateCreated", "sort");

  const formatDate = (date) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleString("default", { month: "short" });
    const day = newDate.getDate();
    return `${day} ${month} `;
  };

  const goToFiles = (datasetID) => {
    navigate(`/dataset/${datasetID}/files`);
  };

  const createDataset = async () => {
    setLoading(true);
    try {
      const response = await createDatasetAPI({ name: datasetName.trim() });
      if (response) {
        setLoading(false);
        getDataSets();
        setShowModal(false);
        setDatasetName("");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response.status === 409) {
        showSkillrToast("Dataset is already present with this name", "error");
      }
    }
  };

  const deleteDataSet = async () => {
    try {
      const response = await deleteDatasetAPI(datasetIdToDelete);
      if (response) {
        setDeleteModal(false);
        getDataSets();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRenameDataset = async (id, name) => {
    if (!name || name.trim() === "") {
      showSkillrToast("Dataset name cannot be empty", "error");
      return;
    }
    // if (dataSets[renameDataset]?.name === itemName) {
    //   return;
    // }

    try {
      const response = await editDataSet(id, { name: name });
      if (response) {
        showSkillrToast("Renamed dataset", "success");
        setDataSets((prev) => {
          const newDataSet = [...prev];
          newDataSet[renameDataset].name = name;
          return newDataSet;
        });
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        showSkillrToast("Dataset is already present with this name", "error");
      }

      if (error?.response?.message) {
        showSkillrToast(error?.response?.message, "error");
      }
      console.log(error);
    }
  };

  const RenameDatasetModal = ({ show, onClose, previousName }) => {
    const [itemName, setItemName] = useState(previousName);
    return (
      <Dialog
        open={show}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            width: 650,
            borderRadius: 20,
          },
        }}
      >
        <Box className={styles.modalContainer}>
          <IconButton onClick={onClose} className={styles.closeIcon}>
            <X />
          </IconButton>
          <Typography textAlign="center" variant="h3" fontWeight={500} mb={8}>
            Rename dataset
          </Typography>

          <TextField
            placeholder="e.g. Invoice issues"
            fullWidth
            autoFocus
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            sx={{ "&>div": { borderRadius: 82 } }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRenameDataset(dataSets[renameDataset]?._id, itemName);
                onClose();
              }
            }}
          />
          <FlexBox justifyContent={"center"} mt={4}>
            <Button
              variant="contained"
              className={styles.button}
              onClick={onClose}
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              variant="dark"
              endIcon={<ChevronRight width={16} height={16} />}
              onClick={() => {
                onClose();
                handleRenameDataset(dataSets[renameDataset]?._id, itemName);
              }}
            >
              Save
            </Button>
          </FlexBox>
        </Box>
      </Dialog>
    );
  };

  const handleSort = (sortValue) => {
    setSortBy(sortValue);
  };

  useEffect(() => {
    getDataSets();
  }, []);

  return (
    <>
      <PageWrapper className={styles.container}>
        {/* create dataset modal */}
        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            style: {
              width: 650,
              borderRadius: 20,
            },
          }}
        >
          <Box className={styles.modalContainer}>
            <IconButton
              onClick={() => setShowModal(false)}
              className={styles.closeIcon}
            >
              <X />
            </IconButton>
            <Typography textAlign="center" variant="h3" fontWeight={500} mb={8}>
              Create dataset
            </Typography>

            <TextField
              placeholder="e.g. Invoice issues"
              fullWidth
              autoFocus
              value={datasetName}
              onChange={(e) => setDatasetName(e.target.value)}
              sx={{ "&>div": { borderRadius: 82 } }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  createDataset();
                }
              }}
            />
            <Box textAlign={"center"} mt={4}>
              <Button
                disabled={loading}
                variant="dark"
                endIcon={<ChevronRight width={16} height={16} />}
                onClick={() => createDataset()}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Dialog>
        {/* / -- create dataset modal */}

        <RenameDatasetModal
          show={showRenameModal}
          onClose={() => setShowRenameModal(false)}
          previousName={dataSets[renameDataset]?.name}
        />

        <DeleteModal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          onConfirm={() => {
            deleteDataSet();
          }}
          title={"Delete forever?"}
          subtitle={
            "This action can't be undone and your dataset will be deleted from your account permanently"
          }
          list={[
            "Any progress made will not be saved",
            "Your files cannot be recovered once deleted",
          ]}
        />
        {dataSets?.length > 0 && (
          <>
            <Box textAlign="center" mb={5}>
              <Typography variant="h3" mb={2} fontWeight={500}>
                Datasets
              </Typography>

              {/* <Typography variant="body2" color="theme.600">
                All your collection data gets processed and auto-categorized
                here for quick reference
              </Typography> */}
            </Box>

            <Box display="flex">
              {/* <FundamentoPopup
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                triggeringComponent={
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: 82,
                      padding: "8px 14px",
                      minWidth: 0,
                    }}
                    startIcon={<SortAscending width={16} height={16} />}
                    endIcon={<ChevronDown width={16} height={16} />}
                  >
                    Sort by: {sortList.find((s) => s.value === sortby).name}
                  </Button>
                }
                className={styles.popup}
              >
                <Box>
                  {sortList.map((sort) => (
                    <Box className={styles.popupItem} key={sort.value}>
                      <Checkbox
                        icon={<Circle />}
                        checkedIcon={<RadioButton />}
                        value={sort.value}
                        onChange={(e) => handleSort(e.target.value)}
                        checked={sortby === sort.value}
                        id={sort.value}
                      />
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor={sort.value}
                      >
                        {sort.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </FundamentoPopup> */}

              <Button
                variant="dark"
                className={styles.darkButton}
                onClick={() => setShowModal(true)}
                startIcon={<PlusCircle width={16} height={16} />}
              >
                New Dataset
              </Button>
            </Box>

            {dataSetsLoading ? (
              <Box className={styles.emptyContainer}>
                <Loader
                  color={theme.palette.grey[400]}
                  width={40}
                  height={40}
                />
              </Box>
            ) : (
              <Box className={styles.datasetsContainer}>
                {dataSets.map((item, index) => (
                  <Grow timeout={400} mountOnEnter={true} in={true} key={index}>
                    <Box
                      className={styles.dataset}
                      key={index}
                      onClick={() => goToFiles(item?._id)}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack width={32} height={32} />
                        {/* <Box width="fit-content" className={styles.faqsInfo}>
                          <Typography
                            variant="caption"
                            className={styles.faqsTrained}
                          >{`${50} / ${100} `}</Typography>
                          <LinearProgress
                            value={50}
                            variant="determinate"
                            sx={{ borderRadius: 20 }}
                            className={styles.linearProgressBar}
                          />
                        </Box> */}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"space-between"}
                        mt={3}
                      >
                        <Box>
                          {item?.name.length > 20 ? (
                            <Tooltip title={item?.name}>
                              <Typography
                                variant="body2"
                                fontWeight={500}
                                style={{
                                  maxWidth: "20ch",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography variant="body2" fontWeight={500}>
                              {item?.name}
                            </Typography>
                          )}
                          <Box>
                            <Typography variant="caption" fontWeight={500}>
                              {item?.mediaCount} files &#x2022;{" "}
                              {formatDate(item?.createdAt)}
                            </Typography>
                          </Box>
                        </Box>

                        <FundamentoPopup
                          onParentClick={(e) => {
                            e.stopPropagation();
                          }}
                          sx={{ padding: 0 }}
                          // className={styles.popup}
                          triggeringComponent={
                            <IconButton>
                              <IconDots
                                style={{ transform: "rotate(90deg)" }}
                                width={20}
                                height={20}
                              />
                            </IconButton>
                          }
                        >
                          <FlexBox
                            p={"16px 24px"}
                            className={styles.popupItem}
                            onClick={() => {
                              setRenameDataset(index);
                              // setItemName(item?.name);
                              // setTimeout(() => {
                              //   inputRef.current.focus();
                              // }, 100);
                              setShowRenameModal(true);
                            }}
                          >
                            <PencilSimple width={16} height={16} />
                            <Typography variant="body2">Rename</Typography>
                          </FlexBox>

                          <RoleBasedAccess>
                            <FlexBox
                              p={"16px 24px"}
                              className={styles.popupItem}
                              onClick={() => {
                                setDatasetIdToDelete(item?._id);
                                setDeleteModal(true);
                              }}
                            >
                              <Trash2 width={16} height={16} color="#eb5757" />
                              <Typography variant="body2" color="error.main">
                                Delete
                              </Typography>
                            </FlexBox>
                          </RoleBasedAccess>
                        </FundamentoPopup>
                      </Box>
                    </Box>
                  </Grow>
                ))}
              </Box>
            )}
          </>
        )}

        {!dataSetsLoading && dataSets.length === 0 && (
          <EmptyState
            title={
              <Typography variant="h3" fontWeight={500}>
                Create your first dataset
              </Typography>
            }
            description={
              <Typography variant="body2" color="theme.600">
                Upload your data here for easy categorization and referencing
              </Typography>
            }
            buttonIcon={<PlusCircle width={16} height={16} />}
            buttonText={"New Dataset"}
            buttonAction={() => setShowModal(true)}
          />
        )}
      </PageWrapper>
    </>
  );
};

export default DatasetsLanding;
