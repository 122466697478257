import { useEffect, useState } from "react";
import { Box, Button, Fade, Typography } from "@mui/material";
import styles from "./index.module.css";
import { useSkillrToast } from "context/toast";
// import data from "./data.json";
import FundamentoTable from "components/FundamentoTable";
import FlexBox from "components/FlexBox";
import { ChatTeardrop } from "components/newSVG";
import theme from "theme";
import { ChevronRight, Circle, Loader } from "react-feather";
import {
  getPlaygroundHistory,
  getAllWorkFlow as getAllWorkFlowApi,
} from "services";
import EmptyState from "components/EmptyState";
import { useURLState } from "custom-hooks/useUrlState";
import { useNavigate } from "react-router-dom";

const History = ({ onHistoryClick }) => {
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [totalPages, setTotalPages] = useState(1);
  const [chatHistory, setChatHistory] = useState({});
  const { showSkillrToast } = useSkillrToast();
  const [loading, setLoading] = useState(false);
  const [workFlows, setWorkFlows] = useState([]);
  const navigate = useNavigate();

  const getRowType = (type) => {
    if (type.length === 1) {
      if (type[0] === "faq") {
        return "FAQ";
      } else if (type[0] === "smartPlus") {
        return "Smart+";
      } else if (type[0] === "workflow") {
        return "Workflow";
      }
    } else {
      return "Multiple";
    }
  };

  const columns = [
    {
      field: "title",
      header: " ",
      style: { width: "70%" },
      render: (row) => {
        return (
          <FlexBox>
            <ChatTeardrop width={20} height={20} />
            <Typography variant="body2" fontWeight={500}>
              {row?.nodes[1]?.question.length > 70
                ? row?.nodes[1]?.question.substring(0, 70) + "..."
                : row?.nodes[1]?.question}
            </Typography>
          </FlexBox>
        );
      },
    },
    {
      field: "type",
      header: " ",
      style: { width: "10%" },
      render: (row) => {
        return (
          <FlexBox className={styles.label}>
            <Circle
              size={4}
              fill={theme.palette.grey[400]}
              stroke={theme.palette.grey[400]}
            />
            {getRowType(row?.type)}
          </FlexBox>
        );
      },
    },
    {
      field: "date",
      header: " ",
      style: { width: "15%" },
      render: (row) => {
        return (
          <Typography fontSize={12} fontWeight={500} color="textSecondary">
            {new Date(row.createdAt).toLocaleString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </Typography>
        );
      },
    },
    {
      field: "arrow",
      header: " ",
      style: { width: "5%" },
      render: (row) => {
        return (
          <ChevronRight
            color={theme.palette.grey[400]}
            className="arrow"
            width={20}
            height={20}
          />
        );
      },
    },
  ];

  const getChatHistory = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.set("page", currentPage);
      const response = await getPlaygroundHistory(params);
      setChatHistory(response);
      setTotalPages(response.totalPages);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row) {
      // onHistoryClick(
      //   chatHistory?.callSessionData?.find((call) => call._id === row.id)
      // );

      navigate(`/bot/history/${row.id}`, {
        state: {
          historyDetails: chatHistory?.callSessionData?.find(
            (call) => call._id === row.id
          ),
          workFlows,
        },
      });

      // const singleCallDetails = callsData.find((call) => call._id === row.id);
      // setModal(true);
      // setCurrentCall(singleCallDetails);
    }
  };

  const getWorkflows = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlowApi(params.toString());
      setWorkFlows(response);
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    getWorkflows();
    getChatHistory();
  }, []);

  useEffect(() => {
    getChatHistory();
  }, [currentPage]);

  return (
    <Box className={styles.container}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100%",
            width: "100%",
          }}
        >
          <Loader width={40} height={40} color={theme.palette.grey[400]} />
        </Box>
      ) : (
        <>
          <Fade in={true} timeout={300} exit={false} unmountOnExit mountOnEnter>
            <Box
              sx={{
                "& > div": {
                  height: "100%",
                },
              }}
            >
              {chatHistory?.callSessionData?.length > 0 && (
                <Box className={styles.tableContainer}>
                  <FundamentoTable
                    data={chatHistory?.callSessionData}
                    columns={columns}
                    passRowToRender={true}
                    pagination={true}
                    currentPage={currentPage}
                    totalPageCount={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                    tableOnClick={handleTableOnClick}
                    rowIdKey="_id"
                  />
                </Box>
              )}
            </Box>
          </Fade>
          {chatHistory?.callSessionData?.length === 0 && (
            <Box
              sx={{
                height: "60vh",
                width: "100%",
              }}
            >
              <EmptyState
                title={"No history available"}
                description={"Play around in the playground"}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default History;
