import FlexBox from "components/FlexBox";
import { useEffect, useState } from "react";
import { X } from "react-feather";

const {
  Dialog,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
} = require("@mui/material");

const AddAccountModal = ({ open, onClose, onConfirm, prevName }) => {
  const [accountName, setAccountName] = useState("");

  const handleClose = () => {
    onClose();
    setAccountName("");
  };

  useEffect(() => {
    if (prevName?.name) {
      setAccountName(prevName.name);
    }
  }, [prevName]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      PaperProps={{
        sx: {
          borderRadius: 5,
          padding: 6,
          boxShadow: 10,
          maxWidth: 464,
          position: "relative",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 20, right: 20 }}
      >
        <X width={20} height={20} />
      </IconButton>

      <Box>
        <Typography textAlign={"center"} variant="h4" fontWeight={500}>
          {prevName?.name ? "Rename Account" : "Add Account"}
        </Typography>
      </Box>

      <Box mt={8}>
        <TextField
          fullWidth
          variant="outlined"
          sx={{ mb: 2 }}
          placeholder="Enter name"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
        />
      </Box>

      <FlexBox mt={8} justifyContent="center">
        <Button
          variant="outlined"
          sx={{ borderRadius: 3, height: 40, minWidth: 0 }}
          color="inherit"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          sx={{ borderRadius: 3, height: 40, minWidth: 0 }}
          onClick={() => {
            onConfirm(accountName);
            handleClose();
          }}
        >
          {prevName?.name ? "Rename" : "Connect"}
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default AddAccountModal;
