import FundamentoTable from "components/FundamentoTable";
import styles from "./index.module.css";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  Duplicate,
  HandMic,
  PencilSimple,
  StopCircle,
  TrashSimple,
} from "components/newSVG";
import theme from "theme";
import PropTypes from "prop-types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { statusColorMapping, statusTextMapping } from "text-constants/campaign";
import FundamentoPopup from "components/FundamentoPopup";
import { MoreVertical } from "react-feather";
import { IconPause, IconPlay } from "components/SVG";

const CampaignList = (props) => {
  const navigate = useNavigate();
  const {
    currentPage,
    setCurrentPage,
    campaignData,
    totalPageCount,
    updateCampaign,
    createDuplicateCampaign,
    deleteCampaign,
  } = props;

  const handlePageOnChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row?.id) {
      const selectedCampaign = campaignData.find((cd) => cd._id === row.id);
      if (selectedCampaign?.status === "draft") {
        navigate(`/campaign/edit/${row.id}`);
      } else {
        navigate(`/campaign/${row.id}`);
      }
    }
  };

  const columns = [
    {
      field: "campaign",
      header: "Campaign",
      style: { width: "40%" },
      render: (row) => {
        return (
          <Box display="flex" alignItems="center" gap={4}>
            <HandMic color="#295B74" width={24} height={24} />
            <Typography variant="body2" fontWeight={500}>
              {row?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "bot",
      header: "Bot",
      render: (row) => {
        return (
          <Box
            className={styles.box}
            sx={{ background: theme.palette.grey[100] }}
          >
            <Typography
              fontSize={10}
              fontWeight={500}
              lineHeight="normal"
              color="#505050"
            >
              {row?.bot?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      header: "Status",
      render: (row) => {
        const statusColor =
          statusColorMapping[row?.status] ??
          statusColorMapping["undefinedError"];
        return (
          <Box
            className={styles.box}
            sx={{
              background: statusColor?.backgroundColor,
            }}
          >
            <Typography
              fontSize={10}
              fontWeight={500}
              lineHeight="normal"
              color={statusColor?.color}
            >
              {statusTextMapping[row?.status] ?? "N/A"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "timeSlot",
      header: "Time Slot",
      render: (rowInfo) => {
        return `${rowInfo?.startTime} - ${rowInfo?.endTime}`;
      },
    },
    {
      field: "campaignSchedule",
      header: "Campaign Schedule",
      render: (rowInfo) => {
        // combine start date and end date
        return `${moment(rowInfo?.startDate).format("DD-MM-YYYY")} - ${moment(
          rowInfo?.endDate
        ).format("DD-MM-YYYY")}`;
      },
    },
    {
      field: "actions",
      header: "Actions",
      render: (rowInfo) => {
        return (
          <FundamentoPopup
            triggeringComponent={
              <IconButton>
                <MoreVertical width={20} height={20} />
              </IconButton>
            }
            className={styles.popup}
          >
            <Box>
              {["paused"].indexOf(rowInfo?.status) > -1 && (
                <Box
                  className={styles.action}
                  onClick={() =>
                    updateCampaign(rowInfo?._id, { status: "active" })
                  }
                >
                  <IconPlay width={24} height={24} />
                  <Typography variant="body2">Restart Campaign</Typography>
                </Box>
              )}
              {["active", "paused"].indexOf(rowInfo?.status) > -1 && (
                <Box
                  className={styles.action}
                  onClick={() =>
                    updateCampaign(rowInfo?._id, { status: "complete" })
                  }
                >
                  <StopCircle width={24} height={24} />
                  <Typography variant="body2">Stop Campaign</Typography>
                </Box>
              )}
              {["active"].indexOf(rowInfo?.status) > -1 && (
                <Box
                  className={styles.action}
                  onClick={() =>
                    updateCampaign(rowInfo?._id, { status: "paused" })
                  }
                >
                  <IconPause width={24} height={24} />
                  <Typography variant="body2">Pause Campaign</Typography>
                </Box>
              )}
              {rowInfo?.status !== "complete" && (
                <Box
                  className={styles.action}
                  onClick={() => navigate(`/campaign/edit/${rowInfo?._id}`)}
                >
                  <PencilSimple width={24} height={24} />
                  <Typography variant="body2">Edit Campaign</Typography>
                </Box>
              )}
              <Box
                className={styles.action}
                onClick={() => createDuplicateCampaign(rowInfo?._id)}
              >
                <Duplicate width={24} height={24} />
                <Typography variant="body2">Create Duplicate</Typography>
              </Box>
              {["draft"].indexOf(rowInfo?.status) > -1 && (
                <Box
                  className={styles.action}
                  onClick={() => deleteCampaign(rowInfo?._id)}
                >
                  <TrashSimple width={24} height={24} />
                  <Typography variant="body2">Delete Campaign</Typography>
                </Box>
              )}
            </Box>
          </FundamentoPopup>
        );
      },
    },
  ];

  return (
    <Box>
      <FundamentoTable
        columns={columns}
        data={campaignData}
        passRowToRender={true}
        pagination={true}
        currentPage={currentPage}
        onPageChange={handlePageOnChange}
        totalPageCount={totalPageCount}
        tableOnClick={handleTableOnClick}
        className={styles.table}
        rowIdKey="_id"
      />
    </Box>
  );
};

CampaignList.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  campaignData: PropTypes.array,
};

export default CampaignList;
