import { Collapse, Typography, Box } from "@mui/material";
import { IconFingerUp, IconNotepad } from "components/SVG";
import { useState } from "react";
import { Edit2, X } from "react-feather";
import theme from "theme";
import styles from "./index.module.css";
import {
  ChartDonut,
  Database,
  Lightning,
  Pencil,
  UploadCloudOutline,
  UploadCloudWhiteOutline,
} from "components/newSVG";

const InformationSection = () => {
  const [showInfo, setShowInfo] = useState(true);

  return (
    <Collapse in={showInfo} unmountOnExit={true}>
      <Box className={styles.infoContainer}>
        <Box className={styles.dismiss} onClick={() => setShowInfo(false)}>
          <Typography variant="caption" color="grey.600">
            Dismiss
          </Typography>

          <X width={12} height={12} color={theme.palette.grey[600]} />
        </Box>
        <Box className={styles.infoItem}>
          <Box className={styles.infoIcon}>
            <UploadCloudOutline width={20} height={20} color="#BE56FF" />
          </Box>

          <Typography variant="subtitle2" fontWeight={500}>
            Toss in all those files, even if they're messy.
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Box className={styles.infoIcon}>
            <Database width={20} height={20} color="#BE56FF" />
          </Box>

          <Typography variant="subtitle2" fontWeight={500}>
            Our AI's got your back! It'll sift through and find what matters.
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Box className={styles.infoIcon}>
            <Lightning width={20} height={20} color="#BE56FF" />
          </Box>

          <Typography variant="subtitle2" fontWeight={500}>
            Energize your AI bot with tailor-made data sets.
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Box className={styles.infoIcon}>
            <ChartDonut width={20} height={20} color="#BE56FF" />
          </Box>

          <Typography variant="subtitle2" fontWeight={500}>
            Sit back & check out the cool insights from your data
          </Typography>
        </Box>
      </Box>
    </Collapse>
  );
};

export default InformationSection;
