import { Avatar, Box, FormControl, Radio, Typography } from "@mui/material";
import { RadioButton, Speaker } from "components/newSVG";
import { Controller, useForm } from "react-hook-form";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { getAudio as getAudioApi } from "services";
import defaultBotVoices from "utils/botVoices";
import { Star } from "react-feather";
import { IconGoogle } from "components/SVG";
import elevenLabs from "assets/images/elevenLabs.png";

const BotVoices = (props) => {
  const { onSave, defaultVoice, selectedLanguage, defaultLanguage } = props;
  const [playingVoice, setPlayingVoice] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [botVoices, setBotVoices] = useState([]);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      botVoice: defaultVoice,
    },
  });

  const getAudio = async (apiData) => {
    setIsPlaying(true);
    const response = await getAudioApi(apiData);
    const base64AudioContent = response;
    const audio = new Audio("data:audio/wav;base64," + base64AudioContent);
    audio.play();

    audio.onended = () => {
      setIsPlaying(false);
    };
  };

  const handleOnPlayVoice = (voice) => {
    setPlayingVoice(voice.name);

    let voices = {
      "en-US": "Hi, I'm Yara, how can I assist you?",
      "en-IN": "Hi, I'm Yara, how can I assist you?",
      "hi-IN": "Namaste; mai hun yara. आप किस तरह सहायता चाहते हैं?",
      "id-ID": "Halo, saya Yara, bagaimana saya bisa membantu Anda?",
      "es-ES": "Hola, soy Yara, ¿cómo puedo ayudarte?",
      "es-US": "Hola, soy Yara, ¿cómo puedo ayudarte?",
      "mr-IN": "हाय, मी यारा आहे, मी तुमची कशी मदद करू?",
      "ml-IN": "ഹായ്, ഞാൻ യാറയാണ്, എനിക്ക് നിങ്ങളെ എങ്ങനെ സഹായിക്കാനാകും?",
      "pa-IN": "ਹੈਲੋ, ਮੈਂ ਯਾਰਾ ਹਾਂ, ਮੈਂ ਤੁਹਾਡੀ ਮਦਦ ਕਿਵੇਂ ਕਰ ਸਕਦਾ ਹਾਂ?",
      "ta-IN": "ஹாய், நான் யாரா, நான் உங்களுக்கு எப்படி உதவுவது?",
      "bn-IN": "হাই, আমি ইয়ারা, আমি কিভাবে আপনাকে সাহায্য করতে পারি?",
      "te-IN": `హాయ్, నేను యారా, నేను మీకు ఎలా సహాయం చేయగలను?`,
      "gu-IN": `હાય, હું યારા છું, હું તમને કેવી રીતે મદદ કરી શકું?`,
      "ar-XA": `مرحبا، أنا يارا، كيف يمكنني مساعدتك؟`,
      "de-DE": `Hallo, ich bin Yara, wie kann ich Ihnen helfen?`,
      "ja-JP": `こんにちは、私はヤラです。何かお手伝いできますか?`,
      "cmn-CN": `您好，我是 Yara，有什么可以帮您的吗？`,
    };

    if (!isPlaying && voices[voice.languageCode]) {
      getAudio({
        text: voices[voice.languageCode],
        botVoice: {
          name: voice.name,
          languageCode: voice.languageCode,
          ...(voice.provider === "ElevenLab" && { elevenLab: true }),
        },
      });
    }
  };

  const watchOnAllFields = () => {
    const watchSubscription = watch((value, fields) => {
      const botProvider = defaultBotVoices.find(
        (v) => v.name === value?.botVoice
      )?.provider;
      onSave({ botVoice: value?.botVoice, botProvider });
    });
    return watchSubscription;
  };

  useEffect(() => {
    const subscription = watchOnAllFields();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const filterByLanguage = () => {
    if (
      typeof selectedLanguage !== "string" ||
      typeof defaultLanguage !== "string"
    ) {
      console.error("Invalid selectedLanguage or defaultLanguage");
      return;
    }

    const selectedLanguageCode = selectedLanguage.split("-")[0];
    const defaultLanguageCode = defaultLanguage.split("-")[0];

    const newBotVoices = defaultBotVoices.filter((v) =>
      v.languageCode.startsWith(selectedLanguageCode)
    );

    if (selectedLanguageCode !== defaultLanguageCode) {
      // setValue("botVoice", newBotVoices[0].name);
      setValue("botVoice", newBotVoices[0]?.name || "");
    } else {
      setValue("botVoice", defaultVoice);
    }
    setBotVoices(newBotVoices);
  };

  useEffect(() => {
    filterByLanguage();
  }, [selectedLanguage]);

  return (
    <Box>
      {/* <Typography variant="subtitle2" gutterBottom paddingLeft={3}>
        Assistant voice
      </Typography> */}

      <Controller
        name="botVoice"
        control={control}
        // defaultValue={bot?.botVoice ? bot?.botVoice.name : ""}
        render={({ field: { onChange, value } }) => (
          <Box>
            <FormControl className={styles.voiceContainer}>
              {botVoices.map((voice) => (
                <Box
                  key={voice.name}
                  className={`${styles.voice}`}
                  onClick={() => handleOnPlayVoice(voice)}
                >
                  {voice.provider === "ElevenLab" && (
                    <div className={`${styles.tag} ${styles.platinum}`}>
                      <Star fill="white" width={10} height={10} />
                      <p>Platinum</p>
                    </div>
                  )}
                  {voice.name.indexOf("Journey") > -1 && (
                    <div className={`${styles.tag} ${styles.gold}`}>
                      <Star fill="white" width={10} height={10} />
                      <p>Gold</p>
                    </div>
                  )}
                  <Radio
                    value={voice.name}
                    onChange={onChange}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    sx={{ padding: 0 }}
                    checked={value === voice.name}
                    checkedIcon={<RadioButton width={20} height={20} />}
                  />
                  <Avatar>
                    {isPlaying && playingVoice === voice.name ? (
                      <div className={styles.boxContainer}>
                        <div className={`${styles.box} ${styles.box1}`}></div>
                        <div className={`${styles.box} ${styles.box2}`}></div>
                        <div className={`${styles.box} ${styles.box3}`}></div>
                      </div>
                    ) : voice.provider === "Google" ? (
                      <IconGoogle />
                    ) : voice.provider === "ElevenLab" ? (
                      <img src={elevenLabs} alt="elevenLabs" />
                    ) : (
                      <Speaker color="#555" />
                    )}
                  </Avatar>
                  <div>
                    <Typography variant="body2" fontWeight={500}>
                      {voice.displayName}
                    </Typography>
                    <Typography fontSize={10} color="textSecondary">
                      {voice.languageCode}
                    </Typography>
                  </div>
                </Box>
              ))}
            </FormControl>
          </Box>
        )}
      />
    </Box>
  );
};

export default BotVoices;
