import React, { createContext, useContext, useState } from "react"


const DatasetDashboardContext = createContext();


// Create a context provider for DatasetDashboard
const DatasetDashboardContextProvider = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [duplicateCheckProgress, setDuplicateCheckProgress] = useState(false);
  const [duplicateCheckDone, setDuplicateCheckDone] = useState(false);
  const [duplicateQuestion, setDuplicateQuestions] = useState();
  const [duplicatFailureMessage, setDuplicateFailureMessage] = useState();
  const [answerStatus, setAnswerStatus] = useState();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedAcrossPages, setSelectedAcrossPages] = useState(false);
  const [selectedAcrossPagesLength,setSelectedAcrossPagesLength]=useState()
  const [totalDuplicateCheckDone,setTotalDuplicateCheckDone]=useState()
  const [totalPercentageOfDupCheck,setTotalPercentageOfDupCheck]=useState(0)
  
  

  return (
    <DatasetDashboardContext.Provider value={{
      selectedFiles,
      setSelectedFiles,
      duplicateCheckProgress,
      setDuplicateCheckProgress,
      duplicatFailureMessage,
      setDuplicateFailureMessage,
      duplicateCheckDone,
      setDuplicateCheckDone,
      duplicateQuestion,
      setDuplicateQuestions,
      answerStatus,
      setAnswerStatus,
      selectedCategory,
      setSelectedCategory,
      selectedAcrossPages,
      setSelectedAcrossPages,
      selectedAcrossPagesLength,
      setSelectedAcrossPagesLength,
      totalDuplicateCheckDone,
      setTotalDuplicateCheckDone,
      totalPercentageOfDupCheck,
    setTotalPercentageOfDupCheck

    }}>
      {children}
    </DatasetDashboardContext.Provider>
  );
};

export const useDatasetContext = () => {
  return useContext(DatasetDashboardContext);
};

export default DatasetDashboardContextProvider