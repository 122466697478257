import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     background: (props) => {
//       if (props.variant === "success") {
//         return theme.palette.success.light;
//       } else if (props.variant === "warning") {
//         return theme.palette.warning.light;
//       }
//       return theme.palette.error.light;
//     },
//     color: (props) => {
//       if (props.variant === "success") {
//         return theme.palette.text.primary;
//       } else if (props.variant === "warning") {
//         return theme.palette.text.primary;
//       }
//       return theme.palette.text.primary;
//     },
//     border: "1px solid",
//     borderColor: (props) => {
//       if (props.variant === "success") {
//         return theme.palette.success.main;
//       } else if (props.variant === "warning") {
//         return theme.palette.warning.light;
//       }
//       return theme.palette.error.main;
//     },

//     padding: theme.spacing(3, 8),
//     borderRadius: "6px",
//   },
// }));

const Root = styled("div")(({ theme, variant }) => ({
  background:
    variant === "success"
      ? theme.palette.success.light
      : variant === "warning"
      ? theme.palette.warning.light
      : theme.palette.error.light,

  color:
    variant === "success"
      ? theme.palette.text.primary
      : variant === "warning"
      ? theme.palette.text.primary
      : theme.palette.text.primary,

  border: "1px solid",

  borderColor:
    variant === "success"
      ? theme.palette.success.main
      : variant === "warning"
      ? theme.palette.warning.light
      : theme.palette.error.main,

  padding: theme.spacing(3, 8),

  borderRadius: "6px",
}));

export default function SkillrAlert(props) {
  const { variant, children, ...other } = props;
  // const classes = useStyles(props);
  return (
    <Root variant={variant} {...other}>
      {children}
    </Root>
  );
}
