import React, { useRef } from "react";
import {
  Button,
  IconButton,
  TextField,
  Box,
  InputAdornment,
  Fade,
  Grow,
} from "@mui/material";
import {
  ChevronDown,
  ChevronUp,
  PlusBlueVariant,
  RedAlert,
} from "components/SVG";
import { TrashSimple } from "components/newSVG";
import { Plus, PlusCircle } from "react-feather";
import {
  Controller,
  get,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import classes from "./index.module.css";
import theme from "theme";

const QuestionVariant = (props) => {
  const { path } = props;
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: path,
  });

  const containerRef = useRef(null);

  const handleAddField = () => {
    append({
      question: "",
    });
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, 0);
  };

  const handleRemoveField = (index) => {
    remove(index);
  };

  // const handleShowMoreLess = () => {
  //   const currentValue = getValues("showMore");
  //   if (currentValue === null) {
  //     setValue("showMore", false);
  //   } else {
  //     setValue("showMore", !currentValue);
  //   }
  // };

  return (
    <Box className={`${classes.questions}`}>
      <Box
        sx={{
          maxHeight: "30vh",
          overflowY: "scroll",
          scrollBehavior: "smooth",
        }}
        ref={containerRef}
      >
        {fields.map(({ id }, questionIndex) => (
          <Box display="flex" alignItems="center" gap={1}>
            <Controller
              key={id}
              name={`${path}.${questionIndex}.question`}
              control={control}
              rules={{
                required: "Question is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Grow in={true} mountOnEnter unmountOnExit timeout={600}>
                  <TextField
                    value={value}
                    onChange={onChange}
                    className={classes.inputField}
                    sx={{ "& + &": { mt: 2 } }}
                    fullWidth
                    placeholder="e.g. Your question"
                    helperText={
                      get(errors, `${path}.${questionIndex}.question`) && (
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                          color="error.main"
                        >
                          <RedAlert style={{ marginRight: 8 }} />
                          {
                            get(errors, `${path}.${questionIndex}.question`)
                              ?.message
                          }
                        </Box>
                      )
                    }
                    InputProps={{
                      endAdornment: fields.length > 1 && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleRemoveField(questionIndex)}
                          >
                            <TrashSimple width={20} height={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grow>
              )}
            />
          </Box>
        ))}
      </Box>
      <Box padding="15px 12px">
        <Button
          variant="text"
          color="primary"
          startIcon={
            <PlusCircle
              fill={theme.palette.primary.main}
              stroke="white"
              width={16}
              height={16}
            />
          }
          onClick={handleAddField}
          sx={{
            width: "max-content",
            padding: 0,
            mt: 2,
          }}
        >
          Add variation
        </Button>
        {/* {fields.length > 1 && (
          <Button
            variant="text"
            color="primary"
            endIcon={
              watch("showMore") ? (
                <ChevronDown
                  stroke={theme.palette.primary.main}
                  width={16}
                  height={16}
                />
              ) : (
                <ChevronUp
                  stroke={theme.palette.primary.main}
                  width={16}
                  height={16}
                />
              )
            }
            onClick={handleShowMoreLess}
            sx={{
              width: "max-content",
              padding: 0,
              mt: 2,
            }}
          >
            {watch("showMore") ? "Show less" : "Show more"}
          </Button>
        )} */}
      </Box>
    </Box>
  );
};

export default QuestionVariant;
