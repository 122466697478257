import { Box, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";

// const PREFIX = "MyCard";
// const classes = {
//   root: `${PREFIX}-root`,
//   cardContent: `${PREFIX}-cardContent`,
//   brandLogo: `${PREFIX}-brandLogo`,
// };

// const Root = styled("div")(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     maxWidth: 513,
//     [theme.breakpoints.down("md")]: {
//       maxWidth: "100%",
//     },
//   },

//   [`& .${classes.cardContent}`]: {
//     padding: theme.spacing(10),
//     [theme.breakpoints.down("md")]: {
//       padding: "24px 20px",
//       "& h4": {
//         fontSize: "19px",
//       },
//       "& p": {
//         fontSize: "14px",
//       },
//     },
//     "&:last-child": {
//       paddingBottom: theme.spacing(10),
//     },
//   },

//   [`& .${classes.brandLogo}`]: {
//     width: "170px",
//     cursor: "pointer",
//     margin: "0 auto",
//     "&:not(:last-child)": {
//       paddingBottom: theme.spacing(6),
//     },
//   },
// }));

const Root = styled(Box)(({ theme }) => ({
  maxWidth: 513,
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

const CardContentdiv = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(10),
  [theme.breakpoints.down("md")]: {
    padding: "24px 20px",
    "& h4": {
      fontSize: "19px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  "&:last-child": {
    paddingBottom: theme.spacing(10),
  },
}));

const BrandLogo = styled(Box)(({ theme }) => ({
  width: "170px",
  cursor: "pointer",
  margin: "0 auto",
  "&:not(:last-child)": {
    paddingBottom: theme.spacing(6),
  },
}));

export { Root, CardContentdiv, BrandLogo };
