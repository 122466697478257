import { Step, StepLabel, Typography, Box, Stepper } from "@mui/material";
import { Check } from "components/newSVG";
import theme from "theme";
import PropTypes from "prop-types";

// const steps = ["Campaign Details", "Redial Configuration", "Preview"];

const CustomStepIcon = (props) => {
  const { active, completed, icon } = props;

  return (
    <Box
      sx={{
        width: 32,
        height: 32,
        background: active || completed ? "#0f0f0f" : "#fff",
        border: `1px solid #0f0f0f`,
        borderRadius: "12px",
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {completed && <Check width={13} height={13} />}

      {active && (
        <Typography color={theme.palette.common.white}>{icon}</Typography>
      )}

      {!completed && !active && (
        <Typography color={theme.palette.common.black}>{icon}</Typography>
      )}
    </Box>
  );
};

const CustomStepper = (props) => {
  const { step, steps } = props;

  return (
    <Stepper
      activeStep={step}
      alternativeLabel
      sx={{
        "& .MuiStepLabel-label": {
          mt: 1,
        },
      }}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={CustomStepIcon}>
            <Typography sx={{ fontSize: 10, color: "#6f6f6f" }}>
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

CustomStepper.propTypes = {
  step: PropTypes.number,
};

export default CustomStepper;
