export const multiModelResponseTypes = [
  {
    name: "DTMF",
    value: "dtmf",
  },
];

export const dateFormatTypes = [
  {
    name: "DDMMYYYY",
    value: "DDMMYYYY",
  },
  {
    name: "MMDDYYYY",
    value: "MMDDYYYY",
  },
];
