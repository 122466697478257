import { useState } from "react";

export const convertType = (value, type, defaultValue) => {
  if (type === "number") {
    return Number(value);
  }
  if (Array.isArray(defaultValue)) {
    return value ? value.split(",") : [];
  }
  return value;
};

export const useURLState = (value, name) => {
  const searchParams = new URLSearchParams(window.location.search);
  const [state, updateState] = useState(() => {
    const type = typeof value;
    const result = searchParams.get(name);
    if (result !== null) {
      return convertType(result, type, value);
    } else {
      return value;
    }
  });

  const updateStateModify = (newValue) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(name, newValue);
    window.history.replaceState(null, "", `?${urlParams.toString()}`);
    updateState(newValue);
  };
  return [state, updateStateModify];
};
