import {
  Box,
  IconButton,
  TextField,
  Typography,
  Tooltip,
  Dialog,
  Button,
} from "@mui/material";
import FundamentoTable from "components/FundamentoTable";
import { useSkillrToast } from "context/toast";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import styles from "./index.module.css";
import { Edit2, Loader, Trash2, X, XCircle } from "react-feather";
import { IconDots, IconTrash, IconUpload2 } from "components/SVG";
import FundamentoPopup from "components/FundamentoPopup";
import {
  editDataSet as editDataSetApi,
  deleteDataSet as deleteDataSetApi,
} from "services";
import { useFileUpload } from "custom-hooks/useFileUpload";
import { useUserContext } from "context/user";
import PropTypes from "prop-types";
import theme from "theme";
import { useNavigate } from "react-router-dom";
import { PencilSimple, Trash } from "components/newSVG";
import EmptyState from "components/EmptyState";

const supportedFileTypes = [
  "audio/wav",
  "audio/mpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const DataSetsTable = (props) => {
  const { refreshDataSet } = props;
  const {
    companyDetails,
    getCompanyDetails,
    getDataSets,
    setDataSets,
    dataSets,
    dataSetsLoading,
  } = useUserContext();
  const [dataSetName, setDataSetName] = useState();
  const [selectedDataSet, setSelectedDataSet] = useState();
  const { showSkillrToast } = useSkillrToast();
  const { fileInputRef, handleFileChange, triggerFileInput, uploadStatus } =
    useFileUpload({
      fileTypes: supportedFileTypes,
      maxUploadLimit: companyDetails?.maxStorageCapacityInMB,
      sizeAlreadyConsumed: companyDetails?.usedStorageCapacity,
      processUploadedFiles: true,
    });
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);

  const columns = [
    { field: "name", header: "Data Set", style: { width: "40%" } },
    { field: "faqs", header: "FAQs" },
    { field: "files", header: "Files" },
    { field: "updated", header: "Last Updated" },
    { field: "icons", header: "" },
  ];

  const handleExistingDataSet = (datasetIndex) => {
    const selectedDataSet = dataSets[datasetIndex];
    setSelectedDataSet(selectedDataSet);
    triggerFileInput({ dataSet: selectedDataSet });
  };

  const editDataSet = (event, dataSetIndex) => {
    const newDataSet = dataSets.map((d, dI) => {
      if (dataSetIndex === dI) {
        return { ...d, editable: true };
      } else {
        return { ...d, editable: false };
      }
    });
    setDataSets(newDataSet);
    event.stopPropagation();
  };

  const updateDataSet = async (dataSetIndex) => {
    const selectedDataSet = dataSets[dataSetIndex];
    const newDataSetName = dataSetName || selectedDataSet?.name;
    const newDataSet = [...dataSets];
    try {
      await editDataSetApi(selectedDataSet?._id, {
        name: newDataSetName,
      });
      newDataSet[dataSetIndex].name = newDataSetName;
    } catch (error) {
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
    newDataSet[dataSetIndex].editable = false;
    setDataSets(newDataSet);
  };

  const deleteDataSet = async (dataSetId) => {
    try {
      await deleteDataSetApi(dataSetId);
      const newDataSet = dataSets.filter((ds) => ds._id !== dataSetId);
      setDataSets(newDataSet);
      getCompanyDetails();
    } catch (error) {
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const DeleteModal = ({ open, onClose }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            width: 500,
            borderRadius: 20,
          },
        }}
      >
        <Box sx={{ textAlign: "center", position: "relative", padding: 10 }}>
          <IconButton className={styles.close} onClick={onClose}>
            <X width={20} height={20} />
          </IconButton>
          <Box className={styles.iconContainer}>
            <Trash width={40} height={40} />
          </Box>

          <Typography variant="h3" fontWeight={500} mt={4}>
            Delete Forever?
          </Typography>

          <Typography variant="body2" my={2} color="grey.600">
            All your collection data gets processed and auto-categorized here
            for quick reference
          </Typography>

          <Box className={styles.modalList}>
            <XCircle color={theme.palette.error.main} width={20} height={20} />

            <Typography variant="body2" align="left">
              Any progress made will not be saved
            </Typography>
          </Box>

          <Box className={styles.modalList}>
            <XCircle color={theme.palette.error.main} width={20} height={20} />

            <Typography variant="body2" align="left">
              All the FAQs will cannot be recovered once deleted
            </Typography>
          </Box>

          <Box
            mt={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "22px",
            }}
          >
            <Button
              variant="outlined"
              onClick={onClose}
              className={styles.modalButton}
            >
              Cancel
            </Button>
            <Button
              className={styles.modalButton}
              startIcon={<Trash color="white" width={16} height={16} />}
              variant="contained"
              color={"error"}
              onClick={() => {
                deleteDataSet(selectedDataSet?._id);
                setDeleteModal(false);
              }}
            >
              Confirm & Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    );
  };

  const handleDeleteDataSet = (dataSetIndex) => {
    setSelectedDataSet(dataSets[dataSetIndex]);
    setDeleteModal(true);
  };

  const getTableData = useMemo(() => {
    const tableData = dataSets.map((datasetInfo, datasetIndex) => {
      const dataset = datasetInfo;
      return {
        id: datasetIndex,
        name: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              "&:hover": {
                "& button": {
                  opacity: 1,
                },
              },
            }}
          >
            {selectedDataSet?._id === dataset._id &&
              ["started", "processing"].indexOf(uploadStatus?.status) > -1 && (
                <Loader
                  style={{ alignSelf: "flex-start" }}
                  width={20}
                  height={20}
                  color={theme.palette.grey[600]}
                />
              )}
            <Box>
              {dataset?.editable ? (
                <TextField
                  variant="standard"
                  InputProps={{ className: styles.datasetName }}
                  defaultValue={dataset.name}
                  autoFocus
                  onChange={(e) => {
                    setDataSetName(e.target.value);
                  }}
                  // onBlur={() => {
                  //   updateDataSet(datasetIndex);
                  // }}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      updateDataSet(datasetIndex);
                    }
                  }}
                />
              ) : (
                <Tooltip title={dataset.name.length > 20 ? dataset.name : ""}>
                  <Typography
                    variant="body2"
                    className={styles.dataSetNameParagraph}
                  >
                    {dataset.name}
                  </Typography>
                </Tooltip>
              )}
              {selectedDataSet?._id === dataset._id &&
                ["started", "processing"].indexOf(uploadStatus?.status) >
                  -1 && (
                  <Typography fontWeight={500} fontSize={10} color="grey.400">
                    {`${uploadStatus?.processedFiles} of ${uploadStatus?.totalFiles} files uploaded`}
                  </Typography>
                )}
            </Box>
            <Tooltip title="To save hit enter key">
              <IconButton
                className={styles.editIcon}
                onClick={(e) => editDataSet(e, datasetIndex)}
              >
                <PencilSimple width={16} height={16} color="#555" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
        // name: dataset.name,
        faqs: dataset.mediaQAndACount,
        files: dataset?.mediaCount,
        updated: `${moment(dataset?.updatedAt).fromNow()}`,
        icons: (
          <Box display={"flex"}>
            {/* <IconButton style={{ marginRight: 16 }}>
              <IconFiles width={20} height={20} color="grey.600" />
            </IconButton> */}
            <FundamentoPopup
              onParentClick={(e) => e.stopPropagation()}
              triggeringComponent={
                <IconButton>
                  <IconDots
                    style={{ transform: "rotate(90deg)" }}
                    width={20}
                    height={20}
                  />
                </IconButton>
              }
            >
              <Box>
                <Box
                  className={styles.dotMenuItem}
                  onClick={() => handleExistingDataSet(datasetIndex)}
                >
                  <IconUpload2 width={16} height={16} />
                  <Typography variant="body2">Upload new files</Typography>
                </Box>

                <Box
                  className={styles.dotMenuItem}
                  color="error.main"
                  onClick={() => handleDeleteDataSet(datasetIndex)}
                  // onClick={() => deleteDataSet(dataset._id)}
                >
                  <IconTrash width={16} height={16} />
                  <Typography variant="body2">Delete forever</Typography>
                </Box>
              </Box>
            </FundamentoPopup>
          </Box>
        ),
      };
    });
    return tableData;
  }, [dataSets, dataSetName, uploadStatus, selectedDataSet]);

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row) {
      const dataSetIndex = row.id;
      const dataSet = dataSets[dataSetIndex];
      if (dataSet?.editable) {
        /**
         * If user is editing the dataset name and clicks on table row
         * then we are disabling the navigation as user is not completed the
         * edit dataset
         */
        event.stopPropagation();
      } else {
        navigate(`/dataset-detail/${dataSet._id}`);
      }
    }
  };

  const handleBeforeUnload = (event) => {
    if (
      uploadStatus?.status === "started" ||
      uploadStatus?.status === "processing"
    ) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  const trackUploadStatus = () => {
    if (uploadStatus?.status === "completed") {
      getCompanyDetails();
      getDataSets();
    }
  };

  useEffect(() => {
    trackUploadStatus();
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploadStatus]);

  return (
    <>
      {dataSets.length > 0 && (
        <FundamentoTable
          columns={columns}
          data={getTableData}
          className={styles.datasetList}
          tableOnClick={handleTableOnClick}
        />
      )}
      {!dataSetsLoading && dataSets.length === 0 && (
        <EmptyState
          title="No dataset found"
          description="We’ll collect all your new files here. Make sure to check again later"
        />
      )}
      <DeleteModal open={deleteModal} onClose={() => setDeleteModal(false)} />
      <input
        type="file"
        multiple
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={supportedFileTypes.join(",")}
      />
    </>
  );
};

DataSetsTable.propTypes = {
  refreshDataSet: PropTypes.any,
};

export default DataSetsTable;
