import { Box, IconButton, Typography } from "@mui/material";
import { PlayFilled } from "components/newSVG";
import { IconPause } from "components/SVG";
import { useRef, useState } from "react";
import theme from "theme";
import PropTypes from "prop-types";

const getFormattedTime = (dataInSeconds) => {
  const totalSeconds = Number(Math.ceil(dataInSeconds));

  const seconds = totalSeconds % 60;
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const hours = Math.floor(totalSeconds / 3600) % 24;

  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedHours = hours < 10 ? `0${hours}` : hours;

  let finalTime = "";

  if (hours) {
    finalTime = formattedHours + "h ";
  }

  if (minutes) {
    finalTime = finalTime + formattedMinutes + "m ";
  }

  if (seconds) {
    finalTime = finalTime + formattedSeconds + "s";
  }

  return finalTime;
};

const AudioPlayer = (props) => {
  const { url, type } = props;
  const audioRef = useRef();
  const [audioDetails, setAudioDetails] = useState({
    status: "pause",
    duration: 0,
  });

  const handleOnPlay = () => {
    audioRef.current.play();
    setAudioDetails({
      ...audioDetails,
      status: "play",
    });
  };

  const handleOnPause = () => {
    audioRef.current.pause();
    setAudioDetails({ ...audioDetails, status: "pause" });
  };

  const handleOnLoadMetaData = () => {
    setAudioDetails({
      ...audioDetails,
      duration: getFormattedTime(audioRef.current.duration),
    });
  };

  const handleOnEnded = () => {
    setAudioDetails({
      ...audioDetails,
      status: "pause",
    });
  };

  return (
    <Box display="flex" gap={4} alignItems="center">
      <Typography
        ml="auto"
        variant="caption"
        color={theme.palette.text.primary}
        fontWeight={400}
      >
        {audioDetails?.duration}
      </Typography>

      {audioDetails?.status === "pause" && (
        <IconButton sx={{ p: 0 }} onClick={handleOnPlay}>
          <PlayFilled height={24} width={24} />
        </IconButton>
      )}

      {audioDetails?.status === "play" && (
        <IconButton sx={{ p: 0 }} onClick={handleOnPause}>
          <IconPause height={24} width={24} />
        </IconButton>
      )}

      <audio
        controls
        ref={audioRef}
        onLoadedMetadata={handleOnLoadMetaData}
        onEnded={handleOnEnded}
        style={{ display: "none" }}
      >
        <source src={url} type={type} />
      </audio>
    </Box>
  );
};

AudioPlayer.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
};

export default AudioPlayer;
