import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronRight, Eye, Lock, Mail } from "react-feather";
import styles from "./index.module.css";
import { useState } from "react";
import { logIn as loginAPI } from "services";
import { Controller, useForm } from "react-hook-form";
import { useSkillrToast } from "context/toast";
import { useUserContext } from "context/user";
import { useLocation, useNavigate } from "react-router-dom";

const AgentLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const { showSkillrToast } = useSkillrToast();
  const { setToken } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const setTokenQueryParam = (tokenValue) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("token", tokenValue);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const onSubmit = async (data) => {
    try {
      const response = await loginAPI(data);
      if (response) {
        localStorage.setItem("agentToken", response?.token);
        setToken(response?.token);
        // set new query param in url named token
        setTokenQueryParam(response?.token);
      }
    } catch (error) {
      showSkillrToast("Login Failed", "error");
    }
  };

  return (
    <Box className={styles.container}>
      <Typography
        color="#0f0f0f"
        variant="h3"
        fontWeight={500}
        textAlign={"center"}
      >
        Sign in to Yara
      </Typography>

      <Box mt={10}>
        <Typography
          variant="caption"
          color={"text.primary"}
          style={{ paddingLeft: 14 }}
        >
          Email
        </Typography>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              variant="outlined"
              fullWidth
              error={errors.email}
              {...field}
              sx={{ "& div": { borderRadius: 22 }, mt: 2, mb: 5 }}
              InputProps={{
                startAdornment: <Mail width={16} height={16} />,
              }}
              placeholder="email@example.com"
            />
          )}
        />

        <Typography
          variant="caption"
          color={"text.primary"}
          style={{ paddingLeft: 14 }}
        >
          Password
        </Typography>
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              variant="outlined"
              fullWidth
              type={showPassword ? "text" : "password"}
              error={errors.password}
              {...field}
              sx={{ "& div": { borderRadius: 22 }, mt: 2 }}
              InputProps={{
                startAdornment: <Lock width={16} height={16} />,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <Eye width={16} height={16} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="********"
            />
          )}
        />
      </Box>

      <Button
        variant="outlined"
        fullWidth
        endIcon={<ChevronRight width={16} height={16} />}
        className={styles.gradient}
        style={{
          background: "linear-gradient(90deg, #51b9fa, #a910da)",
        }}
        onClick={handleSubmit(onSubmit)}
      >
        Sign In
      </Button>
    </Box>
  );
};

export default AgentLogin;
