import { IconButton, Typography } from "@mui/material";
import FlexBox from "components/FlexBox";
import React, { useEffect, useState } from "react";
import { Minus, Plus } from "react-feather";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { House } from "components/newSVG";
import theme from "theme";
import CustomHandle from "../../CustomHandle";

const StartNode = ({ data }) => {
  const { isLastNode, onPlusClick } = data;
  const [showPlus, setShowPlus] = useState();

  const handleClick = () => {
    if (!data.isLastNode) {
      data.onMinusClick();
    } else {
      data.onPlusClick();
    }
  };

  useEffect(() => {
    setShowPlus(isLastNode);
  }, [isLastNode]);

  return (
    <div className={styles.container}>
      <FlexBox columnGap={1} justifyContent="center">
        <House width={10} height={10} />
        <Typography fontSize={10} fontWeight={500} lineHeight={1}>
          start
        </Typography>
      </FlexBox>
      {/* <Handle
        type="source"
        position="bottom"
      /> */}

      <CustomHandle
        id="source"
        position="bottom"
        clickHandler={handleClick}
        icon={
          data.isLastNode ? (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: "-20%",
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      />

      {/* {showPlus && (
        <IconButton
          className={styles.plusButton}
          onClick={(e) => {
            e.stopPropagation();
            onPlusClick();
            // setShowPlus(false);
          }}
        >
          <Plus width={12} height={12} />
        </IconButton>
      )} */}
    </div>
  );
};

export default StartNode;
