import { useEffect, useState } from "react";
import { Mic } from "react-feather/dist";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import styles from "./index.module.css";
import { IconButton } from "@mui/material";

const SpeechToText = (props) => {
  const { onResult, type, active, onClick } = props;
  const { transcript, listening } = useSpeechRecognition();
  const [chatInput, setChatInput] = useState("");

  useEffect(() => {
    setChatInput(transcript);
  }, [transcript]);

  useEffect(() => {
    const chatInputArg = chatInput.trim();
    if (!listening) {
      active && onResult(chatInputArg);
      setChatInput("");
    }
  }, [listening, chatInput]);

  return (
    <IconButton
      className={`${styles.mic} ${type === "customer" ? styles.customer : ""} ${
        active ? styles.active : ""
      }`}
      onClick={() => {
        listening
          ? SpeechRecognition.stopListening()
          : SpeechRecognition.startListening();
        onClick();
      }}
    >
      <Mic width={20} height={20} />
      {/* <h5>{type === "customer" ? "Customer" : "Agent"}</h5> */}
    </IconButton>
  );
};

export default SpeechToText;
