import { Box, Button, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { IconPlus } from "components/SVG";
import theme from "theme";
import { useEffect, useState } from "react";
import CampaignList from "./components/CampaignList";
import {
  getCampaigns as getCampaignsApi,
  updateCampaign as updateCampaignApi,
  duplicateCampaign as duplicateCampaignApi,
  deleteCampaign as deleteCampaignApi,
} from "services";
import { useSkillrToast } from "context/toast";
import { useURLState } from "custom-hooks/useUrlState";
import EmptyState from "components/EmptyState";
import { Link } from "react-router-dom";

const countPerPage = 10;

const Campaign = () => {
  const [campaignData, setCampaignData] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const { showSkillrToast } = useSkillrToast();

  const getCampaings = async () => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams();
      queryParams.set("offset", (currentPage - 1) * countPerPage);
      queryParams.set("count", countPerPage);
      const campaignResponse = await getCampaignsApi(queryParams.toString());
      setCampaignData(campaignResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateCampaign = async (campaignId, payload) => {
    try {
      await updateCampaignApi(campaignId, payload);
      showSkillrToast("Campaign status updated", "success");
      getCampaings();
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const createDuplicateCampaign = async (campaignId) => {
    try {
      await duplicateCampaignApi(campaignId);
      showSkillrToast("Campaign duplicated", "success");
      getCampaings();
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const deleteCampaign = async (campaignId) => {
    try {
      await deleteCampaignApi(campaignId);
      showSkillrToast("Campaign deleted", "success");
      // Adjust currentPage to navigate to the previous page if the current campaign is deleted and only one record remains.
      if (campaignData?.data?.length === 1) {
        setCurrentPage(currentPage - 1);
      } else {
        getCampaings();
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    getCampaings();
  }, [currentPage]);

  return (
    <PageWrapper className={styles.container}>
      <Box padding={8}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h4" fontWeight={500}>
              Campaigns
            </Typography>
            <Typography variant="body2" color={theme.palette.grey[500]}>
              Manage your campaigns here
            </Typography>
          </Box>
          <Link to="/campaign/create">
            <Button
              variant="dark"
              startIcon={<IconPlus />}
              sx={{ height: 40, borderRadius: 12 }}
            >
              Create Campaign
            </Button>
          </Link>
        </Box>

        <Box mt={10}>
          {!loading && campaignData?.data?.length === 0 && (
            <Box
              sx={{
                height: "60vh",
                width: "100%",
              }}
            >
              <EmptyState title="No campaign found" />
            </Box>
          )}
          {!loading && campaignData?.data?.length > 0 && (
            <CampaignList
              campaignData={campaignData?.data}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPageCount={Math.ceil(
                campaignData?.totalRecords / countPerPage
              )}
              updateCampaign={updateCampaign}
              createDuplicateCampaign={createDuplicateCampaign}
              deleteCampaign={deleteCampaign}
            />
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Campaign;
