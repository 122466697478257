import {
  Box,
  Button,
  Dialog,
  Grow,
  IconButton,
  Typography,
} from "@mui/material";
import { ChevronRight, X, XCircle } from "react-feather";
import theme from "theme";
import FlexBox from "components/FlexBox";

const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  title,
  subtitle,
  list,
  icon,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Grow}
      PaperProps={{
        style: {
          width: 650,
          borderRadius: 20,
        },
      }}
    >
      <Box sx={{ textAlign: "center", position: "relative", padding: 10 }}>
        <IconButton
          sx={{ position: "absolute", right: 15, top: 15 }}
          onClick={onClose}
        >
          <X width={20} height={20} sx={{ color: theme.palette.grey[400] }} />
        </IconButton>

        {title && (
          <Typography variant="h3" fontWeight={500} mt={4}>
            {title}
          </Typography>
        )}

        {subtitle && (
          <Typography variant="body2" color="grey.600" mb={8} mt={1}>
            {subtitle}
          </Typography>
        )}

        <Box sx={{ width: "80%", margin: "0 auto" }}>
          {list &&
            list.map((item, index) => (
              <FlexBox
                key={index}
                sx={{
                  padding: "18px 0 18px 40px",
                }}
              >
                {icon ? (
                  <Box sx={{ "& > *": { flexShrink: 0 } }}>{icon}</Box>
                ) : (
                  <XCircle
                    color={theme.palette.error.main}
                    width={20}
                    height={20}
                    sx={{ flexShrink: 0 }}
                  />
                )}

                <Typography variant="body2" align="left">
                  {item}
                </Typography>
              </FlexBox>
            ))}
        </Box>
        <Box
          mt={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "22px",
          }}
        >
          <Button variant="whiteRound" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dark"
            onClick={() => {
              onConfirm();
              onClose();
            }}
            endIcon={<ChevronRight />}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmModal;
