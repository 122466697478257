import { Box, Button, IconButton, Typography } from "@mui/material";
import FunCollapse from "components/FunCollapse";
import { IconPlus } from "components/SVG";
import { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import theme from "theme";
import PropTypes from "prop-types";
import CallOutCome from "../CallOutCome";
import AddCallOutCome from "../AddCallOutCome";
import { TrashSimple } from "components/newSVG";
import { defaultOutcomes } from "text-constants/campaign";

const CreateCampaignStep2 = forwardRef((props, ref) => {
  const { onSave, disabled, campaignDetails } = props;

  const customOutcomesArray = useMemo(() => {
    return campaignDetails?.customOutcomes.map((co) => ({
      id: co.name,
      name: co.name,
    }));
  }, []);

  // This combined outcomes contains both default and custom outcomes
  // and we are doing it because we need to show both outcomes.
  const combinedOutcomes = useMemo(() => {
    return [...defaultOutcomes, ...customOutcomesArray];
  }, []);

  const methods = useForm({
    defaultValues: {
      redialConfiguration:
        campaignDetails?.redialConfiguration ??
        [
          // [
          //   {
          //     repeatTimeHour: "1",
          //     repeatTimeMinute: "30",
          //     type: "interval",
          //     callOutcomeType: "noAnswered",
          //   },
          // ],
        ],
      modal: false,
      selectedRedialIndex: 0,
      outcomeOptions: combinedOutcomes,
    },
  });

  const { control, handleSubmit, reset, setValue, getValues, watch } = methods;

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "redialConfiguration",
  });

  const handleAddCounter = () => {
    append([
      [
        {
          repeatTimeHour: "1",
          repeatTimeMinute: "30",
          type: "interval",
          callOutcomeType: "noAnswered",
        },
      ],
    ]);
  };

  const handleOnSave = (data) => {
    // onSave(data);
    onSave({
      redialConfiguration: data.redialConfiguration,
    });
  };

  const validateForm = () => {
    handleSubmit(handleOnSave)();
  };

  const handleAddCallOutcome = (redialAttemptIndex) => {
    // Getting all the outcomes present
    const existingOutcomesNames = getValues(
      `redialConfiguration[${redialAttemptIndex}]`
    ).map((eoc) => eoc.callOutcomeType);

    // Filtering out the outcomes which are already used and using the rest
    const filteredOutcomes = getValues("outcomeOptions").filter(
      (oc) => !existingOutcomesNames.includes(oc?.name)
    );

    setValue("selectedRedialIndex", redialAttemptIndex);
    setValue("modal", true);
    setValue("outcomeOptions", filteredOutcomes);
  };

  const handleOnCloseAddCallOutCome = () => {
    setValue("modal", false);
    setValue("selectedRedialIndex", null);
    setValue("outcomeOptions", combinedOutcomes);
  };

  const handleOnSaveCallOutCome = (data) => {
    const selectedRedialIndex = getValues("selectedRedialIndex");
    const currentOutcomes = getValues(
      `redialConfiguration[${selectedRedialIndex}]`
    );

    const newOutcomes = data?.outComes?.map((outcome) => ({
      repeatTimeHour: data?.hours,
      repeatTimeMinute: data?.minutes,
      type: data?.type,
      callOutcomeType: outcome?.name,
    }));

    update(selectedRedialIndex, [...currentOutcomes, ...newOutcomes]);
    handleOnCloseAddCallOutCome();
  };

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  const preLoadData = () => {
    if (campaignDetails?.redialConfiguration?.length > 0) {
      // ["noAnswered", "rejected", "busy", "networkError", ...]
      const combinedOutcomesNames = combinedOutcomes.map(
        (combinedOutcome) => combinedOutcome.name
      );

      // Here we are checking and filtering the redial configuration outcome
      // so we are checking that each outcomes is either of default outcome or custom outcome
      // if its neither from both of them, then we are removing that outcome.
      // This will happen when user deletes the customOutcome from step1 and that outcome was already added
      // then we need to remove from redial configuration outcome.
      const newRedialConfiguration = campaignDetails?.redialConfiguration.map(
        (rcInterval) =>
          rcInterval.filter((oc) =>
            combinedOutcomesNames.includes(oc.callOutcomeType)
          )
      );

      reset({
        redialConfiguration: newRedialConfiguration,
        modal: false,
        selectedRedialIndex: 0,
        outcomeOptions: combinedOutcomes,
      });
    }
  };

  useEffect(() => {
    preLoadData();
  }, [campaignDetails]);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {fields.map(({ id }, index) => (
        <Box
          key={id}
          sx={{
            border: `1px solid ${theme.palette.grey[100]}`,
            padding: 4,
            borderRadius: 3,
          }}
        >
          <FunCollapse
            collapsed={true}
            iconPosition="end"
            header={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2">
                  Call Redial Attempt {index + 1}
                </Typography>
                {!disabled && (
                  <IconButton onClick={() => remove(index)}>
                    <TrashSimple width={16} height={16} />
                  </IconButton>
                )}
              </Box>
            }
          >
            <FormProvider {...methods}>
              <CallOutCome
                path={`redialConfiguration[${index}]`}
                disabled={disabled}
              />
            </FormProvider>
            {!disabled && (
              <Button
                sx={{ mt: 4, alignSelf: "flex-start" }}
                variant="outline"
                startIcon={<IconPlus width={16} height={16} />}
                onClick={() => handleAddCallOutcome(index)}
              >
                <Typography variant="caption" sx={{ color: "#505050" }}>
                  Add Call Outcome
                </Typography>
              </Button>
            )}
          </FunCollapse>
        </Box>
      ))}

      {!disabled && (
        <Button
          variant="outlined"
          startIcon={<IconPlus width={16} height={16} />}
          sx={{ alignSelf: "center" }}
          onClick={handleAddCounter}
        >
          <Typography variant="caption" sx={{ color: "#505050" }}>
            Add Redial Attempt
          </Typography>
        </Button>
      )}

      <AddCallOutCome
        open={watch("modal")}
        onClose={handleOnCloseAddCallOutCome}
        onSave={handleOnSaveCallOutCome}
        outComeOptions={getValues("outcomeOptions")}
      />
    </Box>
  );
});

CreateCampaignStep2.propTypes = {
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  campaignDetails: PropTypes.object,
};

CreateCampaignStep2.defaultProps = {
  onSave: () => {},
  disabled: false,
  campaignDetails: {},
};

export default CreateCampaignStep2;
