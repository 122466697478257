import styles from "./index.module.css";
import {
  Box,
  Button,
  Dialog,
  Grow,
  IconButton,
  MobileStepper,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronRight, PlusCircle, X } from "react-feather";
import theme from "theme";
import FlexBox from "components/FlexBox";
import { useEffect, useState } from "react";
import { getProcess, createProcess as createProcessAPI } from "services";
import { useSkillrToast } from "context/toast";
import { useNavigate } from "react-router-dom";

const AllProcess = () => {
  const [textfieldValue, setTextfieldValue] = useState("");
  const { showSkillrToast } = useSkillrToast();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [processList, setProcessList] = useState([]);
  const navigate = useNavigate();

  const getAllProcess = async () => {
    try {
      const response = await getProcess();
      setProcessList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const createProcess = async () => {
    try {
      const response = await createProcessAPI({ name: textfieldValue.trim() });
      if (response.status === 200) {
        showSkillrToast("Process created successfully", "success");
      }
      setShowCreateModal(false);
      getAllProcess();
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 409) {
        showSkillrToast("Process name already exists", "error");
      } else {
        const errorMessage = error?.message ?? "Something went wrong";
        showSkillrToast(errorMessage, "error");
      }
    }
  };

  const getStep = (status) => {
    switch (status) {
      case "readyForQueGen":
        return 0;
      case "readyForGroup":
        return 1;
      case "readyForAnsGen":
        return 2;
      case "readyForAnnotation":
        return 3;
      default:
        return 0;
    }
  };

  useEffect(() => {
    getAllProcess();
  }, []);

  return (
    <>
      <Box className={styles.cardContainer}>
        <Grow in={true} timeout={300} mountOnEnter>
          <Box
            className={styles.generateCard}
            onClick={() => setShowCreateModal(true)}
          >
            <PlusCircle size={32} color="#C7C7C7" />
            <Typography
              variant="body1"
              color="rgba(15,15,15,0.3)"
              fontWeight={500}
            >
              Generate data
            </Typography>
          </Box>
        </Grow>

        {processList?.length > 0 &&
          processList?.map((process) => (
            <Grow in={true} timeout={300} key={process._id} mountOnEnter>
              <div>
                <Box
                  className={styles.card}
                  onClick={() => navigate(`${process._id}`)}
                >
                  <Typography variant="subtitle1" fontWeight={500}>
                    {process.name}
                  </Typography>

                  {/* <Typography
                  variant="caption"
                  color={theme.palette.tag.main}
                  mb={4}
                >
                  All dues cleared
                </Typography> */}

                  <Box my={4}>
                    <FlexBox columnGap={1}>
                      <Typography variant="caption" color="#0f0f0fb3">
                        Status
                      </Typography>
                      <MobileStepper
                        variant="dots"
                        steps={4}
                        position="static"
                        activeStep={
                          process?.status ? getStep(process?.status) : null
                        }
                        sx={{
                          "& .MuiMobileStepper-dot": {
                            backgroundColor: "#0f0f0f1f",
                            width: 5,
                            height: 5,
                          },
                          maxWidth: 400,
                          flexGrow: 1,
                          "& .MuiMobileStepper-dotActive": {
                            backgroundColor: "#0f0f0f",
                          },
                        }}
                      />
                    </FlexBox>

                    <Typography variant="body2" fontWeight={500}>
                      {process?.status === "readyForQueGen" &&
                        "Ready for question generation"}
                      {process?.status === "readyForGroup" &&
                        "Ready for grouping"}
                      {process?.status === "readyForAnsGen" &&
                        "Ready for answer generation"}
                      {process?.status === "readyForAnnotation" &&
                        "Ready for annotation"}
                      {!process?.status && "Upload files to start processing"}
                    </Typography>
                  </Box>

                  {/* <Box mb={4}>
                  <Typography variant="caption" color="#0f0f0fb3">
                    Billing
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    500 minutes processed
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    1200 FAQs generated
                  </Typography>
                </Box> */}

                  <Button
                    variant="outlined"
                    endIcon={<ChevronRight width={12} height={12} />}
                    sx={{ borderRadius: 82, minWidth: 0, mt: "auto" }}
                  >
                    View
                  </Button>
                </Box>
              </div>
            </Grow>
          ))}
      </Box>

      {/* create new process modal */}
      <Dialog
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        TransitionComponent={Grow}
        PaperProps={{
          sx: {
            width: 500,
            padding: 10,
            borderRadius: 5,
            position: "relative",
          },
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 20, right: 20 }}
          onClick={() => setShowCreateModal(false)}
        >
          <X />
        </IconButton>

        <Typography align="center" variant="h4" fontWeight={500}>
          Create process
        </Typography>

        <Typography
          variant="caption"
          color={theme.palette.grey[600]}
          sx={{ mt: 8, ml: 2 }}
        >
          Process name *
        </Typography>
        <TextField
          autofocus
          fullWidth
          placeholder="Enter process name here"
          sx={{ "& >div": { borderRadius: 82 }, mt: 1 }}
          value={textfieldValue}
          onChange={(e) => setTextfieldValue(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              createProcess();
            }
          }}
        />

        <FlexBox justifyContent="center" mt={4}>
          <Button
            variant="whiteRound"
            onClick={() => {
              setShowCreateModal(false);
              setTextfieldValue("");
            }}
          >
            Cancel
          </Button>

          <Button
            variant="dark"
            sx={{ borderRadius: 82 }}
            endIcon={<ChevronRight />}
            onClick={() => {
              createProcess();
              setTextfieldValue("");
            }}
            disabled={!textfieldValue}
          >
            Create
          </Button>
        </FlexBox>
      </Dialog>
    </>
  );
};

export default AllProcess;
