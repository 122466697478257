import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import theme from "./theme/index";
import UserProvider from "context/user";
import SnackbarProvider from "context/toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientID } from "text-constants/common";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <SnackbarProvider>
          <GoogleOAuthProvider clientId={googleClientID}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <UserProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </UserProvider>
            </LocalizationProvider>
          </GoogleOAuthProvider>
        </SnackbarProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
