import theme from "theme";

const styles = {
  dialogPaper: {
    width: 800,
    borderRadius: "20px",
    overflowY: "auto",
    position: "relative",
    height: "80vh",
  },
  titleContainer: {
    textAlign: "center",
    pt: 8,
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  contentContainer: {
    border: `1px solid ${theme.palette.borderColor.light}`,
    borderRadius: "12px",
    padding: 6,
  },
  button: {
    borderRadius: 4,
    padding: "12px 24px",
  },
};

export default styles;
