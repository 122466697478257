import { Fade, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import theme from "theme";
import FlexBox from "components/FlexBox";
import CustomHandle from "../../CustomHandle";
import TargetHandle from "../../TargetHandle";
import NodeBase from "../../NodeBase";

const DecisionNode = (props) => {
  const data = props.data;

  // const [showPlus, setShowPlus] = useState();
  const [isParentHovered, setIsParentHovered] = useState(false);

  // useEffect(() => {
  //   setShowPlus(isLastNode);
  // }, [isLastNode]);

  return (
    <NodeBase
      isActive={data.isActive}
      onDelete={data.onDelete}
      localizationMode={data.localizationMode}
      fullyLocalized={data.fullyLocalized}
      error={data.error}
    >
      <Typography variant="body2" fontWeight={500}>
        {data.name
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())
          .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography variant="body2" fontSize={10} mb={3} color="text.secondary">
        {data.node}
      </Typography>

      <FlexBox
        sx={{
          p: "16px",
          backgroundColor: theme.palette.grey[100],
          borderRadius: "8px",
          mt: 4,
        }}
      >
        <Typography variant="caption">True</Typography>

        {data.flowType !== "triggerWorkflow" && (
          <CustomHandle
            id="true"
            style={{ right: "15%" }}
            position={"right"}
            icon={
              data.trueFlowAdded ? (
                <Minus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              ) : (
                <Plus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              )
            }
            clickHandler={() => {
              data.trueFlowAdded
                ? data.onMinusClick("true")
                : data.onTrueClick({ x: props.xPos, y: props.yPos });
            }}
          />
        )}
      </FlexBox>
      <FlexBox
        sx={{
          p: "16px",
          backgroundColor: theme.palette.grey[100],
          borderRadius: "8px",
          mt: 2,
        }}
      >
        <Typography variant="caption">False</Typography>

        {data.flowType !== "triggerWorkflow" && (
          <CustomHandle
            id="false"
            style={{ right: "15%" }}
            position={"right"}
            icon={
              data.falseFlowAdded ? (
                <Minus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              ) : (
                <Plus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              )
            }
            clickHandler={() => {
              data.falseFlowAdded
                ? data.onMinusClick("false")
                : data.onFalseClick({ x: props.xPos, y: props.yPos });
            }}
          />
        )}
      </FlexBox>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,

          top: 5,
        }}
      />
    </NodeBase>
  );
};

export default DecisionNode;
