import { postRequest } from "./common";

export default function generateQuestions(
  datasetId,
  params,
  data,
  config = {}
) {
  return postRequest(
    `/media/generateQuestions/${datasetId}?${params}`,
    data,
    config
  );
}
