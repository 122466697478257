import { Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { TextField, Typography, Checkbox, Box } from "@mui/material";
import { RedAlert } from "components/SVG";
import PropTypes from "prop-types";

const AutoComplete = (props) => {
  const {
    optionList,
    name,
    label,
    error,
    rules,
    renderOptionKey,
    inputType,
    value,
    onChange,
    onClick,
    defaultValue,
    renderOption: customRenderOption,
    ...rest
  } = props;

  const autoCompleteFilter = createFilterOptions();

  const defaultRenderOption = (props, option, { selected }) => (
    <li {...props}>
      {rest?.multiple ? (
        <>
          <Checkbox
            color="primary"
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography variant="body2">{option[renderOptionKey]}</Typography>
        </>
      ) : (
        <Typography variant="body2">{option[renderOptionKey]}</Typography>
      )}
    </li>
  );

  return (
    <Autocomplete
      defaultValue={defaultValue}
      disablePortal
      name={name}
      options={optionList}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      value={value}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        return option[renderOptionKey];
      }}
      renderOption={customRenderOption || defaultRenderOption}
      filterOptions={(options, params) => {
        const filtered = autoCompleteFilter(options, params);
        // If passed value not found, suggest to create new one
        if (
          rest?.freeSolo &&
          filtered.length === 0 &&
          params.inputValue.trim() !== ""
        ) {
          filtered.push({
            inputValue: params.inputValue,
            [renderOptionKey]: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      isOptionEqualToValue={(option, valueId) => option.id === valueId.id}
      onChange={(e, data) => onChange(data)}
      onClick={onClick}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!inputType ? label : undefined}
          placeholder={label}
          variant="outlined"
          error={error && Object.keys(error).length ? true : false}
          helperText={
            error && (
              <Box component="span" display="flex" alignItems="center">
                <RedAlert style={{ marginRight: 8 }} />
                {error.message}
              </Box>
            )
          }
        />
      )}
      {...rest}
    />
  );
};

AutoComplete.propTypes = {
  optionList: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.object,
  rules: PropTypes.object,
  renderOptionKey: PropTypes.string,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
  renderOption: PropTypes.func,
};

AutoComplete.defaultProps = {
  optionList: [],
  rules: {},
  renderOptionKey: "name",
  inputType: "standard",
  onChange: () => {},
};

export default AutoComplete;
