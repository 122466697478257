import { Box, LinearProgress, Typography } from "@mui/material";
import { IconUpload2 } from "components/SVG";
import { useSkillrToast } from "context/toast";
import { useRef, useState } from "react";
import uploadAudioFileApi from "services/uploadAudioFile";
import PropTypes from "prop-types";
import { getAudioDuration, getNewFileName } from "utils/utils";

const UploadFile = (props) => {
  const { workFlowId, supportedFileTypes, maxAudioTime, onUploadComplete } =
    props;

  const { showSkillrToast } = useSkillrToast();
  const fileInputRef = useRef();
  const [uploadProgress, setUploadProgress] = useState();

  const handleUploadProgress = (progressEvent) => {
    if (progressEvent?.loaded > 0) {
      setUploadProgress(
        Math.floor((progressEvent?.loaded / progressEvent?.total) * 100)
      );
    }
  };

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("audioFile", file);
      const config = {
        onUploadProgress: handleUploadProgress,
      };
      const uploadResponse = await uploadAudioFileApi(
        workFlowId,
        formData,
        config
      );
      fileInputRef.current.value = "";
      onUploadComplete(uploadResponse);
    } catch (error) {
      fileInputRef.current.value = "";
      setUploadProgress();
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      const renamedFile = getNewFileName(file);
      const audioDuration = await getAudioDuration(renamedFile);
      if (audioDuration < maxAudioTime) {
        uploadFile(renamedFile);
      } else {
        showSkillrToast("Audio duration should not exceed 20 seconds", "error");
      }
    } catch (error) {
      showSkillrToast(error?.message, "error");
    }
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Box
        display="flex"
        sx={{
          border: `1px dashed #0f0f0f`,
          background: "#fff",
          gap: 2,
          padding: 4,
          borderRadius: 3,
          cursor: "pointer",
        }}
        onClick={handleFileUpload}
      >
        {uploadProgress ? (
          <LinearProgress
            value={uploadProgress}
            color="primary"
            variant="determinate"
            style={{ width: "100%" }}
            sx={{
              height: 5,
              borderRadius: 5,
              bgcolor: "#E9E9E9",
              "& .MuiLinearProgress-bar": {
                borderRadius: 5,
                bgcolor: "#0F0F0F",
              },
            }}
          />
        ) : (
          <>
            <IconUpload2 width={16} height={16} />
            <Typography variant="caption" color="#0f0f0f">
              Upload File
            </Typography>

            <Typography variant="caption" sx={{ fontSize: 10, ml: "auto" }}>
              Max time: 20 Sec
            </Typography>
          </>
        )}
      </Box>

      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={fileInputRef}
        accept={supportedFileTypes.join(",")}
      />
    </>
  );
};

UploadFile.propTypes = {
  workFlowId: PropTypes.string,
  supportedFileTypes: PropTypes.array,
  maxAudioTime: PropTypes.number,
  onUploadComplete: PropTypes.func.isRequired,
};

UploadFile.defaultProps = {
  supportedFileTypes: ["audio/wav", "audio/mpeg"],
  maxAudioTime: 20,
};

export default UploadFile;
