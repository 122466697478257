import { Handle } from "reactflow";
import styles from "./index.module.css";

const CustomHandle = ({ id, style, position, clickHandler, icon }) => {
  return (
    <div
      className={styles.handleContainer}
      onClick={(event) => {
        event.stopPropagation();
        clickHandler();
      }}
      style={style}
    >
      <Handle
        id={id}
        type="source"
        position={position}
        isConnectable={true}
        className={styles.handle}
      />
      {icon}
    </div>
  );
};

export default CustomHandle;
