import { useEffect, useRef, useState } from "react";
// import "../../styles.css";
import {
  Bell,
  Edit3,
  Loader,
  Phone,
  PhoneOff,
  RefreshCw,
  Settings,
  User,
} from "react-feather";
import axios from "axios";
import SpeechToText from "components/SpeechToText";
import { Wand } from "components/newSVG";
import Sidebar from "components/Sidebar";
const apiURL = process.env.REACT_APP_BACKEND_API_ENDPOINT;

const Demo = () => {
  const [currentAgent, setCurrentAgent] = useState("bot");
  const [currentLang, setCurrentLang] = useState("english");
  const [value, setValue] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [messages, setMessages] = useState([]);
  const [callStatus, setCallStatus] = useState(false);
  const [agentActive, setAgentActive] = useState(false);
  const [customerActive, setCustomerActive] = useState(false);
  const [apiResponseTime, setApiResponeTime] = useState();
  const [summary, setSummary] = useState();
  const [userId, setUserId] = useState();
  const [sentiment, setSentiment] = useState("Neutral");
  const [loading, setLoading] = useState(false);
  // const timerRef = useRef();

  // const { listen, listening, stop } = useSpeechRecognition({
  //   onResult: (result) => {
  //     console.log("on result", result);
  //     setValue(result);
  //   },
  // });
  const [time, setTime] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const messagesContainer = useRef(null);
  const assistantContainer = useRef(null);

  const startTimer = () => {
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  function formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const displayTimer = () => {
    const formattedTime = formatTime(time);
    return formattedTime;
  };

  const addNewMessage = async (message, type) => {
    if (message.trim() === "") {
      return;
    }
    let newMessage = {
      id: messages.length + 1,
      message,
      timestamp: displayTimer(),
      type,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setValue("");
  };

  const getAudio = async (text) => {
    const response = await axios.post(apiURL + "/audio", {
      text,
    });
    const base64AudioContent = response.data;
    const audio = new Audio("data:audio/wav;base64," + base64AudioContent);
    audio.play();
  };

  const chatWithAgent = async (question) => {
    try {
      const startTime = new Date();
      const { data: response } = await axios.post(apiURL + "/api/chat", {
        question,
        userId,
      });
      const endTime = new Date();
      const difference = Math.round((endTime - startTime) / 1000);

      let newMessage = {
        id: messages.length + 1,
        message: response?.message,
        timestamp: displayTimer(),
        type: "agent",
      };
      setSentiment(response?.sentiment);
      setUserId(response?.userId);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setValue("");
      setApiResponeTime(difference);
      getAudio(response?.message);
    } catch (error) {
      console.log("error", error);
    }
  };

  const createSummary = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(apiURL + "/api/summary", {
        question: "",
      });
      // let newMessage = {
      //   id: messages.length + 1,
      //   message: response?.message,
      //   timestamp: new Date().toISOString(),
      //   type: "summary",
      // };
      // setMessages((prevMessages) => [...prevMessages, newMessage]);
      setSummary(response?.message);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const resetPrompt = async () => {
    try {
      await axios.post(apiURL + "/reset-prompt");
    } catch (error) {
      console.error(error);
    }
  };

  const containKeywords = (text, keywords) => {
    const regex = new RegExp(keywords.join("|"), "i");
    return regex.test(text);
  };

  const handleStartCall = () => {
    setCallStatus(true);
    startTimer();
  };

  const handleEndCall = () => {
    setCallStatus(false);
    stopTimer();
    setTime(0);
    addNewMessage("Your call has ended.", "end");
  };

  const handleRefreshButton = () => {
    setMessages([]);
    setCallStatus(false);
    stopTimer();
    setSummary();
    setUserId();
    setApiResponeTime();
    resetPrompt();
  };

  useEffect(() => {
    if (messages[messages.length - 1]?.type === "customer") {
      chatWithAgent(messages[messages.length - 1].message);
    }
  }, [messages]);

  useEffect(() => {
    if (callStatus) {
      if (currentAgent === "bot") {
        setMessages([
          {
            id: 1,
            message: "Hi, How may I assist you",
            timestamp: displayTimer(),
            type: "agent",
          },
        ]);
        getAudio("Hi, How may I assist you");
      } else {
        setMessages([]);
      }
    }
  }, [currentAgent, callStatus]);

  // const messagesContainer = useRef(null);
  // useEffect(
  //   () =>
  //     messagesContainer?.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //     }),
  //   [messages]
  // );

  // const assistanceContainer = useRef(null);
  // useEffect(
  //   () =>
  //     assistanceContainer?.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //     }),
  //   [messages]
  // );

  const scrollToBottom = () => {
    messagesContainer.current.scrollTop =
      messagesContainer.current.scrollHeight;
  };

  const scrollToBottomForAssistance = () => {
    assistantContainer.current.scrollTop =
      assistantContainer.current.scrollHeight;
  };

  const configureObserver = () => {
    const ref = messagesContainer.current;
    // Configuration options for the observer
    const observerConfig = {
      childList: true, // Observe changes to the child nodes of the parent element
      subtree: true, // Observe changes in all descendants of the parent element
    };
    const containerObserverInstance = new MutationObserver((mutationList) => {
      scrollToBottom();
    });
    containerObserverInstance.observe(ref, observerConfig);
    return containerObserverInstance;
  };

  const configureObserverForAssistance = () => {
    const ref = assistantContainer.current;
    // Configuration options for the observer
    const observerConfig = {
      childList: true, // Observe changes to the child nodes of the parent element
      subtree: true, // Observe changes in all descendants of the parent element
    };
    const containerObserverInstance = new MutationObserver((mutationList) => {
      scrollToBottomForAssistance();
    });
    containerObserverInstance.observe(ref, observerConfig);
    return containerObserverInstance;
  };

  useEffect(() => {
    const observer = configureObserver();
    const observer2 = configureObserverForAssistance();

    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <div className="App">
      {/* <aside>
        <h3 className="flexcenter heading" style={{}}>
          <img
            src={
              "https://flow-v1-assessmentlibrary-icons.s3.ap-south-1.amazonaws.com/FundamentoLogo.svg"
            }
            width={26}
            height={26}
            alt="Fundamento logo"
            style={{ marginRight: 6 }}
          />
          Fundamento
        </h3>

        <div className="customer-details">
          <div className="customer-details-item">
            <p className="body2">Customer</p>
            <p className="subtitle2">Rohan Sen</p>
          </div>
          <div className="customer-details-item">
            <p className="body2">Account ID</p>
            <p className="subtitle2">MMB-786955</p>
          </div>

          <div className="customer-details-item">
            <p className="body2">Phone Number</p>
            <p className="subtitle2">9876543210</p>
          </div>

          <div className="customer-details-item">
            <p className="body2">Email</p>
            <p className="subtitle2">rohan@gmail.com</p>
          </div>

          <div className="customer-details-item">
            <p className="body2">Avg response time</p>
            <p className="subtitle2">
              {apiResponseTime ? `${apiResponseTime} secs` : "-"}
            </p>
          </div>

          <div className="customer-details-item">
            <p className="body2">Current sentiment</p>
            <p className="subtitle2"> {sentiment}</p>
          </div>
        </div>

        <div style={{ marginTop: "auto" }}>
          <div className="aside-button">
            <Bell width={16} />
            Notifications
          </div>
          <div className="aside-button">
            <Settings width={16} />
            Settings
          </div>
          <div className="aside-button" style={{ color: "#0f0f0f" }}>
            <User width={16} />
            Sumit Rajput
          </div>
        </div>
      </aside> */}
      <Sidebar />

      <main>
        <div className="content">
          <div
            style={{
              width: "50%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="transcript-box">
              <div className="call-controls">
                {!callStatus && (
                  <button
                    className="call-button send"
                    onClick={handleStartCall}
                  >
                    <Phone fill="white" width={16} height={16} />
                    Start call
                  </button>
                )}
                {callStatus && (
                  <>
                    <div className="flexcenter" style={{ gap: 12 }}>
                      <SpeechToText
                        onResult={(result) => {
                          console.log("result-customer", result);
                          addNewMessage(result, "customer");
                          setCustomerActive(false);
                        }}
                        active={customerActive}
                        onClick={() => setCustomerActive(true)}
                        type="customer"
                      />
                      <p>Customer mic</p>
                    </div>
                    <p
                      className="body2"
                      style={{ marginLeft: "auto", marginRight: 16 }}
                    >
                      {formatTime(time)}
                    </p>
                    <button className="call-button end" onClick={handleEndCall}>
                      <PhoneOff fill="white" width={16} height={16} />
                      End call
                    </button>
                  </>
                )}
                {/* <div className="mode-selector">
                    <p>Select mode</p>
                    <p className="body2">Pick from the available options below</p>
                    <div
                      className="flexcenter"
                      style={{ width: "max-content", marginTop: 16 }}
                    >
                      <div
                        className="flexcenter"
                        style={{ width: "max-content", gap: 16 }}
                      >
                        <input type="radio" /> Human Agent
                      </div>
                      <div
                        className="flexcenter"
                        style={{ width: "max-content", gap: 16 }}
                      >
                        <input type="radio" /> Virtual Agent
                      </div>
                    </div>
                  </div> */}
              </div>
              <div className="flexcenter">
                <p className="subtitle2">Call Transcript</p>

                <span
                  style={{ marginLeft: "auto", position: "relative" }}
                  onClick={handleRefreshButton}
                >
                  <RefreshCw
                    width={16}
                    className="tooltip-action"
                    style={{ cursor: "pointer" }}
                  />
                  <div className="tooltip">Clear call history</div>
                </span>
              </div>
              <div
                style={{ flex: 1, overflowY: "auto" }}
                ref={messagesContainer}
              >
                <div>
                  {messages.map((message) => (
                    <div className={`message`}>
                      <div style={{ width: "22%" }}>
                        <div className={`avatar ${message.type}`}>
                          {message.type === "end"
                            ? ""
                            : message.type === "customer"
                            ? "Customer"
                            : "Agent"}
                        </div>
                        {/* <div className={`avatar customer`}>Customer</div> */}
                      </div>

                      <div style={{ flex: 1 }}>
                        <p>
                          {message.message}
                          {message.type === "end" && (
                            <span
                              style={{
                                cursor: "pointer",
                                fontStyle: "italic",
                              }}
                              onClick={createSummary}
                            >
                              {" "}
                              Please click here to generate summary of this
                              call.
                            </span>
                          )}
                        </p>
                      </div>

                      <div style={{ width: "13%" }}>
                        <p className="timestamp">{message.timestamp}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="right" ref={assistantContainer}>
            <div className="yara-assistance" style={{ padding: "16px 24px" }}>
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#fbfbfb",
                  padding: "20px 0",
                }}
              >
                <p
                  className="flexcenter subtitle2"
                  style={{ marginBottom: 16 }}
                >
                  Yara Assistance
                </p>

                <div className="flexcenter">
                  <button
                    className={`action-button ${
                      activeTab === 0 ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(0)}
                  >
                    <Edit3 width={16} />
                    Suggested responses
                  </button>
                  <button
                    className={`action-button ${
                      activeTab === 1 ? "active" : ""
                    }`}
                    // onClick={createSummary}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Phone width={16} /> Call summary
                  </button>
                </div>
              </div>
              {activeTab === 0 && (
                <div>
                  {messages?.map(
                    (message) =>
                      message.type === "agent" && (
                        <div className="yara-suggestion">
                          <p className="yara">Yara</p>

                          <div className="yara-message">
                            {message.type === "agent"
                              ? "Suggested response: "
                              : "Summary: "}
                            {message.message}
                          </div>

                          {containKeywords(message.message, [
                            "Call me Back",
                            "Schedule callback",
                            "Forward call",
                          ]) && (
                            <div className="action-buttons">
                              <button className={`action-button`}>
                                Schedule Callback
                                {console.log(activeTab)}
                              </button>
                              <button className={`action-button`}>
                                Forward Call
                              </button>
                            </div>
                          )}
                        </div>
                      )
                  )}
                </div>
              )}
              {activeTab === 1 && (
                <div style={{ height: "80%", width: "100%" }}>
                  {!summary && (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="action-button wand"
                        onClick={createSummary}
                        style={{ minWidth: 170 }}
                      >
                        {loading ? (
                          <Loader width={18} height={18} />
                        ) : (
                          <>
                            <Wand width={18} height={24} />
                            Generate summary
                          </>
                        )}
                      </button>
                    </div>
                  )}
                  {summary && (
                    <div className="yara-suggestion">
                      <p className="yara">Yara</p>

                      <div className="yara-message">
                        Summary:
                        {summary}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Demo;
