import PageWrapper from "components/PageWrapper";
import Header from "components/Header";
import styles from "./index.module.css";
import { Box, Button, Grow, Typography } from "@mui/material";
import { ChevronRight } from "react-feather";
import FlexBox from "components/FlexBox";
import { useEffect, useState } from "react";
import YaraImage from "assets/images/yara-bot.png";
import ConfigureBot from "./ConfigureBot";
import { getAllBots } from "services";

const Bots = () => {
  const tabs = [];
  const [bots, setBots] = useState([]);
  const [configureBotDrawer, setConfigureBotDrawer] = useState(false);
  const [selectedBot, setSelectedBot] = useState(null);

  const handleOnConfigure = (id) => {
    setConfigureBotDrawer(true);
    setSelectedBot(bots.find((bot) => bot._id === id));
  };

  const handleOnCloseConfigureDrawer = () => {
    setConfigureBotDrawer(false);
  };

  const fetchAllBots = async () => {
    try {
      const response = await getAllBots();
      setBots(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = (data) => {
    setBots((prev) => {
      const newBots = prev.map((bot) => {
        if (bot._id === selectedBot._id) {
          return { ...bot, ...data };
        }
        return bot;
      });
      return newBots;
    });
  };

  useEffect(() => {
    fetchAllBots();
  }, []);

  return (
    <PageWrapper className={styles.container}>
      <Box>
        <Header
          title="Deploy"
          subTitle="Customise agent behaviour and deploy virtual agent."
          tabs={tabs}
          defaultActiveTab="generate"
          onTabChange={() => {}}
        />
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className={styles.cardContainer}>
          {/* <Box className={styles.generateCard} onClick={() => {}}>
            <PlusCircle size={32} color="rgba(15,15,15,0.3)" />
            <Typography
              variant="body1"
              color="rgba(15,15,15,0.3)"
              fontWeight={500}
            >
              New Deployment
            </Typography>
          </Box> */}

          {bots?.length > 0 &&
            bots?.map((bot) => (
              <Box onClick={() => handleOnConfigure(bot._id)} key={bot._id}>
                <Grow in={true} timeout={300} key={bot._id} mountOnEnter>
                  <Box className={styles.card}>
                    <FlexBox sx={{ width: "100%" }}>
                      <Box className={styles.imageContainer}>
                        <img src={YaraImage} alt="Yara bot" />
                      </Box>
                      <Box display="flex" gap={1} ml="auto">
                        {bot.default && (
                          <Box
                            className={styles.roundedBox}
                            sx={{ background: "#f5f5f5" }}
                          >
                            <Typography
                              variant="caption"
                              fontSize={10}
                              color="#0f0f0f"
                            >
                              default
                            </Typography>
                          </Box>
                        )}
                        <Box
                          className={styles.roundedBox}
                          sx={{ background: "#CCF6E7" }}
                        >
                          <Typography
                            variant="caption"
                            fontSize={10}
                            color="#006B46"
                          >
                            active
                          </Typography>
                        </Box>
                      </Box>
                    </FlexBox>
                    <Typography variant="subtitle1" fontWeight={500} mt={4}>
                      {bot?.name}
                    </Typography>

                    {/* <Box my={4}>
                    <FlexBox columnGap={1}>
                      <Typography variant="caption" color="#0f0f0fb3">
                        Status
                      </Typography>
                    </FlexBox>

                    <Typography variant="body2" fontWeight={500}>
                      Test
                    </Typography>
                  </Box> */}

                    <Button
                      variant="outlined"
                      endIcon={<ChevronRight width={12} height={12} />}
                      sx={{ borderRadius: 82, minWidth: 0, mt: "auto" }}
                    >
                      Configure
                    </Button>
                  </Box>
                </Grow>
              </Box>
            ))}
        </Box>
      </Box>

      {/* Configure Drawer */}
      <ConfigureBot
        open={configureBotDrawer}
        onClose={handleOnCloseConfigureDrawer}
        botData={selectedBot}
        onSave={(data) => handleSave(data)}
      />
    </PageWrapper>
  );
};

export default Bots;
