import {
  Box,
  Button,
  Collapse,
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Trash2, X } from "react-feather";
import classes from "./index.module.css";
import theme from "theme";
import { ChevronRight, IconSixVeritcalDots } from "components/SVG";
import { useEffect, useRef, useState } from "react";
import { PlusCircle } from "react-feather";
import createNewParentCategoryApi from "services/createNewParentCategory";
import { useSkillrToast } from "context/toast";
import { SkillrButton } from "components";
import getParentCategoriesApi from "services/getParentCategories";
import EmptyState from "components/EmptyState";
import editCategoryApi from "services/editCategory";
import Loader from "components/Loader";
import deleteCategoryApi from "services/deleteCategory";
import deleteParentCategoryApi from "services/deleteParentCategory";
import DeleteModal from "components/DeleteModal";

const ManageCategories = (props) => {
  const { open, onClose } = props;
  const { showSkillrToast } = useSkillrToast();
  const [newCategoryStatus, setNewCategoryStatus] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [parentCategories, setParentCategories] = useState([]);
  const dragDropRef = useRef({
    start: {},
    enter: {},
    end: {},
  });
  const [deleteModal, setDeleteModal] = useState({
    title: "",
    subTitle: "",
    list: [],
    status: false,
  });
  const [selectedDetails, setSelectedDetails] = useState();

  const handleOnClose = () => {
    onClose();
  };

  const getParentCategories = async () => {
    try {
      setLoading(true);
      const allParentCategories = await getParentCategoriesApi();
      setParentCategories(allParentCategories);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const editCategory = async (categoryId, apiData) => {
    try {
      await editCategoryApi(categoryId, apiData);
      // getParentCategories();
    } catch (error) {
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const onDragStart = (event, dragParentIndex, dragChildrenIndex) => {
    dragDropRef.current.start.dragParentIndex = dragParentIndex;
    dragDropRef.current.start.dragChildrenIndex = dragChildrenIndex;
    event.dataTransfer.setData(
      "dropData",
      JSON.stringify({ dragParentIndex, dragChildrenIndex })
    );
  };

  const onDragEnter = (event, dragParentIndex, dragChildrenIndex) => {
    dragDropRef.current.enter.dragParentIndex = dragParentIndex;
    dragDropRef.current.enter.dragChildrenIndex = dragChildrenIndex;
  };

  const onDragOver = (event, dragId) => {
    // console.log("onDragOver");
  };

  const onDragEnd = (event, dragParentIndex, dragChildrenIndex) => {
    if (
      dragDropRef.current.start.dragParentIndex !==
      dragDropRef.current.enter.dragParentIndex
    ) {
      const newCategories = [...parentCategories];
      const grabbedParentIndex = dragDropRef.current.start.dragParentIndex;
      const targetParentIndex = dragDropRef.current.enter.dragParentIndex;

      const grabbedChildrenIndex = dragDropRef.current.start.dragChildrenIndex;
      const targetChildrenIndex = dragDropRef.current.enter.dragChildrenIndex;

      const [grabbedItem] = newCategories[grabbedParentIndex].categories.splice(
        grabbedChildrenIndex,
        1
      );

      newCategories[targetParentIndex].categories.splice(
        targetChildrenIndex,
        0,
        grabbedItem
      );

      editCategory(grabbedItem?._id, {
        parentCategory: parentCategories[targetParentIndex]._id,
      });
      setParentCategories([...newCategories]);
    }
  };

  const handleOnDrop = (event, dropParentIndex) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("dropData");
    if (data) {
      const dataObject = JSON.parse(data);
      let newCategory = [...parentCategories];

      if (newCategory[dropParentIndex].categories.length > 0) {
        newCategory[dropParentIndex].categories.push(
          parentCategories[dataObject?.dragParentIndex].categories[
            dataObject?.dragChildrenIndex
          ]
        );
      } else {
        newCategory[dropParentIndex].categories = [
          parentCategories[dataObject?.dragParentIndex].categories[
            dataObject?.dragChildrenIndex
          ],
        ];
      }

      const [grabbedItem] = newCategory[
        dataObject?.dragParentIndex
      ].categories.splice(dataObject?.dragChildrenIndex, 1);

      editCategory(grabbedItem._id, {
        parentCategory: parentCategories[dropParentIndex]._id,
      });
      setParentCategories([...newCategory]);
    }
  };

  const handleOnDragOver = (event) => {
    event.preventDefault();
  };

  const handleOnParentDragOver = (event) => {
    event.preventDefault();
  };

  const handleCategoryDropDown = (parentIndex) => {
    let newCategories = [...parentCategories];
    newCategories[parentIndex].show = !newCategories[parentIndex].show;
    setParentCategories([...newCategories]);
  };

  const createNewCategory = (newCategoryDetails) => {
    return createNewParentCategoryApi(newCategoryDetails);
  };

  const handleCreateNewCategory = async () => {
    try {
      setLoading(true);
      const apiData = {
        name: newCategoryName,
      };
      await createNewCategory(apiData);
      getParentCategories();
      setLoading(false);
      setNewCategoryName("");
      setNewCategoryStatus(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleDeleteCategory = async (categoryDetails) => {
    try {
      const deleteCategoryResponse = await deleteCategoryApi(categoryDetails);
      return deleteCategoryResponse;
    } catch (error) {
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleDeleteParentCategory = async (parentCategoryDetails) => {
    try {
      const deleteCategoryResponse = await deleteParentCategoryApi(
        parentCategoryDetails
      );
      return deleteCategoryResponse;
    } catch (error) {
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleOnDeleteCloseModal = () => {
    setDeleteModal({ ...deleteModal, status: false });
    setSelectedDetails();
  };

  const handleOnDeleteConfirm = async () => {
    if (selectedDetails?.type === "category") {
      await handleDeleteCategory(selectedDetails?.id);
    } else {
      await handleDeleteParentCategory(selectedDetails?.id);
    }
    setSelectedDetails();
    getParentCategories();
  };

  const handleOnCategoryDelete = (parentCategoryIndex, categoryIndex) => {
    const categoryId =
      parentCategories[parentCategoryIndex].categories[categoryIndex]._id;
    setSelectedDetails({ type: "category", id: categoryId });
    setDeleteModal({
      ...deleteModal,
      title: "Delete Category?",
      subiTitle:
        "This action can't be undone and the data will be deleted from your account permanently",
      list: ["This will move all the category question to default category"],
      status: true,
    });
  };

  const handleOnParentCategoryDelete = (parentCategoryIndex) => {
    const parentCategoryId = parentCategories[parentCategoryIndex]._id;
    setSelectedDetails({ type: "parentCategory", id: parentCategoryId });
    setDeleteModal({
      ...deleteModal,
      title: "Delete Parent Category?",
      subTitle:
        "This action can't be undone and the data will be deleted from your account permanently",
      list: ["This will move all the sub category to General category"],
      status: true,
    });
  };

  useEffect(() => {
    getParentCategories();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleOnClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
            width: 650,
            minHeight: 650,
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" position="relative">
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={handleOnClose}
          >
            <X width={20} height={20} />
          </IconButton>
        </Box>
        <Box className={classes.container}>
          <Typography
            textAlign="center"
            variant="h3"
            fontWeight={500}
            fontSize={26}
          >
            Manage Categories
          </Typography>

          <Typography
            variant="body2"
            textAlign="center"
            color={theme.palette.grey[600]}
          >
            Deleting any category will re-assign the intents to default
          </Typography>

          <Box mt={4}>
            {newCategoryStatus ? (
              <TextField
                autoFocus
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                sx={{ "&>div": { borderRadius: 82 } }}
                fullWidth
                placeholder="e.g. New category"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SkillrButton
                        onClick={handleCreateNewCategory}
                        disabled={newCategoryName?.length > 0 ? false : true}
                        loading={loading}
                        style={{ minWidth: 0 }}
                        variant="text"
                        // startIcon={
                        //   <PlusCircle
                        //     width={16}
                        //     height={16}
                        //     fill={
                        //       newCategoryName?.length > 0
                        //         ? theme.palette.primary.main
                        //         : theme.palette.primary.light
                        //     }
                        //     stroke="white"
                        //   />
                        // }
                      >
                        Create
                      </SkillrButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Button
                variant="outlined"
                fullWidth
                startIcon={<PlusCircle width={16} height={16} />}
                sx={{ justifyContent: "flex-start", borderRadius: 82 }}
                onClick={() => setNewCategoryStatus(true)}
              >
                Create new parent category
              </Button>
            )}
          </Box>

          {loading && <Loader />}

          {!loading && (
            <Box overflow="auto">
              {parentCategories.map((p, parentIndex) => (
                <Box key={p._id}>
                  <Box
                    className={classes.parentCategory}
                    onDrop={(event) => handleOnDrop(event, parentIndex)}
                    onDragOver={handleOnParentDragOver}
                  >
                    <IconButton
                      onClick={() => handleCategoryDropDown(parentIndex)}
                    >
                      <ChevronRight
                        width={16}
                        height={16}
                        color="#0f0f0f"
                        className={classes.chevron}
                        style={{ transform: p?.show && "rotate(90deg)" }}
                      />
                    </IconButton>
                    <Typography
                      variant="caption"
                      color={theme.palette.text.primary}
                    >
                      {p.name}
                    </Typography>

                    <Box ml="auto">
                      <Typography variant="caption" fontSize={10}>
                        {p.categories.length} sub-categories
                      </Typography>
                      <IconButton
                        sx={{ marginLeft: 3 }}
                        disabled={!p.allowDelete}
                        onClick={() =>
                          handleOnParentCategoryDelete(parentIndex)
                        }
                      >
                        <Trash2 width={16} height={16} />
                      </IconButton>
                    </Box>
                  </Box>

                  <Collapse in={p?.show} unmountOnExit={true}>
                    {p?.categories?.length === 0 && (
                      <Box className={classes.emptyCategories}>
                        <Box
                          onDrop={(event) => handleOnDrop(event, parentIndex)}
                          onDragOver={(event) =>
                            handleOnDragOver(event, parentIndex)
                          }
                          sx={{
                            flex: 1,
                            border: "2px dashed #aaa",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          <Typography variant="caption">
                            Drop new categories
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {p?.categories?.length > 0 && (
                      <Box
                        onDragOver={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          const container = event.currentTarget;
                          const containerRect =
                            container.getBoundingClientRect();
                          const offsetBottom =
                            containerRect.bottom - event.clientY;
                          if (offsetBottom < 50) {
                            container.scrollTop += 10;
                          }
                        }}
                      >
                        {p.categories.map((child, childIndex) => (
                          <Box
                            className={classes.childrenCategory}
                            sx={
                              child.name === "default"
                                ? { cursor: "not-allowed" }
                                : { cursor: "grabbing" }
                            }
                            key={child.id}
                            draggable={child.name !== "default"}
                            onDragStart={(event) =>
                              onDragStart(event, parentIndex, childIndex)
                            }
                            onDragEnter={(event) =>
                              onDragEnter(event, parentIndex, childIndex)
                            }
                            onDragEnd={(event) =>
                              onDragEnd(event, parentIndex, childIndex)
                            }
                            onDragOver={(event) =>
                              onDragOver(event, parentIndex, childIndex)
                            }
                          >
                            <IconSixVeritcalDots
                              width={16}
                              height={16}
                              style={
                                child.name === "default"
                                  ? { opacity: 0.3 }
                                  : { opacity: 1 }
                              }
                            />

                            <Typography variant="caption">
                              {child.name}
                            </Typography>
                            <Box ml="auto">
                              <IconButton
                                sx={{ marginLeft: 3 }}
                                disabled={!child.allowDelete}
                                onClick={() =>
                                  handleOnCategoryDelete(
                                    parentIndex,
                                    childIndex
                                  )
                                }
                              >
                                <Trash2 width={16} height={16} />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Collapse>
                </Box>
              ))}
            </Box>
          )}

          {!loading && parentCategories?.length === 0 && (
            <Box mt={6}>
              <EmptyState title="No parent categories found" />
            </Box>
          )}
        </Box>
        <DeleteModal
          open={deleteModal.status}
          onClose={handleOnDeleteCloseModal}
          onConfirm={handleOnDeleteConfirm}
          title={deleteModal.title}
          subtitle={deleteModal.subTitle}
          list={deleteModal.list}
        />
      </Dialog>
    </>
  );
};

export default ManageCategories;
