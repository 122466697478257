// import axios from "axios";
import { getRequest } from "./common";

const downloadQuestionAnswerCsv = (datasetId, queryParams, config = {}) => {
  return getRequest(
    `/csv/download/questionAnswers/${datasetId}?${queryParams}`,
    config
  );
};

export default downloadQuestionAnswerCsv;
