import { FileCopy, FileCopyOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import { IconCopy } from "components/SVG";
import { useUserContext } from "context/user";
import { useEffect, useState } from "react";
import { Copy, Key } from "react-feather";

const CompanySettings = () => {
  const { companyDetails } = useUserContext();
  const [companyName, setCompanyName] = useState();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  };

  useEffect(() => {
    setCompanyName(companyDetails?.name);
  }, [companyDetails]);

  return (
    <Box width="75%" margin={"0 auto"}>
      <Typography variant="h3" fontWeight={500} textAlign={"center"} mb={8}>
        Company Settings
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        Company name
      </Typography>

      <TextField
        variant="outlined"
        fullWidth
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
      />

      <Typography variant="subtitle2" gutterBottom mt={4}>
        Access key
      </Typography>

      <FlexBox>
        <TextField
          variant="outlined"
          fullWidth
          disabled
          value={companyDetails?.accessKey}
          InputProps={{
            endAdornment: companyDetails?.accessKey ? (
              <Tooltip title="Copy to clipboard">
                <IconButton
                  onClick={() => copyToClipboard(companyDetails?.accessKey)}
                >
                  <IconCopy />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Generate access key">
                <IconButton>
                  <Key />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </FlexBox>

      <FlexBox justifyContent="center" mt={4}>
        <Button variant="dark" disabled={companyName === companyDetails?.name}>
          Save
        </Button>
      </FlexBox>
    </Box>
  );
};

export default CompanySettings;
